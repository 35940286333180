import React from 'react';
import {Box, BoxProps, Tag, TagCloseButton, TagLabel, Wrap, WrapItem} from '@chakra-ui/react';
import kebabCase from 'lodash/kebabCase';

import {SearchOptions, useSearch, useLocale} from 'shared';
import {AddIcon, Button, EditIcon, RemoveIcon} from 'components/core';
import {ScanQuery} from 'models/scan-query';

export const SearchDescription = ({
  searchOptions,
  addQueryFilter,
  ...props
}: {
  searchOptions: SearchOptions;
  addQueryFilter?: () => void;
} & BoxProps) => {
  const {removeQueryFilter, removeAllQueryFilters} = useSearch({searchOptions});
  const query = ScanQuery.fromJSON(searchOptions.query);
  const fields = query.describe();

  return (
    <Box {...props}>
      <Wrap align="center" spacing={2}>
        {fields.map(({field, operator, value}, index) => (
          <WrapItem key={field}>
            <Tag colorScheme="primary">
              <TagLabel>
                <FieldDescription field={field} operator={operator} value={value} />
              </TagLabel>
              <TagCloseButton onClick={() => removeQueryFilter(field)} />
            </Tag>
          </WrapItem>
        ))}
        <WrapItem>
          {addQueryFilter && (
            <Button
              size="sm"
              h="24px"
              onClick={addQueryFilter}
              rightIcon={
                fields.length ? (
                  <EditIcon fontSize="16px" color="gray.400" />
                ) : (
                  <AddIcon fontSize="16px" color="gray.400" />
                )
              }
            >
              {fields.length ? 'Edit filters' : 'Add search filters'}
            </Button>
          )}
          {fields.length > 1 && (
            <Button
              size="sm"
              h="24px"
              onClick={removeAllQueryFilters}
              ml={2}
              rightIcon={<RemoveIcon fontSize="20px" color="gray.400" />}
            >
              Clear all
            </Button>
          )}
        </WrapItem>
      </Wrap>
    </Box>
  );
};

const FieldDescription = ({field, operator, value}) => {
  const locale = useLocale();
  const fieldLabel = locale.scanFields[field] || kebabCase(field);

  if (field === 'freeText') return <>{value}</>;

  switch (operator) {
    case 'is-not':
      return <>{`${fieldLabel}\u2009≠\u2009${value}`}</>;
    case 'is-greater-than':
      return <>{`${fieldLabel}\u2009>\u2009${value}`}</>;
    case 'is-lower-than':
      return <>{`${fieldLabel}\u2009<\u2009${value}`}</>;
    case 'is-empty':
      return <>{`${fieldLabel} is empty`}</>;
    case 'is-not-empty':
      return <>{`${fieldLabel} is not empty`}</>;
    case 'is':
    default:
      return <>{`${fieldLabel}\u2009=\u2009${value}`}</>;
  }
};
