import {useState} from 'react';
import {createContainer} from 'unstated-next';
import {useLatest} from 'react-use';
import OpenSeadragon from 'openseadragon';

import {useKeyHandler} from 'components/core/key-handler';

export const PANNING_MODE = 'panning';

export function useImageViewerState(initialState) {
  const [mode, setMode] = useState(PANNING_MODE);
  const [isQuickPanning, setIsQuickPanning] = useState(false);
  const [isBusy, setIsBusy] = useState(false); // TODO: to set up a "loading" state and prevent any action
  const [zenMode, setZenMode] = useState(false);
  const zenModeLatest = useLatest(zenMode);

  useKeyHandler({
    viewId: (initialState.image as Datastore.Image).id,
    value: 'h',
    onDown: () => {
      setZenMode(!zenModeLatest.current);
    }
  });

  return {
    image: initialState.image as Datastore.Image,
    scan: initialState.scan as Datastore.Scan,
    getViewer: initialState.getViewer as () => OpenSeadragon.Viewer & {zoomPerClick: number},
    mode,
    setMode,
    isQuickPanning,
    setIsQuickPanning,
    isBusy,
    setIsBusy,
    zenMode,
    setZenMode
  };
}

export const ImageContainer = createContainer(useImageViewerState);

export type ImageViewerState = ReturnType<typeof useImageViewerState>;
