import {OwnedItem} from 'models/owned-item';
import {User} from 'models/user';

export class Annotation extends OwnedItem {
  constructor(data, {deserialize, ...otherOptions} = {}) {
    data = {...data};

    if (deserialize && data.verifiedOn) {
      data.verifiedOn = new Date(data.verifiedOn);
    }

    if (data.verifier) {
      data.verifier = new User(data.verifier, {deserialize, ...otherOptions});
    }

    super(data, {deserialize, ...otherOptions});
  }
}

export function createSVGPath(path, autoClose) {
  if (path.length) {
    let pathString = '';
    let suffix = '';

    const [first, ...rest] = path;

    if (rest.length) {
      pathString = ' L ' + rest.map(({x, y}) => `${x} ${y}`).join(',');

      if (autoClose) {
        suffix = ' Z';
      }
    }

    pathString = `M ${first.x} ${first.y}${pathString}${suffix}`;

    return pathString;
  }

  return 'M 0 0';
}

/*
Compare the position of 2 points
*/
export function areTheSamePoint(a, b) {
  return a.x === b.x && a.y === b.y;
}

/*
Return the point that belongs to both paths (the "connection" between the paths)
*/
export function getConnectionPoint(path1, path2) {
  return path1.find((pt1) => path2.find((pt2) => areTheSamePoint(pt1, pt2)));
}

/*
Merge an array of paths
*/
export function concatenatePaths(paths) {
  return paths.reduce((acc, path) => _concatenatePaths(acc, path), []);
}

/*
Merge 2 paths together
*/
function _concatenatePaths(path1, path2) {
  if (!path1.length) {
    return path2;
  }

  const connectedPoint = getConnectionPoint(path1, path2);
  if (!connectedPoint) {
    throw new Error('Unable to concatenate 2 paths that are not connected');
  }

  const isConnectedAtTheStart = (path) => areTheSamePoint(connectedPoint, path[0]);

  const reverse = (path) => {
    path = path.slice();
    path.reverse();
    return path;
  };

  if (isConnectedAtTheStart(path1)) {
    path1 = reverse(path1);
  }
  if (!isConnectedAtTheStart(path2)) {
    path2 = reverse(path2);
  }

  return [...path1, ...path2.slice(1)]; // removing the connection from the second part to avoid duplicate points
}

export function round(number, precision = 2) {
  const multiplier = Math.pow(10, precision);
  return Math.round(number * multiplier) / multiplier;
}

export function getDistance(a, b) {
  return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
}

export function pathIncludesPoint(path, point) {
  return path.some((pt) => areTheSamePoint(point, pt));
}

export function convertPercentPointToImagePoint({x, y}, {width, height}) {
  return {
    x: (x * width) / 100,
    y: (y * height) / 100
  };
}

/*
Takes a single path, an array of points to erase and return an array of paths
Used to display paths on the screen while the user is erasing
and to compute the paths to update when calling the backend.
*/
export function erasePoints(annotationPath, erasedPoints) {
  let paths = split(annotationPath, erasedPoints);
  paths = rotate(paths);
  paths = paths.filter((path) => path.length > 1); // a path is made of 2 points at least
  return paths;
}

/*
Split a given path into N paths, removing the erased points
path: [1,2,3,4,5,6,7], erasedPoints: [3,6] => expected: [[1,2], [4,5], [7]],,
*/
function split(path, erasedPoints) {
  let chunk = [];
  const result = [];

  for (const element of path) {
    if (pathIncludesPoint(erasedPoints, element)) {
      if (chunk.length) {
        result.push(chunk);
      }
      chunk = [];
    } else {
      chunk.push(element);
    }
  }

  if (chunk.length > 0) {
    result.push(chunk); // don't forget the last chunk!
  }

  return result;
}

/*
Combine an array of paths (result of a split), rotating the points without changing the order
[[1,2], [4,5,1]] => [[4,5,1,2]]
*/
function rotate(paths) {
  if (paths.length < 2) {
    return paths;
  }
  const firstChunk = paths[0];
  const lastChunk = paths[paths.length - 1];
  const firstElement = firstChunk[0];
  const lastElement = lastChunk[lastChunk.length - 1];

  const middle = paths.slice(1, -1);
  const mergedLastChunk = [...lastChunk, ...firstChunk.slice(1)];

  return areTheSamePoint(firstElement, lastElement) ? [...middle, mergedLastChunk] : paths;
}
