import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useToast} from '@chakra-ui/react';
import invariant from 'tiny-invariant';

import {ItemSelection, formatSearchParams, parseSearchParams, useApp, useLocale} from 'shared';
import {ScanQuery} from 'models/scan-query';
import {useModal} from 'components/core';
import {DialogAddScan} from 'components/scan-form/dialog-add-scan';
import {DialogBulkAddWSILabel, DialogBulkRemoveWSILabel} from './dialog-bulk-wsi-label';
import {DialogAdvancedSearch} from './dialog-advanced-search';
import {DialogReplaceFields} from './dialog-replace-fields';
import {DialogEmptyFields} from './dialog-empty-fields';
import {DialogExportCSV} from './dialog-export-csv';
import {AIDataContainer} from 'components/settings/ai-labels-api';
import {useQueryClient} from 'react-query';

type Props = {
  selection: ItemSelection;
  setSelection?: (selection: ItemSelection) => void;
  reloadScans: () => any;
  total: number;
};
export function useScanListActions({selection, setSelection, total, reloadScans}: Props) {
  const locale = useLocale();
  const app = useApp();
  const location = useLocation();
  const modal = useModal();
  const toast = useToast();
  const queryClient = useQueryClient();

  const searchParams = parseSearchParams(location.search);
  const query = ScanQuery.fromJSON(searchParams.query);
  const numberOfItems = selection.getNumberOfItems(total);

  const addScans = async () => {
    await modal.dialog({
      render: (close) => <DialogAddScan onClose={close} />,
      modalProps: {size: '3xl'}
    });
    reloadScans();
  };

  const deleteScans = async () => {
    if (
      !(await modal.confirm(
        locale.todo(`Are you sure you want to delete ${numberOfItems} scan(s)?`),
        {
          title: locale.warningDialogTitle,
          okButton: locale.todo('Delete')
        }
      ))
    ) {
      return;
    }
    const done = await app.task(async () => {
      await app.deleteScans({query, selection});
    });
    if (!done) return;
    toast({
      title: 'Delete scans',
      description: `${numberOfItems} scan(s) were deleted`,
      status: 'success',
      isClosable: true
    });
    if (setSelection) setSelection(new ItemSelection());
    reloadScans();
    queryClient.invalidateQueries('scan'); // invalidate queries used by other panes in the split layout
  };

  const replaceScans = async () => {
    const changes = await modal.dialog({
      render: (close) => (
        <DialogReplaceFields numberOfItems={numberOfItems} defaultValues={{}} onClose={close} />
      ),
      modalProps: {size: '3xl'}
    });
    if (!changes) return;
    const done = await app.task(async () => {
      await app.updateScans({query, selection, changes});
    });
    if (!done) return;
    toast({
      title: 'Replace fields',
      description: `${numberOfItems} scan(s) were updated`,
      status: 'success',
      isClosable: true
    });
    if (setSelection) setSelection(new ItemSelection());
    reloadScans();
    queryClient.invalidateQueries('scan'); // invalidate queries used by other panes in the split layout
  };

  const emptyScanFields = async () => {
    const fieldsToEmpty = await modal.dialog({
      render: (close) => <DialogEmptyFields numberOfItems={numberOfItems} onClose={close} />,
      modalProps: {size: 'xl'}
    });
    if (!fieldsToEmpty) return;

    const changes = fieldsToEmpty
      .map(({name}) => name)
      .reduce((acc, name) => ({...acc, [name]: null}), {});

    const done = await app.task(async () => {
      await app.updateScans({query, selection, changes});
    });
    if (!done) return;
    toast({
      title: 'Empty fields',
      description: `${numberOfItems} scan(s) were updated`,
      status: 'success',
      isClosable: true
    });
    if (setSelection) setSelection(new ItemSelection());
    reloadScans();
    queryClient.invalidateQueries('scan'); // invalidate queries used by other panes in the split layout
  };

  const exportCSV = async () => {
    await modal.dialog({
      render: (close) => (
        <DialogExportCSV selection={selection} onClose={close} searchOptions={searchParams} />
      ),
      modalProps: {size: '3xl'}
    });
  };

  const {data} = AIDataContainer.useContainer();
  const addBulkWSILabels = async () => {
    invariant(data);
    const {labels, models} = data;
    const count = await modal.dialog({
      render: (close) => (
        <DialogBulkAddWSILabel
          query={query}
          selection={selection}
          numberOfItems={numberOfItems}
          onClose={close}
          labels={labels}
          models={models}
        />
      ),
      modalProps: {size: '2xl'}
    });
    if (!count) return;
    toast({status: 'success', description: `${count} WSI labels added`});
    reloadScans();
    queryClient.invalidateQueries('image'); // invalidate queries used by other panes in the split layout
  };

  const removeBulkWSILabels = async () => {
    invariant(data);
    const {labels, models} = data;
    const count = await modal.dialog({
      render: (close) => (
        <DialogBulkRemoveWSILabel
          query={query}
          selection={selection}
          numberOfItems={numberOfItems}
          onClose={close}
          labels={labels}
          models={models}
        />
      ),
      modalProps: {size: '2xl'}
    });
    if (count === undefined) return;
    toast({
      status: 'info',
      description: count ? `${count} WSI label(s) removed` : 'No label to removed'
    });
    reloadScans();
    queryClient.invalidateQueries('image'); // invalidate queries used by other panes in the split layout
  };

  return {
    addScans,
    addBulkWSILabels,
    deleteScans,
    emptyScanFields,
    exportCSV,
    removeBulkWSILabels,
    replaceScans
  };
}

export function useScanSearch() {
  const history = useHistory();
  const location = useLocation();
  const modal = useModal();
  const trackSearchQuery = useTrackSearchQuery();
  const searchParams = parseSearchParams(location.search);
  const query = ScanQuery.fromJSON(searchParams.query);

  const quickSearch = async (text: string) => {
    query.setExpression('freeText', {value: text, operator: 'is'});
    searchParams.query = query.toJSON();
    searchParams.offset = 0;
    trackSearchQuery(query);
    history.replace({search: formatSearchParams(searchParams)});
  };

  const advancedSearch = async ({clearFreeText = false} = {}) => {
    // "Advanced Search" and "Add Search Filters" do the same thing
    // but only "Advanced Search" clears the current full text search
    // TODO improve the implementation
    if (clearFreeText) query.deleteExpression('freeText');

    const updatedScanQuery = await modal.dialog({
      render: (close) => <DialogAdvancedSearch query={query} onClose={close} />,
      modalProps: {size: '3xl'}
    });

    if (!updatedScanQuery) return;

    searchParams.query = updatedScanQuery;
    searchParams.offset = 0;

    trackSearchQuery(updatedScanQuery);
    history.replace({search: formatSearchParams(searchParams)});
  };

  return {quickSearch, advancedSearch};
}

function useTrackSearchQuery() {
  const app = useApp();
  const track = async (scanQuery: ScanQuery) => {
    const accessToken = app.getAccessToken();
    const backend = await app.getBackend();
    await backend.trackUserAction({
      type: 'SEARCH',
      accessToken,
      payload: {query: scanQuery.toJSON()}
    });
  };
  return track;
}
