import React, {forwardRef, useState} from 'react';
import {Radio, RadioGroup, Select, SelectProps, Stack} from '@chakra-ui/react';

import {KeywordsContainer, useApp, useLocale} from 'shared';
import {Autocomplete} from 'components/core';
import {splitTags} from 'models/model-utils';

type Props = {
  value: string;
  onChange: ({value, label}: {value: string; label: string}) => void;
};

// TODO forward ref
export const OrganPicker = (props: Props) => {
  const {getKeywords} = KeywordsContainer.useContainer();
  const items = getKeywords('organ');
  return <Autocomplete allowNewValues={false} items={items} {...props} />;
};

export const SupplierPicker = (props: Props) => {
  const {getKeywords} = KeywordsContainer.useContainer();
  const items = getKeywords('supplier');
  return <Autocomplete allowNewValues={false} items={items} {...props} />;
};

export const SpecimenTypePicker = (props: SelectProps) => {
  const {getKeywords} = KeywordsContainer.useContainer();
  const items = getKeywords('specimenType');

  return (
    <Select {...props}>
      <option value=""></option>
      {items.map((item) => (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </Select>
  );
};

export const StainingPicker = forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const {getKeywords} = KeywordsContainer.useContainer();
  const items = getKeywords('staining');
  return <Autocomplete allowNewValues={false} items={items} {...props} />;
});

type TagPickerProps = {value: string; onChange: (value: string) => void};
export const TagPicker = (props: TagPickerProps) => {
  const app = useApp();
  const {user} = app.state;
  const tagRestrictions = splitTags(user.tagRestrictions);
  if (tagRestrictions.length === 1) {
    return <>{props.value}</>;
  }
  return tagRestrictions.length === 0 ? (
    <AutocompleteFreeText path="tags" {...props} />
  ) : (
    <TagPickerRestricted {...props} items={tagRestrictions} />
  );
};

// Generic autocomplete field that allows users to enter anything
// Used for `tags`, `disease`, `scannedAt`, `protein` and `gene` fields
export const AutocompleteFreeText = (props: TagPickerProps & {path: string}) => {
  const locale = useLocale();
  const {path, onChange} = props;
  const {items, loadItems} = useScanExistingItems(path);
  return (
    <Autocomplete
      allowNewValues={true}
      value={locale.formatTextInput(props.value)}
      onChange={({value}) => {
        value = locale.parseTextInput(value);
        onChange(value);
      }}
      onInputChange={(value) => {
        if (!value) return;
        loadItems(value);
        onChange(locale.parseTextInput(value));
      }}
      items={normalizeItems(items)}
    />
  );
};
export const TagPickerRestricted = (props: TagPickerProps & {items: string[]}) => {
  const {value: tags, onChange, items} = props;
  return (
    <RadioGroup
      id="tags"
      value={tags}
      onChange={(value) => {
        onChange(value);
      }}
    >
      <Stack>
        {items.map((tag) => (
          <Radio key={tag} value={tag}>
            {tag}
          </Radio>
        ))}
      </Stack>
    </RadioGroup>
  );
};

export function useScanExistingItems(path: string) {
  const app = useApp();
  const [items, setItems] = useState<string[]>([]);
  const loadItems = async (value: string) => {
    const items = await app.getScanAutocomplete({field: path, value});
    setItems(items);
  };
  return {items, loadItems};
}

function normalizeItems(items?: string[]): {value: string; label: string}[] {
  return (items || []).map((item) => ({value: item, label: item}));
}
