import {Box, BoxProps, Flex} from '@chakra-ui/react';

type Props = {
  width: number;
  unit: string;
};
export const ScaleBar = ({width, unit}: Props) => {
  return (
    <Flex alignItems="center">
      <Bar width={width} mr={2} />
      <Box color="white" fontWeight="bold" textShadow="1px 1px black">
        {unit}
      </Box>
    </Flex>
  );
};

const Bar = ({width, ...props}: {width: number} & BoxProps) => {
  return (
    <Box
      width={`${width}px`}
      borderWidth="0 4px 4px 4px"
      h="8px"
      borderColor="white"
      boxShadow="xl"
      position="relative"
      transition="width 0.3s"
      {...props}
    >
      <Box
        borderWidth="0 2px 2px 2px"
        h="6px"
        borderColor="inverseBoxBackgroundColor"
        position="absolute"
        left="-3px"
        right="-3px"
        top="1px"
      />
    </Box>
  );
};
