import React from 'react';
import {Box, Icon, IconProps} from '@chakra-ui/react';
import {IoMdEye} from 'react-icons/io';
import {MdContentCopy, MdNotInterested} from 'react-icons/md';

import {useLocale} from 'shared';
import {CheckIcon, formatDateTime} from 'components/core';
import {useScanColumns} from 'components/scan-list/scan-table';

export const useQualityControlColumns = () => {
  const locale = useLocale();
  const {
    thumbnail,
    columns: {details, batch, supplier, createdOn}
  } = useScanColumns();
  const defaultColumns = [thumbnail({size: 80}), details, batch, supplier, createdOn];

  const verifiedOn = {
    id: 'verifiedOn',
    Header: 'Verified',
    accessor: (scan: Datastore.Scan) => {
      if (!scan.verifiedOn) return null;
      const date = new Date(scan.verifiedOn);
      return (
        <>
          {formatDateTime(date, {mask: 'yyyy-MM-dd'})}
          <Box mt={1} color="gray.500">
            {formatDateTime(date, {mask: 'HH:mm'})}
          </Box>
        </>
      );
    },
    styleProps: {
      width: '120px'
    }
  };

  const rejectionReason = {
    id: 'rejectionReason',
    Header: 'Reason',
    accessor: (scan: Datastore.Scan) => {
      return <>{locale.rejectionReasons[scan.rejectionReason]}</>;
    }
  };

  return {
    defaultColumns,
    verifiedOn,
    rejectionReason
  };
};

export const ToBeVerifiedIcon = (props: IconProps) => <Icon as={IoMdEye} {...props} />;
export const VerifiedIcon = (props: IconProps) => <CheckIcon {...props} />;
export const RejectedIcon = (props: IconProps) => <Icon as={MdNotInterested} {...props} />;
export const RescannedIcon = (props: IconProps) => <Icon as={MdContentCopy} {...props} />;
