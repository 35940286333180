import React, {useState} from 'react';
import {
  Alert,
  Box,
  Code,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  ListItem,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  UnorderedList,
  VStack,
  FormErrorMessage,
  Stack
} from '@chakra-ui/react';
import {useQueryClient} from 'react-query';

import {useLocale} from 'shared';
import {Button} from 'components/core';
import {useCreateAIModel, useDeleteAIModel} from 'components/settings/ai-labels-api';
import {AIModel} from 'components/settings/types';

type Props = {
  models: AIModel[];
  onClose: (displayName?: string) => void;
};
export const DialogCreateAIModel = ({models, onClose}: Props) => {
  const locale = useLocale();
  const queryClient = useQueryClient();
  const mutation = useCreateAIModel();
  const [displayName, setDisplayName] = useState('');

  const submit = () => {
    mutation.mutate(displayName, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['ai-labels']);
        onClose(displayName);
      }
    });
  };

  const isDuplicate = models.some(
    (model) => model.displayName.toLowerCase() === displayName.toLowerCase()
  );

  const isValidDisplayName = displayName.trim().length > 2;
  const isFormValid = isValidDisplayName && !isDuplicate;

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        submit();
      }}
    >
      <ModalHeader>Add AI model</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <VStack spacing={4}>
          <FormControl id="displayName" isInvalid={isDuplicate}>
            <FormLabel>Model name</FormLabel>
            <Input value={displayName} onChange={(event) => setDisplayName(event.target.value)} />
            {isDuplicate ? (
              <FormErrorMessage>A model with this name already exists</FormErrorMessage>
            ) : (
              <FormHelperText>
                Name of the category of labels to create E.g. "Stomach"
              </FormHelperText>
            )}
          </FormControl>

          {mutation.isError && !mutation.isLoading && (
            <Alert status="error">
              Unable to create the model {(mutation.error as Error)?.message}
            </Alert>
          )}
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button type="button" onClick={() => onClose()} mr={2} isDisabled={mutation.isLoading}>
          {locale.cancelButtonLabel}
        </Button>
        <Button
          type="submit"
          primary
          isDisabled={!isFormValid}
          isLoading={mutation.isLoading || !!queryClient.isFetching()}
        >
          Save
        </Button>
      </ModalFooter>
    </form>
  );
};

export const DialogDeleteAIModel = ({
  model,
  onClose
}: {
  model: AIModel;
  onClose: (isDeleted?: boolean) => void;
}) => {
  const locale = useLocale();
  const queryClient = useQueryClient();
  const mutation = useDeleteAIModel();

  const {id, displayName, name} = model;

  const submit = () => {
    mutation.mutate(id!, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['ai-labels']);
        onClose(true);
      }
    });
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        submit();
      }}
    >
      <ModalHeader>Delete AI model</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack spacing={4}>
          <Text>
            Are you sure you want to delete the model <b>{displayName}</b>?
          </Text>
          <Box>
            <UnorderedList>
              <ListItem>
                <Code>name</Code> = {name}
              </ListItem>
              <ListItem>
                <Code>displayName</Code> = {displayName}
              </ListItem>
              <ListItem>
                <Code>id</Code> = {id}
              </ListItem>
            </UnorderedList>
          </Box>
          {mutation.isError && !mutation.isLoading && (
            <Alert status="error">
              Unable to delete the model {(mutation.error as Error)?.message}
            </Alert>
          )}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button type="button" onClick={() => onClose()} mr={2} isDisabled={mutation.isLoading}>
          {locale.cancelButtonLabel}
        </Button>
        <Button
          type="submit"
          colorScheme="red"
          variant="solid"
          isLoading={mutation.isLoading || !!queryClient.isFetching()}
        >
          Delete
        </Button>
      </ModalFooter>
    </form>
  );
};
