import {ImageContainer} from 'components/image-viewer/image-container';
import {createPlugin} from '..';
import {RotationControl} from './rotation-control';

export function RotationPlugin() {
  return createPlugin({
    displayName: 'RotationPlugin',
    BottomArea() {
      const {getViewer} = ImageContainer.useContainer();
      return <RotationControl onChange={(angle) => getViewer().viewport.setRotation(angle)} />;
    }
  });
}
