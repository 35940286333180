import React from 'react';
import {Link} from '@chakra-ui/layout';

type Props = {
  message: React.ReactNode;
  info?: React.ReactNode;
};
/*
Don't use `react-router` link in this page that can be used as the fallback from `index.tsx`,
before the Router is mounted.
*/
export const Sorry = ({message, info}: Props) => {
  return (
    <div
      style={{
        margin: '0 auto',
        maxWidth: 900,
        paddingTop: '2rem',
        paddingBottom: '2rem',
        textAlign: 'center'
      }}
    >
      <h3>Sorry! 🙇</h3>
      <h4>{message}</h4>
      {info && <p>{info}</p>}
      <p>
        <Link href="/" textDecoration="underline" color="primary">
          Back to Datastore
        </Link>
      </p>
    </div>
  );
};

export const NotFound = () => <Sorry message="There's nothing at this address." />;
