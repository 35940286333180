import {useQuery} from 'react-query';
import {Box} from '@chakra-ui/react';
import {createContainer} from 'unstated-next';

import {useApp} from 'shared';

export type KeywordCategory = 'organ' | 'supplier' | 'specimenType' | 'staining';
export type KeywordItem = {
  id?: string;
  value: string;
  label: string;
  createdOn: string;
  updatedOn: string;
  deletedOn: string;
};

type KeywordData = Record<KeywordCategory, KeywordItem[]>;

function useKeywordsState() {
  const app = useApp();

  const loadKeywords = async () => {
    const accessToken = app.getAccessToken();
    const backend = await app.getBackend();
    const {keywords} = await backend.findKeywords({accessToken});
    return keywords;
  };
  const {data, isLoading} = useQuery<KeywordData, Error>('keywords', loadKeywords, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  const getKeywords = (category: KeywordCategory): KeywordItem[] => {
    return (data?.[category] || []).filter((item) => !item.deletedOn);
  };

  const getValues = (category: KeywordCategory) => {
    return getKeywords(category).map((item) => item.value);
  };

  return {keywords: data || [], getKeywords, getValues, isLoading};
}

export const KeywordsContainer = createContainer(useKeywordsState);

type Props = {
  category: KeywordCategory;
  value: string;
};
export const KeywordLabel = ({category, value}: Props) => {
  const {getKeywords} = KeywordsContainer.useContainer();
  if (!value) return null;
  const keywords = getKeywords(category);
  const item = keywords.find((item) => item.value === value);
  if (!item)
    return (
      <>
        {value}{' '}
        <Box as="span" color="gray.500">
          [deleted]
        </Box>
      </>
    );
  return <>{item.label}</>;
};
