import React from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import {
  Box,
  BoxProps,
  Link,
  LinkProps,
  Tag,
  useColorModeValue,
  Wrap,
  WrapItem
} from '@chakra-ui/react';

import {splitTags} from 'models/model-utils';
import {
  KeywordLabel,
  SearchQueryItem,
  formatSearchParams,
  useLocale,
  KeywordCategory
} from 'shared';
import {formatDateTime, TimeAgo} from 'components/core';
import {AIDataContainer, getAILabelByName} from 'components/settings/ai-labels-api';

export const CompactDate = ({date}: {date: Date}) => {
  if (!date) return null;
  const differenceInHours = (Date.now() - +date) / 1000 / 60 / 60;
  const isRecent = differenceInHours < 12;
  if (isRecent) {
    return (
      <>
        <TimeAgo date={date} />
        <Box mt={1} color="gray.500">
          {formatDateTime(date, {mask: 'HH:mm'})}
        </Box>
      </>
    );
  }
  const showTime = differenceInHours < 24 * 7;
  return (
    <>
      {formatDateTime(date, {mask: 'yyyy-MM-dd'})}
      {showTime && (
        <Box mt={1} color="gray.500">
          {formatDateTime(date, {mask: 'HH:mm'})}
        </Box>
      )}
    </>
  );
};

export const FilenameLink = ({scanId, filename}: {scanId: string; filename: string}) => {
  const location = useLocation();
  return (
    <Link
      as={RouterLink}
      to={{...location, pathname: `/scans/${scanId}`}}
      variant="primary"
      title={filename}
    >
      {filename || '(Deleted image)'}
    </Link>
  );
};

export const BatchNumberLink = ({batchNumber, ...props}: {batchNumber: number} & LinkProps) => {
  const location = useLocation();
  const query: SearchQueryItem[] = [
    {field: 'image.batch.number', value: batchNumber.toString(), operator: 'is'}
  ];
  const searchParams = formatSearchParams({query});

  if (!batchNumber) return <EmptyValue />;

  return (
    <Link as={RouterLink} to={{...location, pathname: '/scans', search: searchParams}} {...props}>
      #{batchNumber}
    </Link>
  );
};

export const ImageViewerLink = ({
  imageId,
  isAvailable = true,
  children
}: {
  imageId: string;
  isAvailable?: boolean;
  children: React.ReactNode;
}) => {
  const location = useLocation();
  if (!isAvailable) return <Box cursor="not-allowed">{children}</Box>;

  return <RouterLink to={{...location, pathname: `/images/${imageId}`}}>{children}</RouterLink>;
};

export const KeywordLink = ({category, value}: {category: KeywordCategory; value: string}) => {
  const location = useLocation();
  const query = [{field: category, value, operator: 'is'}];
  const searchParams = formatSearchParams({query});

  if (!value) return <EmptyValue />;

  return (
    <Link as={RouterLink} to={{...location, pathname: '/scans', search: searchParams}}>
      <KeywordLabel category={category} value={value} />
    </Link>
  );
};

export const TagLinkGroup = ({scanTags, ...props}: {scanTags: string}) => {
  const tags = splitTags(scanTags);
  if (!tags.length) return null;
  return (
    <Wrap>
      {tags.map((tag) => (
        <WrapItem key={tag}>
          <TagLink tag={tag} />
        </WrapItem>
      ))}
    </Wrap>
  );
};

export const TagLink = ({tag}: {tag: string}) => {
  const location = useLocation();
  const query = [{field: 'tags', value: tag, operator: 'is'}];
  const searchParams = formatSearchParams({query});
  const bg = useColorModeValue('primary.100', 'rgba(255, 255, 255, 0.2)');

  return (
    <Tag
      size="md"
      variant="subtle"
      fontWeight="normal"
      as={RouterLink}
      to={{...location, pathname: '/scans', search: searchParams}}
      _hover={{bg}}
    >
      {tag}
    </Tag>
  );
};

export const WSIAnnotationGroup = ({
  annotations,
  isCompact
}: {
  annotations: Datastore.WSIAnnotation[];
  isCompact?: boolean;
}) => {
  if (annotations.length === 0) return null;
  return (
    <>
      {annotations.map((annotation) => (
        <WSILabel
          key={annotation.labelName}
          labelName={annotation.labelName}
          isCompact={isCompact}
        />
      ))}
    </>
  );
};

export const WSILabel = ({labelName, isCompact}: {labelName: string; isCompact?: boolean}) => {
  const locale = useLocale();
  const {data} = AIDataContainer.useContainer();
  if (!data) return null;
  const {labels} = data;
  const label = getAILabelByName(labels, labelName);
  const displayName = label ? locale.get(label.displayName) : labelName;
  const color = label?.color || 'gray.500';

  if (isCompact) {
    return <WSILabelIcon color={color} />;
  }

  return (
    <Tag size="sm" bg="blue.800" color="white" opacity="0.8">
      <WSILabelIcon color={color} mr={1} />
      {displayName}
    </Tag>
  );
};

const WSILabelIcon = ({color, ...props}: {color: string} & BoxProps) => {
  return <Box bg={color} w="12px" h="12px" borderWidth="1px" borderColor="white" {...props} />;
};

export const EmptyValue = () => <Box color="gray.500">--</Box>;
