import {useQuery} from 'react-query';

import {useApp} from 'shared';
import {User} from 'models/user';

export function useFetchUsers(searchOptions) {
  const app = useApp();
  const {offset, limit} = searchOptions;

  const loadUsers = async () => {
    const accessToken = app.getAccessToken();
    const backend = await app.getBackend();
    let {users} = await backend.findUsers({offset, limit, accessToken});
    users = users.map((user) => new User(user, {deserialize: true}));
    return users;
  };

  return useQuery(['users', searchOptions], loadUsers, {
    cacheTime: 1000 * 60 * 60 // override the default duration of 5 minutes => 1 hour
  });
}
