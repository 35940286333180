import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import styled from '@emotion/styled';
import {Box, Badge, BoxProps, Icon, Link, Text} from '@chakra-ui/react';

import {CheckIcon, WarningIcon} from 'components/core';
import {ScanRecord} from './import-csv-container';
import {DataGrid} from './data-grid';

export type ScanEditableFieldName =
  | 'comments'
  | 'customField1'
  | 'customField2'
  | 'customField3'
  | 'disease'
  | 'organ'
  | 'specimenType'
  | 'tags';

export type ScanFieldName = 'imageFilename' | 'supplier' | ScanEditableFieldName;

type Props = {
  records: ScanRecord[];
  fields: string[];
} & BoxProps;

export const RecordTable = ({records, fields, ...props}: Props) => {
  const dataColumns = fields.map((field) => optionalColumnsByKey[field]);
  const columns = [
    {
      Header: 'Filename',
      accessor: (record) => {
        const {scanId, imageFilename} = record;
        if (!scanId) return <>{imageFilename}</>;
        return (
          <Link variant="primary" as={RouterLink} to={`/scans/${scanId}`}>
            {imageFilename}
          </Link>
        );
      }
    },
    {
      Header: 'Supplier',
      accessor: (record) => record.supplier
    },
    ...dataColumns,
    {
      Header: 'Status',
      accessor: (record) => <CellStatus record={record} />,
      className: 'status'
    }
  ];
  const getRowProps = (row) => {
    const record = row.original;
    const {previewStatus, status} = record;
    if (previewStatus === 'not-valid' || status === 'failed') return {className: 'is-error'};
    if (status === 'updated') return {className: 'is-success'};
    return {};
  };
  return (
    <>
      <Styles {...props}>
        <DataGrid data={records} columns={columns} getRowProps={getRowProps} size="sm" mb={4} />
      </Styles>
    </>
  );
};

const Styles = styled(Box)`
  .status {
    width: 120px;
  }
  .is-error {
    td {
      background-color: ${(props) => (props.theme as any).colors.red['50']};
    }
  }
  .is-success {
    td {
      background-color: ${(props) => (props.theme as any).colors.green['50']};
    }
  }
`;

const CellStatus = ({record}: {record: ScanRecord}) => {
  const {status, previewStatus} = record;

  const getErrorMessage = () => {
    if (record.errorType === 'CASE_NUMBER_NOT_FOUND') {
      return 'Not found';
    }
    if (record.errorType === 'DUPLICATE_CASE_NUMBER') {
      return 'Duplicate';
    }
    return 'Error';
  };

  if (status === 'updated') {
    return (
      <Box color="green.700">
        <Icon as={CheckIcon} /> Updated
      </Box>
    );
  }
  if (previewStatus === 'not-valid' || status === 'failed') {
    return (
      <Box color="red.700">
        <Icon as={WarningIcon} /> {getErrorMessage()}
      </Box>
    );
  }
  if (previewStatus === 'valid') {
    return (
      <Box color="primary.500">
        <Icon as={CheckIcon} /> Ready
      </Box>
    );
  }
  return <>{status}</>;
};

const optionalColumnsByKey: Record<ScanEditableFieldName, any> = {
  comments: {
    Header: 'Comments',
    accessor: ({data}) => data.comments
  },
  customField1: {
    Header: 'CustomField1',
    accessor: ({data}) => <Text maxHeight="100px">{data.customField1}</Text>
  },
  customField2: {
    Header: 'CustomField2',
    accessor: ({data}) => data.customField2
  },
  customField3: {
    Header: 'CustomField3',
    accessor: ({data}) => data.customField3
  },
  disease: {
    Header: 'Disease',
    accessor: ({data}) => data.disease
  },
  organ: {
    Header: 'Organ',
    accessor: ({data}) => data.organ
  },
  specimenType: {
    Header: 'Type of Specimen',
    accessor: ({data}) => data.specimenType
  },
  tags: {
    Header: 'Tags',
    accessor: ({data}) => {
      const tags = data.tags?.split(',') || [];
      return tags.map((tag, index) => (
        <div key={index}>
          <Badge whiteSpace="normal" variant="outline">
            {tag}
          </Badge>
        </div>
      ));
    }
  }
};
