import React, {forwardRef, useEffect, useState} from 'react';
import {
  Box,
  IconButton,
  Input,
  InputProps,
  InputGroup,
  InputRightElement,
  HStack
} from '@chakra-ui/react';
import {useCombobox, UseComboboxProps, UseComboboxStateChangeTypes} from 'downshift';

import {CheckIcon, ChevronDownIcon, CloseIcon} from './icons';

const borderColor = `var(--chakra-colors-gray-200)`;

type Item = {value: string; label: string};

type Props = Omit<InputProps, 'onChange'> & {
  value: string;
  items: Item[];
  onChange: (item: Item) => void;
  onInputChange?: (value?: string) => void;
  allowNewValues?: boolean;
};

export const Autocomplete = forwardRef<HTMLInputElement, Props>(
  ({items, value, onChange, onInputChange, allowNewValues = false}, ref) => {
    const [inputItems, setInputItems] = useState(items);
    const itemToString = (item) => item?.label || '';

    const getDefaultSelectedItem = () => {
      if (!value) return undefined;
      return items.find((item) => item.value === value) || {value, label: value};
    };

    const {
      selectItem,
      isOpen,
      getToggleButtonProps,
      getMenuProps,
      getInputProps,
      getComboboxProps,
      highlightedIndex,
      getItemProps
    } = useCombobox<Item>({
      items: inputItems,
      defaultSelectedItem: getDefaultSelectedItem(),
      itemToString,
      onInputValueChange: (params) => {
        const {inputValue} = params;
        if (onInputChange) onInputChange(inputValue);
        setInputItems(
          allowNewValues
            ? items
            : items.filter((item) => item.label.toLowerCase().startsWith(inputValue!.toLowerCase()))
        );
      },
      stateReducer: allowNewValues ? inputStateReducer : selectStateReducer,
      onSelectedItemChange: ({selectedItem}) => {
        onChange(selectedItem!);
      }
    });

    useEffect(() => {
      if (allowNewValues) {
        setInputItems(items);
      }
    }, [allowNewValues, items]);

    return (
      <Box position="relative">
        <div {...getComboboxProps()}>
          <InputGroup>
            <Input
              {...getInputProps({ref})}
              px="10px"
              borderColor={borderColor}
              lineHeight="20px"
            />
            <InputRightElement width="auto">
              <HStack mr={2} spacing={1}>
                {value !== '' && (
                  <IconButton
                    aria-label="Clear selection"
                    onClick={() => {
                      selectItem({value: '', label: ''});
                    }}
                    icon={<CloseIcon fontSize="16px" color="gray.500" />}
                    p="0"
                    height="24px"
                    minW="20px"
                    borderWidth={0}
                    backgroundColor="transparent"
                    isDisabled={value === ''}
                  />
                )}
                {items?.length > 0 && (
                  <IconButton
                    {...getToggleButtonProps()}
                    aria-label="Show options"
                    icon={<ChevronDownIcon fontSize="20px" color="gray.600" />}
                    borderWidth={0}
                    p="0"
                    height="24px"
                    minW="20px"
                    backgroundColor="transparent"
                  />
                )}
              </HStack>
            </InputRightElement>
          </InputGroup>
        </div>
        <Box
          as="ul"
          {...getMenuProps()}
          display={isOpen && inputItems.length > 0 ? 'block' : 'none'}
          maxHeight="180px"
          overflowY="auto"
          margin={0}
          borderTop={0}
          background="white"
          position="absolute"
          zIndex={1000}
          padding={0}
          top="48px"
          minWidth="300px"
          borderWidth="1px"
          boxShadow={`0 0 0 1px ${borderColor}, 0 4px 11px ${borderColor}`}
        >
          {isOpen &&
            inputItems.map((item, index) => {
              const isSelected = item.value === value;

              const getBgColor = () => {
                if (highlightedIndex === index) return 'primary.100';
                if (isSelected) return 'primary.50';
                return undefined;
              };

              return (
                <Box
                  as="li"
                  py={1}
                  px={4}
                  bg={getBgColor()}
                  key={`${item}${index}`}
                  {...getItemProps({item, index})}
                  position="relative"
                >
                  {item.label}
                  {isSelected && <CheckIcon color="primary.500" position="absolute" right={2} />}
                </Box>
              );
            })}
        </Box>
      </Box>
    );
  }
);

type StateReducer = UseComboboxProps<Item>['stateReducer'];

const inputStateReducer: StateReducer = (state, {type, changes}) => {
  return changes;
};

const selectStateReducer: StateReducer = (state, {type, changes}) => {
  const handler = handlers[type];
  return handler ? handler(state, {type, changes}) : changes;
};

const types = useCombobox.stateChangeTypes;
const handlers: Record<UseComboboxStateChangeTypes[any], StateReducer> = {
  // When the user leaves the input field, restore the previous value if nothing was selected
  [types.InputBlur]: (state, {type, changes}) => {
    const {inputValue, selectedItem} = changes;
    if (!selectedItem) {
      return {...changes, inputValue: ''};
    }
    const shouldRestorePreviousValue =
      selectedItem && (!inputValue || inputValue !== selectedItem.value);
    return {
      ...changes,
      ...(shouldRestorePreviousValue && {
        inputValue: selectedItem.value
      })
    };
  },
  // Clear the content of the input field when the menu button is pressed,
  // so that we show ALL available values to the user
  [types.ToggleButtonClick]: (state, {type, changes}) => {
    return {
      ...changes,
      inputValue: ''
    };
  }
};
