import React, {useRef, useState} from 'react';
import {
  Box,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ButtonGroup,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle
} from '@chakra-ui/react';
import {useMutation} from 'react-query';
import {IoMdPricetag} from 'react-icons/io';
import invariant from 'tiny-invariant';

import {ItemSelection, useApp} from 'shared';
import {Query} from 'models/query';
import {Button, RemoveIcon} from 'components/core';
import {AnnotationLabelInput} from 'components/annotations/annotation-label-input';
import {AILabel, AIModel} from 'components/settings/types';

type UserInput = {
  labelName: string;
  description: string;
};

type Props = {
  query: Query;
  selection: ItemSelection;
  numberOfItems: number;
  labels: AILabel[];
  models: AIModel[];
  onClose: (result?: number) => void;
};
export const DialogBulkAddWSILabel = ({
  query,
  selection,
  numberOfItems,
  onClose,
  labels,
  models
}: Props) => {
  const mutation = useMutationAddLabels();
  const ref = useRef<HTMLInputElement>(null);
  const [labelName, setLabelName] = useState('');

  const defaultModelName = '';
  const isFormValid = !!labelName;

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        invariant(ref.current);
        const description = ref.current.value;
        mutation.mutate(
          {query, selection, labelName, description},
          {
            onSuccess: (result) => {
              onClose(result);
            }
          }
        );
      }}
    >
      <ModalHeader>
        Add WSI label
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Stack spacing={6}>
          {numberOfItems && (
            <Box>Pick the label to be applied to the {numberOfItems} selected image(s).</Box>
          )}
          <Box>
            <AnnotationLabelInput
              labelName={labelName}
              onChange={(value) => setLabelName(value)}
              models={models}
              labels={labels}
              defaultModelName={defaultModelName}
            />
          </Box>
          <Box>
            <FormControl id="description">
              <FormLabel>
                Description{' '}
                <Box as="span" color="gray.500" ml={2} fontStyle="italic">
                  (Optional)
                </Box>
              </FormLabel>
              <Input ref={ref} />
            </FormControl>
          </Box>
          {mutation.isError && (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>
                Unable to assign the WSI labels {(mutation.error as Error).message}
              </AlertDescription>
            </Alert>
          )}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button
            type="submit"
            primary
            rightIcon={<IoMdPricetag fontSize={20} />}
            isDisabled={!isFormValid}
            isLoading={mutation.isLoading}
          >
            Add WSI label
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </form>
  );
};

type MutationProps = Pick<Props, 'selection' | 'query'> & UserInput;

function useMutationAddLabels() {
  const app = useApp();
  return useMutation(async ({query, selection, labelName, description}: MutationProps) => {
    const backend = await app.getBackend();
    const imagesBackend = await app.getImagesBackend();
    const accessToken = app.getAccessToken();
    const imageIds = await backend.findImageIds({query, selection, accessToken});
    let count = 0;
    for await (const imageId of imageIds) {
      const {wsiAnnotation} = await imagesBackend.addWSIAnnotation({
        imageId,
        labelName,
        description,
        accessToken
      });
      if (wsiAnnotation) {
        count++;
      }
    }
    return count;
  });
}

export const DialogBulkRemoveWSILabel = ({
  query,
  selection,
  numberOfItems,
  onClose,
  labels,
  models
}: Props) => {
  const mutation = useMutationRemoveLabels();
  const [labelName, setLabelName] = useState('');

  const defaultModelName = '';
  const isFormValid = !!labelName;

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        mutation.mutate(
          {query, selection, labelName},
          {
            onSuccess: (result) => {
              onClose(result);
            }
          }
        );
      }}
    >
      <ModalHeader>
        Remove WSI label
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Stack spacing={6}>
          {numberOfItems && (
            <Box>Pick the label to be removed from the {numberOfItems} selected image(s).</Box>
          )}
          <Box>
            <AnnotationLabelInput
              labelName={labelName}
              onChange={(value) => setLabelName(value)}
              models={models}
              labels={labels}
              defaultModelName={defaultModelName}
            />
          </Box>
          {mutation.isError && (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>
                Unable to delete the WSI labels {(mutation.error as Error).message}
              </AlertDescription>
            </Alert>
          )}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button
            type="submit"
            primary
            rightIcon={<RemoveIcon />}
            isDisabled={!isFormValid}
            isLoading={mutation.isLoading}
          >
            Remove WSI label
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </form>
  );
};

function useMutationRemoveLabels() {
  const app = useApp();
  return useMutation(async ({query, selection, labelName}: Omit<MutationProps, 'description'>) => {
    const backend = await app.getBackend();
    const imagesBackend = await app.getImagesBackend();
    const accessToken = app.getAccessToken();
    const imageIds = await backend.findImageIds({query, selection, accessToken});
    let count = await imagesBackend.removeBulkWSIAnnotations({
      imageIds,
      labelName,
      accessToken
    });
    return count;
  });
}
