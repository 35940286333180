import pickBy from 'lodash/pickBy';
import mapValues from 'lodash/mapValues';

import {ParsedRowData} from './import-csv-container';
import {ScanFieldName} from './csv-record-table';
import {KeywordsContainer} from 'shared';

type FieldSettings = {
  name: ScanFieldName;
  aliases: string[];
  lookup?: boolean; // fields used for case lookup are not editable
  values?: string[];
};
export function useFieldSetup() {
  const {getValues} = KeywordsContainer.useContainer();
  const organs = getValues('organ');
  const suppliers = getValues('supplier');
  const specimenTypes = getValues('specimenType');

  const fieldSetup: FieldSettings[] = [
    {name: 'imageFilename', aliases: ['File Name'], lookup: true},
    {name: 'supplier', aliases: ['Supplier'], lookup: true, values: suppliers},
    {name: 'specimenType', aliases: ['Type of Specimen'], values: specimenTypes},
    {name: 'organ', aliases: ['Organ'], values: organs},
    {name: 'comments', aliases: ['Comments']},
    {name: 'disease', aliases: ['Diagnosis']},
    {name: 'customField1', aliases: ['Custom Field 1']},
    {name: 'customField2', aliases: ['Custom Field 2']},
    {name: 'customField3', aliases: ['Custom Field 3']},
    {name: 'tags', aliases: ['Tags']}
  ];

  // Given a field name found in the file header, return the normalized field name to be used to update cases
  function normalizeFieldName(field: string): string {
    const foundFieldItem =
      fieldSetup.find(({name}) => compare(name)(field)) ||
      fieldSetup.find(({aliases}) => aliases.find(compare(field)));

    return foundFieldItem?.name || field;
  }

  function checkRequiredFields(fields: string[]) {
    const requiredFields = fieldSetup.filter(({lookup}) => lookup);
    const firstMissingField = requiredFields.find(({name}) => !fields.includes(name));
    if (!firstMissingField) return true;

    const {aliases} = firstMissingField;
    throw new Error(`"${aliases[0]}" must be included in the first row`);
  }

  function checkFileHeader(fields: string[]) {
    checkRequiredFields(fields);
    checkEditableFields(fields);
  }

  function checkEditableFields(fields: string[]) {
    const editableFields = fieldSetup.filter(({lookup}) => !lookup);
    const isEditableFieldIncluded = editableFields.some(({name}) => fields.includes(name));
    if (isEditableFieldIncluded) return true;

    const displayFieldNames = editableFields.map(({aliases}) => aliases[0]).join(', ');
    throw new Error(
      `At least one of the following fields should be included in the first row: ${displayFieldNames}`
    );
  }

  function checkFileData(rows: ParsedRowData[]) {
    function checkRow(row, index) {
      Object.entries(row).forEach(([key, value]) => {
        const isValid = validateFieldValue(key, value);
        if (!isValid)
          throw new Error(`Invalid data on row ${index + 1} for "${key}" field: ${value}`);
      });
    }
    rows.forEach(checkRow);
  }

  function validateFieldValue(key: string, value: any): boolean {
    const validators = {
      specimenType: (value) => {
        return specimenTypes.includes(value);
      },
      organ: (value) => {
        return organs.includes(value);
      }
    };
    const validate = validators[key];
    return validate ? validate(value) : true;
  }

  return {checkFileHeader, checkFileData, fieldSetup, normalizeFieldName};
}

const compare = (a: string) => (b: string) => a.toLowerCase() === b.toLowerCase();

export function normalizeRow(row: string[]) {
  const dataWithoutEmptyValues = pickBy(row, (value, key) => !!value);
  return mapValues(dataWithoutEmptyValues, (value, key) => normalizeFieldValue(key, value));
}

function normalizeFieldValue(key: string, value: string): string | string[] {
  const normalizers = {
    tags: (value) => {
      // Exporting a spreadsheet may create values separated by `, ` (with a space)
      if (!value) return '';
      return value.replace(/ /g, '');
    },
    organ: (value) => {
      return value.toLowerCase();
    },
    comments: truncate(768),
    customField1: truncate(768),
    customField2: truncate(768),
    customField3: truncate(768)
  };

  const normalize = normalizers[key];
  return normalize ? normalize(value) : value;
}

export function isValidRow(row) {
  const fieldNames = Object.keys(row);
  return fieldNames.length > 1;
}

const truncate = (limit) => (text) => text.trim().slice(0, limit);
