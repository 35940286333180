import React, {useRef} from 'react';
import {useHistory, Link as RouterLink} from 'react-router-dom';
import {Box, HStack, Icon, IconButton, Stack} from '@chakra-ui/react';
import {useIsMutating} from 'react-query';
import {MdOpenInNew} from 'react-icons/md';

import {RoundBackButton} from 'components/core';
import {OpenSeadragon} from './openseadragon';
import {Toolbar, getAvailableModes} from './toolbar';
import {ImageContainer} from './image-container';
import {ImageViewerPlugin} from './types';

import './openseadragon-custom.css';

type Props = {
  image: Datastore.Image;
  scan?: Datastore.Scan;
  plugins: ImageViewerPlugin[];
  showBackButton?: boolean;
};
export const ImageViewer = ({image, scan, plugins = [], showBackButton}: Props) => {
  const ref = useRef<OpenSeadragon | null>(null);
  function getViewer() {
    if (ref.current) {
      return ref.current.instance;
    }
  }

  return (
    <ImageContainer.Provider initialState={{image, scan, getViewer}}>
      <Box position="relative" width="100%" height="100%" overflow="hidden">
        <Providers plugins={plugins}>
          <MainArea image={image} viewerRef={ref} plugins={plugins} />
          <Box position="absolute" top={6} left={6} zIndex={998} pointerEvents="none">
            <TopArea plugins={plugins} showBackButton={showBackButton || false} />
            <Toolbar plugins={plugins} />
          </Box>
          <RightArea plugins={plugins} />
          <BottomArea plugins={plugins} />
        </Providers>
      </Box>
    </ImageContainer.Provider>
  );
};

// compose all plugin's providers, creating a nested tree of provider, before sub-components are rendered
function Providers({plugins, children}: {plugins: ImageViewerPlugin[]; children: React.ReactNode}) {
  if (!plugins.length) return children;
  const elements = plugins.filter((plugin) => !!plugin.Provider).map((plugin) => plugin.Provider);
  return createNestedTree(elements, children);
}

function createNestedTree(elements, children) {
  return elements.length > 1
    ? React.createElement(elements[0], {
        children: createNestedTree(elements.slice(1), children)
      })
    : React.createElement(elements[0], {children});
}

const MainArea = (props: {
  image: Datastore.Image;
  viewerRef: React.MutableRefObject<OpenSeadragon | null>;
  plugins: ImageViewerPlugin[];
}) => {
  const {image, viewerRef, plugins} = props;
  const cursor = useCursor(plugins);

  const tilesURL = image.info.tilesURL;
  const tileSources = tilesURL
    ? `${tilesURL}/iiif2/info.json`
    : [
        {
          '@context': 'http://iiif.io/api/image/2/context.json',
          '@id': image.zoomableImageURL,
          height: image.info.dimensions.height,
          width: image.info.dimensions.width,
          profile: ['http://iiif.io/api/image/2/level2.json'],
          protocol: 'http://iiif.io/api/image'
        }
      ];

  return (
    <OpenSeadragon
      ref={viewerRef}
      tileSources={tileSources}
      showNavigationControl={false}
      showNavigator
      debugMode={false}
      navigatorPosition="BOTTOM_RIGHT"
      gestureSettingsMouse={{
        clickToZoom: false,
        dblClickToZoom: true,
        pinchToZoom: true
      }}
      styleProps={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: '#111a39',
        cursor
      }}
    >
      {plugins.map((plugin) => plugin.MainArea && plugin.MainArea())}
    </OpenSeadragon>
  );
};

const TopArea = ({
  plugins,
  showBackButton
}: {
  plugins: ImageViewerPlugin[];
  showBackButton: boolean;
}) => {
  const history = useHistory();
  const {image, zenMode} = ImageContainer.useContainer();

  return (
    <Stack
      alignItems="center"
      spacing={4}
      isInline
      opacity={zenMode ? 0 : 1}
      transition="opacity 0.3s"
    >
      {showBackButton ? (
        <Box>
          <RoundBackButton
            onClick={() => {
              history.goBack();
            }}
            color="inverseTextColor"
            bg="inverseBoxBackgroundColor"
            borderColor="inverseBoxBorderColor"
            _hover={{
              backgroundColor: 'inverseBackgroundColor'
            }}
            pointerEvents="all"
            aria-label="back"
          />
        </Box>
      ) : (
        <IconButton
          as={RouterLink}
          to={`/images/${image.id}`}
          icon={<Icon as={MdOpenInNew} boxSize="24px" />}
          aria-label="Open in a new window"
          color="inverseTextColor"
          bg="inverseBoxBackgroundColor"
          _hover={{
            backgroundColor: 'inverseBackgroundColor'
          }}
          pointerEvents="all"
        />
      )}
      <Box>
        {plugins.map((plugin) =>
          plugin.TopArea ? <plugin.TopArea key={plugin.displayName} /> : null
        )}
      </Box>
    </Stack>
  );
};

const RightArea = ({plugins}: {plugins: ImageViewerPlugin[]}) => {
  const {zenMode} = ImageContainer.useContainer();
  return (
    <Box
      position="absolute"
      top={6}
      right={6}
      zIndex={99}
      opacity={zenMode ? 0 : 1}
      transition="opacity 0.3s"
    >
      <Stack spacing={4}>
        {plugins.map((plugin) =>
          plugin.RightArea ? <plugin.RightArea key={plugin.displayName} /> : null
        )}
      </Stack>
    </Box>
  );
};

const BottomArea = ({plugins}: {plugins: ImageViewerPlugin[]}) => {
  const {zenMode} = ImageContainer.useContainer();
  return (
    <Box
      position="absolute"
      bottom={6}
      left={6}
      opacity={zenMode ? 0 : 1}
      transition="opacity 0.3s"
    >
      <HStack>
        {plugins.map((plugin) =>
          plugin.BottomArea ? <plugin.BottomArea key={plugin.displayName} /> : null
        )}
      </HStack>
    </Box>
  );
};

function useCursor(plugins: ImageViewerPlugin[]) {
  const {/*isBusy, */ isQuickPanning, mode} = ImageContainer.useContainer();
  const isBusy = useIsMutating();

  if (isBusy) {
    return 'wait';
  }
  if (isQuickPanning) {
    return 'move';
  }

  const modes = getAvailableModes(plugins);
  const activeModeSettings = modes.find(({key}) => key === mode);
  return activeModeSettings && activeModeSettings.cursor;
}
