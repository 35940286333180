import {Box, BoxProps, Grid, GridProps, GridItem, VStack, StackDivider} from '@chakra-ui/react';

import {KeywordLink, TagLinkGroup} from 'components/scan-list/scan-columns';

type Props = {
  scan: Datastore.Scan;
};
export const ScanDataPane = ({scan}: Props) => {
  const {comments, customField1, customField2, customField3} = scan;
  const hasComments = [comments, customField1, customField2, customField3].some(
    (text) => !!text?.trim()
  );
  return (
    <VStack
      alignItems="flex-start"
      divider={<StackDivider borderColor="gray.200" />}
      spacing={3}
      fontSize="14px"
    >
      <FieldGroup>
        <Field label="Supplier" value={<KeywordLink category="supplier" value={scan.supplier} />} />
        <Field
          label="Tags"
          value={
            scan.tags ? (
              <Box transform="translateY(2px)">
                <TagLinkGroup scanTags={scan.tags} />
              </Box>
            ) : (
              <EmptyContent />
            )
          }
        />
        <Field label="Organ" value={<KeywordLink category="organ" value={scan.organ} />} />
        {scan.staining && (
          <Field
            label="Staining"
            value={<KeywordLink category="staining" value={scan.staining} />}
          />
        )}
        <Field
          label="Type of Specimen"
          value={<KeywordLink category="specimenType" value={scan.specimenType} />}
        />
        {scan.zoomLevel && <Field label="Magnification" value={scan.zoomLevel} />}
      </FieldGroup>

      {hasComments && (
        <FieldGroup>
          {scan.comments && (
            <Field label="Comments" value={<ReadOnlyText>{scan.comments}</ReadOnlyText>} />
          )}
          {scan.customField1 && (
            <Field
              label="Custom Field 1"
              value={<ReadOnlyText>{scan.customField1}</ReadOnlyText>}
            />
          )}
          {scan.customField2 && (
            <Field
              label="Custom Field 2"
              value={<ReadOnlyText>{scan.customField2}</ReadOnlyText>}
            />
          )}
          {scan.customField3 && (
            <Field
              label="Custom Field 3"
              value={<ReadOnlyText>{scan.customField3}</ReadOnlyText>}
            />
          )}
        </FieldGroup>
      )}
    </VStack>
  );
};

function FieldGroup(props: GridProps) {
  return <Grid templateColumns="120px 1fr" columnGap={4} rowGap={2} {...props} />;
}
function Field({label, value}) {
  return (
    <>
      <GridItem alignItems="center">
        <Box textAlign="right" color="gray.500">
          {label}
        </Box>
      </GridItem>
      <GridItem alignItems="center">{value || <EmptyContent />}</GridItem>
    </>
  );
}

function ReadOnlyText(props: BoxProps) {
  if (!props.children) return <EmptyContent />;
  return <Box whiteSpace="pre-line" {...props} />;
}

const EmptyContent = () => <Box color="gray.400">--</Box>;
