import {useQuery} from 'react-query';

import {SearchOptions, useApp} from 'shared';
import {Annotation} from 'models/annotation';
import {getAILabelByName, useFetchAILabels} from 'components/settings/ai-labels-api';

type Result = {
  annotations: Datastore.Annotation[];
  totalNumberOfAnnotations: number
}

export function useFetchAnnotations(searchOptions: SearchOptions) {
  const app = useApp();

  // We use the dependent queries https://react-query.tanstack.com/guides/dependent-queries strategy
  // to fetch the labels and then the annotations
  const {data} = useFetchAILabels();
  const labels = data?.labels;

  const loadAnnotations = async () => {
    const accessToken = app.getAccessToken();
    const imagesBackend = await app.getImagesBackend();
    const {offset, limit} = searchOptions;
    let {annotations, totalNumberOfAnnotations} = await imagesBackend.findAnnotations({
      productId: 'pidport-datastore',
      offset,
      limit,
      accessToken
    });

    annotations = annotations.map((annotation) => new Annotation(annotation, {deserialize: true}));

    annotations = annotations.map((annotation) => ({
      ...annotation,
      label: getAILabelByName(labels || [], annotation.labelName)
    }));

    return {annotations, totalNumberOfAnnotations};
  };

  return useQuery<Result, Error>(['annotation-list', searchOptions], loadAnnotations, {
    enabled: !!labels,
    keepPreviousData: false,
    cacheTime: 1000 * 60 * 60 // override the default duration of 5 minutes => 1 hour
  });
}

export function useFetchAnnotation(annotationId, returnFields) {
  const app = useApp();
  const accessToken = app.getAccessToken();

  const loadAnnotation = async () => {
    const imagesBackend = await app.getImagesBackend();
    const {annotation} = await imagesBackend.getAnnotation({
      annotationId,
      returnFields,
      accessToken
    });
    return new Annotation(annotation, {deserialize: true}) as unknown as Datastore.Annotation; // TODO fix the types!
  };

  return useQuery(['annotation', annotationId, returnFields], loadAnnotation);
}
