import React from 'react';
import {Alert, Badge, Box, ButtonGroup, Flex} from '@chakra-ui/react';

import {useApp, useLocale} from 'shared';
import {
  AddIcon,
  Button,
  KeyHandler,
  Heading1,
  PageContainer,
  Spinner,
  SubTitle,
  useModal,
  PaginationTableInstance
} from 'components/core';
import {useFetchUsers} from './fetch-users';
import {UserAdd} from './user-add';
import {useUserTable} from './user-table';
import {UserTableWithFilters} from './user-table-filters';

export const UserListPage = () => {
  const {data: users, isLoading, error, refetch} = useFetchUsers({offset: 0, limit: 1000});

  if (isLoading) return <Spinner />;
  if (error) return <Alert status="error">Unable to load the users</Alert>;
  return <UserList users={users} refetch={refetch} />;
};

const UserList = ({users, refetch}: {users: Datastore.User[]; refetch: () => void}) => {
  const locale = useLocale();
  const app = useApp();
  const modal = useModal();

  const addUser = async () => {
    const result = await modal.dialog({
      render: (close) => <UserAdd onClose={close} />,
      modalProps: {
        size: 'xl'
      }
    });

    if (!result) {
      return;
    }

    const {email, accessLevel, roles} = result;

    const done = await app.task(async () => {
      await app.ensureUser({email, accessLevel, roles});
    });

    if (done) {
      await refetch();
    }
  };

  const tableInstance = useUserTable({users}) as PaginationTableInstance<Datastore.User>;
  const {
    rows,
    state: {pageIndex, pageSize}
  } = tableInstance;

  const total = users.length;
  const start = pageIndex * pageSize + 1;
  const end = Math.min((pageIndex + 1) * pageSize, rows.length);
  const showPagination = total > tableInstance.state.pageSize;
  const isFiltered = tableInstance.state.filters.length > 0;

  return (
    <PageContainer maxW="container.xl">
      <Heading1>
        {locale.todo('Users')}
        {rows.length > 0 && (
          <SubTitle>
            {start} - {end} of {rows.length}
            {isFiltered && (
              <Badge ml={2} colorScheme="blue">
                Filtered
              </Badge>
            )}
          </SubTitle>
        )}
      </Heading1>
      <UserTableWithFilters tableInstance={tableInstance} />
      <Flex mt={6} flexShrink={0} justifyContent="space-between" pr={1} pb={1}>
        <Box>
          <Button onClick={addUser} primary leftIcon={<AddIcon />}>
            {locale.todo('Add')}
          </Button>
        </Box>
        {showPagination && <PaginationControls tableInstance={tableInstance} />}
      </Flex>
    </PageContainer>
  );
};

const PaginationControls = ({tableInstance}) => {
  const locale = useLocale();
  const {previousPage, nextPage, canPreviousPage, canNextPage} = tableInstance;
  return (
    <>
      <ButtonGroup>
        <Button onClick={() => previousPage()} isDisabled={!canPreviousPage}>
          {locale.previousButtonLabel}
        </Button>
        <Button type="button" onClick={() => nextPage()} isDisabled={!canNextPage}>
          {locale.nextButtonLabel}
        </Button>
      </ButtonGroup>
      <KeyHandler value="ArrowLeft" onDown={previousPage} />
      <KeyHandler value="ArrowRight" onDown={nextPage} />
    </>
  );
};
