import React, {useMemo} from 'react';
import {Box} from '@chakra-ui/react';

import {useLocale} from 'shared';
import {Table} from 'components/core';

export const StatsTableView = ({results}) => {
  const locale = useLocale();
  const data = useMemo(() => results, [results]);
  const columns = useMemo(
    () => [
      {
        Header: 'Year',
        accessor: (item) => item.year
      },
      {
        Header: 'Month',
        accessor: (item) => item.month
      },
      {
        Header: 'Number of scans',
        accessor: (item) => locale.formatNumber(item.count)
      }
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );
  return (
    <Box w={600} m="0 auto">
      <Table data={data} columns={columns} size="sm" />
    </Box>
  );
};
