import React, {useState} from 'react';
import get from 'lodash/get';
import {
  Box,
  ListItem,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  HStack,
  UnorderedList
} from '@chakra-ui/react';
import hasValues from 'has-values';

import {useLocale} from 'shared';
import {ScanForm} from 'components/scan-form';
import {Button, useModal} from 'components/core';
import {describeChanges} from 'models/model-utils';

type Props = {
  numberOfItems: number;
  defaultValues: any;
  onClose: (value: any) => void;
};
export const DialogReplaceFields = ({numberOfItems, defaultValues, onClose}: Props) => {
  const locale = useLocale();
  const modal = useModal();
  const [changes, setChanges] = useState(defaultValues);

  const isEmpty = !hasValues(changes);

  const handleChange = (field, value) => {
    if (value === null) {
      value = undefined;
    }
    setChanges((values) => ({...values, [field]: value}));
  };

  const clearChanges = () => {
    setChanges({});
  };

  const submit = async (event) => {
    event.preventDefault();
    const okay = await modal.confirm(
      <>
        <div>
          Are you sure you want to <strong>definitely</strong> replace the following fields in{' '}
          <strong>{numberOfItems} selected scans</strong>?
        </div>
        <div style={{marginTop: '.5rem'}}>Replacements:</div>
        <UnorderedList>
          {describeChanges(changes).map(({field, value}, index) => (
            <ListItem key={index}>
              {field}: {value}
            </ListItem>
          ))}
        </UnorderedList>
      </>,
      {title: 'Caution!', okButton: 'Replace'}
    );

    if (!okay) {
      return;
    }

    onClose(changes);
  };

  return (
    <form
      onSubmit={submit}
      autoComplete="off"
      style={{
        minHeight: 0,
        display: 'flex',
        flexDirection: 'column',
        flex: '1'
      }}
    >
      <ModalHeader>
        {locale.todo('Replace')}
        <span
          style={{
            fontSize: '1rem',
            fontWeight: 500,
            color: 'rgb(128, 128, 128)',
            marginLeft: '0.75rem'
          }}
        >
          In {numberOfItems} selected scans
        </span>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Box flex={1} overflowY="scroll" h={600} borderWidth="1px" p={4}>
          <ScanForm
            getValue={(field) => get(changes, field)}
            setValue={(field, value) => handleChange(field, value)}
            excludeFields={['image.filename', 'reference']}
          />
        </Box>
      </ModalBody>
      <ModalFooter>
        <HStack>
          <Button type="button" onClick={clearChanges} disabled={isEmpty}>
            {locale.todo('Clear')}
          </Button>
          <Button type="button" onClick={() => onClose(false)}>
            {locale.cancelButtonLabel}
          </Button>
          <Button type="submit" primary disabled={isEmpty}>
            {locale.todo('Replace')}
          </Button>
        </HStack>
      </ModalFooter>
    </form>
  );
};
