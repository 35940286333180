import {useMutation, useQueryClient} from 'react-query';
import {useInterval} from 'react-use';
import invariant from 'tiny-invariant';

import {useApp} from 'shared';
import {Prediction} from 'models/prediction';

type ImageQueryData = {
  image: Datastore.Image;
};
type CreatePredictionData = {
  imageId: string;
  modelName: string;
  modelVersion: string;
};

export function useRequestPrediction() {
  const app = useApp();
  const queryClient = useQueryClient();
  const accessToken = app.getAccessToken();

  const requestPrediction = useMutation<Datastore.Prediction, Error, CreatePredictionData>(
    async ({imageId, modelName, modelVersion}) => {
      const imagesBackend = await app.getImagesBackend();
      const {prediction} = await imagesBackend.requestPrediction({
        imageId,
        modelName,
        modelVersion,
        accessToken
      });
      // const prediction = {id: 'xxx', status: 'RUNNING', modelName};
      return new Prediction(prediction, {deserialize: true}) as unknown as Datastore.Prediction;
    },
    {
      onSuccess(prediction, {imageId}) {
        queryClient.setQueryData<ImageQueryData>(['image', imageId], (current) => {
          invariant(current);
          const updatedImage = {
            ...current.image,
            predictions: [prediction]
          };
          return {...current, image: updatedImage};
        });
      }
    }
  );

  return requestPrediction;
}
export function useRemovePrediction() {
  const app = useApp();
  const queryClient = useQueryClient();
  const accessToken = app.getAccessToken();

  const removePrediction = useMutation<
    void,
    Error,
    {imageId: string; predictionId: Datastore.Prediction['id']}
  >(
    async ({predictionId}) => {
      const imagesBackend = await app.getImagesBackend();
      await imagesBackend.deletePrediction({predictionId: predictionId, accessToken});
    },
    {
      onSuccess(_, {imageId}) {
        queryClient.setQueryData<ImageQueryData>(['image', imageId], (current) => {
          invariant(current);
          return {image: {...current.image, predictions: []}};
        });
      }
    }
  );

  return removePrediction;
}

export function useRefreshPrediction(imageId: string, prediction: Datastore.Prediction) {
  const app = useApp();
  const queryClient = useQueryClient();
  const accessToken = app.getAccessToken();

  const loadPrediction = async () => {
    const imagesBackend = await app.getImagesBackend();
    const returnFields = ['id', 'modelName', 'status', 'results'];
    const result = await imagesBackend.getPrediction({
      predictionId: prediction.id,
      returnFields,
      accessToken
    });
    const updatedPrediction = new Prediction(result.prediction, {
      deserialize: true
    }) as unknown as Datastore.Prediction;
    queryClient.setQueryData<ImageQueryData>(['image', imageId], (current) => {
      invariant(current);
      const updatedImage = {
        ...current.image,
        predictions: [updatedPrediction]
      };
      return {...current, image: updatedImage};
    });
  };
  const shouldFetch = prediction && ['WAITING', 'RUNNING'].includes(prediction.status);
  return useInterval(loadPrediction, shouldFetch ? 1000 : null);
}
