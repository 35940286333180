import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  AccordionProps
} from '@chakra-ui/react';

import {usePermissions} from 'shared';
import {RelatedScans} from './related-scans';
import {useFetchScan} from './scan-actions';
import {ScanImageInfo} from './scan-image-info';
import {ScanQualityControl} from './scan-quality-control';

type Props = {
  scan: Datastore.Scan;
  refetch: ReturnType<typeof useFetchScan>['refetch'];
} & AccordionProps;

export const ScanDetailsSidebar = ({scan, refetch, ...props}: Props) => {
  const {hasPermission} = usePermissions();
  const isQualityControlScan = scan.image.status === 'AVAILABLE' && !!scan.status; // scans before 2021 were not controlled

  return (
    <Accordion {...props} defaultIndex={[0, 1, 2]}>
      {hasPermission('quality-control/view') && isQualityControlScan && (
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Quality Control
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel px={0}>
            <ScanQualityControl scan={scan} refetch={refetch} />
          </AccordionPanel>
        </AccordionItem>
      )}

      <AccordionItem>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            Image info
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel px={0}>
          <ScanImageInfo scan={scan} />
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            Related scans
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel px={0}>
          <RelatedScans scan={scan} />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
