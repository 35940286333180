import React, {useRef, useState} from 'react';
import {
  Box,
  Input,
  InputProps,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger
} from '@chakra-ui/react';
import Calendar from 'react-calendar';

import {useLocale} from 'shared';
import './date-input.css';

export const ESC = 27;
export const TAB = 9;

type Props = {
  value: Date;
  onChange: (value: Date | null) => void;
} & InputProps;
export function DateInput({value, onChange}: Props) {
  const [draftValue, setDraftValue] = useState<string | undefined>(undefined);
  const locale = useLocale();
  const [isOpen, setIsOpen] = React.useState(false);
  const close = () => setIsOpen(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const popupRef = useRef<HTMLInputElement>(null);

  useClickOutside([popupRef, inputRef], () => {
    if (isOpen) {
      close();
    }
  });

  return (
    <Popover
      placement="bottom-start"
      returnFocusOnClose={false}
      isOpen={isOpen}
      closeOnBlur={false}
      autoFocus={false}
    >
      <PopoverTrigger>
        <Input
          ref={inputRef}
          placeholder={locale.dateInputPlaceholder}
          value={draftValue || locale.formatDateInput(value)}
          onChange={(event) => {
            const value = event.target.value.trim();
            if (value === '') {
              setDraftValue(undefined);
              onChange(null);
              return;
            }
            const date = locale.parseDateInput(value);
            if (date) {
              setDraftValue(undefined);
              onChange(date);
              return;
            }
            setDraftValue(value);
            onChange(null);
          }}
          onFocus={() => {
            setIsOpen(true);
          }}
          onKeyDown={({keyCode}) => {
            // Without this handler, the first button of the popup gets the focus when pressing TAB.
            // TODO pressing TAB should close AND put the focus on the next field,
            // now the user has to press TAB 2 times :(
            if (keyCode === TAB || keyCode === ESC) {
              close();
            }
          }}
        />
      </PopoverTrigger>
      <PopoverContent ref={popupRef} minW={320} bg="white">
        <PopoverBody>
          <Box>
            <Calendar
              value={draftValue ? null : value}
              onChange={(value) => {
                close();
                onChange(value);
              }}
            />
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

function useClickOutside(refs, callback) {
  React.useEffect(() => {
    const handleClickOutside = (e) => {
      if (!refs.some((ref) => ref.current?.contains(e.target))) callback(e);
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [callback, refs]);
}
