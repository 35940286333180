import {useMemo, useState} from 'react';
import {Button, HStack, Icon, Text} from '@chakra-ui/react';
import {MdRestoreFromTrash} from 'react-icons/md';

import {ItemSelection, SearchOptions} from 'shared';
import {InfoIcon} from 'components/core';
import {PaginatedScanList} from 'components/scan-list/scan-list-shared';
import {useScanColumns} from 'components/scan-list/scan-table';
import {CompactDate} from 'components/scan-list/scan-columns';
import {useTrashBinActions} from './trash-bin-actions';

type Props = {
  scans: Datastore.Scan[];
  total: number;
  searchOptions: Required<SearchOptions>;
  reloadScans: () => void;
};
export const DeletedScanList = ({scans, reloadScans, total, searchOptions}: Props) => {
  const {
    thumbnail,
    columns: {details, supplier, createdOn, annotations}
  } = useScanColumns();
  const columns = useMemo(
    () => [
      thumbnail({size: 80}),
      details,
      supplier,
      createdOn,
      annotations,
      {
        id: 'deletedOn',
        Header: 'Deleted at',
        accessor: (scan: Datastore.Scan) => {
          const date = scan.deletedOn;
          return <CompactDate date={date} />;
        }
      },
      {
        id: 'deletedById',
        Header: 'Deleted by',
        accessor: (scan: Datastore.Scan) => scan.deletedBy?.account.email || '--'
      }
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const [selection, setSelection] = useState(new ItemSelection());

  const {restore} = useTrashBinActions({reloadScans, total, selection, setSelection});

  return (
    <PaginatedScanList
      scans={scans}
      searchOptions={searchOptions}
      total={total}
      columns={columns}
      selection={selection}
      setSelection={setSelection}
      header={
        <HStack borderLeftWidth="3px" pl={2}>
          <InfoIcon color="gray.400" />
          <Text color="gray.500" fontStyle="italic" fontSize="sm">
            Showing all scans marked as deleted. Scans without annotations will be deleted forever
            after 30 days.
          </Text>
        </HStack>
      }
      footer={
        <>
          <Button
            onClick={restore}
            isDisabled={selection.getNumberOfItems(total) === 0}
            leftIcon={<Icon as={MdRestoreFromTrash} fontSize="24px" />}
          >
            Restore
          </Button>
        </>
      }
    />
  );
};
