import {OwnedItem} from 'models/owned-item';

export class TrackingEvent extends OwnedItem {
  constructor(data, {deserialize, ...otherOptions} = {}) {
    data = {...data};
    super(data, {deserialize, ...otherOptions});
  }
}

export default TrackingEvent;
