import React from 'react';
import ReactDOM from 'react-dom';
import {ChakraProvider} from '@chakra-ui/react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';

import app from 'app';
import {AppProvider, KeywordsContainer, LocaleContainer, PreferencesContainer} from 'shared';
import {AIDataContainer} from 'components/settings/ai-labels-api';
import {Root} from 'components/root';
import {ImportCSVContainer} from 'components/import-csv/import-csv-container';
import {ModalContainer, ModalRoot} from 'components/core/modal';
import {ErrorBoundary, ErrorFallback} from 'components/error-handling';
import {TabLayoutContainer} from 'components/scan-split-layout/tab-layout-container';
import {customTheme} from './custom-theme';

import './index.css';

(async () => {
  let element;
  const queryClient = new QueryClient();

  try {
    await app.initialize();
    element = (
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={customTheme}>
          <ModalContainer.Provider>
            <AppProvider app={app}>
              <ErrorBoundary fallback={<ErrorFallback />}>
                <PreferencesContainer.Provider>
                  <LocaleContainer.Provider>
                    <KeywordsContainer.Provider>
                      <AIDataContainer.Provider>
                        <ImportCSVContainer.Provider>
                          <ModalRoot />
                          <TabLayoutContainer.Provider>
                            <Root />
                          </TabLayoutContainer.Provider>
                        </ImportCSVContainer.Provider>
                      </AIDataContainer.Provider>
                    </KeywordsContainer.Provider>
                  </LocaleContainer.Provider>
                </PreferencesContainer.Provider>
              </ErrorBoundary>
            </AppProvider>
          </ModalContainer.Provider>
        </ChakraProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    );
  } catch (err) {
    console.error(err);
    element = <ErrorFallback error={err as Error} />;
  }

  ReactDOM.render(element, document.getElementById('root'));
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(); commented out by me
