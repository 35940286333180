import React from 'react';
import PropTypes from 'prop-types';

import {withOpenSeadragon} from 'components/image-viewer/openseadragon';
import {createSVGPath} from 'models/annotation';

export const AnnotationView = (props) => {
  return (
    <svg
      width={'100%'}
      height={'100%'}
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
    >
      <AnnotationPath {...props} />
    </svg>
  );
};

@withOpenSeadragon
export class AnnotationPath extends React.Component<any> {
  static propTypes = {
    path: PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number
      })
    ),
    autoClose: PropTypes.bool,
    onClick: PropTypes.func,
    width: PropTypes.string,
    height: PropTypes.string,
    style: PropTypes.shape({
      fill: PropTypes.string,
      fillOpacity: PropTypes.number,
      stroke: PropTypes.string,
      strokeWidth: PropTypes.string
    }),
    openSeadragon: PropTypes.object // OpenSeadragon is not available when paths are rendered in the thumbnail images
  };

  static defaultProps = {
    path: [],
    autoClose: false,
    width: '100%',
    height: '100%',
    enableKeyboardShortcuts: false,
    style: {
      fill: 'silver',
      fillOpacity: 0.8,
      stroke: 'silver',
      strokeWidth: '1px'
    }
  };

  annotationRef = React.createRef();

  componentDidMount() {
    const {openSeadragon} = this.props;

    if (openSeadragon) {
      openSeadragon.addClickHandler(this.handleClick);
    }
  }

  componentWillUnmount() {
    const {openSeadragon} = this.props;

    if (openSeadragon) {
      openSeadragon.removeClickHandler(this.handleClick);
    }
  }

  handleClick = (event) => {
    const {onClick} = this.props;

    const pathElement = this.annotationRef.current;
    if (onClick && event.originalEvent.target === pathElement) {
      return onClick(event);
    }

    return false;
  };

  render() {
    const {path, onClick, autoClose} = this.props;
    const SVGPath = createSVGPath(path, autoClose);

    return (
      <>
        {onClick && this.renderClickableArea(SVGPath)}
        {this.renderAnnotationPath(SVGPath)}
      </>
    );
  }

  renderClickableArea(SVGPath) {
    const {style, id} = this.props;

    return (
      <path
        ref={this.annotationRef as any}
        id={id}
        d={SVGPath}
        cursor="pointer"
        pointerEvents="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect="non-scaling-stroke"
        strokeWidth="12px"
        stroke={style.stroke}
        strokeOpacity="0"
        fill="none"
      />
    );
  }

  renderAnnotationPath(SVGPath) {
    const {style} = this.props;

    return (
      <path
        d={SVGPath}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect="non-scaling-stroke"
        {...style}
      />
    );
  }
}
