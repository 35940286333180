import React, {useState} from 'react';
import {Badge, Button, Box, Center, Code, Flex, Icon} from '@chakra-ui/react';
import {useDropzone} from 'react-dropzone';
import {parse} from 'papaparse';
import {FaFileCsv} from 'react-icons/fa';
import debugModule from 'debug';

import {Heading1, PageContainer} from 'components/core';
import {useLocale} from 'shared';
import {PreviewScans} from './preview-scans';

const debug = debugModule('medmain:csv');

export const CSVBulkActionsPage = () => {
  const [ids, setIds] = useState<string[]>([]);
  return (
    <PageContainer maxW="container.lg" borderWidth="1px" p={4} borderRadius="lg">
      <Heading1>CSV Bulk actions</Heading1>
      <Box mb={2} fontSize="lg">
        <Badge colorScheme="blue" variant="solid" mr={2}>
          Step 1
        </Badge>
        Pick a CSV file with the Scan ID
      </Box>
      <FilePicker onPickFile={setIds} />
      <Box mt={6} fontSize="lg">
        <Badge colorScheme="blue" variant="solid" mr={2}>
          Step 2
        </Badge>
        Run actions on all scans found
      </Box>
      {ids.length > 0 ? (
        <PreviewScans ids={ids} />
      ) : (
        <Center py={8} borderWidth="1px" mt={2} color="gray.500">
          No scan to preview
        </Center>
      )}
    </PageContainer>
  );
};

const FilePicker = ({onPickFile}: {onPickFile: (ids: string[]) => void}) => {
  const locale = useLocale();
  const [filename, setFilename] = useState<string>('');
  const [rows, setRows] = useState<any[]>([]);

  const parseCSV = (file: File) => {
    parse(file, {
      header: false,
      skipEmptyLines: true,
      complete: function (results) {
        const {data} = results;
        try {
          onPickFile(data.map((row) => row[0]));
          setFilename(file.name);
          setRows(data);
        } catch (error) {
          debug(error);
        }
      }
    });
  };

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop: (files) => parseCSV(files[0]),
    accept: ['.csv']
  });

  return (
    <Flex alignItems="center">
      <Box {...getRootProps({})}>
        <input {...getInputProps()} />
        <Button
          style={{
            width: `100%`,
            height: '100%',
            minHeight: `50px`,
            ...(isDragActive && {
              backgroundColor: 'rgb(242, 242, 242)',
              borderColor: 'rgba(0, 0, 0, 0.157)'
            })
          }}
        >
          <Icon as={FaFileCsv} fontSize="32px" color="gray.500" mr={2} />
          {isDragActive ? locale.todo('Drop CSV File') : locale.todo('Click or drag a CSV file')}
        </Button>
      </Box>
      <Box pl={6}>
        {filename ? (
          <Flex>
            <Box>
              Selected file: <Code>{filename}</Code>
            </Box>
            <Box ml={2}>({rows.length} rows)</Box>
          </Flex>
        ) : (
          <Box color="gray.500">(No CSV file selected)</Box>
        )}
      </Box>
    </Flex>
  );
};
