import React, {useContext} from 'react';
import {FullScreen, FullScreenHandle, useFullScreenHandle} from 'react-full-screen';

const FullScreenContext = React.createContext<FullScreenHandle | null>(null);

export const FullScreenProvider = (props) => {
  const fullScreenHandle = useFullScreenHandle();
  return (
    <FullScreen handle={fullScreenHandle}>
      <FullScreenContext.Provider value={fullScreenHandle} {...props} />
    </FullScreen>
  );
};

export const useFullScreen = () => useContext(FullScreenContext);
