import {Box} from '@chakra-ui/react';

import {createPlugin} from '..';
import {ImageViewerPlugin, PluginSettings} from 'components/image-viewer/types';
import {ScaleBarPluginContainer} from './scale-bar-container';
import {ScaleBar} from './scale-bar';

export const ScaleBarPlugin = (settings?: PluginSettings): ImageViewerPlugin => {
  return createPlugin({
    displayName: 'ScaleBar',
    Provider(props) {
      return <ScaleBarPluginContainer.Provider {...props} />;
    },
    BottomArea() {
      const {scaleBar} = ScaleBarPluginContainer.useContainer();
      return <Box pt={20}>{scaleBar && <ScaleBar width={scaleBar[0]} unit={scaleBar[1]} />}</Box>;
    }
  });
};
