import React from 'react';
import {Box, BoxProps, ButtonGroup, Flex, Grid, GridItem, Icon} from '@chakra-ui/react';

import {useLocale, usePermissions} from 'shared';
import {Button, DateTime, Spinner} from 'components/core';
import {
  RejectedIcon,
  RescannedIcon,
  VerifiedIcon
} from 'components/quality-control/quality-control-shared';
import {useQualityCheckActions} from './quality-control-actions';

type Props = {scan: Datastore.Scan; refetch: () => void};
export const ScanQualityControl = ({scan, refetch}: Props) => {
  const {hasPermission} = usePermissions();
  const {verifyScan, rejectScan, undoVerification, rescan, undoRescan, isLoading} =
    useQualityCheckActions(scan.id, refetch);

  const {status} = scan;
  if (!status) return null;

  if (isLoading)
    return (
      <Box h={100} borderWidth="1px" mt={6}>
        <Spinner />
      </Box>
    );

  if (status === 'TO_BE_VERIFIED') {
    return (
      <Box my={0} p={0} fontSize="14px">
        <Box p={2} textAlign="center">
          {hasPermission('quality-control/verify') ? (
            <ButtonGroup w="100%">
              <Button onClick={rejectScan} colorScheme="red" flex={1} leftIcon={<RejectedIcon />}>
                Reject
              </Button>
              <Button onClick={verifyScan} colorScheme="green" flex={1} leftIcon={<VerifiedIcon />}>
                Verify
              </Button>
            </ButtonGroup>
          ) : (
            <>To be verified</>
          )}
        </Box>
      </Box>
    );
  }
  if (status === 'VERIFIED') {
    return (
      <ControlBox
        colorScheme="green"
        header={
          <>
            <Flex>
              <VerifiedIcon mr={1} fontSize="24px" />
              Verified scan
            </Flex>
            {hasPermission('quality-control/verify') && (
              <Button onClick={undoVerification} size="sm" variant="link" ml={2}>
                Undo
              </Button>
            )}
          </>
        }
      >
        <QualityControlDetails scan={scan} />
      </ControlBox>
    );
  }
  if (status === 'REJECTED') {
    return (
      <ControlBox
        colorScheme="red"
        header={
          <>
            <Flex>
              <Icon as={RejectedIcon} mr={2} fontSize="24px" />
              Rejected scan
            </Flex>
            {hasPermission('quality-control/verify') && (
              <Button onClick={undoVerification} size="sm" variant="link" ml={2}>
                Undo
              </Button>
            )}
          </>
        }
      >
        <QualityControlDetails scan={scan} />
        {hasPermission('quality-control/rescan') && (
          <Box mx={2} mt={2}>
            <Button
              onClick={rescan}
              size="sm"
              w="100%"
              variant="solid"
              colorScheme="orange"
              leftIcon={<RescannedIcon />}
            >
              Rescan
            </Button>
          </Box>
        )}
      </ControlBox>
    );
  }
  if (status === 'RESCANNED') {
    return (
      <ControlBox
        colorScheme="orange"
        header={
          <>
            <Flex>
              <Icon as={RescannedIcon} mr={2} fontSize="24px" />
              Rescanned
            </Flex>
            {hasPermission('quality-control/verify') && (
              <Button onClick={undoRescan} size="sm" variant="link" ml={2}>
                Undo
              </Button>
            )}
          </>
        }
      >
        <QualityControlDetails scan={scan} />
      </ControlBox>
    );
  }
  return null;
};

const ControlBox = ({
  header,
  colorScheme,
  children,
  ...props
}: BoxProps & {header: React.ReactNode; colorScheme: string}) => {
  return (
    <Box
      bg={`${colorScheme}.50`}
      borderColor={`${colorScheme}.200`}
      borderWidth="1px"
      borderRadius="4px"
    >
      <Flex
        align="center"
        color={`${colorScheme}.700`}
        justify="space-between"
        py={2}
        px={2}
        borderBottomWidth="1px"
        borderColor={`${colorScheme}.200`}
      >
        {header}
      </Flex>
      <Box py={2}>{children}</Box>
    </Box>
  );
};

const Label = (props: BoxProps) => <GridItem textAlign="right" {...props} color="gray.600" />;

const QualityControlDetails = ({scan}) => {
  const locale = useLocale();
  const {rejectionReason, verifiedOn, verifier} = scan;
  const email = verifier?.account?.email || 'Unknown';
  return (
    <Grid templateColumns="85px 1fr" gridColumnGap={4} fontSize="13px">
      <Label>Control by</Label>
      <GridItem>{email}</GridItem>
      {rejectionReason && (
        <>
          <Label>Reason</Label>
          <GridItem>{locale.rejectionReasons[rejectionReason]}</GridItem>
        </>
      )}
      <Label>Date</Label>
      <GridItem>
        <DateTime date={new Date(verifiedOn)} />
      </GridItem>
    </Grid>
  );
};
