import React from 'react';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  AlertIcon,
  Box,
  Flex,
  FormLabel,
  FormControl,
  GridItem,
  Grid,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
  ModalBody,
  NumberInputField,
  NumberInput,
  Select
} from '@chakra-ui/react';
import invariant from 'tiny-invariant';

import {ACCESS_LEVELS, useApp, useLocale} from 'shared';
import {Heading2, Button, Spinner} from 'components/core';
import {AnnotationLabelInput} from 'components/annotations/annotation-label-input';
import {ScanForm} from 'components/scan-form';
import {useSearchQueryForm} from './scan-query-state';
import {useFetchAILabels} from 'components/settings/ai-labels-api';

export const DialogAdvancedSearch = ({query, onClose}) => {
  const l = useLocale();
  const app = useApp();
  const queryState = useSearchQueryForm(query);
  const {data, error, isLoading} = useFetchAILabels();
  const {user} = app.state;

  const handleChange = (field, value) => {
    if (value === null) {
      value = undefined;
    }
    queryState.setValue(field, value);
  };

  const getIsAnnotatedValue = () => {
    const expression = query.getExpression('image.numberOfAnnotations');
    if (!expression) {
      return '';
    }
    return expression.operator === 'is-not' ? 'yes' : 'no';
  };

  const handleIsAnnotatedChange = (value) => {
    if (value === '') {
      queryState.deleteExpression('image.numberOfAnnotations');
    } else {
      queryState.setExpression('image.numberOfAnnotations', {
        operator: value === 'yes' ? 'is-not' : 'is',
        value: 0
      });
    }
  };

  const getAnnotationVerificationValue = () => {
    let expression = query.getExpression('image.numberOfVerifiedAnnotations');
    if (expression) {
      return 'yes';
    }
    expression = query.getExpression('image.numberOfUnverifiedAnnotations');
    if (expression) {
      return 'no';
    }
    return '';
  };

  const handleAnnotationVerificationChange = (value) => {
    if (value === '') {
      queryState.deleteExpression('image.numberOfVerifiedAnnotations');
      queryState.deleteExpression('image.numberOfUnverifiedAnnotations');
    } else if (value === 'yes') {
      queryState.setExpression('image.numberOfVerifiedAnnotations', {
        operator: 'is-not',
        value: 0
      });
      queryState.deleteExpression('image.numberOfUnverifiedAnnotations');
    } else {
      queryState.setExpression('image.numberOfUnverifiedAnnotations', {
        operator: 'is-not',
        value: 0
      });
      queryState.deleteExpression('image.numberOfVerifiedAnnotations');
    }
  };

  const getHasMultipleAnnotationLabelsValue = () => {
    const expression = query.getExpression('image.numberOfAnnotationLabels');
    if (!expression) {
      return '';
    }
    return expression.operator === 'is-greater-than' ? 'yes' : 'no';
  };

  const handleHasMultipleAnnotationLabelsChange = (value) => {
    if (value === '') {
      queryState.deleteExpression('image.numberOfAnnotationLabels');
    } else {
      queryState.setExpression('image.numberOfAnnotationLabels', {
        operator: value === 'yes' ? 'is-greater-than' : 'is',
        value: 1
      });
    }
  };

  const getHasScannedDiagnosisValue = () => {
    const expression = query.getExpression('hasScannedDiagnosis');
    if (!expression) {
      return '';
    }
    return expression.value === true ? 'yes' : 'no';
  };

  const handleHasScannedDiagnosisChange = (value) => {
    if (value === '') {
      queryState.deleteExpression('hasScannedDiagnosis');
    } else {
      queryState.setExpression('hasScannedDiagnosis', {
        operator: 'is',
        value: value === 'yes'
      });
    }
  };

  const handleStatusChange = (value) => {
    if (value === '') {
      queryState.deleteExpression('status');
    } else {
      queryState.setExpression('status', {operator: 'is', value});
    }
  };

  const handleFormatChange = (value) => {
    if (value === '') {
      queryState.deleteExpression('imageFormat');
    } else {
      queryState.setExpression('imageFormat', {operator: 'is', value});
    }
  };

  const clear = () => {
    queryState.clear();
  };

  const fields = query.toJSON().map(({field}) => field);
  const freeText = query.getValue('freeText');
  const isFormFilled = fields.filter((field) => field !== 'freeText').length > 0;

  if (isLoading) {
    return (
      <>
        <ModalHeader>Search</ModalHeader>
        <ModalBody>
          <Box h={300}>
            <Spinner />
          </Box>
        </ModalBody>
      </>
    );
  }

  if (error) {
    return (
      <>
        <ModalHeader>Search</ModalHeader>
        <ModalBody>
          <Alert status="error">Unable to load the AI labels</Alert>
        </ModalBody>
      </>
    );
  }

  invariant(data);
  const {labels, models} = data;

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onClose(query);
      }}
    >
      <ModalHeader>
        Search filters
        <Box color="gray.500" fontSize="0.8rem">
          <strong>{l.todo(`Tip: `)}</strong>
          {l.todo(`Use * to ignore one or more characters.`)}
        </Box>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {freeText && (
          <Alert status="info" mb={4}>
            <AlertIcon />
            <AlertTitle>Current search: "{freeText}"</AlertTitle>
            <AlertDescription>Fill the form to add more search filters.</AlertDescription>
          </Alert>
        )}
        <Box flex={1} overflowY="scroll" h={500} borderWidth="1px" p={4} borderRadius="0.375rem">
          <ScanForm
            getValue={(field) => query.getValue(field)}
            setValue={(field, value) => handleChange(field, value)}
            isSearchForm
          />

          <Grid templateColumns="repeat(12, 1fr)" gap={2} mt={4}>
            <GridItem colSpan={4}>
              <FormControl id="hasScannedDiagnosis">
                <FormLabel>{l.todo('Scanned diagnosis?')}</FormLabel>
                <Select
                  value={getHasScannedDiagnosisValue()}
                  onChange={(event) => handleHasScannedDiagnosisChange(event.target.value)}
                >
                  <option></option>
                  <option value="yes">{l.todo('Yes')}</option>
                  <option value="no">{l.todo('No')}</option>
                </Select>
              </FormControl>
            </GridItem>

            <GridItem colSpan={3}>
              <FormControl id="Status">
                <FormLabel>{l.todo('Status')}</FormLabel>
                <Select
                  value={query.getValue('status')}
                  onChange={(event) => handleStatusChange(event.target.value)}
                >
                  <option></option>
                  <option value="TO_BE_VERIFIED">To be verified</option>
                  <option value="VERIFIED">Verified</option>
                  <option value="REJECTED">To be rescanned</option>
                  <option value="RESCANNED">Rescanned</option>
                </Select>
              </FormControl>
            </GridItem>

            <GridItem colSpan={3}>
              <FormControl id="format">
                <FormLabel>{l.todo('Image format')}</FormLabel>
                <Select
                  value={query.getValue('imageFormat')}
                  onChange={(event) => handleFormatChange(event.target.value)}
                >
                  <option></option>
                  <option value="JPEG">JPEG</option>
                  <option value="NDPI">NDPI</option>
                  <option value="SCN">SCN</option>
                  <option value="SVS">SVS</option>
                  <option value="TIFF">TIFF</option>
                </Select>
              </FormControl>
            </GridItem>

            <GridItem colSpan={2}>
              <FormControl id="batch">
                <FormLabel>{l.todo('Batch #')}</FormLabel>
                <NumberInput
                  value={query.getValue('image.batch.number')}
                  onChange={(value) => {
                    handleChange('image.batch.number', value);
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>
            </GridItem>
          </Grid>

          <Heading2 color="primary.500" mt={4}>
            WSI Labels
          </Heading2>

          <AnnotationLabelInput
            labelName={query.getValue('image.wsiAnnotations.labelName')}
            onChange={(value) => {
              handleChange('image.wsiAnnotations.labelName', value);
            }}
            models={models}
            labels={labels}
            mt={4}
          />

          <Heading2 color="primary.500" mt={4}>
            Annotations
          </Heading2>

          <Grid templateColumns="repeat(12, 1fr)" gap={2} mt={4}>
            <GridItem colSpan={4}>
              <FormControl id="isAnnotated">
                <FormLabel>{l.todo('Annotated?')}</FormLabel>
                <Select
                  value={getIsAnnotatedValue()}
                  onChange={(event) => handleIsAnnotatedChange(event.target.value)}
                >
                  <option></option>
                  <option value="yes">{l.todo('Yes')}</option>
                  <option value="no">{l.todo('No')}</option>
                </Select>
              </FormControl>
            </GridItem>

            {user.accessLevel >= ACCESS_LEVELS.SUPER_ADMIN && (
              <GridItem colSpan={4}>
                <FormControl id="annotationVerification">
                  <FormLabel>{l.todo('Verified?')}</FormLabel>
                  <Select
                    value={getAnnotationVerificationValue()}
                    onChange={(event) => handleAnnotationVerificationChange(event.target.value)}
                  >
                    <option></option>
                    <option value="yes">{l.todo('Yes')}</option>
                    <option value="no">{l.todo('No')}</option>
                  </Select>
                </FormControl>
              </GridItem>
            )}

            <GridItem colSpan={4}>
              <FormControl id="multipleAnnotationLabels">
                <FormLabel>{l.todo('Multiple labels?')}</FormLabel>
                <Select
                  value={getHasMultipleAnnotationLabelsValue()}
                  onChange={(event) => handleHasMultipleAnnotationLabelsChange(event.target.value)}
                >
                  <option></option>
                  <option value="yes">{l.todo('Yes')}</option>
                  <option value="no">{l.todo('No')}</option>
                </Select>
              </FormControl>
            </GridItem>
          </Grid>

          <AnnotationLabelInput
            labelName={query.getValue('image.annotations.labelName')}
            onChange={(value) => {
              handleChange('image.annotations.labelName', value);
            }}
            models={models}
            labels={labels}
            mt={4}
          />
        </Box>
      </ModalBody>

      <ModalFooter>
        <Flex w="100%">
          <Box flexGrow={1}>
            <Button type="button" onClick={clear} disabled={query.isEmpty()}>
              {l.todo('Clear')}
            </Button>
          </Box>
          <Box>
            <Button type="button" onClick={() => onClose(false)} mr={2}>
              {l.cancelButtonLabel}
            </Button>
            <Button type="submit" primary isDisabled={!isFormFilled}>
              {l.todo('Search')}
            </Button>
          </Box>
        </Flex>
      </ModalFooter>
    </form>
  );
};
