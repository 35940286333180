import {useState} from 'react';
import {createContainer} from 'unstated-next';
import debugModule from 'debug';
import {useUpdateEffect} from 'react-use';

import {useModal} from 'components/core';
import {useApp, useLocale, usePreferences} from 'shared';
import {ImageContainer} from 'components/image-viewer/image-container';
import {AIDataContainer, getAILabelByName} from 'components/settings/ai-labels-api';
import {AnnotationModelChooser} from './annotation-model-chooser';
import {useRefreshPrediction, useRequestPrediction, useRemovePrediction} from './prediction-api';

const debug = debugModule('medmain:predictions');

const DEFAULT_THRESHOLD = 50;

export function useImagePredictions() {
  const app = useApp();
  const modal = useModal();
  const locale = useLocale();

  const {image, scan} = ImageContainer.useContainer();
  const {predictions} = image;
  const {data} = AIDataContainer.useContainer();
  const allLabels = data?.labels || [];

  const [viewMode, setViewMode] = usePreferences('predictionViewMode');
  const [threshold, setThreshold] = useState<number>(DEFAULT_THRESHOLD);

  const [activePredictionId, setActivePredictionId] = useState<string | undefined>(
    predictions[0]?.id
  );

  const activePrediction = predictions.find(({id}) => id === activePredictionId);
  const labelNames = activePrediction?.results ? Object.keys(activePrediction.results.labels) : [];
  const labels = labelNames.map((name) => getAILabelByName(allLabels, name));
  const [activeLabelNames, setActiveLabelNames] = useState<string[]>(
    labels.map((label) => label.name)
  ); // when a prediction is loaded, all its labels are selected

  useUpdateEffect(() => {
    if (!predictions.length) return;
    setActivePredictionId(predictions[0]?.id);
    setActiveLabelNames(labels.map((label) => label.name));
  }, [predictions.length, predictions[0]?.status]); // eslint-disable-line react-hooks/exhaustive-deps

  useRefreshPrediction(image.id, predictions?.[0]);

  const requestPrediction = useRequestPrediction();
  const removePrediction = useRemovePrediction();

  const handlePrediction = async () => {
    const {predictions} = image;
    const prediction = predictions?.[0];
    let modelName;
    let modelVersion;

    if (!prediction) {
      const modelChoice = await modal.dialog({
        render: (close) => <AnnotationModelChooser onClose={close} />
      });
      if (!modelChoice) {
        return;
      }
      ({modelName, modelVersion} = modelChoice);
    } else {
      modelName = prediction.modelName;
      if (prediction.status === 'WAITING') {
        const ok = await modal.confirm(
          locale.todo(
            'A prediction has already been requested. Are you sure you want to abort the request?'
          ),
          {title: 'Warning'}
        );
        if (!ok) {
          return;
        }
        await app.removePrediction(image);
        window.location.reload();
        return;
      }
      if (prediction.status === 'RUNNING') {
        await modal.alert(
          locale.todo('A prediction is currently running. Thank you for waiting.'),
          {title: 'Running prediction'}
        );
        return;
      }
      if (prediction.status === 'COMPLETED') {
        const okay = await modal.confirm(
          locale.todo('A prediction already exists. Would you like to request a new one?'),
          {title: 'New prediction'}
        );
        if (!okay) {
          return;
        }
      }
      if (prediction.status === 'FAILED') {
        const okay = await modal.confirm(
          locale.todo(`The last prediction has failed. Would you like to try again?`),
          {title: 'Prediction failure'}
        );
        if (!okay) {
          return;
        }
      }
    }

    debug('Request a prediction', modelName, modelVersion);
    await requestPrediction.mutate({imageId: image.id, modelName, modelVersion});
  };

  const handleRemovePrediction = async (predictionId) => {
    if (
      !(await modal.confirm('Are you sure you want to remove this prediction?', {title: 'Warning'}))
    )
      return;
    await removePrediction.mutateAsync({imageId: image.id, predictionId});
  };

  const isTrialImage = scan?.type === 'TRIAL';

  return {
    predictions,
    activePredictionId,
    activeLabelNames,
    labels,
    handlePrediction,
    setActiveLabelNames,
    switchPrediction: setActivePredictionId,
    viewMode,
    setViewMode,
    threshold,
    setThreshold,
    removePrediction: handleRemovePrediction,
    // TODO add trial flow methods if needed (verify, undoVerification...)
    isTrialImage
  };
}

export const PredictionPluginContainer = createContainer(useImagePredictions);
