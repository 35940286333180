import React from 'react';

import {Sorry} from './sorry';

// From https://reactjs.org/docs/concurrent-mode-suspense.html#handling-errors
type Props = {fallback: React.ReactNode};
type State = {error: Error | null};
export class ErrorBoundary extends React.Component<Props, State> {
  state = {error: null};
  static getDerivedStateFromError(error) {
    return {error};
  }
  render() {
    if (!!this.state.error) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

export const ErrorFallback = ({error}: {error?: Error}) => {
  return (
    <Sorry message={'An error has occurred. ' + error?.message} info={window.navigator.userAgent} />
  );
};
