import React, {useMemo} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Box, Flex, Link} from '@chakra-ui/react';

import {useLocale} from 'shared';
import {TimeAgo, Table, formatDateTime} from 'components/core';

const DEFAULT_ORDER_BY = 'createdOn';
const DEFAULT_ORDER_DIRECTION = 'DESC';

export const AnnotationList = ({annotations}) => {
  const locale = useLocale();

  const data = useMemo(() => annotations, [annotations]);

  const columns = useMemo(
    () => [
      {
        Header: 'Image',
        accessor: (annotation) => (
          <Link
            as={RouterLink}
            to={`/images/${annotation.image.id}`}
            color="primary.600"
            _hover={{color: 'primary.500'}}
          >
            {annotation.image?.filename || '-'}
          </Link>
        )
      },
      {
        Header: 'Model',
        accessor: (annotation) => locale.get(annotation.label?.model?.displayName),
        styleProps: {
          width: 100
        }
      },
      {
        Header: 'Label',
        accessor: (annotation) => {
          return (
            <Flex alignItems="center">
              <Box
                w="5px"
                h="20px"
                bg={annotation.label?.color}
                mr={1}
                borderRightWidth="1px"
                borderLeftWidth="1px"
              />
              {locale.get(annotation.label?.displayName)}
            </Flex>
          );
        },
        styleProps: {
          width: 240,
          py: 0
        }
      },
      {
        Header: 'User',
        accessor: (annotation) => annotation.user.account.getFullName(locale),
        styleProps: {
          width: 200
        }
      },
      {
        id: 'createdOn',
        Header: 'Date',
        accessor: (annotation) => {
          const date = annotation.createdOn;
          return (
            <>
              <Box>{formatDateTime(date)}</Box>
              <Box color="gray.500" mt={1}>
                <TimeAgo date={date} />
              </Box>
            </>
          );
        },
        styleProps: {
          width: 200
        }
      }
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Table
      data={data}
      columns={columns}
      size="sm"
      orderBy={DEFAULT_ORDER_BY}
      orderDirection={DEFAULT_ORDER_DIRECTION}
    />
  );
};
