import React, {useRef, useState} from 'react';
import {
  Box,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ButtonGroup,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Alert,
  AlertIcon,
  AlertDescription,
  Code
} from '@chakra-ui/react';
import {IoMdPricetag} from 'react-icons/io';
import invariant from 'tiny-invariant';

import {Button} from 'components/core';
import {AnnotationLabelInput} from 'components/annotations/annotation-label-input';
import {AILabel, AIModel} from 'components/settings/types';

type UserInput = {
  labelName: string;
  description: string;
};

type Props = {
  labels: AILabel[];
  models: AIModel[];
  onClose: (result?: UserInput) => void;
  image: Datastore.Image; // not available when doing bulk updates
};
export const DialogAddWSILabel = ({onClose, labels, models, image}: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const [labelName, setLabelName] = useState('');

  const defaultModelName = '';
  invariant(image.wsiAnnotations);
  const isDuplicate = image.wsiAnnotations.some((label) => label.labelName === labelName);
  const isFormValid = !!labelName && !isDuplicate;

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        invariant(ref.current);
        const description = ref.current.value;
        onClose({labelName, description});
      }}
    >
      <ModalHeader>
        Add WSI label
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Stack spacing={6}>
          <Box>
            Pick the label to be applied to the image <Code>{image.filename}</Code>.
          </Box>
          <Box>
            <AnnotationLabelInput
              labelName={labelName}
              onChange={(value) => setLabelName(value)}
              models={models}
              labels={labels}
              defaultModelName={defaultModelName}
            />
          </Box>
          <Box>
            <FormControl id="description">
              <FormLabel>
                Description{' '}
                <Box as="span" color="gray.500" ml={2} fontStyle="italic">
                  (Optional)
                </Box>
              </FormLabel>
              <Input ref={ref} />
            </FormControl>
          </Box>
          {isDuplicate && (
            <Alert status="warning">
              <AlertIcon />
              <AlertDescription>
                Duplicate label: "{labelName}". Please pick an other label.
              </AlertDescription>
            </Alert>
          )}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button
            type="submit"
            primary
            rightIcon={<IoMdPricetag fontSize={20} />}
            isDisabled={!isFormValid}
          >
            Add WSI label
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </form>
  );
};
