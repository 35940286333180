import React, {useMemo} from 'react';
import {Box} from '@chakra-ui/react';

import {useLocale} from 'shared';
import {Table, TimeAgo} from 'components/core';
import {BatchNumberLink} from 'components/scan-list/scan-columns';

export const UploadingImageTable = ({images}) => {
  const locale = useLocale();

  const data = useMemo(() => images, [images]);
  const columns = useMemo(
    () => [
      {
        Header: 'Filename',
        accessor: (image) => image.filename
      },
      {
        Header: 'Size',
        accessor: (image) => locale.formatFileSize(image.size),
        styleProps: {
          width: '150px',
          textAlign: 'right'
        }
      },
      {
        Header: 'Batch',
        accessor: (image) => <BatchNumberLink batchNumber={image.batch.number} />,
        styleProps: {
          width: '80px'
        }
      },
      {
        Header: 'User',
        accessor: (image) => {
          return image.user?.account?.email || '?';
        },
        styleProps: {
          width: '200px'
        }
      },
      {
        Header: 'Date',
        accessor: (image) => <TimeAgo date={new Date(image.updatedOn)} />,
        styleProps: {
          width: '150px'
        }
      }
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );
  return (
    <Box borderWidth="1px">
      <Table data={data} columns={columns} size="sm" />
    </Box>
  );
};
