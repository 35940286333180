import {ImageViewerPlugin} from 'components/image-viewer/types';

export * from './annotation-plugin';
export * from './download-plugin';
export * from './magnification-plugin';
export * from './prediction-plugin';
export * from './rotation-plugin';
export * from './scale-bar-plugin';
export * from './wsi-annotations-plugin';
export * from './zoom-plugin';

const defaultPlugin = {
  MainArea() {
    return null;
  },
  TopArea() {
    return null;
  },
  RightArea() {
    return null;
  },
  BottomArea() {
    return null;
  }
};

export function createPlugin(plugin: ImageViewerPlugin): ImageViewerPlugin {
  return {...defaultPlugin, ...plugin};
}
