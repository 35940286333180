import React from 'react';

import {usePermissions} from 'shared';
import {QualityControlList, useQualityControlList} from './quality-control-scan-list';
import {QualityControlNav} from './quality-control-home';
import {VerificationButtons} from './quality-control-bulk-actions';
import {useQualityControlColumns} from './quality-control-shared';

export const QualityControlInbox = () => {
  const {hasPermission} = usePermissions();
  const canVerify = hasPermission('quality-control/verify');

  const {defaultColumns: columns} = useQualityControlColumns();

  const state = useQualityControlList({status: 'TO_BE_VERIFIED', orderBy: 'createdOn'});

  return (
    <>
      <QualityControlNav tabIndex={0} />
      <QualityControlList
        columns={columns}
        footer={
          canVerify ? (
            <VerificationButtons
              selection={state.selection}
              setSelection={state.setSelection}
              reloadScans={state.scanListState.refetch}
            />
          ) : null
        }
        {...state}
      />
    </>
  );
};
