import {useState} from 'react';
import {createContainer} from 'unstated-next';
import debugModule from 'debug';
import {useUpdateEffect} from 'react-use';

import {ScanColumnKey} from 'components/scan-list/scan-table';
import {DEFAULT_COLUMN_KEYS} from 'components/scan-list/scan-table-custom-columns';

const debug = debugModule('medmain:preferences');

export type ViewMode = 'table' | 'gallery';

export type Preferences = {
  scanListViewMode: ViewMode;
  galleryThumbnailSize: number;
  scanTableColumns: ScanColumnKey[];
  exportColumns: string[];
  predictionViewMode: 'highlight' | 'heatmap';
  defaultSettingsPathname: string;
  scanDetailsPaneIsOpen: boolean;
};

export const defaultValues: Preferences = {
  scanListViewMode: 'gallery',
  galleryThumbnailSize: 256,
  scanTableColumns: DEFAULT_COLUMN_KEYS,
  exportColumns: ['id', 'imageFilename', 'supplier', 'tags'],
  predictionViewMode: 'highlight',
  defaultSettingsPathname: '/settings/labels',
  scanDetailsPaneIsOpen: false
};

export function usePreferencesState() {
  const key = 'datastore-preferences';
  const [preferences, setPreferences] = useState(() => readLocalStorage(key));

  useUpdateEffect(() => {
    writeLocalStorage(key, preferences);
  }, [preferences]); // TODO throttle side effects?

  function getPreference<K extends keyof Preferences>(key: K): Preferences[K] {
    const value = preferences[key];
    return value;
  }

  function setPreference<K extends keyof Preferences>(key: K, value: Preferences[K]): void {
    setPreferences((current) => ({
      ...(current as Preferences),
      [key]: value
    }));
  }

  return {getPreference, setPreference};
}

export const PreferencesContainer = createContainer(usePreferencesState);

export function usePreferences<K extends keyof Preferences>(key: K) {
  const {getPreference, setPreference} = PreferencesContainer.useContainer();
  const value = getPreference(key);
  const setValue = (value) => setPreference(key, value);
  return [value, setValue] as const;
}

function readLocalStorage(key: string) {
  try {
    debug('Read preferences from Local Storage');
    const rawContent = window.localStorage.getItem(key);
    const storedPreferences = rawContent ? JSON.parse(rawContent) : {};
    return {...defaultValues, ...storedPreferences};
  } catch (err) {
    debug('Unable to read the local storage');
    return defaultValues;
  }
}

function writeLocalStorage(key: string, values: Record<string, any>) {
  try {
    debug('Write preferences to Local Storage', values);
    window.localStorage.setItem(key, JSON.stringify(values));
  } catch (err) {
    debug('Unable to write to the local storage');
  }
}

// HOC needed because ImageViewer is still a "class component"
export const withPreference = (Component) => (props: any) => {
  const state = PreferencesContainer.useContainer();
  return <Component {...props} preferenceState={state} />;
};
