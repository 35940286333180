import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';

import {usePermissions} from 'shared';
import {ScanHome} from './scan-home';
import {UserListPage} from './users/user-list-page';
import {UserEditor} from './users/user-editor';
import {AnnotationListPage} from './annotations/annotation-list-page';
import {AnnotationReport} from './annotation-report';
import {TrialHome} from './trial/trial-home';
import {ImportCSVPage} from './import-csv/import-csv-page';
import {Dashboard} from './dashboard';
import {PredictionListPage} from './predictions/prediction-list-page';
import {QualityControlHome} from './quality-control/quality-control-home';
import {TrashBinPage} from './trash-bin/trash-bin-page';
import {NotFound} from './sorry';
import {SettingsRoot} from './settings/settings-root';
import {EvaluationsRoot} from './evaluations/evaluations-root';
import {CSVBulkActionsPage} from './csv-bulk-actions/csv-bulk-actions-page';
import {AppLayout} from './layout/layout';

// Used for code splitting => default export
const Home = () => {
  const {hasPermission, isSuperAdmin} = usePermissions();

  return (
    <AppLayout>
      <Switch>
        <Redirect exact from="/" to="/scans" />
        <Route path="/scans" component={ScanHome} />
        <Route path="/import-csv/" component={ImportCSVPage} />
        {hasPermission('dashboard') && <Route path="/dashboard/" component={Dashboard} />}
        {hasPermission('quality-control/view') && (
          <Route path="/quality-control/" component={QualityControlHome} />
        )}
        {hasPermission('trial/view') && <Route path="/trial" component={TrialHome} />}
        {isSuperAdmin && <Route exact path="/annotations" component={AnnotationListPage} />}
        {isSuperAdmin && (
          <Route path="/annotations/report/:year?/:month?" component={AnnotationReport} />
        )}
        {hasPermission('evaluations/view') && (
          <Route path="/evaluations" component={EvaluationsRoot} />
        )}
        {isSuperAdmin && <Route exact path="/users" component={UserListPage} />}
        {isSuperAdmin && <Route path="/users/:userId" component={UserEditor} />}
        {isSuperAdmin && <Route exact path="/predictions" component={PredictionListPage} />}
        {isSuperAdmin && <Route path="/bin" component={TrashBinPage} />}
        {hasPermission('settings/view') && <Route path="/settings" component={SettingsRoot} />}
        {isSuperAdmin && <Route path="/csv-bulk-actions" component={CSVBulkActionsPage} />}
        <Route component={NotFound} />
      </Switch>
    </AppLayout>
  );
};

export default Home;
