import {Box, IconButton} from '@chakra-ui/react';

import {DeleteIcon, Table} from 'components/core';
import {AIModel} from 'components/settings/types';

type Props = {
  models: AIModel[];
  onDelete: (model: AIModel) => void;
};
export const ModelList = ({models, onDelete}: Props) => {
  const data = models;
  const columns = [
    {
      Header: 'Name',
      accessor: (model: AIModel) => model.displayName
    },
    {
      Header: 'Number of labels',
      accessor: (model: AIModel) =>
        model.labelCount || (
          <Box fontStyle="italic" color="gray.500">
            No labels
          </Box>
        ),
      styleProps: {
        width: 150
      }
    },
    {
      id: 'actions',
      accessor: (model: AIModel) => {
        const canDelete = !model.labelCount;
        return (
          <>
            {canDelete && (
              <IconButton
                size="sm"
                aria-label="Delete"
                onClick={() => onDelete(model)}
                icon={<DeleteIcon />}
                colorScheme="red"
              />
            )}
          </>
        );
      },
      styleProps: {
        width: 50
      }
    }
  ];
  return (
    <Box maxW={600} borderWidth="1px">
      <Table data={data} columns={columns} size="sm" />
    </Box>
  );
};
