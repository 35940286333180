import {useQuery} from 'react-query';

import {useApp} from 'shared';

export type ReportQuery = {
  year: number;
  month: number;
  verificationStatus: 'all' | 'verified' | 'unverified';
  viewType: 'calendar' | 'billing';
};
export function useFetchReport(query: ReportQuery) {
  const app = useApp();

  const loadData = async () => {
    const {users} = await app.generateAnnotationReport(query);
    return users;
  };

  return useQuery(['annotation-report', query], loadData, {
    cacheTime: 1000 * 60 * 60 // override the default duration of 5 minutes => 1 hour
  });
}
