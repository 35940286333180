import React, {useMemo, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Alert, Box, Button as ChakraButton, ButtonGroup, Flex, Icon} from '@chakra-ui/react';
import {MdDelete, MdEdit, MdGridOn} from 'react-icons/md';
import flatten from 'lodash/flatten';
import compact from 'lodash/compact';
import invariant from 'tiny-invariant';

import {
  ACCESS_LEVELS,
  ItemSelection,
  PaginationCount,
  SearchOptions,
  useParseURL,
  useApp,
  useLocale,
  hasPermission
} from 'shared';
import {ScanQuery} from 'models/scan-query';
import {
  AddIcon,
  Button,
  ChevronUpIcon,
  DropdownMenu,
  MenuItem,
  Heading1,
  Spinner,
  SubTitle,
  RefetchingIndicator,
  RemoveIcon
} from 'components/core';
import {ScanSplitLayout} from 'components/scan-split-layout/scan-split-layout';
import {useQualityControlActions} from 'components/quality-control/quality-control-bulk-actions';
import {useScanListActions, useScanSearch} from './scan-list-actions';
import {QuickSearchForm} from './quick-search-form';
import {PaginatedScanList} from './scan-list-shared';
import {SearchDescription} from './search-description';
import {RejectedIcon, VerifiedIcon} from 'components/quality-control/quality-control-shared';
import {useScanColumns} from './scan-table';
import {EraserIcon} from 'components/image-viewer/plugins/icons';
import {ScanTableCustomColumnsContainer} from './scan-table-custom-columns';
import {commonReturnFields, useFetchScans} from './fetch-scans';

export const ScanListPage = () => {
  return (
    <ScanTableCustomColumnsContainer.Provider>
      <ScanListPageInner />
    </ScanTableCustomColumnsContainer.Provider>
  );
};

const ScanListPageInner = () => {
  const {getReturnFields} = ScanTableCustomColumnsContainer.useContainer();
  const customFields = getReturnFields();
  const returnFields = [...commonReturnFields, ...customFields];

  const searchOptions = useParseURL({limit: 50});

  const fetchScansQuery = useFetchScans(searchOptions, returnFields);
  const {refetch} = fetchScansQuery;

  return (
    <ScanSplitLayout onRefresh={refetch}>
      <ScanListPane fetchScansQuery={fetchScansQuery} searchOptions={searchOptions} />
    </ScanSplitLayout>
  );
};

export const ScanListPane = ({
  fetchScansQuery,
  searchOptions
}: {
  searchOptions: Required<SearchOptions>;
  fetchScansQuery: ReturnType<typeof useFetchScans>;
}) => {
  const {data, error, isLoading, isFetching, refetch: reloadScans} = fetchScansQuery;
  if (isLoading) return <Spinner />;
  if (error) return <Alert status="error">Unable to load the scans</Alert>;
  invariant(data);
  const {scans, total} = data;

  return (
    <Flex p={6} flexDir="column" h="100%" overflow="auto">
      <ViewScans
        scans={scans}
        total={total}
        searchOptions={searchOptions}
        reloadScans={reloadScans}
        isRefetching={isFetching}
      />
    </Flex>
  );
};

type Props = {
  scans: Datastore.Scan[];
  total: number;
  searchOptions: Required<SearchOptions>;
  reloadScans: () => void;
  isRefetching: boolean;
};
const ViewScans = ({scans, searchOptions, total, reloadScans, isRefetching}: Props) => {
  const locale = useLocale();
  const app = useApp();
  const {user} = app.state;
  const history = useHistory();
  const location = useLocation();

  const [selection, setSelection] = useState(new ItemSelection());
  const {
    addScans,
    addBulkWSILabels,
    removeBulkWSILabels,
    deleteScans,
    replaceScans,
    emptyScanFields,
    exportCSV
  } = useScanListActions({
    selection,
    setSelection,
    total,
    reloadScans
  });
  const {advancedSearch, quickSearch} = useScanSearch();
  const {verifyScans, rejectScans} = useQualityControlActions({
    selection,
    setSelection,
    reloadScans
  });
  const {keys: customColumnKeys, setKeys: setCustomColumnKeys} =
    ScanTableCustomColumnsContainer.useContainer();

  const isSuperAdmin = user.accessLevel >= ACCESS_LEVELS.SUPER_ADMIN; // >= 3
  const canAnnotate = user.accessLevel >= ACCESS_LEVELS.ADMIN; // >= 2

  const actionMenuItems: Array<MenuItem[]> = compact([
    isSuperAdmin && [
      {
        label: locale.todo('Export to CSV'),
        icon: <Icon as={MdGridOn} color="gray.500" fontSize="16x" />,
        onClick: exportCSV
      }
    ],
    isSuperAdmin && [
      {
        label: locale.todo('Replace fields'),
        icon: <Icon as={MdEdit} color="gray.500" fontSize="16x" />,
        onClick: replaceScans
      },
      {
        label: locale.todo('Empty fields'),
        icon: <EraserIcon color="#999" fontSize="16x" />,
        onClick: emptyScanFields
      }
    ],
    canAnnotate && [
      {
        label: locale.todo('Add WSI label'),
        icon: <Icon as={AddIcon} color="gray.500" fontSize="14px" />,
        onClick: addBulkWSILabels
      },
      {
        label: locale.todo('Remove WSI label'),
        icon: <Icon as={RemoveIcon} color="gray.500" fontSize="14px" />,
        onClick: removeBulkWSILabels
      }
    ],
    isSuperAdmin && [
      {
        label: locale.todo('Verify'),
        icon: <VerifiedIcon color="green.500" mr={1} fontSize="20px" width="20px" />,
        onClick: verifyScans
      },
      {
        label: locale.todo('Reject'),
        icon: <RejectedIcon color="red.400" fontSize="16x" />,
        onClick: rejectScans
      }
    ],
    hasPermission('scans/delete/bulk', user) && [
      {
        label: locale.todo('Delete scans'),
        icon: <Icon as={MdDelete} color="gray.500" fontSize="16x" />,
        onClick: deleteScans
      }
    ]
  ]);
  const availableActionCount = compact(flatten(actionMenuItems as any)).length;

  const showReport = () => {
    history.push({pathname: `/scans/report`, search: location.search});
  };
  const showStats = () => {
    history.push({pathname: `/scans/stats`, search: location.search});
  };

  const query = ScanQuery.fromJSON(searchOptions.query);
  const searchText = query.getExpression('freeText')?.value || '';

  const {
    thumbnail,
    columns: {details}
  } = useScanColumns();
  const {keys, columns: customColumns} = ScanTableCustomColumnsContainer.useContainer();

  const columns = useMemo(
    () => [thumbnail({size: 80}), details, ...customColumns],
    [keys] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // We don't want users to perform bulk actions on all scans in the DB (E.g. deleting everything... yes it happens!)
  const canPerformBulkActions = () => {
    if (selection.mode === 'all') {
      return !selection.isEmpty(total);
    }
    return !selection.isEmpty(total);
  };

  return (
    <>
      <Flex mb={1} alignItems="center" justifyContent="space-between">
        <Flex flexGrow={1} alignItems="center">
          <Heading1 m={0}>
            {locale.todo('Scans')}
            {scans.length > 0 && (
              <SubTitle>
                <PaginationCount
                  searchOptions={searchOptions}
                  total={total}
                  pageItemCount={scans.length}
                />
              </SubTitle>
            )}
          </Heading1>
          {isRefetching && <RefetchingIndicator ml={2} size="md" />}
        </Flex>
        <QuickSearchForm
          defaultValue={searchText}
          onSearch={(text) => {
            quickSearch(text);
          }}
        />
      </Flex>
      <PaginatedScanList
        scans={scans}
        searchOptions={searchOptions}
        total={total}
        selection={selection}
        setSelection={setSelection}
        columns={columns}
        customColumnKeys={customColumnKeys}
        onChangeCustomColumnKeys={setCustomColumnKeys}
        header={
          <>
            {!query.isEmpty() ? (
              <SearchDescription searchOptions={searchOptions} addQueryFilter={advancedSearch} />
            ) : (
              <Box color="gray.500" fontStyle="italic" fontSize="sm" borderLeftWidth="3px" pl={2}>
                Showing all scans (no filters)
              </Box>
            )}
          </>
        }
        footer={
          <ButtonGroup>
            {hasPermission('scans/create', user) && (
              <Button onClick={addScans} primary leftIcon={<AddIcon fontSize="20px" />}>
                {locale.todo('Add')}
              </Button>
            )}

            <Button onClick={() => advancedSearch({clearFreeText: true})}>
              {locale.todo('Search')}
            </Button>

            {user.accessLevel >= ACCESS_LEVELS.SUPER_ADMIN && (
              <Button onClick={showReport} disabled={total === 0}>
                {locale.todo('Report')}
              </Button>
            )}

            {user.accessLevel >= ACCESS_LEVELS.SUPER_ADMIN && (
              <Button onClick={showStats} disabled={total === 0}>
                {locale.todo('Stats')}
              </Button>
            )}

            {availableActionCount > 0 && (
              <Box>
                <DropdownMenu items={actionMenuItems} placement="top-start">
                  <ChakraButton
                    isDisabled={!canPerformBulkActions()}
                    rightIcon={<ChevronUpIcon />}
                    variant="outline"
                  >
                    {locale.todo('Actions')}
                  </ChakraButton>
                </DropdownMenu>
              </Box>
            )}
          </ButtonGroup>
        }
      />
    </>
  );
};
