import {OwnedItem} from 'models/owned-item';

import {Batch} from './batch';
import {Annotation} from './annotation';
import {Prediction} from './prediction';

export class Image extends OwnedItem {
  constructor(data, options) {
    data = {...data};

    data.batch = new Batch(data.batch, options);

    if (data.annotations) {
      data.annotations = data.annotations.map((annotation) => new Annotation(annotation, options));
    }

    if (data.predictions) {
      data.predictions = data.predictions.map((prediction) => new Prediction(prediction, options));
    }

    super(data, options);
  }

  getResolutionInDPI() {
    let result;

    const resolution = this.info?.resolution;
    if (resolution) {
      result = resolution.x;
      if (resolution.unit === 'DPC') {
        result *= 2.54;
      }
    }

    if (result === 25.4) {
      // TODO: Remove this when we are able to properly fetch the resolution from the image
      result = 22620 * 2.54; // 57454.8
    }

    return result;
  }

  getPrediction(id) {
    return this.predictions?.find((prediction) => prediction.id === id);
  }

  updatePrediction(updatedPrediction) {
    const predictions = this.predictions.map((prediction) =>
      prediction.id === updatedPrediction.id
        ? new Prediction(updatedPrediction, {deserialize: true})
        : prediction
    );
    this.predictions = predictions;
  }
}

export function getImageFormat({filename, type}) {
  type = (type || '').toLowerCase();

  if (type === 'image/jpeg') {
    return 'JPEG';
  }

  if (type === 'image/tiff') {
    return 'TIFF';
  }

  filename = (filename || '').toLowerCase();

  if (filename.endsWith('.jpeg') || filename.endsWith('.jpg')) {
    return 'JPEG';
  }

  if (filename.endsWith('.tiff') || filename.endsWith('.tif')) {
    return 'TIFF';
  }

  if (filename.endsWith('.ndpi')) {
    return 'NDPI';
  }

  if (filename.endsWith('.svs')) {
    return 'SVS';
  }

  if (filename.endsWith('.scn')) {
    return 'SCN';
  }

  return 'Unknown';
}

export function truncateFilename(filename) {
  const parts = filename.split('.');
  return parts[0];
}
