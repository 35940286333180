import React from 'react';
import round from 'lodash/round';
import {
  Box,
  Flex,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverFooter,
  PopoverTrigger,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb
} from '@chakra-ui/react';

import {Button, ChevronDownIcon} from 'components/core';

type Props = {
  magnification: number;
  maxMagnification: number;
  changeMagnification: (value: number) => void;
  resetMagnification: () => void;
};
export const MagnificationPicker = ({
  magnification,
  maxMagnification,
  changeMagnification,
  resetMagnification
}: Props) => {
  const magnificationPercent = (magnification / maxMagnification) * 100;

  const buttonMagnifications = [2, 5, 10, 20, 40].filter(
    (value) => value <= maxMagnification // show X40 option only for hi-resolution images
  );

  return (
    <Popover placement="bottom-start">
      {({isOpen, onClose}) => (
        <>
          <PopoverTrigger>
            <Box px={2} py={2} pointerEvents="all" cursor="pointer">
              {magnification > 0 ? `${magnification}x` : '...'}
              <ChevronDownIcon fontSize="16px" />
            </Box>
          </PopoverTrigger>
          <PopoverContent pointerEvents="all" width="350px" color="black">
            <PopoverCloseButton />
            <PopoverHeader>Magnification: {magnification}x</PopoverHeader>
            <PopoverBody>
              <Slider
                colorScheme="primary"
                value={magnificationPercent}
                onChange={(value) => changeMagnification(round((value / 100) * maxMagnification))}
                mt={2}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb backgroundColor="primary.500" color="blue" />
              </Slider>
            </PopoverBody>
            <PopoverFooter>
              <Flex justifyContent="space-between">
                <Button size="sm" onClick={() => resetMagnification()}>
                  Fit
                </Button>
                {buttonMagnifications.map((value) => (
                  <Button size="sm" key={value} onClick={() => changeMagnification(value)}>
                    {value}x
                  </Button>
                ))}
              </Flex>
            </PopoverFooter>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
