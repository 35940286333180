import React from 'react';
import {useQuery} from 'react-query';
import {Alert, Box, Flex, HStack} from '@chakra-ui/react';

import {useApp} from 'shared';
import {RefetchingIndicator, Spinner, Heading2, WarningIcon, PageContainer} from 'components/core';
import {BatchesByDay} from './batches-by-day';
import {ProcessingActivity} from './processing-activity';
import {isProcessingLate} from './dashboard-utils';

export const Dashboard = () => {
  const {data, isLoading, error, isFetching} = useFetchDashboard();
  if (isLoading) return <Spinner />;
  if (error) return <Alert status="error">Unable to load the scans</Alert>;

  return (
    <PageContainer maxW="container.lg" overflowY="auto">
      <Box position="relative">
        {isFetching && <RefetchingIndicator position="absolute" top={4} right={4} />}
        <ImageSection data={data} />
        <BatchSection batches={data.batches} />
      </Box>
    </PageContainer>
  );
};

function useFetchDashboard() {
  const app = useApp();

  const loadDashboard = async () => {
    const accessToken = app.getAccessToken();
    const backend = await app.getBackend();
    const {dashboard} = await backend.getDashboard({accessToken});
    return dashboard;
  };
  return useQuery(['dashboard'], loadDashboard, {
    keepPreviousData: true,
    refetchInterval: 1000 * 30, // refresh the dashboard data every 30 second
    cacheTime: 1000 * 60 * 60 // override the default duration of 5 minutes => 1 hour
  });
}

const BatchSection = ({batches}) => {
  return (
    <Box p={4} borderWidth="1px" bg="cardBg">
      <Heading2>Latest batches</Heading2>
      <BatchesByDay batches={batches} />
    </Box>
  );
};

const ImageSection = ({data}) => {
  const lateImages = data.scans.filter(isProcessingLate);

  return (
    <Box borderWidth="1px" mb={4} p={4} bg="cardBg">
      <Flex mb={2}>
        <Heading2 mb={0}>Image pipeline</Heading2>
        {lateImages.length > 0 && (
          <HStack color="orange.400" fontSize="sm" spacing={1} ml={2}>
            <WarningIcon color="orange.400" />
            <Box>Processing is late!</Box>
            <Box>({lateImages.length})</Box>
          </HStack>
        )}
      </Flex>
      <ProcessingActivity data={data} />
    </Box>
  );
};
