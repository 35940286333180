import React from 'react';
import {Box, Flex, Stack, StackDivider} from '@chakra-ui/react';

import {useLocale} from 'shared';
import {getAILabelByName} from 'components/settings/ai-labels-api';
import {AddIcon, RemoveIcon, TimeAgo} from 'components/core';
import {AILabel} from 'components/settings/types';
import {Panel, PanelIconButton, PanelHeader, PanelHeading1} from 'components/image-viewer/panel';

type Props = {
  annotations: Datastore.WSIAnnotation[];
  labels: AILabel[];
  onAddAnnotation?: () => void;
  onRemoveAnnotation?: (labelId: string) => void;
};
export const WSILabelPanel = ({
  annotations,
  labels,
  onAddAnnotation,
  onRemoveAnnotation
}: Props) => {
  if (annotations.length === 0) return null;
  return (
    <Panel>
      <PanelHeader>
        <Flex justifyContent="space-between">
          <PanelHeading1>WSI labels</PanelHeading1>
          {onAddAnnotation && (
            <PanelIconButton onClick={onAddAnnotation} aria-label="Add">
              <AddIcon fontSize="12px" />
            </PanelIconButton>
          )}
        </Flex>
      </PanelHeader>
      <Box p={2}>
        <Stack divider={<StackDivider borderColor="rgba(255,255,255,0.5)" />}>
          {annotations.map((annotation) => (
            <WSILabel
              key={annotation.id}
              annotation={annotation}
              labels={labels}
              onRemoveAnnotation={onRemoveAnnotation}
            />
          ))}
        </Stack>
      </Box>
    </Panel>
  );
};

type LabelProps = Omit<Props, 'annotations' | 'onAddAnnotation'> & {
  annotation: Datastore.WSIAnnotation;
};
const WSILabel = ({annotation, labels, onRemoveAnnotation}: LabelProps) => {
  const {createdOn, description} = annotation;
  return (
    <Box>
      <Flex justifyContent="space-between" mb={2}>
        <Box color="rgba(255, 255, 255, 1)" fontSize="16px">
          <LabelName annotation={annotation} labels={labels} />
        </Box>
        {onRemoveAnnotation && (
          <Box>
            <PanelIconButton onClick={() => onRemoveAnnotation(annotation.id)} aria-label="Remove">
              <RemoveIcon boxSize="12px" />
            </PanelIconButton>
          </Box>
        )}
      </Flex>
      {description && <Box color="rgba(255, 255, 255, 0.8)">{annotation.description}</Box>}
      <Box color="rgba(255, 255, 255, 0.8)" mt={1}>
        Added <TimeAgo date={new Date(createdOn)} />
      </Box>
    </Box>
  );
};

const LabelName = ({annotation, labels}: Omit<LabelProps, 'onRemoveAnnotation'>) => {
  const locale = useLocale();
  const {labelName} = annotation;

  const label = getAILabelByName(labels, labelName);

  if (!label) return <>{labelName}</>;

  return (
    <Flex alignItems="center">
      {locale.get(label.displayName)}
      <Box bg={label.color} w="12px" h="12px" borderRadius="0px" ml={2} borderWidth="1px" />
    </Flex>
  );
};
