import {useMemo} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Box, Link, VStack} from '@chakra-ui/react';
import {formatDuration, intervalToDuration} from 'date-fns';

import {formatDateTime, Table, TimeAgo} from 'components/core';
import {EvaluationIndexResource} from 'medrive-types';
import {EvaluationStatusIcon} from 'components/evaluations/evaluation-shared';

type Props = {
  evaluations: EvaluationIndexResource[];
};
export const EvaluationList = ({evaluations}: Props) => {
  const data = useMemo(() => evaluations, [evaluations]);
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: (result: EvaluationIndexResource) => {
          const {id} = result.evaluation;
          return (
            <Link as={RouterLink} to={`/evaluations/${id}`} color="primary.500" fontWeight="bold">
              {id}
            </Link>
          );
        }
      },
      {
        Header: 'Dataset',
        accessor: (result: EvaluationIndexResource) => {
          return (
            <>
              <Box mb={2}>{result.dataset.name}</Box>
              <Link
                as={RouterLink}
                to={`/evaluations/${result.evaluation.id}/predictions`}
                color="primary.500"
              >
                View slides
              </Link>
            </>
          );
        }
      },
      {
        Header: 'Model',
        accessor: (result: EvaluationIndexResource) => {
          return (
            <VStack alignItems="flex-start">
              <Box>{result.evaluation.modelName}</Box>
              <Box>{result.evaluation.modelVersion}</Box>
            </VStack>
          );
        }
      },
      {
        Header: 'Slides',
        accessor: (result: EvaluationIndexResource) => {
          return result.evaluation.slideNum;
        },
        styleProps: {
          textAlign: 'right'
        }
      },
      {
        id: 'status',
        accessor: (result: EvaluationIndexResource) => {
          return <EvaluationStatusIcon status={result.evaluation.status} />;
        },
        styleProps: {
          width: '20px'
        }
      },
      {
        Header: 'Duration',
        accessor: (result: EvaluationIndexResource) => {
          const {evaluation} = result;
          const start = new Date(evaluation.createdAt);
          const end = evaluation.finishedAt ? new Date(evaluation.finishedAt) : new Date();
          const duration = intervalToDuration({start, end});
          const format = getDurationFormat(duration);
          return formatDuration(duration, {format})
            .replace(/days?/, 'd')
            .replace(/hours?/, 'h')
            .replace(/minutes?/, 'm')
            .replace(/seconds?/, 's');
        },
        styleProps: {
          width: '100px'
        }
      },
      {
        Header: 'Date',
        accessor: (result: EvaluationIndexResource) => {
          const date = new Date(result.evaluation.createdAt);
          return (
            <>
              <TimeAgo date={date} />
              <Box mt={2}>{formatDateTime(date, {mask: 'yyyy-MM-dd'})}</Box>
            </>
          );
        },
        styleProps: {
          width: '130px'
        }
      }
    ],
    []
  );

  return <Table data={data} columns={columns} size="sm" />;
};

function getDurationFormat(duration: Duration) {
  const {days, hours} = duration;
  if (days && days > 0) return ['days', 'hours'];
  if (hours && hours > 0) return ['hours', 'minutes'];
  return ['minutes', 'seconds'];
}
