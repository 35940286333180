import React, {memo} from 'react';
import {
  Badge,
  Button,
  ButtonGroup,
  Box,
  BoxProps,
  Center,
  Container,
  DarkMode,
  Flex,
  Spinner,
  IconButton,
  Icon,
  Alert,
  HStack,
  Text,
  VStack,
  useDisclosure,
  useColorModeValue
} from '@chakra-ui/react';
import {Link as RouterLink} from 'react-router-dom';
import {HiOutlineDocumentText} from 'react-icons/hi';
import {MdBrokenImage, MdHourglassEmpty, MdOpenInNew, MdOutlineModeComment} from 'react-icons/md';
import {useUpdateEffect} from 'react-use';
import invariant from 'tiny-invariant';

import {useLocale, usePreferences} from 'shared';
import {FetchImageReturnData, useFetchImage} from 'components/image-viewer/fetch-image';
import {ImageViewerWithPlugins} from 'components/image-viewer/image-viewer-with-plugins';
import {ChevronDownIcon, ChevronUpIcon, EditIcon, InfoIcon, useModal} from 'components/core';
import {Sorry} from 'components/sorry';
import {ScanImageInfo} from 'components/scan-form/scan-image-info';
import {useFetchScan} from 'components/scan-form/scan-actions';
import {truncateFilename} from 'models/image';
import {ScanDataPane} from './scan-data-pane';
import {TabLayoutContainer} from './tab-layout-container';
import {TagLinkGroup} from 'components/scan-list/scan-columns';
import {DialogQuickEdit} from './dialog-quick-edit';

const pollingInterval = 1000 * 30; // polling is needed for processing images

type Props = {
  imageId: string;
  onSave?: () => void;
};
// memo() avoids re-renders when the parent layout component is polling scan list data
export const ScanViewPane = memo(({imageId, onSave}: Props) => {
  const locale = useLocale();
  const {data, isLoading, error} = useFetchImage(imageId, {
    shouldFetchRelatedImage: false,
    shouldFetchParentScan: true,
    refetchInterval: (data: FetchImageReturnData) => {
      const status = data?.image?.status;
      if (['UPLOADED', 'PROCESSING'].includes(status)) {
        return pollingInterval;
      }
      return false;
    }
  });

  const [defaultIsOpen, setDefaultIsOpen] = usePreferences('scanDetailsPaneIsOpen');
  const {getButtonProps, getDisclosureProps, isOpen} = useDisclosure({defaultIsOpen});

  useUpdateEffect(() => {
    setDefaultIsOpen(isOpen);
  }, [isOpen]);

  if (error) {
    return (
      <Sorry message={<>Unable to load the image.</>} info={(error as Error)?.message || ''} />
    );
  }

  const {image, parentScan: scan} = data || {};

  const isAvailable = image?.status === 'AVAILABLE';

  if (isLoading) {
    return (
      <Center h="100%" bg="blue.900">
        <Spinner size="xl" color="white" speed="1s" />
      </Center>
    );
  }

  invariant(image);

  return (
    <Flex h="100%" flexDir="column">
      {scan && onSave && <TagsArea scan={scan} onSave={onSave} />}
      {isAvailable ? (
        <ImageViewerWithPlugins image={image} scan={scan} isFullScreen={false} />
      ) : (
        <Center bg="gray.500" h="100%" color="white" fontSize="lg">
          <VStack spacing={6} p={6}>
            <NotAvailableImage status={image.status} />
          </VStack>
        </Center>
      )}
      <Box
        as="button"
        {...getButtonProps()}
        w="100%"
        display="flex"
        py={2}
        px={4}
        alignItems="center"
        justifyContent="center"
        bg="gray.100"
        borderTopWidth="2px"
        borderTopColor="gray.300"
      >
        <HStack
          divider={<Separator />}
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          <HStack>
            {scan ? <ToggleIcon isOpen={isOpen} /> : <Box>[Deleted scan]</Box>}
            <Box>{truncateFilename(image.filename)}</Box>
          </HStack>
          <Badge variant="outline">{image.format}</Badge>
          <Box color="gray.500">{locale.formatFileSize(image.size)}</Box>
          {isAvailable && (
            <Box color="gray.500">{`${image.info.dimensions.width} x ${image.info.dimensions.height}`}</Box>
          )}
        </HStack>
      </Box>
      {scan && (
        <Box px={2} py={4} flex="0 0 50%" {...getDisclosureProps()} overflow="auto">
          <ScanInfoPane scanId={scan.id} />
        </Box>
      )}
    </Flex>
  );
});

const ToggleIcon = ({isOpen}: {isOpen: boolean}) => {
  return isOpen ? (
    <Icon as={ChevronDownIcon} boxSize="28px" />
  ) : (
    <Icon as={ChevronUpIcon} boxSize="28px" />
  );
};

const Separator = () => {
  const color = useColorModeValue('gray.400', 'rgba(255,255,255,0.5)');
  return (
    <Text color={color} mx={1} fontSize="xl">
      •
    </Text>
  );
};

const ScanInfoPane = ({scanId}) => {
  const {data: scan, error} = useFetchScan(scanId);
  const {onEditClick} = TabLayoutContainer.useContainer();
  if (!scan) return null;
  if (error) return <Alert status="error">Unable to load the scan {scanId}</Alert>;
  return (
    <Container maxW="container.lg">
      <Card
        header={
          <Flex justifyContent="space-between" alignItems="center">
            <HStack>
              <Icon as={HiOutlineDocumentText} color="blue.400" boxSize="24px" />
              <Box>Scan details</Box>
            </HStack>
            <Box>
              <ButtonGroup size="sm">
                <IconButton
                  as={RouterLink}
                  to={`/scans/${scan.id}`}
                  aria-label="Open details in a new page"
                  icon={<Icon as={MdOpenInNew} fontSize="20px" />}
                />
                <IconButton
                  onClick={() => onEditClick(scan)}
                  aria-label="Edit"
                  variant="solid"
                  colorScheme="primary"
                  icon={<EditIcon fontSize="20px" />}
                />
              </ButtonGroup>
            </Box>
          </Flex>
        }
      >
        <ScanDataPane scan={scan} />
      </Card>

      <Card
        header={
          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <Icon as={InfoIcon} color="blue.400" mr={2} />
              Image Info
            </Box>
            <ButtonGroup size="sm">
              {scan.image.status === 'AVAILABLE' && (
                <IconButton
                  as={RouterLink}
                  to={`/images/${scan.image.id}`}
                  aria-label="Open image viewer in new page"
                  icon={<Icon as={MdOpenInNew} fontSize="20px" />}
                />
              )}
            </ButtonGroup>
          </Flex>
        }
        mt={4}
      >
        <ScanImageInfo scan={scan} />
      </Card>
    </Container>
  );
};

function NotAvailableImage({status}: {status: Datastore.Image['status']}) {
  switch (status) {
    case 'UPLOADED':
      return (
        <>
          <Icon as={MdHourglassEmpty} boxSize="150px" color="gray.300" />
          <Box>Waiting, check the queue in the dashboard...</Box>
        </>
      );
    case 'PROCESSING':
      return (
        <>
          <Icon as={MdHourglassEmpty} boxSize="150px" color="gray.300" />
          <Box>Processing...</Box>
        </>
      );
    case 'PROCESS_FAILED':
      return (
        <>
          <Icon as={MdBrokenImage} boxSize="150px" color="red.200" />
          <Box>Processing failed!</Box>
        </>
      );
    default:
      return <>{status}</>;
  }
}

function Card({
  header,
  children,
  ...props
}: {header: React.ReactNode; children: React.ReactNode} & BoxProps) {
  return (
    <Box borderWidth="1px" borderRadius="md" {...props}>
      <Box px={4} py={2} borderBottomWidth="1px">
        {header}
      </Box>
      <Box p={4}>{children}</Box>
    </Box>
  );
}

function TagsArea({scan, onSave}: {scan: Datastore.Scan; onSave: Props['onSave']}) {
  const modal = useModal();
  async function editScan() {
    const isUpdated = await modal.dialog({
      render: (close) => <DialogQuickEdit scanId={scan.id} onClose={close} />,
      modalProps: {size: 'xl'}
    });
    invariant(onSave);
    if (isUpdated) {
      onSave();
    }
  }
  return (
    <DarkMode>
      <HStack
        bg="blue.800"
        py={3}
        px={6}
        alignItems="center"
        borderTopColor="gray.300"
        color="rgba(255,255,255,.9)"
        spacing={4}
      >
        <Box flexShrink={0}>
          {scan.tags ? (
            <TagLinkGroup scanTags={scan.tags} />
          ) : (
            <Box>
              <i>No tags</i>
            </Box>
          )}
        </Box>
        <Button
          onClick={editScan}
          leftIcon={<EditIcon fontSize="16px" />}
          size="sm"
          variant="solid"
          height="24px"
          flexShrink={0}
        >
          Edit
        </Button>
        {scan.comments && (
          <Text fontSize="sm" isTruncated>
            <Icon as={MdOutlineModeComment} mr={2} />
            {scan.comments}
          </Text>
        )}
      </HStack>
    </DarkMode>
  );
}
