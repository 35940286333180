import React from 'react';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import {Badge, Box} from '@chakra-ui/react';

import {useLocale} from 'shared';
import {DateTime, Table} from 'components/core';
import {BatchNumberLink, KeywordLink} from 'components/scan-list/scan-columns';

type Batch = {
  number: number;
  total: number;
  startDate: string;
  endDate: string;
  supplier: string;
};

export const BatchesByDay = ({batches}: {batches: Batch[]}) => {
  const batchesByDate = groupBy(batches, (batch) => convertDateToKey(new Date(batch.startDate)));
  return (
    <>
      {Object.keys(batchesByDate).map((dateKey) => {
        const {year, month, day} = convertKeyToDate(dateKey);
        const batches = batchesByDate[dateKey];

        return <DailyList key={dateKey} year={year} month={month} day={day} batches={batches} />;
      })}
    </>
  );
};

const DailyList = ({year, month, day, batches}) => {
  const locale = useLocale();
  const date = new Date(year, month - 1, day);
  const total = sumBy(batches, 'total');
  const data = batches;
  const columns = [
    {
      Header: 'Batch',
      accessor: (batch) => <BatchNumberLink batchNumber={batch.number} variant="primary" />,
      styleProps: {
        width: '25%'
      }
    },
    {
      Header: 'Number of scans',
      accessor: (batch) => locale.formatNumber(batch.total),
      styleProps: {
        width: '25%'
      }
    },
    {
      Header: 'Supplier',
      accessor: (batch) => <KeywordLink category="supplier" value={batch.supplier} />,
      styleProps: {
        width: '25%'
      }
    },
    {
      Header: 'Started at',
      accessor: (batch) => <DateTime date={new Date(batch.startDate)} />,
      styleProps: {
        width: '25%'
      }
    }
  ];
  return (
    <Box mb={4}>
      <Box mb={2}>
        {locale.formatMonth(month, {format: 'partial'})} {day} (
        {locale.formatDayOfWeek(date.getDay(), {format: 'partial'})})
        <Badge ml={2}>{locale.formatNumber(total)}</Badge>
      </Box>
      <Box borderWidth="1px">
        <Table data={data} columns={columns} size="sm" showHeader={true} />
      </Box>
    </Box>
  );
};

// native date object => `2020-1-6` string
function convertDateToKey(date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
}

// `2020-1-6` string => {year, month, day} object
function convertKeyToDate(key) {
  const [year, month, day] = key.split('-');
  return {
    year: parseInt(year, 10),
    month: parseInt(month, 10),
    day: parseInt(day, 10)
  };
}
