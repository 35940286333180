import React, {useState} from 'react';
import {Prompt, useLocation, useHistory, useParams} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import set from 'lodash/set';
import {
  Alert,
  Badge,
  Box,
  ButtonGroup,
  Flex,
  TabList,
  TabPanel,
  TabPanels,
  Tab,
  Tabs,
  useToast,
  GridItem,
  Grid,
  Icon
} from '@chakra-ui/react';
import {useUpdateEffect} from 'react-use';
import {FiFile} from 'react-icons/fi';

import {ACCESS_LEVELS, POLICIES, useApp, useLocale} from 'shared';
import {splitTags} from 'models/model-utils';
import {Button, KeyHandler, Heading1, RoundBackButton, Spinner, useModal} from 'components/core';
import {ScanForm} from 'components/scan-form/scan-form';
import {ScanHistory} from './scan-history';
import {ScanImagePreview} from './scan-image-preview';
import {useFetchScan, useMutationUpdateScan, useScanNavigation} from './scan-actions';
import {ScanDetailsSidebar} from './scan-details-sidebar';
import {ScannedDiagnosis} from './scanned-diagnosis';

type Changes = Record<string, any>;

export const ScanEditorPage = () => {
  const history = useHistory();
  const location = useLocation();
  const app = useApp();
  const locale = useLocale();
  const toast = useToast();
  const modal = useModal();
  const {scanId} = useParams<{scanId: string}>();
  const {data: originalScan, isLoading, isFetching, error, refetch} = useFetchScan(scanId);
  const mutationUpdateScan = useMutationUpdateScan(scanId);
  const {showPreviousScan, showNextScan, loading} = useScanNavigation(scanId);
  const [changes, setChanges] = useState<Changes>({});

  useUpdateEffect(() => setChanges({}), [scanId]);

  const scan = {...originalScan, ...changes};

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Alert status="error" flexDirection="column" alignItems="flex-start">
        <Box>Unable to load the scan</Box>
        {(error as Error).message && <Box>{(error as Error).message}</Box>}
      </Alert>
    );
  }

  const {user} = app.state;

  const tagRestrictions = splitTags(user.tagRestrictions);
  const showHistory = user.accessLevel >= ACCESS_LEVELS.SUPER_ADMIN;
  const isDeleted = !!scan.deletedOn;
  const canDeleteScan = POLICIES['scan/delete'](user, scan);

  const handleChange = (field, value) => {
    setChanges((current) => updateStateAtPath(current, field, value));
  };

  const save = async (event) => {
    event.preventDefault();
    await mutationUpdateScan.mutate(changes as any);
    toast({title: 'Scan updated', description: scan.image.filename, isClosable: true});
    setChanges({});
  };

  const deleteScan = async () => {
    const okay = await modal.confirm(
      <>{locale.todo('Are you sure you want to permanently delete this item?')}</>,
      {title: locale.warningDialogTitle, okButton: locale.todo('Delete')}
    );
    if (!okay) return;
    const done = await app.task(async () => {
      await app.deleteScan({scanId});
    });
    if (!done) return;
    toast({title: 'Scan deleted', description: scan.image.filename, isClosable: true});
    showList();
  };

  const showList = () => {
    history.push({pathname: '/scans', search: location.search});
  };

  // Note about Tabs `isLazy` option:
  // we want to refetch the history every time the tab is displayed,
  // it's needed when removing PDF or changing the `tags`

  return (
    <Flex
      as="form"
      onSubmit={save}
      autoComplete="off"
      minHeight={0}
      flexDirection="column"
      flex={1}
      w="100%"
      maxW="container.xl"
      m="0 auto"
    >
      <Flex flexShrink={0} alignItems="center">
        <RoundBackButton onClick={showList} aria-label="Back to scan list" />
        <Heading1 mt="-3px" marginBottom={0} marginLeft={4}>
          {locale.todo('Scan')}
          {scan.deletedOn && (
            <Badge ml={4} fontSize="0.6em" colorScheme="red" variant="solid">
              Deleted
            </Badge>
          )}
        </Heading1>
      </Flex>

      <Box mt={3} overflowY="scroll" borderWidth="1px" borderRadius="lg" bg="cardBg">
        <Grid templateColumns="1fr 400px" p={6} gap={6}>
          <GridItem borderRightWidth="1px" pr={6}>
            <Tabs variant="enclosed" isLazy>
              <TabList>
                <Tab>{locale.todo('General')}</Tab>
                <Tab>
                  {locale.todo('Scanned diagnosis')}
                  {scan.hasScannedDiagnosis && <Icon as={FiFile} size={16} ml={2} />}
                </Tab>
                {showHistory && <Tab>{locale.todo('History')}</Tab>}
              </TabList>
              <TabPanels>
                <TabPanel>
                  {/*
                  We show the spinner while data is being revalidated to force the form to be re-mounted
                  and avoid an issue when the Autocomplete field receives new data
                  */}
                  {isFetching ? (
                    <Spinner />
                  ) : (
                    <ScanForm
                      getValue={(field) => get(scan, field)}
                      setValue={(field, value) => handleChange(field, value)}
                      requireFields={tagRestrictions.length ? ['tags'] : []}
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  <ScannedDiagnosis scan={scan} refetch={refetch} />
                </TabPanel>
                {showHistory && (
                  <TabPanel>
                    <ScanHistory scan={scan} />
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>
          </GridItem>

          <GridItem>
            <ScanImagePreview scan={scan} />
            <ScanDetailsSidebar scan={scan} refetch={refetch} mt={3} size="sm" allowMultiple />
          </GridItem>
        </Grid>
      </Box>
      {!isDeleted && (
        <>
          <Flex mt={6} flexShrink={0} justifyContent="space-between">
            <ButtonGroup>
              <Button type="submit" primary isDisabled={isEmpty(changes) || app.state.isBusy}>
                {locale.todo('Save')}
              </Button>
              {canDeleteScan && (
                <Button type="button" onClick={deleteScan} disabled={!!scan.deletedOn}>
                  {locale.todo('Delete')}
                </Button>
              )}
            </ButtonGroup>

            <ButtonGroup>
              <Button onClick={showPreviousScan} isLoading={loading === 'previous'}>
                {locale.previousButtonLabel}
              </Button>
              <Button onClick={showNextScan} isLoading={loading === 'next'}>
                {locale.nextButtonLabel}
              </Button>
            </ButtonGroup>
            <KeyHandler value="ArrowLeft" onDown={() => showPreviousScan()} />
            <KeyHandler value="ArrowRight" onDown={() => showNextScan()} />
          </Flex>

          <Prompt
            when={!isEmpty(changes)}
            message={locale.todo(
              'This scan has been modified. Are you sure you want to leave without saving the changes?'
            )}
          />
        </>
      )}
    </Flex>
  );
};

function updateStateAtPath(current, path, value) {
  const copy = {...current};
  set(copy, path, value);
  return copy;
}
