import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Flex,
  HStack,
  Tag,
  TagCloseButton,
  TagLabel,
  TagLeftIcon
} from '@chakra-ui/react';
import {useHistory} from 'react-router-dom';
import {MdFilterList} from 'react-icons/md';

import {formatSearchParams, PaginationControls, PaginationCount, useParseURL} from 'shared';
import {Heading1, PageContainer, Spinner, SubTitle} from 'components/core';
import {useFetchEvaluations} from 'components/evaluations/evaluations-api';
import {EvaluationList} from './evaluation-list';
import {EvaluationSearchFormValues, SearchArea} from './search-area';
import {Query} from 'models/query';

export const EvaluationListPage = () => {
  const searchOptions = useParseURL({limit: 50});
  const {data, isLoading, error} = useFetchEvaluations(searchOptions);
  const history = useHistory();

  const queryInstance = EvaluationQuery.fromJSON(searchOptions.query) as EvaluationQuery;
  const searchValues: EvaluationSearchFormValues = {
    text: queryInstance.getValue('freeText'),
    modelName: queryInstance.getValue('modelName'),
    modelVersion: queryInstance.getValue('modelVersion')
  };

  function triggerSearch({text, modelName, modelVersion}: EvaluationSearchFormValues) {
    const number = parseNumber(text);
    if (number) {
      history.push(`/evaluations/${number}`);
      return;
    }
    queryInstance.updateExpression('freeText', text);
    queryInstance.updateExpression('modelName', modelName);
    queryInstance.updateExpression('modelVersion', modelVersion);

    searchOptions.query = queryInstance.toJSON();
    searchOptions.offset = 0;
    history.replace({search: formatSearchParams(searchOptions)});
  }

  function reset() {
    triggerSearch({text: '', modelName: '', modelVersion: ''});
  }

  if (isLoading) return <Spinner />;

  if (error)
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>Unable to load the evaluations</AlertDescription>
      </Alert>
    );

  const {data: results, total} = data!;
  const isFiltered = searchOptions.query.length > 0;

  return (
    <PageContainer maxW="container.xl">
      <HStack alignItems="flex-end" mb={3}>
        <Heading1 mb={0}>Evaluations</Heading1>
        {total > 0 && (
          <SubTitle>
            <PaginationCount
              searchOptions={searchOptions}
              total={total}
              pageItemCount={results.length}
            />
          </SubTitle>
        )}
        {isFiltered && (
          <Box ml={4}>
            <Tag colorScheme="blue">
              <TagLeftIcon as={MdFilterList} />
              <TagLabel>Filtered</TagLabel>
              <TagCloseButton onClick={reset} />
            </Tag>
          </Box>
        )}
      </HStack>
      <SearchArea values={searchValues} onSearch={triggerSearch} />
      <Box overflow="auto" borderWidth="1px">
        <EvaluationList evaluations={results} />
      </Box>
      <Flex mt={6} flexShrink={0} justifyContent="space-between">
        <Box />
        <PaginationControls searchOptions={searchOptions} total={total} />
      </Flex>
    </PageContainer>
  );
};

class EvaluationQuery extends Query {
  static schema = [
    {field: 'freeText', type: 'string'},
    {field: 'modelName', type: 'string'},
    {field: 'modelVersion', type: 'string'}
  ];

  updateExpression(field: string, value?: string) {
    if (value) {
      this.setExpression(field, {value, operator: 'is'});
    } else {
      this.deleteExpression(field);
    }
  }
}

function parseNumber(text) {
  if (isNaN(text)) return undefined;
  return parseInt(text);
}
