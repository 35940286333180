import React from 'react';
import {Box, ButtonGroup, Flex, HStack, IconButton} from '@chakra-ui/react';
import {Column} from 'react-table';
import {MdGridOn, MdViewList} from 'react-icons/md';

import {PaginationControls, SearchOptions, ViewMode, usePreferences, ItemSelection} from 'shared';
import {GallerySizeControl, ScanGallery} from 'components/scan-list/scan-gallery';
import {BasicScanTable} from 'components/scan-list/scan-table';
import {ColumnPicker} from './scan-table-custom-columns';

type Props = {
  scans: Datastore.Scan[];
  searchOptions: Required<SearchOptions>;
  total: number;
  columns: Column<Datastore.Scan>[];
  header?: React.ReactNode;
  footer?: React.ReactNode;
  selection?: ItemSelection;
  setSelection?: (selection: ItemSelection) => void;
  customColumnKeys?: string[];
  onChangeCustomColumnKeys?: (values: string[]) => void;
};
export const PaginatedScanList = ({
  scans,
  searchOptions,
  total,
  selection,
  setSelection,
  header,
  footer,
  columns,
  customColumnKeys,
  onChangeCustomColumnKeys
}: Props) => {
  const [viewMode, setViewMode] = usePreferences('scanListViewMode');
  const [imageSize, setImageSize] = usePreferences('galleryThumbnailSize');

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" mt={1} mb={1}>
        <Box flexGrow={1}>{header}</Box>
        <HStack spacing={2}>
          {viewMode === 'gallery' && (
            <GallerySizeControl value={imageSize} onChange={setImageSize} />
          )}
          {viewMode === 'table' && customColumnKeys && onChangeCustomColumnKeys && (
            <ColumnPicker columns={customColumnKeys} onChange={onChangeCustomColumnKeys} />
          )}
          <ViewModeSwitcher value={viewMode} onChange={setViewMode} />
        </HStack>
      </Flex>

      {viewMode === 'table' ? (
        <BasicScanTable
          scans={scans}
          searchOptions={searchOptions}
          columns={columns}
          selection={selection}
          onSelect={setSelection}
        />
      ) : (
        <ScanGallery
          scans={scans}
          thumbnailSize={imageSize}
          selection={selection}
          onSelect={setSelection}
        />
      )}

      <Flex mt={6} flexShrink={0} justifyContent="space-between">
        <Box>{footer}</Box>
        <PaginationControls searchOptions={searchOptions} total={total} />
      </Flex>
    </>
  );
};

export const ViewModeSwitcher = ({
  value,
  onChange
}: {
  value: ViewMode;
  onChange: (value: ViewMode) => void;
}) => {
  return (
    <ButtonGroup size="sm" isAttached variant="outline">
      <IconButton
        onClick={() => onChange('table')}
        aria-label="Table view"
        mr="-px"
        color={value === 'table' ? 'primary.400' : 'gray.400'}
        icon={<MdViewList size="24px" />}
      />
      <IconButton
        onClick={() => onChange('gallery')}
        aria-label="Gallery view"
        color={value === 'gallery' ? 'primary.400' : 'gray.400'}
        icon={<MdGridOn size="24px" color="gray.500" />}
      />
    </ButtonGroup>
  );
};
