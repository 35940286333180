import {OwnedItem} from 'models/owned-item';
import mean from 'lodash/mean';

import aiLabelGroups from '../data/ai-label-groups.json';

export class Prediction extends OwnedItem {
  constructor(data, {deserialize, ...otherOptions} = {}) {
    data = {...data};

    if (deserialize) {
      deserializeBackendData(data);
    }

    if (data.verifiedOn) {
      data.verifiedOn = new Date(data.verifiedOn);
    }

    super(data, {deserialize, ...otherOptions});
  }
}

/*
=== Schemas of prediction.results ===
{
  version: 3, // The version of the schema.
  labels: { // An object of labelKey/labelName
    '0': 'stomach-adenocarcinoma',
    '1': 'stomach-normal-mucosa'
  },
  cellSize: 400, // Size of a cell in pixels
  numberOfConsideredCells: 454,
  cells: {
    '3:2': {'0': 95.004} // 'x:y': {'labelKey': probability}
  }
}
*/

/* eslint-disable complexity */
function deserializeBackendData(data) {
  const {results} = data;

  if (!results) {
    return;
  }

  const {version, labels, cellSize, cells} = results;

  if (version < 2) {
    return;
  }

  if (!(labels && cells)) {
    return;
  }

  const normalizedLabel = {};
  for (let [key, label] of Object.entries(labels)) {
    label = {name: label};
    normalizedLabel[key] = label;
  }

  const deserializedCells = {};
  for (const [cellKey, predictions] of Object.entries(cells)) {
    for (const [labelKey, probability] of Object.entries(predictions)) {
      const label = normalizedLabel[labelKey];
      if (!label) {
        throw new Error(`Invalid label key found in an image cell: ${labelKey}`);
      }
      if (!deserializedCells[cellKey]) {
        deserializedCells[cellKey] = {};
      }
      deserializedCells[cellKey][label.name] = probability;
    }
  }

  const deserializedLabels = {};
  for (const label of Object.values(normalizedLabel)) {
    deserializedLabels[label.name] = label;
  }

  const groupedLabels = {};
  for (const deserializedLabel of Object.values(deserializedLabels)) {
    const name = groupLabel(deserializedLabel.name);
    if (!groupedLabels[name]) {
      groupedLabels[name] = {name, ...(version >= 4 && {probabilities: []})};
    }
  }

  const groupedCells = {};
  for (const [cellKey, predictions] of Object.entries(deserializedCells)) {
    for (let [labelName, probability] of Object.entries(predictions)) {
      if (!groupedCells[cellKey]) {
        groupedCells[cellKey] = {};
      }
      labelName = groupLabel(labelName);
      if (!groupedCells[cellKey][labelName]) {
        groupedCells[cellKey][labelName] = [];
      }
      groupedCells[cellKey][labelName].push(probability);
    }
  }
  for (const predictions of Object.values(groupedCells)) {
    for (const [labelName, probabilities] of Object.entries(predictions)) {
      predictions[labelName] = mean(probabilities);
    }
  }

  data.results = {
    version,
    labels: groupedLabels,
    cellSize,
    cells: groupedCells
  };
}
/* eslint-enable complexity */

function groupLabel(name) {
  for (const labelGroup of aiLabelGroups) {
    if (labelGroup.labelNames.includes(name)) {
      return labelGroup.name;
    }
  }

  return name;
}
