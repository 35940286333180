import {useState} from 'react';
import {createContainer} from 'unstated-next';
import {useLatest} from 'react-use';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';

import {useKeyHandler, useModal} from 'components/core';
import {ImageContainer} from 'components/image-viewer/image-container';
import {AIDataContainer, getAILabelByName} from 'components/settings/ai-labels-api';
import {AILabel} from 'components/settings/types';
import {AnnotationLabelChooser} from './annotation-label-chooser';
import {AnnotationEditor} from './annotation-editor';

export function useImageAnnotations() {
  const modal = useModal();
  const {image, setMode} = ImageContainer.useContainer();
  const {data} = AIDataContainer.useContainer();
  const allLabels = data?.labels || [];

  const annotations = image.annotations;
  const [currentLabelName, setCurrentLabelName] = useState<string | undefined>(undefined);
  const [activeLabelNames, setActiveLabelNames] = useState<string[]>(
    annotations!.map((annotation) => annotation.labelName)
  );
  const currentLabelNameRef = useLatest(currentLabelName);

  useKeyHandler({viewId: image.id, value: 'D', onDown: () => enterDrawingMode('annotating')});
  useKeyHandler({viewId: image.id, value: 'E', onDown: () => enterDrawingMode('erasing')});
  useKeyHandler({viewId: image.id, value: 'Escape', onDown: () => setMode('panning')});

  const toggleLabel = (labelNames: string[]) => {
    setActiveLabelNames(labelNames);
    if (!labelNames.includes(currentLabelName!)) {
      setCurrentLabelName(undefined);
    }
  };

  const addLabel = async () => {
    const input = await chooseCurrentLabel();
    if (!input) return false;
    const {labelName} = input;
    setCurrentLabelName(labelName);
    ensureLabelIsActive(labelName);
  };

  const editAnnotationDetails = async (annotationId: string) => {
    const changes = await modal.dialog({
      render: (close) => (
        <AnnotationEditor image={image} annotationId={annotationId} onClose={close} />
      ),
      modalProps: {
        size: 'xl'
      }
    });
    if (!changes) return;
    const {labelName} = changes;
    ensureLabelIsActive(labelName);
  };

  const ensureLabelIsActive = (labelName: string) => {
    if (!activeLabelNames.includes(labelName)) {
      setActiveLabelNames((labelNames) => [...labelNames, labelName]);
    }
  };

  const labelNames = compact(
    uniq(annotations!.map(({labelName}) => labelName).concat(currentLabelName as string))
  );

  const annotationLabels = labelNames.map((labelName) => ({
    ...getAILabelByName(allLabels, labelName as string),
    isCurrent: labelName === currentLabelName,
    isActive: (activeLabelNames || []).includes(labelName as string)
  }));

  function getCurrentAnnotationLabel() {
    return annotationLabels.find((label) => label.isCurrent);
  }

  const currentAnnotationLabel = getCurrentAnnotationLabel(); // the label used to draw or to erase

  function hasCurrentAnnotationLabel(annotation: Datastore.Annotation) {
    const {labelName} = annotation;
    const currentAnnotationLabel = getCurrentAnnotationLabel();
    return currentAnnotationLabel && labelName === currentAnnotationLabel.name;
  }

  // Ensure that a label is currently selecting when using a drawing tool (pen or eraser)
  async function ensureCurrentLabel() {
    if (currentLabelNameRef.current) return true; // we need to check the `latest` value in keyboard event handlers
    const input = await chooseCurrentLabel();
    if (!input) return false;
    const {labelName} = input;
    setCurrentLabelName(labelName);
    setActiveLabelNames((labelNames) => uniq([...labelNames, labelName]));
    return true;
  }

  async function enterDrawingMode(mode: string) {
    if (!(await ensureCurrentLabel())) {
      return false;
    }
    setMode(mode);
    return true;
  }

  async function chooseCurrentLabel(): Promise<{labelName: string} | undefined> {
    const getDefaultModelName = () => {
      const firstLabelName = annotations?.[0]?.labelName;
      const label: AILabel = getAILabelByName(allLabels!, firstLabelName!);
      return label?.modelName;
    };
    return await modal.dialog({
      render: (close) => (
        <AnnotationLabelChooser
          defaultModelName={getDefaultModelName()}
          onClose={(labelName) => {
            if (!labelName) {
              close();
              return;
            }
            close({labelName});
          }}
        />
      ),
      modalProps: {
        size: 'xl'
      }
    });
  }

  return {
    addLabel,
    activeLabelNames,
    annotationLabels,
    currentAnnotationLabel,
    currentLabelName,
    editAnnotationDetails,
    enterDrawingMode,
    hasCurrentAnnotationLabel,
    labelNames,
    setCurrentLabelName,
    toggleLabel
  };
}

export const AnnotationsContainer = createContainer(useImageAnnotations);
