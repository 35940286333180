export function isProcessingLate(scan: Datastore.Scan) {
  const {image} = scan;
  const MAX_NUMBER_OF_MINUTE = 60;
  const isProcessing = ['PROCESSING'].includes(image.status);
  if (!isProcessing) return false;
  const date = new Date(image.updatedOn);
  const differenceInMinutes = (Date.now() - +date) / 1000 / 60;
  const isLate = differenceInMinutes > MAX_NUMBER_OF_MINUTE;
  return isLate;
}
