import React, {useRef, useState, useCallback} from 'react';
import {Box} from '@chakra-ui/react';
import {useEvent, useLatest} from 'react-use';

type Status = 'IDLE' | 'STARTED' | 'DRAGGING';

type DragEvent = React.MouseEvent<Element, MouseEvent> | React.TouchEvent<Element>;

type Props = {
  onChange: (angle: number, anim: boolean) => void;
  angle?: number;
  size?: number;
};
export const RotationControl = ({onChange, angle = 0, ...props}: Props) => {
  return (
    <InnerRotationControl
      onChange={(value, anim) => onChange(fromViewerAngle(value), anim)}
      angle={toViewerAngle(angle)}
      {...props}
    />
  );
};

// To make calculations simpler, the inner component handles angles following the trigonometry convention.
// Let's visualize the components as a planet rotating on an orbit around an invisible star
// - if angle === 0 the planet is at the right position
// - if angle === 90 degrees the planet is at the top position
const InnerRotationControl = ({onChange, angle: initialAngle = 90, size = 140}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [currentAngle, setCurrentAngle] = useState(initialAngle);
  const [status, setStatus] = useState<Status>('IDLE');
  const statusRef = useLatest(status);

  const getPolarCoordinateFromEvent = useCallback(
    (event: DragEvent) => {
      const node = ref?.current;
      if (!node) return undefined;
      const {x, y} = eventToCartesianCoordinate({node, event, size});
      return toPolarCoordinate({x, y});
    },
    [size]
  );

  const onStartDrag = useCallback((event: DragEvent) => {
    event.preventDefault();
    setStatus('STARTED');
  }, []);

  const onDrag = useCallback((event: DragEvent) => {
    event.preventDefault();
    if (statusRef.current === 'IDLE') return;
    const coordinate = getPolarCoordinateFromEvent(event);
    if (!coordinate) return;
    const angle = adjustAngle(coordinate.angle, 1);
    setCurrentAngle(angle);
    setStatus('DRAGGING');
    onChange(angle, false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onEndDrag = useCallback((event: DragEvent) => {
    event.preventDefault();
    setStatus('IDLE');
  }, []);

  useEvent('mousemove', onDrag);
  useEvent('mouseup', onEndDrag);

  // Clicking on the component makes the planet move by 45 degrees steps
  const onClick = useCallback((event: DragEvent) => {
    event.preventDefault();
    if (statusRef.current === 'DRAGGING') return;
    const coordinate = getPolarCoordinateFromEvent(event);
    if (!coordinate) return;
    const {angle: rawAngle, radius} = coordinate;
    const threshold = size / 15; // clicking in the center resets the position
    const angle = radius > threshold ? adjustAngle(rawAngle, 45) : initialAngle;
    setCurrentAngle(angle);
    onChange(angle, true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const radius = size * 0.3;
  const center = size / 2;
  const fontSize = 26;

  return (
    <Box
      ref={ref}
      width={size}
      height={size}
      cursor="pointer"
      pointerEvents="none"
      userSelect="none"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${size} ${size}`}>
        <filter id="dropShadow" filterUnits="userSpaceOnUse">
          <feGaussianBlur in="SourceAlpha" stdDeviation="5"></feGaussianBlur>
          <feOffset dx="2" dy="2"></feOffset>
          <feComponentTransfer>
            <feFuncA type="linear" slope="0.5"></feFuncA>
          </feComponentTransfer>
          <feMerge>
            <feMergeNode></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <Orbit radius={radius} center={center} onClick={onClick} />
        <Planet
          angle={currentAngle}
          orbitRadius={radius}
          center={center}
          onClick={onClick}
          onStartDrag={onStartDrag}
          onDrag={onDrag}
          onEndDrag={onEndDrag}
        />
        <text
          x={size / 2}
          y={size / 2 + fontSize / 3}
          textAnchor="middle"
          fontSize={fontSize}
          fontFamily="Arial"
          fill="white"
          stroke="#777"
          strokeWidth="1px"
          filter="url(#dropShadow)"
          onClick={onClick}
        >
          {fromViewerAngle(currentAngle)}°
        </text>
      </svg>
    </Box>
  );
};

type OrbitProps = {
  radius: number;
  center: number;
  onClick: (event: DragEvent) => void;
};
const Orbit = ({radius, center, onClick}: OrbitProps) => {
  return (
    <circle
      cx={center}
      cy={center}
      r={radius}
      fill="transparent"
      stroke="white"
      strokeOpacity={0.8}
      strokeWidth="5"
      onClick={onClick}
      pointerEvents="all"
      filter="url(#dropShadow)"
    />
  );
};

type PlanetProps = {
  orbitRadius: number;
  angle: number;
  center: number;
  onClick: (event: DragEvent) => void;
  onStartDrag: (event: DragEvent) => void;
  onDrag: (event: DragEvent) => void;
  onEndDrag: (event: DragEvent) => void;
};
const Planet = ({
  orbitRadius,
  angle,
  center,
  onClick,
  onStartDrag,
  onDrag,
  onEndDrag
}: PlanetProps) => {
  const angleInRadians = (angle / 180) * Math.PI;

  const dx = orbitRadius * Math.cos(angleInRadians);
  const dy = orbitRadius * Math.sin(angleInRadians);

  const x = center + dx;
  const y = center - dy;
  const isClockwise = angle < 90 && angle > -90;
  const directionFlag = isClockwise ? '1' : '0';

  return (
    <>
      <path
        strokeWidth="5"
        strokeLinecap="round"
        fill="none"
        stroke="#f983e3"
        d={`M${center} ${
          center - orbitRadius
        } A ${orbitRadius} ${orbitRadius} 0 0 ${directionFlag} ${x} ${y}`}
        onClick={onClick}
      ></path>
      <circle
        cx={x}
        cy={y}
        r={orbitRadius * 0.3}
        fill={angle !== 90 ? '#f983e3' : 'white'}
        style={{cursor: 'move'}}
        stroke="transparent"
        strokeWidth="20px"
        pointerEvents="all"
        filter="url(#dropShadow)"
        onMouseDown={onStartDrag}
        onTouchStart={onStartDrag}
        onTouchMove={onDrag}
        onTouchEnd={onEndDrag}
        onTouchCancel={onEndDrag}
      />
    </>
  );
};

// From the counterclockwise angle (the convention in trigonometry) to the OSD rotation angle
// 0 degrees => 90 degrees
function toViewerAngle(angle: number) {
  return angle + 90;
}

function fromViewerAngle(viewerAngle: number) {
  const angle = -(viewerAngle - 90);
  return angle > 180 ? angle - 360 : angle;
}

function adjustAngle(angle: number, step: number): number {
  return Math.round(angle / step) * step;
}

function eventToCartesianCoordinate({
  event,
  node,
  size
}: {
  event: DragEvent;
  node: HTMLElement;
  size: number;
}) {
  var dimensions = node.getBoundingClientRect();

  const clientX = isTouchEvent(event) ? event.touches?.[0]?.clientX : event.clientX;
  const clientY = isTouchEvent(event) ? event.touches?.[0]?.clientY : event.clientY;
  const x = clientX - dimensions.left;
  const y = clientY - dimensions.top;
  return {
    x: x - size / 2,
    y: size / 2 - y // the y axis is in the opposite direction
  };
}

type CartesianPoint = {x: number; y: number};

type PolarPoint = {angle: number; radius: number};

function toPolarCoordinate({x, y}: CartesianPoint): PolarPoint {
  const angle = (Math.atan2(y, x) / Math.PI) * 180;
  const radius = Math.sqrt(x * x + y * y);
  return {angle, radius};
}

function isTouchEvent(event: React.TouchEvent | React.MouseEvent): event is React.TouchEvent {
  return event && 'touches' in event;
}
