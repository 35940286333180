import {Machine, assign, MachineConfig, MachineOptions} from 'xstate';
import {useMachine} from '@xstate/react';
import {createContainer} from 'unstated-next';
import pMap from 'p-map';
import debugModule from 'debug';
import {useToast} from '@chakra-ui/react';

import {useApp} from 'shared';
import {ScanFieldName} from './csv-record-table';

const debug = debugModule('medmain:csv');

export function useImportCSVStateMachine() {
  const app = useApp();
  const toast = useToast();
  const machine = createStateMachine({app, toast});
  const showDevTools = process.env.NODE_ENV !== 'production'; // Redux DevTools browser extension to debug in local
  return useMachine(machine, {devTools: showDevTools});
}

export function useImportCSV() {
  const [current, send] = useImportCSVStateMachine();
  return {send, current, rowTotal: current.context.records.length};
}

export const ImportCSVContainer = createContainer(useImportCSV);

export type ParsedRowData = {
  imageFilename: string;
  supplier: string;
};

interface Scan {
  id: string;
  imageFilename: string;
}

export interface ScanRecord {
  scanId: string | undefined;
  imageFilename: string;
  supplier: string;
  data: any; //Omit<CaseFormData, "caseNumber" | "intendedOwnerOrganizationId">;
  previewStatus?: 'valid' | 'not-valid';
  errorType?: 'CASE_NUMBER_NOT_FOUND' | 'DUPLICATE_CASE_NUMBER';
  status: 'waiting' | 'checking' | 'checked' | 'updating' | 'updated' | 'failed';
}

interface MachineContext {
  // supplier: string;
  filename: string;
  records: ScanRecord[];
  fields: ScanFieldName[];
  error?: {
    message: string;
  };
}

type StateSchema = {
  states: {
    idle: {};
    checking: {};
    previewing: {};
    updating: {};
    done: {};
  };
};

type CheckEvent = {
  type: 'CHECK';
  // supplier: string;
  filename: string;
  data: ParsedRowData[];
  fields: ScanFieldName[];
};
type ParsingErrorEvent = {
  type: 'PARSING_ERROR';
  message: string;
};
type FetchCaseEvent = {
  type: 'FETCH_CASE';
  imageFilename: string;
  foundScans: Scan[];
};
type BackEvent = {
  type: 'BACK';
};
type RunEvent = {
  type: 'RUN';
};
type UpdateCaseSuccessEvent = {
  type: 'UPDATE_CASE_SUCCESS';
  imageFilename: string;
};
type UpdateCaseFailureEvent = {
  type: 'UPDATE_CASE_FAILURE';
  imageFilename: string;
};
type ResetEvent = {
  type: 'RESET';
};

type MachineEvent =
  | CheckEvent
  | ParsingErrorEvent
  | FetchCaseEvent
  | BackEvent
  | RunEvent
  | UpdateCaseSuccessEvent
  | UpdateCaseFailureEvent
  | ResetEvent;

function createStateMachine({app, toast}) {
  const initialContext: MachineContext = {
    // supplier: '',
    filename: '',
    records: [] as ScanRecord[],
    fields: [] as ScanFieldName[]
  };

  const config: MachineConfig<MachineContext, StateSchema, MachineEvent> = {
    id: 'import-csv',
    context: initialContext,
    initial: 'idle',
    states: {
      idle: {
        on: {
          CHECK: {
            actions: ['check'],
            target: 'checking'
          },
          PARSING_ERROR: {actions: 'showParsingError'}
        }
      },
      checking: {
        invoke: {
          src: 'checkFileContent',
          onDone: {
            target: 'previewing'
          },
          onError: {
            actions: ['logError']
          }
        },
        on: {
          FETCH_CASE: {actions: ['fetchCase']}
        }
      },
      previewing: {
        on: {
          BACK: {target: 'idle', actions: ['reset']},
          RUN: {target: 'updating', actions: ['notifyStart']}
        }
      },
      updating: {
        invoke: {
          src: 'updateAllScans',
          onDone: {
            target: 'done',
            actions: ['notifyEnd']
          },
          onError: {
            actions: ['logError']
          }
        },
        on: {
          UPDATE_CASE_SUCCESS: {
            actions: ['updateCaseSuccess']
          },
          UPDATE_CASE_FAILURE: {
            actions: ['updateCaseFailure']
          }
        }
      },
      done: {
        on: {
          RESET: {target: 'idle', actions: ['reset']}
        }
      }
    }
  };

  const services = {
    checkFileContent: (context, event) => async (callback) => {
      const {records} = context;

      const checkSingleRecord = async (record) => {
        const {imageFilename, supplier} = record;
        const foundScans = await findScansByFilename({app, supplier, imageFilename});
        callback({type: 'FETCH_CASE', imageFilename, foundScans});
      };

      await pMap(records, checkSingleRecord, {concurrency: 1});
    },
    updateAllScans: (context, event) => async (callback) => {
      const validRecords = context.records.filter((record) => record.previewStatus === 'valid');
      try {
        const updateSingleRecord = async (record) => {
          const {scanId, imageFilename, data} = record;
          debug('Update scan', scanId, imageFilename, data);
          try {
            await app.updateScan({scanId, changes: data});
            callback({type: 'UPDATE_CASE_SUCCESS', imageFilename});
          } catch (error) {
            callback({type: 'UPDATE_CASE_FAILURE', imageFilename});
          }
        };

        await pMap(validRecords, updateSingleRecord, {concurrency: 1});
      } catch (error) {
        console.log('Error while updating all scans', error);
      }
    }
  };

  const actions = {
    check: assign<MachineContext>({
      // supplier: (context, event) => {
      //   return (event as CheckEvent).supplier;
      // },
      filename: (context, event) => {
        return (event as CheckEvent).filename;
      },
      fields: (context, event) => {
        return (event as CheckEvent).fields;
      },
      records: (context, event) => {
        const {data} = event as CheckEvent;
        const records: ScanRecord[] = data.map(({imageFilename, supplier, ...rowData}) => {
          return {
            imageFilename,
            supplier,
            scanId: undefined,
            data: rowData,
            status: 'waiting'
          };
        });
        return records;
      },
      error: () => {
        return undefined;
      }
    }),
    showParsingError: assign<MachineContext>({
      error: (context, event) => {
        const {message} = event as ParsingErrorEvent;
        return {message};
      }
    }),
    fetchCase: assign<MachineContext>({
      records: (context, event) => {
        const {foundScans, imageFilename} = event as FetchCaseEvent;
        const {records} = context;

        const previewStatus: ScanRecord['previewStatus'] =
          foundScans.length === 1 ? 'valid' : 'not-valid';
        const scanId = foundScans.length === 1 ? foundScans[0].id : undefined;

        function getErrorType(): ScanRecord['errorType'] | undefined {
          if (foundScans.length === 0) return 'CASE_NUMBER_NOT_FOUND';
          if (foundScans.length > 1) return 'DUPLICATE_CASE_NUMBER';
          return undefined;
        }

        return updateByFilename({
          records,
          imageFilename,
          changes: {
            scanId,
            previewStatus,
            status: 'checked',
            errorType: getErrorType()
          }
        });
      }
    }),
    updateCaseSuccess: assign<MachineContext>({
      records: (context, event) => {
        const {records} = context;
        const {imageFilename} = event as UpdateCaseSuccessEvent;
        return updateByFilename({
          records,
          imageFilename,
          changes: {status: 'updated'}
        });
      }
    }),
    updateCaseFailure: assign<MachineContext>({
      records: (context, event) => {
        const {records} = context;
        const {imageFilename} = event as UpdateCaseFailureEvent;
        return updateByFilename({
          records,
          imageFilename,
          changes: {status: 'failed'}
        });
      }
    }),
    reset: assign<MachineContext>({
      // supplier: () => '',
      filename: () => '',
      records: (context, event) => {
        return [];
      },
      fields: () => []
    }),
    logError(context, event) {
      debug(`UNEXPECTED ERROR`, event.type, event.data);
    },
    notifyStart(context: MachineContext) {
      const {records, filename, fields} = context;
      const validRecords = records.filter((record) => record.previewStatus === 'valid');
      const payload = {
        supplier: records[0].supplier,
        total: records.length,
        valid: validRecords.length,
        fields,
        filename
      };
      app.startImportCSV(payload);
    },
    notifyEnd(context: MachineContext) {
      const {records, filename} = context;
      app.endImportCSV({
        errors: records.filter(({status}) => status === 'failed').length,
        updated: records.filter(({status}) => status === 'updated').length,
        filename
      });
    }
  };

  const options: MachineOptions<MachineContext, MachineEvent> = {
    actions,
    services,
    guards: {},
    activities: {},
    delays: {}
  };

  return Machine(config, options);
}

async function findScansByFilename({app, supplier, imageFilename}) {
  const query = [
    {field: 'supplier', operator: 'is', value: supplier},
    {field: 'imageFilename', operator: 'is', value: imageFilename},
    {field: 'status', operator: 'is-not', value: 'RESCANNED'}
  ];

  // TODO re-use logic from `useFetchScans` instead of calling the backend directly
  const backend = await app.getBackend();
  const accessToken = app.getAccessToken();
  const {scans} = await backend.findScans({
    query,
    order: [{field: 'id', direction: 'asc'}],
    offset: 0,
    limit: 10,
    returnFields: ['id', 'imageFilename', 'supplier'],
    accessToken
  });
  return scans;
}

function updateByFilename({records, imageFilename, changes}) {
  return records.map((record) =>
    record.imageFilename === imageFilename ? {...record, ...changes} : record
  );
}
