import React from 'react';
import PropTypes from 'prop-types';
import flatten from 'lodash/flatten';

import {withOpenSeadragon} from 'components/image-viewer/openseadragon';
import {
  pathIncludesPoint,
  round,
  erasePoints,
  getDistance,
  convertPercentPointToImagePoint
} from 'models/annotation';
import {KeyHandler} from 'components/core';
import {AnnotationView} from 'components/annotations/annotation-view';

@withOpenSeadragon
export class Eraser extends React.Component<any> {
  static propTypes = {
    paths: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.shape({x: PropTypes.number, y: PropTypes.number}))
    ).isRequired,
    onStart: PropTypes.func,
    onEnd: PropTypes.func.isRequired,
    imageDimensions: PropTypes.shape({width: PropTypes.number, height: PropTypes.number})
      .isRequired,
    precision: PropTypes.number,
    annotationStyle: PropTypes.object, // {fill, strokeOpacity, strokeWidth, stroke}
    magnetism: PropTypes.number,
    disabled: PropTypes.bool.isRequired,
    style: PropTypes.object,
    openSeadragon: PropTypes.object.isRequired
  };

  static defaultProps = {
    magnetism: 8,
    precision: 3
  };

  state = {
    erasedPoints: [],
    isEnding: false,
    isDiscarded: false
  };

  eraserRef = React.createRef();

  componentDidMount() {
    const {openSeadragon} = this.props;

    openSeadragon.addDragHandler(this.handleDrag);
    openSeadragon.addDragEndHandler(this.handleDragEnd);
  }

  componentWillUnmount() {
    const {openSeadragon} = this.props;

    openSeadragon.removeDragHandler(this.handleDrag);
    openSeadragon.removeDragEndHandler(this.handleDragEnd);
  }

  handleDrag = (event) => {
    const {onStart, disabled, openSeadragon} = this.props;
    const {erasedPoints, isEnding, isDiscarded} = this.state;

    if (disabled) {
      return false;
    }

    if (isEnding) {
      return;
    }

    if (isDiscarded) {
      return;
    }

    if (onStart) {
      onStart();
    }

    const imagePoint = openSeadragon.convertScreenPointToImagePoint(event.position);
    const points = this.getPointsToErase(imagePoint);
    const newErasedPoints = points.filter((point) => !pathIncludesPoint(erasedPoints, point));

    this.setState({erasedPoints: [...erasedPoints, ...newErasedPoints]});
  };

  handleDragEnd = async () => {
    const {onEnd} = this.props;
    const {erasedPoints, isEnding} = this.state;

    if (!erasedPoints.length) {
      return;
    }

    if (isEnding) {
      return;
    }

    this.setState({isEnding: true});
    try {
      await onEnd({erasedPoints});
    } finally {
      this.setState({isEnding: false});
    }

    this.setState({erasedPoints: []});
  };

  handleDiscardStart = () => {
    const {erasedPoints, isEnding} = this.state;

    if (isEnding) {
      return;
    }

    if (!erasedPoints.length) {
      return false; // If there is no current erasing, let's bubble up the event
    }

    this.setState({
      erasedPoints: [],
      isDiscarded: true
    });
  };

  handleDiscardEnd = () => {
    const {isEnding} = this.state;

    if (isEnding) {
      return;
    }

    this.setState({isDiscarded: false});
  };

  getPointsToErase = (imagePoint) => {
    const point = this.convertToPercentPoint(imagePoint);

    const points = this.findPoints(point);

    return points;
  };

  findPoints = (point) => {
    const {magnetism, paths} = this.props;

    const maxDistance = magnetism;

    const points = flatten(paths);

    const closestPoints = points.filter((pt) => {
      const distance = getDistance(this.convertToScreenPoint(point), this.convertToScreenPoint(pt));
      return distance < maxDistance;
    });
    return closestPoints;
  };

  // image pixel => image %
  convertToPercentPoint = ({x: imageX, y: imageY}) => {
    const {
      imageDimensions: {width, height},
      precision
    } = this.props;

    return {
      x: round((imageX / width) * 100, precision),
      y: round((imageY / height) * 100, precision)
    };
  };

  // image % => screen pixel
  convertToScreenPoint = (point) => {
    const {imageDimensions, openSeadragon} = this.props;

    return openSeadragon.convertImagePointToScreenPoint(
      convertPercentPointToImagePoint(point, imageDimensions)
    );
  };

  getRenderedPaths = () => {
    const {paths} = this.props;
    const {erasedPoints} = this.state;

    if (!erasePoints.length) {
      return paths;
    }

    return flatten(
      paths.map((path) => {
        const erasedPaths = erasePoints(path, erasedPoints);
        return erasedPaths.length ? erasedPaths : [[]]; // keep deleted annotations using `[[]]` otherwise `flatten()` would ignore them
      })
    );
  };

  render() {
    const {annotationStyle, style} = this.props;

    const renderedPaths = this.getRenderedPaths(); // including empty arrays for deleted annotations

    return (
      <div
        ref={this.eraserRef as any}
        style={{
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
          position: 'relative',
          ...style
        }}
      >
        <KeyHandler
          value={'Escape'}
          onDown={this.handleDiscardStart}
          onUp={this.handleDiscardEnd}
        />
        {renderedPaths.map((path, index) => {
          return (
            <div
              key={index}
              style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%'}}
            >
              <AnnotationView path={path} style={annotationStyle} />
            </div>
          );
        })}
      </div>
    );
  }
}
