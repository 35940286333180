import React from 'react';
import {
  Button as ChakraButton,
  ButtonProps,
  IconButton as ChakraIconButton,
  IconButtonProps
} from '@chakra-ui/react';
// import styled from '@emotion/styled';

// import { usePersistSearchParams } from "app/persist-search-params";
import {BackIcon} from './icons';

interface Props extends ButtonProps {
  primary?: boolean;
}
// Medmain custom Button: either a purple button or a white button for secondary actions
export const Button = ({primary, ...props}: Props) => {
  // For primary, ensure colorScheme set to "primary".
  if (primary) {
    return <ChakraButton {...props} colorScheme="primary" variant="solid" />;
  }
  // For other situation, variant defaults to "outline", yet overrideable.
  return <ChakraButton {...props} variant={props.variant || 'outline'} />;
};

export const IconButton = (props: IconButtonProps) => {
  return <ChakraIconButton {...props} variant={props.variant || 'outline'} />;
};

export const RoundBackButton = (props: IconButtonProps) => {
  return <IconButton icon={<BackIcon />} isRound {...props} />;
};

// Back button used to go from an item page to the list page, reading the `from` URL parameter
// export const RoundBackToListButton = (
//   props: IconButtonProps & { pathname: string }
// ) => {
//   const { showList } = usePersistSearchParams();
//   const { pathname } = props;

//   return (
//     <IconButton
//       icon={<BackIcon />}
//       isRound
//       fontSize={"1.5rem"}
//       variant="outline"
//       onClick={() => showList(pathname)}
//       {...props}
//     />
//   );
// };

// Context menu used in Image thumbnail and comment list
// export const ContextMenuButton = styled(ChakraButton)`
//   background-color: transparent;
//   color: ${(props) => props.theme.colors.gray['500']};
//   &:hover {
//     ${(props) => menuButtonHoverColors(props)}
//   }
//   &:focus {
//     ${(props) => menuButtonHoverColors(props)}
//     box-shadow: none;
//   }
//   &:active {
//     ${(props) => menuButtonHoverColors(props)}
//   }
// `;

// Rounded variation used in the Case Details page
// TODO: could we use the rounded version everywhere?
// export const ContextMenuRoundButton = styled(ContextMenuButton)`
//   border-radius: 99px;
//   border-width: 1px;
//   background-color: white;
// `;

// const menuButtonHoverColors = (props) => `
//   background-color: ${props.theme.colors.gray['100']};
//   color: ${props.theme.colors.gray['900']};
// `;
