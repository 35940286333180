import {useToast} from '@chakra-ui/react';

import {useApp, useLocale} from 'shared';
import {useModal} from 'components/core';

export function useTrashBinActions({selection, setSelection, total, reloadScans}) {
  const locale = useLocale();
  const app = useApp();
  const modal = useModal();
  const toast = useToast();

  const numberOfItems = selection.getNumberOfItems(total);

  async function restore() {
    if (
      !(await modal.confirm(
        locale.todo(
          `Are you sure you want to restore ${numberOfItems} scan(s) from the trash bin?`
        ),
        {
          title: 'Restore',
          okButton: locale.todo('Restore')
        }
      ))
    ) {
      return;
    }
    const backend = await app.getBackend();
    const accessToken = app.getAccessToken();
    await backend.restoreScans({selection, accessToken});
    toast({
      title: 'Restore scans',
      description: `${numberOfItems} scan(s) were restored`,
      status: 'success',
      isClosable: true
    });
    reloadScans();
  }

  return {restore};
}
