import React from 'react';
import {Icon, IconProps} from '@chakra-ui/react';
import {
  MdArrowBack,
  MdArrowForward,
  MdCheck,
  MdClose,
  MdDelete,
  MdEdit,
  MdFullscreen,
  MdFullscreenExit,
  MdHelpOutline,
  MdInfo,
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
  MdKeyboardArrowUp,
  MdLoop,
  MdRemove,
  MdSearch,
  MdWarning
} from 'react-icons/md';
import {GoPlus} from 'react-icons/go';
import {FaDownload} from 'react-icons/fa';

const wrap = (Component: React.FC, settings?: IconProps) => (props: IconProps) =>
  <Icon as={Component} {...settings} {...props} />;

export const AddIcon = wrap(GoPlus, {fontSize: 20});

export const BackIcon = wrap(MdArrowBack, {fontSize: 24});

export const CheckIcon = wrap(MdCheck, {fontSize: 24});

export const ChevronDownIcon = wrap(MdKeyboardArrowDown);

export const ChevronRightIcon = wrap(MdKeyboardArrowRight);

export const ChevronUpIcon = wrap(MdKeyboardArrowUp);

export const CloseIcon = wrap(MdClose);

export const DeleteIcon = wrap(MdDelete, {fontSize: 20});

export const DownloadIcon = wrap(FaDownload, {fontSize: 24});

export const EditIcon = wrap(MdEdit);

export const FullScreenEnterIcon = wrap(MdFullscreen, {fontSize: 24});

export const FullScreenExitIcon = wrap(MdFullscreenExit);

export const InfoIcon = wrap(MdInfo, {fontSize: 24});

export const NextIcon = wrap(MdArrowForward, {fontSize: 20});

export const PreviousIcon = wrap(MdArrowBack, {fontSize: 20});

export const PyramidIcon = (props) => (
  <Icon viewBox="0 0 256 310" {...props}>
    <path d="M254 236L148 10c-3-6-8-10-15-10-6 0-12 3-15 8L3 195c-4 6-4 13 0 19l56 87c5 7 13 10 21 7l163-48c5-1 9-5 11-10 3-4 3-10 0-14zm-23 9L92 286c-5 2-9-2-8-6l50-238c1-4 7-5 9-1l92 195a7 7 0 01-4 9z" />
  </Icon>
);

export const HelpIcon = wrap(MdHelpOutline, {fontSize: 20});

export const ReloadIcon = wrap(MdLoop, {fontSize: 20});

export const RemoveIcon = wrap(MdRemove, {fontSize: 24});

export const SearchIcon = wrap(MdSearch, {fontSize: 20});

export const WarningIcon = wrap(MdWarning, {fontSize: 24});
