import {Link as RouterLink, useLocation} from 'react-router-dom';
import {
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Input,
  Link,
  Select,
  Textarea,
  Tooltip
} from '@chakra-ui/react';

import {ACCESS_LEVELS, formatSearchParams, SearchQueryItem, useApp, useLocale} from 'shared';
import {DateInput, SearchIcon} from 'components/core';
import {
  AutocompleteFreeText,
  OrganPicker,
  SpecimenTypePicker,
  StainingPicker,
  SupplierPicker,
  TagPicker
} from './fields';

export const ScanForm = ({getValue, setValue, excludeFields = [], isSearchForm}: any) => {
  const app = useApp();
  const locale = useLocale();

  const {user} = app.state;

  const freeTextFields = ['comments', 'customField1', 'customField2', 'customField3'];

  const filename = getValue('image.filename');
  const isFilenameEditable = isSearchForm; // || user.accessLevel >= ACCESS_LEVELS.SUPER_ADMIN;

  return (
    <Grid templateColumns="repeat(12)" columnGap={4} rowGap={4}>
      {!excludeFields.includes('image.filename') && (
        <GridItem colSpan={8}>
          <FormControl id="filename">
            <FormLabel>{locale.todo('Filename')}</FormLabel>
            {isFilenameEditable ? (
              <>
                <Input
                  id="filename"
                  value={filename}
                  onChange={(event) => {
                    const value = locale.parseTextInput(event.target.value);
                    setValue('image.filename', value);
                  }}
                />
              </>
            ) : (
              <Flex align="center">
                {filename}
                <SearchByFilenameButton filename={filename} />
              </Flex>
            )}
          </FormControl>
        </GridItem>
      )}

      {!excludeFields.includes('reference') && (
        <GridItem colSpan={4}>
          <FormControl id="reference">
            <FormLabel>{locale.todo('Reference')}</FormLabel>
            <Input
              id="reference"
              value={locale.formatTextInput(getValue('reference'))}
              onChange={(event) => {
                const value = locale.parseTextInput(event.target.value);
                setValue('reference', value);
              }}
            />
          </FormControl>
        </GridItem>
      )}

      {user.accessLevel >= ACCESS_LEVELS.SUPER_ADMIN && (
        <GridItem colSpan={8}>
          <FormControl id="supplier">
            <FormLabel>{locale.todo('Supplier')}</FormLabel>
            <SupplierPicker
              value={locale.formatTextInput(getValue('supplier'))}
              onChange={({value}) => {
                setValue('supplier', locale.parseTextInput(value));
              }}
            />
          </FormControl>
        </GridItem>
      )}

      <GridItem colSpan={4}>
        <FormControl id="zoomLevel">
          <FormLabel>{locale.todo('Magnification')}</FormLabel>
          <Select
            value={locale.formatNumberInput(getValue('zoomLevel'))}
            onChange={(event) => {
              setValue('zoomLevel', locale.parseNumberInput(event.target.value));
            }}
          >
            <option value=""></option>
            <option value="10">x10</option>
            <option value="20">x20</option>
            <option value="40">x40</option>
          </Select>
        </FormControl>
      </GridItem>

      <GridItem colSpan={12}>
        <FormControl id="tags">
          <FormLabel>{locale.todo('Tags')}</FormLabel>
          <TagPicker value={getValue('tags')} onChange={(value) => setValue('tags', value)} />
        </FormControl>
      </GridItem>

      <GridItem colSpan={4}>
        <FormControl id="organ">
          <FormLabel>{locale.todo('Organ')}</FormLabel>
          <OrganPicker
            value={locale.formatTextInput(getValue('organ'))}
            onChange={({value}) => {
              setValue('organ', locale.parseTextInput(value));
            }}
          />
        </FormControl>
      </GridItem>

      <GridItem colSpan={4}>
        <FormControl id="specimenType">
          <FormLabel>{locale.todo('Type of specimen')}</FormLabel>
          <SpecimenTypePicker
            id="specimenType"
            value={getValue('specimenType') || ''}
            onChange={(event) => {
              const value = locale.parseTextInput(event.target.value);
              setValue('specimenType', value);
            }}
          />
        </FormControl>
      </GridItem>

      <GridItem colSpan={4}>
        <FormControl id="staining">
          <FormLabel>{locale.todo('Staining')}</FormLabel>
          <StainingPicker
            value={locale.formatTextInput(getValue('staining'))}
            onChange={({value}) => {
              setValue('staining', locale.parseTextInput(value));
            }}
          />
        </FormControl>
      </GridItem>

      {freeTextFields.map((field) => (
        <GridItem key={field} colSpan={12}>
          <FormControl id={field}>
            <FormLabel>{locale.scanFields[field]}</FormLabel>
            <Textarea
              id={field}
              value={locale.formatTextInput(getValue(field))}
              onChange={(event) => {
                const value = locale.parseTextInput(event.target.value);
                setValue(field, value);
              }}
              rows={isSearchForm ? 1 : 5}
            />
          </FormControl>
        </GridItem>
      ))}

      <GridItem colSpan={4}>
        <FormControl id="disease">
          <FormLabel>{locale.todo('Disease')}</FormLabel>
          <AutocompleteFreeText
            path="disease"
            value={getValue('disease')}
            onChange={(value) => setValue('disease', value)}
          />
        </FormControl>
      </GridItem>

      <GridItem colSpan={4}>
        <FormControl id="gene">
          <FormLabel htmlFor="gene">{locale.todo('Gene')}</FormLabel>
          <AutocompleteFreeText
            path="gene"
            value={getValue('gene')}
            onChange={(value) => setValue('gene', value)}
          />
        </FormControl>
      </GridItem>
      <GridItem colSpan={4}>
        <FormControl id="protein">
          <FormLabel>{locale.todo('Protein')}</FormLabel>
          <AutocompleteFreeText
            path="protein"
            value={getValue('protein')}
            onChange={(value) => setValue('protein', value)}
          />
        </FormControl>
      </GridItem>
      <GridItem colSpan={4}>
        <FormControl id="scannedOn">
          <FormLabel htmlFor="">{locale.todo('Scanned on')}</FormLabel>
          <DateInput
            id="scannedOn"
            value={getValue('scannedOn')}
            onChange={(value) => {
              setValue('scannedOn', value);
            }}
          />
        </FormControl>
      </GridItem>

      {user.accessLevel >= ACCESS_LEVELS.SUPER_ADMIN && (
        <GridItem colSpan={4}>
          <FormControl id="scannedAt">
            <FormLabel>{locale.todo('Scanned at')}</FormLabel>
            <AutocompleteFreeText
              path="scannedAt"
              value={getValue('scannedAt')}
              onChange={(value) => setValue('scannedAt', value)}
            />
          </FormControl>
        </GridItem>
      )}
      <GridItem colSpan={4}>
        <FormControl id="collectedOn">
          <FormLabel>{locale.todo('Collected on')}</FormLabel>
          <DateInput
            id="collectedOn"
            value={getValue('collectedOn')}
            onChange={(value) => {
              setValue('collectedOn', value);
            }}
          />
        </FormControl>
      </GridItem>
    </Grid>
  );
};

const SearchByFilenameButton = ({filename}: {filename: string}) => {
  const location = useLocation();
  const query: SearchQueryItem[] = [{field: 'image.filename', value: filename, operator: 'is'}];
  const searchParams = formatSearchParams({query});

  return (
    <Tooltip label="Search by filename" fontSize="md" hasArrow>
      <Link ml={2} as={RouterLink} to={{...location, pathname: '/scans', search: searchParams}}>
        <IconButton
          icon={<SearchIcon />}
          aria-label="Search"
          size="md"
          variant="outline"
          borderRadius="4px"
        />
      </Link>
    </Tooltip>
  );
};
