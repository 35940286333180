import React, {useContext, useEffect} from 'react';

const KEY_DOWN = 'keydown';
const KEY_UP = 'keyup';

type Handler = {
  viewId: string;
  value: string;
  onDown: (event: KeyboardEvent) => any;
  onUp?: (event: KeyboardEvent) => any;
  isCaseSensitive?: boolean;
  enabled?: boolean; // used to disable shortcut when the view is not visible
};
type EventType = 'keydown' | 'keyup';

const context = React.createContext<KeyProvider | null>(null);
export const {Provider, Consumer} = context;

export function useKeyHandler(handler: Handler) {
  const keyProvider = useContext(context);
  useEffect(() => {
    let unbind = keyProvider!.addHandler(handler);
    return () => {
      unbind();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}

// When the user switches the image viewer tab, we need to enable/disable keyboard shortcuts
export function useToggleKeyHandlers() {
  const keyProvider = useContext(context);

  function toggle(viewerId: string, isVisible: boolean) {
    keyProvider!.handlerStack.forEach((handler) => {
      if (handler.viewId === viewerId) {
        handler.enabled = isVisible;
      }
    });
  }

  return toggle;
}

export class KeyProvider extends React.Component {
  // Don't use React state for this component, we don't want to re-render when events are fired!
  handlerStack: Handler[] = [];
  isKeyPressed = false;

  componentDidMount() {
    window.document.addEventListener(KEY_DOWN, this.handleKey);
    window.document.addEventListener(KEY_UP, this.handleKey);
  }

  componentWillUnmount() {
    window.document.removeEventListener(KEY_DOWN, this.handleKey);
    window.document.removeEventListener(KEY_UP, this.handleKey);
  }

  handleKey = (event: KeyboardEvent) => {
    const {target, type, key} = event; // `event.key` is the actual key, " " for the Space bar, "Escape" for the ESC key...

    if (isInput(target)) {
      return;
    }

    if (isModalEvent(event)) {
      return;
    }

    if (type === KEY_DOWN && this.isKeyPressed) {
      return; // Don't fire multiple keyDown event when the user keeps the key pressed
    }

    this.isKeyPressed = type === KEY_DOWN;

    const handlers = this.findHandlers(key, type as EventType);
    this.runHandlers(handlers, event);
  };

  findHandlers(key: string, type: EventType) {
    return this.handlerStack
      .filter((handler) => handler.enabled !== false)
      .filter(({value, isCaseSensitive}) => {
        const isTheSameKey = isCaseSensitive
          ? key === value
          : key.toLowerCase() === value.toLowerCase();
        return isTheSameKey;
      })
      .filter((handler) => {
        return Boolean(getHandlerFunction(handler, type));
      });
  }

  runHandlers = (handlers: Handler[], event: KeyboardEvent) => {
    if (handlers.length === 0) {
      return;
    }

    for (let index = handlers.length - 1; index >= 0; index--) {
      const handler = handlers[index];
      const func = getHandlerFunction(handler, event.type as EventType);
      const result = func!(event);
      const eventConsumed = result !== false;
      if (eventConsumed) {
        break; // the event is consumed => don't propagate the event
      }
    }
  };

  addHandler = (handler: Handler) => {
    const {onDown, onUp} = handler;

    if (!(onDown || onUp)) {
      throw new Error('At least one function `onDown` or `onUp` should be provided');
    }

    const handlerStack = this.handlerStack;

    handlerStack.push(handler);

    const removeHandler = () => {
      const index = handlerStack.indexOf(handler);
      handlerStack.splice(index, 1);
    };

    return removeHandler;
  };

  render() {
    const {children} = this.props;

    return <Provider value={this}>{children}</Provider>;
  }
}

type Props = {
  keyProvider: KeyProvider;
} & Handler;
class WrappedKeyHandler extends React.Component<Props> {
  removeHandler!: () => void;

  static defaultProps = {
    isCaseSensitive: false
  };

  componentDidMount() {
    const {keyProvider, ...handler} = this.props;

    this.removeHandler = keyProvider.addHandler(handler);
  }

  componentWillUnmount() {
    this.removeHandler();
  }

  render() {
    return null;
  }
}

export const KeyHandler = (props) => (
  <Consumer>{(keyProvider) => <WrappedKeyHandler {...props} keyProvider={keyProvider} />}</Consumer>
);

function getHandlerFunction(handler: Handler, type: EventType) {
  if (type === KEY_DOWN) {
    return handler.onDown;
  }
  if (type === KEY_UP) {
    return handler.onUp;
  }
}

/*
Ignore ReactModal `Escape` keydown events
TODO check if the bug about event propagation in React Portal is fixed https://github.com/reactjs/react-modal/issues/699
*/
function isModalEvent({target}) {
  return /ReactModal/i.test(target.className);
}

// Stolen from https://github.com/ayrton/react-key-handler

function isInput(element) {
  if (!element) {
    return false;
  }
  if (!(element instanceof window.HTMLElement)) {
    return false;
  }
  const {tagName} = element;
  const editable = isContentEditable(element);

  return tagName === 'INPUT' || tagName === 'TEXTAREA' || editable;
}

function isContentEditable(element) {
  if (typeof element.getAttribute !== 'function') {
    return false;
  }

  return Boolean(element.getAttribute('contenteditable'));
}
