import React, {useMemo, memo} from 'react';
import compact from 'lodash/compact';

import {ACCESS_LEVELS, hasPermission, useApp} from 'shared';
import {
  AnnotationPlugin,
  DownloadPlugin,
  MagnificationPlugin,
  PredictionPlugin,
  RotationPlugin,
  ScaleBarPlugin,
  WSIAnnotationsPlugin,
  ZoomPlugin
} from 'components/image-viewer/plugins';
import {ImageViewer} from './image-viewer';

type Props = {
  image: Datastore.Image;
  scan?: Datastore.Scan;
  isFullScreen?: boolean;
};
export const ImageViewerWithPlugins = memo((props: Props) => {
  const {image, scan, isFullScreen = true} = props;
  const {allowAnnotations, allowPrediction, allowDownload} = useViewerOptions();

  const plugins = useMemo(
    () =>
      compact([
        ZoomPlugin(),
        isFullScreen && MagnificationPlugin(),
        RotationPlugin(),
        ScaleBarPlugin(),
        allowDownload && DownloadPlugin(),
        allowAnnotations && AnnotationPlugin(),
        allowAnnotations && WSIAnnotationsPlugin(),
        allowPrediction && PredictionPlugin()
      ]),
    [image.id] // eslint-disable-line react-hooks/exhaustive-deps
  );
  return <ImageViewer image={image} scan={scan} plugins={plugins} showBackButton={isFullScreen} />;
});

function useViewerOptions() {
  const app = useApp();
  const {
    state: {user}
  } = app;
  return {
    allowAnnotations: user.accessLevel >= ACCESS_LEVELS.ADMIN,
    allowPrediction: hasPermission('predictions/request', user),
    allowDownload: hasPermission('image/download', user)
  };
}
