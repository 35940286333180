/*
A debounced version of ChakraUI Slider to solve issues related with `onChangeEnd` event
E.g. https://github.com/chakra-ui/chakra-ui/issues/3860
*/
import {useState} from 'react';
import {Slider as ChakraSlider, SliderProps} from '@chakra-ui/slider';
import {useDebounce} from 'react-use';

export const Slider = ({value, onChange, ...props}: SliderProps) => {
  const [localValue, setLocalValue] = useState(value);
  const delayMs = 200;
  useDebounce(
    () => {
      if (localValue === value) return; // avoid triggering an event when the component is mounted
      onChange!(localValue as number);
    },
    delayMs,
    [localValue]
  );
  return <ChakraSlider {...props} value={localValue} onChange={setLocalValue} />;
};
