import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {Alert, Box, HStack, Select} from '@chakra-ui/react';

import {useLocale, withLocale} from 'shared';
import {Heading1, Heading2, Spinner, SubTitle} from 'components/core';
import {CalendarView} from './calendar-view';
import {BillingView} from './billing-view';
import {Navigation} from './navigation';
import {useFetchReport} from './fetch-report';

/*
Example of URL to show the report:
/annotations/report/2019/3?status=unverified&view=calendar
*/

// Mapping between the keys used in the code and the URL parameters
const urlParameterNames = {
  verificationStatus: 'verification',
  viewType: 'view'
};

// @ts-ignore
@withRouter
@withLocale
export class AnnotationReport extends Component<any> {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  /*
  Called when the user pushes the "Previous" and "Next" buttons
  */
  navigate = (year, month) => {
    const {
      history,
      location: {search}
    } = this.props;

    history.push({pathname: `/annotations/report/${year}/${month}`, search});
  };

  /*
  Called when the user changes the status: `verified` / `unverified` / `all`,
  or the view type `calendar` / `summary`
  */
  onChangeQuery = ({key, value}) => {
    const {
      history,
      location: {search, pathname}
    } = this.props;

    const params = new URLSearchParams(search);
    params.set(urlParameterNames[key], value);

    history.push({pathname, search: params.toString()});
  };

  render() {
    const {
      match: {
        params: {year: yearParam, month: monthParam}
      },
      location: {search},
      locale: l
    } = this.props;

    const today = new Date();
    const year = yearParam ? Number(yearParam) : today.getFullYear();
    const month = monthParam ? Number(monthParam) : today.getMonth() + 1;
    const params = new URLSearchParams(search);
    const verificationStatus = params.get(urlParameterNames.verificationStatus) || 'all';
    const viewType = params.get(urlParameterNames.viewType) || 'calendar';

    const query = {year, month, verificationStatus, viewType};

    return (
      <>
        <Heading1>
          {l.todo('Annotation report')}
          <SubTitle>{l.todo('Number of annotated images by user')}</SubTitle>
        </Heading1>
        {viewType === 'calendar' ? (
          <CalendarReport
            query={query}
            onChangeQuery={this.onChangeQuery}
            navigate={this.navigate}
          />
        ) : (
          <BillingReport
            query={query}
            onChangeQuery={this.onChangeQuery}
            navigate={this.navigate}
          />
        )}
      </>
    );
  }
}

const CalendarReport = ({query, onChangeQuery, navigate}) => {
  const {data, isLoading, error} = useFetchReport(query);

  if (isLoading) return <Spinner />;
  if (error) return <Alert status="error">Unable to load report data</Alert>;

  return (
    <>
      <ReportHeader query={query} onChangeQuery={onChangeQuery} navigate={navigate} />
      <CalendarView {...query} users={data} />
    </>
  );
};

const BillingReport = ({query, onChangeQuery, navigate}) => {
  const {data, isLoading, error} = useFetchReport(query);

  if (isLoading) return <Spinner />;
  if (error) return <Alert status="error">Unable to load report data</Alert>;

  return (
    <>
      <ReportHeader query={query} onChangeQuery={onChangeQuery} navigate={navigate} />
      <BillingView {...query} users={data} />
    </>
  );
};

const ReportHeader = ({query, onChangeQuery, navigate}) => {
  const locale = useLocale();
  const {year, month, verificationStatus, viewType} = query;

  const statusOptions = [
    {value: 'all', label: locale.todo('(All)')},
    {value: 'verified', label: locale.todo('Verified')},
    {value: 'unverified', label: locale.todo('Unverified')}
  ];

  const viewOptions = [
    {value: 'calendar', label: locale.todo('Calendar')},
    {value: 'billing', label: locale.todo('Billing')}
  ];

  return (
    <Navigation
      year={year}
      month={month}
      navigate={navigate}
      style={{marginTop: '-.5rem', marginBottom: '1rem'}}
    >
      <HStack alignItems="center">
        <Heading2 m={0}>
          {locale.formatMonth(month)} {year}
        </Heading2>
        <Box>
          <Select
            value={verificationStatus}
            onChange={(event) =>
              onChangeQuery({
                key: 'verificationStatus',
                value: event.target.value
              })
            }
          >
            {statusOptions.map(({value, label}) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </Box>
        <Box>
          <Select
            value={viewType}
            onChange={(event) => onChangeQuery({key: 'viewType', value: event.target.value})}
          >
            {viewOptions.map(({value, label}) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </Box>
      </HStack>
    </Navigation>
  );
};
