import React from 'react';
import {Alert, Box, Flex} from '@chakra-ui/react';

import {PaginationControls, PaginationCount} from 'shared/pagination';
import {useParseURL} from 'shared/search';
import {useFetchAILabels} from 'components/settings/ai-labels-api';
import {Heading1, PageContainer, Spinner, SubTitle} from 'components/core';
import {useFetchPredictions} from './fetch-predictions';
import {PredictionList} from './prediction-list';

export const PredictionListPage = () => {
  const searchOptions = useParseURL({limit: 20});
  const {isLoading, data, error} = useFetchPredictions(searchOptions);
  const labelsQuery = useFetchAILabels();

  if (isLoading || labelsQuery.isLoading) return <Spinner />;
  if (error || labelsQuery.error)
    return <Alert status="error">Unable to load the predictions</Alert>;
  if (!data) return null;

  const {predictions, totalNumberOfPredictions: total} = data;
  const {models} = labelsQuery.data || {};

  return (
    <PageContainer maxW="container.lg">
      <Heading1>
        Predictions
        <SubTitle>
          <PaginationCount
            searchOptions={searchOptions}
            total={total}
            pageItemCount={predictions.length}
          />
        </SubTitle>
      </Heading1>
      <Box overflow="auto" borderWidth="1px">
        <PredictionList predictions={predictions} models={models} />
      </Box>
      <Flex mt={6} flexShrink={0} justifyContent="flex-end">
        <PaginationControls searchOptions={searchOptions} total={total} />
      </Flex>
    </PageContainer>
  );
};
