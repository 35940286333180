import {Redirect, Route, Switch, useLocation} from 'react-router';
import {useUpdateEffect} from 'react-use';

import {usePreferences} from 'shared';
import {AIModelsPage} from './models/ai-models-page';
import {AILabelsPage} from './labels/ai-labels-page';
import {OrgansPage, SuppliersPage, SpecimenTypePage, StainingPage} from './keywords/keywords-pages';

export const SettingsRoot = () => {
  const location = useLocation();
  const {pathname} = location;
  const [defaultPathname, setDefaultPathname] = usePreferences('defaultSettingsPathname');

  useUpdateEffect(() => {
    if (pathname !== '/settings' && pathname !== defaultPathname) {
      setDefaultPathname(pathname);
    }
  });

  return (
    <Switch>
      <Route exact path="/settings/labels" component={AILabelsPage} />
      <Route exact path="/settings/models" component={AIModelsPage} />
      <Route exact path="/settings/suppliers" component={SuppliersPage} />
      <Route exact path="/settings/organs" component={OrgansPage} />
      <Route exact path="/settings/specimens" component={SpecimenTypePage} />
      <Route exact path="/settings/staining" component={StainingPage} />
      <Redirect from="/settings" to={defaultPathname} />
    </Switch>
  );
};
