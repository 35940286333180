import {Prediction} from 'models/prediction';
import aiThresholds from 'data/ai-thresholds.json';

const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/tiff'];
const ACCEPTED_IMAGE_EXTENSIONS = ['.svs', '.ndpi', '.scn'];
const MAXIMUM_IMAGE_SIZE = 2 * 1000 * 1000 * 1000; // 2 Gbytes
const IMMUTABLE_FILES_MAX_AGE = 3153600000; // 100 years!

export const createMedmainImagesMixin = (options) => (Base) => {
  const {
    backendURL,
    acceptedImageTypes = ACCEPTED_IMAGE_TYPES,
    acceptedImageExtensions = ACCEPTED_IMAGE_EXTENSIONS,
    maximumImageSize = MAXIMUM_IMAGE_SIZE
  } = options;
  return class MedmainImagesMixin extends Base {
    getAcceptedImageTypes() {
      return acceptedImageTypes;
    }

    getAcceptedImageExtensions() {
      return acceptedImageExtensions;
    }

    getMaximumImageSize() {
      return maximumImageSize;
    }

    getImageImportSpecifications() {
      return this.getLocale().imageImportSpecifications({maximumImageSize});
    }

    async uploadImage(image) {
      try {
        await this._startImageUpload(image);
        await this._uploadImage(image);
        await this._completeImageUpload(image);
      } catch (err) {
        if (image.status === 'UPLOADING') {
          await this._failImageUpload(image);
        }
        throw err;
      }
    }

    async abortImageUpload(image) {
      this._abortImageUpload(image);
    }

    async _startImageUpload(image) {
      const imagesBackend = await this.getImagesBackend();
      const accessToken = this.getAccessToken();
      const {
        image: {putOriginalImageURL}
      } = await imagesBackend.startImageUpload({imageId: image.id, accessToken});
      image.putOriginalImageURL = putOriginalImageURL;
      image.status = 'UPLOADING';
      this.publish();
    }

    async _completeImageUpload(image) {
      const imagesBackend = await this.getImagesBackend();
      const accessToken = this.getAccessToken();
      await imagesBackend.completeImageUpload({imageId: image.id, accessToken});
      image.status = 'UPLOADED';
      this.publish();
    }

    async _failImageUpload(image) {
      const imagesBackend = await this.getImagesBackend();
      const accessToken = this.getAccessToken();
      await imagesBackend.failImageUpload({imageId: image.id, accessToken});
      image.status = 'UPLOAD_FAILED';
      this.publish();
    }

    _uploadImage(image) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.open('PUT', image.putOriginalImageURL, true);

        xhr.setRequestHeader('Cache-Control', `max-age=${IMMUTABLE_FILES_MAX_AGE}`);

        xhr.timeout = 30 * 60 * 1000; // 30 minutes

        const throwError = (message) => {
          const err = new Error(message);
          image.xhr = undefined;
          reject(err);
        };

        xhr.onload = () => {
          if (xhr.status === 200) {
            image.xhr = undefined;
            this.publish();
            resolve();
          } else {
            throwError(`Image upload failed (HTTP Status: ${xhr.status})`);
          }
        };

        xhr.onerror = () => {
          throwError(`XMLHttpRequest error during image upload`);
        };

        xhr.ontimeout = () => {
          throwError(`XMLHttpRequest timeout during image upload`);
        };

        xhr.onabort = () => {
          throwError(`Upload has been aborted`);
        };

        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            image.uploadProgress = event.loaded / event.total;
            this.publish();
          }
        };

        xhr.send(image.file);

        image.uploadProgress = 0;
        image.xhr = xhr;
        this.publish();
      });
    }

    _abortImageUpload(image) {
      if (image.xhr) {
        image.xhr.abort();
      }
    }

    async reprocessImage(image) {
      const imagesBackend = await this.getImagesBackend();
      const accessToken = this.getAccessToken();
      await imagesBackend.reprocessImage({imageId: image.id, accessToken});
      image.status = 'UPLOADED';
      this.publish();
    }

    // === Prediction ===

    async startVerifyPrediction({image, prediction}) {
      const imagesBackend = await this.getImagesBackend();
      const accessToken = this.getAccessToken();

      const {predictions} = await imagesBackend.startVerifyPrediction({
        predictionId: prediction.id,
        accessToken
      });
      image.predictions = predictions.map(
        (prediction) => new Prediction(prediction, {deserialize: true})
      );
      this.publish();
    }

    async markPredictionAsVerified(image) {
      const imagesBackend = await this.getImagesBackend();
      const accessToken = this.getAccessToken();

      const {prediction: verifiedPrediction} = await imagesBackend.markPredictionAsVerified({
        predictionId: image.predictions[0].id,
        accessToken
      });

      image.updatePrediction(verifiedPrediction);
      this.publish();
    }

    async undoMarkPredictionAsVerified(image) {
      const imagesBackend = await this.getImagesBackend();
      const accessToken = this.getAccessToken();

      const {prediction: amendedPrediction} = await imagesBackend.undoMarkPredictionAsVerified({
        predictionId: image.predictions[0].id,
        accessToken
      });
      image.updatePrediction(amendedPrediction);
      this.publish();
    }

    async updatePredictionResults({image, predictionId, mode, labelName, positions}) {
      const imagesBackend = await this.getImagesBackend();
      const accessToken = this.getAccessToken();
      const {predictions} = await imagesBackend.updatePrediction({
        predictionId,
        mode,
        labelName,
        positions,
        accessToken
      });
      const amendedPrediction = predictions[0];
      image.updatePrediction(amendedPrediction);
      this.publish();
      return image.predictions;
    }

    // === AI models, labels and thresholds ===

    getAIThresholds() {
      return aiThresholds;
    }

    getAIThreshold(name) {
      return this.getAIThresholds().find((threshold) => threshold.name === name);
    }

    getAIDefaultThreshold() {
      return this.getAIThresholds()[0];
    }

    // === Utilities ===

    async getImagesBackend() {
      if (!this._imagesBackend) {
        this._imagesBackend = await this.createBackend(backendURL);
      }
      return this._imagesBackend;
    }
  };
};
