import React, {useMemo} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Badge, Box, Flex, Link, Wrap, WrapItem} from '@chakra-ui/react';
import {useTable, useFilters, useGlobalFilter, usePagination, useSortBy} from 'react-table';
import compact from 'lodash/compact';

import {ROLES} from 'shared';
import {formatDateTime, TimeAgo} from 'components/core';

export function useUserTable({users}: {users: Datastore.User[]}) {
  const data = useMemo(() => users, []); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = useMemo(
    () => [
      {
        id: 'email',
        Header: 'Email',
        accessor: (user: Datastore.User) => user.account.email,
        Cell: ({row: {original}}) => {
          const user = original as Datastore.User;
          return (
            <>
              <Link
                as={RouterLink}
                to={`/users/${user.id}`}
                color="primary.600"
                _hover={{color: 'primary.500'}}
              >
                {user.account.email}
              </Link>
              {user.account.type === 'BOT' && <Badge ml={1}>BOT</Badge>}
            </>
          );
        }
      },
      {
        id: 'account.firstName',
        Header: 'First name',
        accessor: (user) => user.account.firstName,
        Cell: ({value}) => value || <EmptyCell />,
        styleProps: {
          width: 100
        }
      },
      {
        id: 'account.lastName',
        Header: 'Last name',
        accessor: (user) => user.account.lastName,
        Cell: ({value}) => value || <EmptyCell />,
        styleProps: {
          width: 100
        }
      },
      {
        id: 'account.organization',
        Header: 'Org. / Dpt.',
        accessor: (user) => [user.account.organization, user.account.department],
        Cell: ({value}) => {
          const [organization, department] = value;
          if (!organization && !department) return <EmptyCell />;
          return compact([organization, department]).map((item, index) => (
            <div key={index}>{item}</div>
          ));
        },
        styleProps: {
          width: 180
        }
      },
      {
        id: 'accessLevel',
        Header: 'Access level',
        accessor: (user: Datastore.User) => user.accessLevel,
        Cell: ({row: {original: user}}) => {
          return <UserAccessLevel user={user as Datastore.User} />;
        },
        filter: 'accessLevelFilter',
        disableGlobalFilter: true,
        styleProps: {
          width: 120
        }
      },
      {
        id: 'roles',
        Header: 'Roles / Tags',
        accessor: (user: Datastore.User) => user.roles,
        Cell: ({row: {original: user}}) => {
          return <UserRoles user={user as Datastore.User} />;
        },
        filter: 'roleFilter',
        disableGlobalFilter: true,
        styleProps: {
          width: 200
        }
      },
      {
        id: 'createdOn',
        Header: 'Added',
        accessor: (user: Datastore.User) => user.createdOn,
        Cell: ({row: {original: user}}) => {
          const date = user.createdOn;
          return (
            <>
              <Box>{formatDateTime(date, {mask: 'yyyy-MM-dd'})}</Box>
              <Box color="gray.500" mt={1}>
                <TimeAgo date={date} />
              </Box>
            </>
          );
        },
        disableGlobalFilter: true,
        styleProps: {
          width: 150
        }
      }
    ],
    []
  );

  const filterTypes = useMemo(
    () => ({
      accessLevelFilter: (rows, id, values: number[]) => {
        return rows.filter((row) => {
          const user: Datastore.User = row.original;
          return values.includes(user.accessLevel);
        });
      },
      roleFilter: (rows, id, role: string) => {
        return rows.filter((row) => {
          const user: Datastore.User = row.original;
          return user.roles.includes(role);
        });
      }
    }),
    []
  );
  return useTable<Datastore.User>(
    {
      columns,
      data,
      filterTypes,
      initialState: {pageIndex: 0, pageSize: 50}
    } as any,
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );
}

const UserAccessLevel = ({user}: {user: Datastore.User}) => {
  const {accessLevel} = user;
  const colors = ['white', 'green.400', 'yellow.400', 'orange.400', 'red.400', 'primary.400'];
  return (
    <Flex alignItems="center">
      <Box
        as="span"
        boxSize="12px"
        bg={colors[accessLevel]}
        borderRadius="50px"
        borderWidth="1px"
        borderColor="gray.400"
        mr={1}
      />
      {accessLevel || (
        <Box color="gray.500">
          <i>No access</i>
        </Box>
      )}
    </Flex>
  );
};

const UserRoles = ({user}: {user: Datastore.User}) => {
  const {roles, tagRestrictions} = user;
  const tags = (tagRestrictions as unknown as string)?.split(',') || [];

  if (!roles.length && !tags.length) {
    return <EmptyCell />;
  }
  return (
    <>
      <Wrap>
        {roles
          .map((roleName) => ROLES[roleName]?.shortLabel || roleName)
          .map((role) => (
            <WrapItem key={role}>
              <Badge textTransform="none">{role}</Badge>
            </WrapItem>
          ))}
      </Wrap>
      {tagRestrictions && (
        <Wrap>
          {tags.map((tag) => (
            <WrapItem key={tag}>
              <Badge colorScheme="orange" textTransform="none">
                {tag}
              </Badge>
            </WrapItem>
          ))}
        </Wrap>
      )}
    </>
  );
};

const EmptyCell = () => <Box color="gray.400">--</Box>;
