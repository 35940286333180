import React, {useState} from 'react';
import {Alert, Box} from '@chakra-ui/react';
import compact from 'lodash/compact';
import invariant from 'tiny-invariant';

import {ItemSelection, PaginationCount, SearchOptions, useLocale, useParseURL} from 'shared';
import {useFetchScans} from 'components/scan-list/fetch-scans';
import {Spinner, SubTitle} from 'components/core';
import {PaginatedScanList} from 'components/scan-list/scan-list-shared';

type ScanListState = ReturnType<typeof useFetchQualityControlScans>;
type Props = {
  status: Datastore.Scan['status'];
  scanListState: ScanListState;
  searchOptions: Required<SearchOptions>;
  selection?: ItemSelection;
  setSelection?: (selection: ItemSelection) => void;
  columns: any[];
  footer?: React.ReactNode;
};

export const QualityControlList = ({
  status,
  searchOptions,
  scanListState,
  selection,
  setSelection,
  columns,
  footer
}: Props) => {
  const {data, isLoading, error} = scanListState;
  const locale = useLocale();
  if (isLoading) return <Spinner />;

  if (error) return <Alert>Unable to load the scans {(error as Error).message}</Alert>;

  invariant(data);
  const {scans, total} = data;

  return (
    <PaginatedScanList
      scans={scans}
      searchOptions={searchOptions}
      total={total}
      selection={selection}
      setSelection={setSelection}
      header={
        <Box>
          {locale.qualityControlListHeader[status]}
          {scans.length > 0 && (
            <SubTitle>
              <PaginationCount
                searchOptions={searchOptions}
                total={total}
                pageItemCount={scans.length}
              />
            </SubTitle>
          )}
        </Box>
      }
      columns={columns}
      footer={footer}
    />
  );
};

export function useFetchQualityControlScans(searchOptions: SearchOptions) {
  const returnFields = [
    'id',
    'supplier',
    'tags',
    'createdOn',
    'status',
    'verifiedOn',
    'rejectionReason',
    'imageId', // required to get image related fields: URL and status
    'image.batch.number',
    'image.filename',
    'image.size',
    'image.smallImageURL',
    'image.status'
  ];

  return useFetchScans(searchOptions, returnFields);
}

export function useQualityControlList({
  status,
  orderBy
}: {
  status: Datastore.Scan['status'];
  orderBy: SearchOptions['orderBy'];
}) {
  const [selection, setSelection] = useState(new ItemSelection());
  const searchOptions = useParseURL({
    limit: 50,
    orderBy,
    query: getSearchQuery(status)
  });
  const scanListState = useFetchQualityControlScans(searchOptions);
  return {
    status,
    scanListState,
    searchOptions,
    selection,
    setSelection
  };
}

export function getSearchQuery(status: Datastore.Scan['status']): SearchOptions['query'] {
  const scanStatusQuery = {field: 'status', operator: 'is', value: status};
  // Exclude images unavailable (E.g. being processed)
  const imageStatusQuery = status === 'TO_BE_VERIFIED' && {
    field: 'image.status',
    operator: 'is',
    value: 'AVAILABLE'
  };
  const query = compact([scanStatusQuery, imageStatusQuery]);
  return query;
}
