import React from 'react';
import {Box, Flex, HStack, Select} from '@chakra-ui/react';
import {MdFirstPage, MdLastPage} from 'react-icons/md';

import {useLocale} from 'shared';
import {IconButton} from './button';
import {BackIcon, NextIcon} from './icons';

export const pageSizes = [10, 20, 50, 100];

export const Pagination = (props) => {
  const {
    total,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize},
    ...otherProps
  } = props;

  return (
    <Flex alignItems="center" {...otherProps}>
      <Box flexGrow={1}>
        <DisplayPageNumber limit={pageSize} total={total} offset={pageIndex * pageSize} />
      </Box>
      <HStack>
        <Box>
          <Select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            focusBorderColor="primary.500"
            size="sm"
            maxWidth={200}
          >
            {pageSizes.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize} / page
              </option>
            ))}
          </Select>
        </Box>
        <PaginationButtons {...props} />
      </HStack>
    </Flex>
  );
};

const DisplayPageNumber = ({offset, limit, total}) => {
  const locale = useLocale();
  return (
    <Box>
      {offset + 1}-{Math.min(offset + limit, total)} of {locale.formatNumber(total)}
    </Box>
  );
};

const PaginationButtons = ({
  total,
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  state: {pageIndex, pageSize}
}) => {
  return (
    <HStack spacing={2}>
      <IconButton
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
        aria-label="Go to first page"
        icon={<MdFirstPage fontSize="16px" />}
        size="sm"
        borderRadius="md"
      />
      <IconButton
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
        aria-label="Go to previous page"
        icon={<BackIcon fontSize="16px" />}
        size="sm"
        borderRadius="md"
      />
      <IconButton
        onClick={() => nextPage()}
        disabled={!canNextPage}
        aria-label="Go to next page"
        icon={<NextIcon fontSize="16px" />}
        size="sm"
        borderRadius="md"
      />
      <IconButton
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
        aria-label="Go to last page"
        icon={<MdLastPage fontSize="16px" />}
        size="sm"
        borderRadius="md"
      />
    </HStack>
  );
};
