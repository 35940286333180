import {useQueries, useQuery, UseQueryResult, QueryObserverOptions} from 'react-query';
import debugModule from 'debug';

import {useApp} from 'shared';
import Scan from 'models/scan';
import {Image} from 'models/image';
import {User} from 'models/user';

const debug = debugModule('medmain');

export type FetchImageReturnData = {
  image: Datastore.Image;
  parentScan?: Datastore.Scan;
};
type FetchImageResult = UseQueryResult<FetchImageReturnData, Error>;

type Options = {
  shouldFetchParentScan?: boolean;
  shouldFetchRelatedImage?: boolean;
} & QueryObserverOptions<any, any>;
export function useFetchImage(imageId: string, options?: Options) {
  const {
    shouldFetchParentScan = true,
    shouldFetchRelatedImage = false,
    ...queryOptions
  } = options || {};
  const app = useApp();
  const {getImage} = useImagesBackend();

  const loadImage = async () => {
    let relatedImage, parentScan;
    debug('Loading the image', imageId);
    const result = await getImage({imageId});
    if (!result) throw new Error(`Image not found: ${imageId}`);
    const {image} = result;
    if (image && shouldFetchParentScan) {
      parentScan = await fetchParentScan(app, image);
      if (parentScan && shouldFetchRelatedImage) {
        relatedImage = await fetchRelatedImage(app, parentScan);
        if (relatedImage) {
          debug('Related image found', relatedImage.id, relatedImage.filename);
        }
      }
    }
    return {image, relatedImage, parentScan};
  };

  return useQuery<FetchImageReturnData, Error>(['image', imageId], loadImage, {
    retry: false,
    refetchOnWindowFocus: false,
    ...queryOptions
  });
}

export function useFetchMultipleImages(imageIds: string[]) {
  const {getImage} = useImagesBackend();

  const imageQueries = useQueries(
    imageIds.map((id) => {
      return {
        queryKey: ['image', id],
        queryFn: () => getImage({imageId: id})
      };
    })
  );

  const isLoading = imageQueries.some((query) => query.isLoading);
  const error = (imageQueries as FetchImageResult[]).find((query) => !!query.error)?.error;

  // @ts-ignore
  const images = imageQueries.map((query: FetchImageResult) => query.data?.image);

  return {images, isLoading, error};
}

function useImagesBackend() {
  const app = useApp();
  const defaultReturnFields = [
    'id',
    'createdOn',
    'filename',
    'format',
    'info',
    'size',
    'status',
    'zoomableImageURL',
    'annotations.id',
    'annotations.labelName',
    'annotations.path',
    'annotations.verifiedOn',
    'annotations.path',
    'predictions.id',
    'predictions.modelName',
    'predictions.status',
    'predictions.verificationStatus',
    'predictions.verifiedOn',
    'predictions.verifierId',
    'predictions.results',
    'predictions.updatedOn',
    'wsiAnnotations.id',
    'wsiAnnotations.createdOn',
    'wsiAnnotations.labelName',
    'wsiAnnotations.description',
    'wsiAnnotations.userId'
  ];

  const getImage = async ({imageId, returnFields = defaultReturnFields}) => {
    const imagesBackend = await app.getImagesBackend();
    const accessToken = app.getAccessToken();
    try {
      const {image, user} = await imagesBackend.getImage({imageId, returnFields, accessToken});
      return {
        image: new Image(image, {deserialize: true}) as unknown as Datastore.Image,
        user: new User(user, {deserialize: true})
      };
    } catch (err) {
      if ((err as Error & {code: string}).code === 'IMAGE_NOT_FOUND') {
        return undefined;
      }
      throw err;
    }
  };

  return {
    getImage
  };
}

async function fetchParentScan(app, image) {
  const backend = await app.getBackend();
  const accessToken = app.getAccessToken();
  const {scans: foundScans} = await backend.findScans({
    query: [{field: 'imageId', operator: 'is', value: image.id}],
    returnFields: [
      'id',
      'reference',
      'specimenType',
      'comments',
      'image.filename',
      'image.id',
      'tags',
      'type',
      'image.info'
    ],
    accessToken
  });
  if (foundScans.length) {
    return foundScans[0];
  }
}

// return the "related image", to be displayed on the right side in the "Split Mode"
// it has to be a scan with the same "reference" and a non-empty "staining" value
async function fetchRelatedImage(app, scan) {
  const relatedScan = await fetchRelatedScan(app, scan);
  if (!relatedScan) {
    return undefined;
  }
  const image = relatedScan.image;
  return image;
}

async function fetchRelatedScan(app, currentScan) {
  const backend = await app.getBackend();
  const accessToken = app.getAccessToken();

  const reference = currentScan.reference;
  if (!reference) {
    return undefined;
  }

  let {scans: relatedScans} = await backend.findScans({
    query: [{field: 'reference', operator: 'is', value: reference}],
    returnFields: ['id', 'reference', 'staining', 'image.id', 'image.filename', 'image.size'],
    accessToken
  });

  relatedScans = relatedScans
    .filter((scan) => scan.id !== currentScan.id)
    .filter((scan) => Boolean(scan.staining))
    .map((scan) => new Scan(scan, {deserialize: true}));

  if (!relatedScans) {
    return undefined;
  }

  return relatedScans[0];
}
