import React from 'react';

type Props = {
  url: string;
  width?: string | number;
  height?: string | number;
};
export const PDFViewer = ({url, width = '100%', height = '400'}: Props) => {
  return (
    <object data={url} type="application/pdf" width={width} height={height}>
      <embed src={url} width={width} height={height} type="application/pdf" />
      PDF not supported by your browser!
    </object>
  );
};
