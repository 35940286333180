import React, {useState} from 'react';
import {
  Box,
  ButtonGroup,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Select,
  Alert
} from '@chakra-ui/react';
import invariant from 'tiny-invariant';

import {ACCESS_LEVELS, useApp, useLocale} from 'shared';
import {Button, Spinner} from 'components/core';
import {AnnotationModelInput} from 'components/annotations/annotation-model-input';
import {useFetchAILabels} from 'components/settings/ai-labels-api';

type Output = {
  modelName: string;
  modelVersion: string;
};
type Props = {
  onClose: (output?: Output) => void;
  defaultModelName?: string;
};
export const AnnotationModelChooser = ({onClose, defaultModelName}: Props) => {
  const locale = useLocale();
  const app = useApp();
  const {
    state: {user}
  } = app;

  const [modelName, setModelName] = useState(defaultModelName || null);
  const [modelVersion, setModelVersion] = useState('');
  const {data, error, isLoading} = useFetchAILabels();

  if (isLoading)
    return (
      <>
        <Header />
        <ModalBody>
          <Box h={100}>
            <Spinner />
          </Box>
        </ModalBody>
      </>
    );

  if (error)
    return (
      <>
        <Header />
        <ModalBody>
          <Alert status="error">Unable to load the AI labels</Alert>
        </ModalBody>
      </>
    );

  invariant(data);
  const {models} = data;

  const versions = modelName ? getModelVersions() : [];
  const canPickModelVersion = user.accessLevel >= ACCESS_LEVELS.SUPER_ADMIN;

  const onChangeModelName = (modelName: string | null) => {
    const versions = getModelVersions();
    const modelVersion = versions[0].number; // set the first model version as the default one
    setModelName(modelName);
    setModelVersion(modelVersion);
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        invariant(modelName);
        onClose({modelName, modelVersion});
      }}
      autoComplete="off"
    >
      <Header />

      <ModalBody>
        <AnnotationModelInput
          modelName={modelName || ''}
          onChange={onChangeModelName}
          models={models}
        />
        {canPickModelVersion && (
          <Box mt={4}>
            <ModelVersionInput
              modelVersion={modelVersion}
              onChange={setModelVersion}
              versions={versions}
              disabled={versions.length === 0}
            />
          </Box>
        )}
      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          <Button type="button" onClick={() => onClose(undefined)}>
            {locale.cancelButtonLabel}
          </Button>
          <Button type="submit" primary disabled={!modelName}>
            {locale.annotationModelChooserChooseButtonLabel}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </form>
  );
};

const Header = () => {
  const locale = useLocale();
  return (
    <ModalHeader>
      {locale.annotationModelChooserHeading}
      <ModalCloseButton />
    </ModalHeader>
  );
};

function getModelVersions() {
  const versions = [
    {number: 'v1', label: 'v1'},
    {number: 'v2', label: 'v2'}
  ];
  return versions;
}

const ModelVersionInput = ({
  modelVersion,
  versions,
  onChange,
  disabled
}: {
  modelVersion: string;
  versions: {number: string; label: string}[];
  onChange: (value: string) => void;
  disabled: boolean;
}) => {
  const options = versions
    ? versions.map(({number, label}) => ({value: number, label}))
    : [{value: '', label: '--'}];

  return (
    <FormControl id="modelVersion">
      <FormLabel>Version</FormLabel>
      <Select
        value={modelVersion}
        onChange={(event) => onChange(event.target.value)}
        isDisabled={disabled}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};
