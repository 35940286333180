import { createSVGPath } from "models/annotation";

export const ThumbnailAnnotation = ({path, style}) => {
  const SVGPath = createSVGPath(path, false);
  return (
    <svg
      width={'100%'}
      height={'100%'}
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={SVGPath}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect="non-scaling-stroke"
        {...style}
      />
    </svg>
  );
};
