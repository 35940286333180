import {useEffect, useRef} from 'react';
import colormap from 'colormap';
import debugModule from 'debug';

import './openseadragon-canvas-overlay';
import {OpenSeadragon, useOpenSeadragon} from 'components/image-viewer/openseadragon';

const debug = debugModule('medmain:prediction-viewer');

type Props = {
  prediction: Datastore.Prediction;
  imageWidth: number;
  imageHeight: number;
  highlightLabels: string[];
  threshold: number;
  viewMode: 'highlight' | 'heatmap';
};

type Position = [number, number];

const colors = colormap({
  colormap: 'jet',
  nshades: 101,
  format: 'rgbaString',
  alpha: 0.5
});

export const PredictionViewer = ({
  prediction,
  imageWidth,
  imageHeight,
  highlightLabels,
  threshold,
  viewMode
}: Props) => {
  const osd = useOpenSeadragon();
  debug('Render the prediction viewer', highlightLabels.length, viewMode, threshold, prediction.id);
  const overlayRef = useRef<any>();

  const refForViewer = useRef<Props>();
  refForViewer.current = {
    highlightLabels,
    threshold,
    viewMode,
    prediction,
    imageWidth,
    imageHeight
  };

  useEffect(() => {
    overlayRef.current = osd.instance.canvasOverlay({
      onRedraw: () => handleOverlay(overlayRef.current, osd, refForViewer!.current!),
      clearBeforeRedraw: true
    });
    return () => {
      overlayRef.current.clear();
      overlayRef.current._viewer.canvas.removeChild(overlayRef.current._canvasdiv);
      overlayRef.current._canvasdiv = null;
      overlayRef.current.destroy();
      delete overlayRef.current._canvasOverlayInfo;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    osd.instance.forceRedraw();
  }, [highlightLabels, viewMode, threshold, prediction.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

function handleOverlay(
  overlay,
  osd: OpenSeadragon,
  {prediction, imageWidth, imageHeight, highlightLabels, threshold, viewMode}: Props
) {
  const scaleX = 1;
  const scaleY = imageHeight / imageWidth;

  const cellSize = prediction.results.cellSize;
  const numOfCellsX = imageWidth / cellSize;
  const numOfCellsY = imageHeight / cellSize;
  const cellWidth = (cellSize / imageWidth) * scaleX;
  const cellHeight = (cellSize / imageHeight) * scaleY;

  const locations = getLocations(prediction);

  const showBlueBg = false;
  debug('Drawing', highlightLabels.length);
  if (viewMode === 'highlight') {
    prepareHighlightOverlay(overlay, scaleX, scaleY);
  }
  if (viewMode === 'heatmap') {
    const viewport = osd.instance.viewport;
    prepareHeatmapOverlay(overlay, viewport, scaleX, scaleY, showBlueBg);
  }

  const context = overlay.context2d();
  for (let i = 0; i < locations.length; i++) {
    const [x, y] = locations[i];
    const positionKey = formatCellLocation(locations[i]);
    const probabilities = Object.entries(prediction.results.cells[positionKey])
      .filter(([labelName, value]) => highlightLabels.includes(labelName))
      .map(([_, value]) => value);
    const p = probabilities.length ? Math.max(...probabilities) : 0;
    const dx = (x / numOfCellsX) * scaleX;
    const dy = (y / numOfCellsY) * scaleY;

    if (viewMode === 'highlight') {
      if (p >= threshold) {
        context.clearRect(dx, dy, cellWidth, cellHeight);
      }
    }

    if (viewMode === 'heatmap') {
      if (p > 10) {
        if (showBlueBg) {
          context.clearRect(dx, dy, cellWidth, cellHeight);
        }
        const colorIndex = Math.floor(p);
        const color = colors[colorIndex];
        context.fillStyle = color;
        context.fillRect(dx, dy, cellWidth, cellHeight);
        context.strokeRect(dx, dy, cellWidth, cellHeight);
      }
    }
  }
}

function getLocations(prediction: Datastore.Prediction): Position[] {
  const {
    results: {cells}
  } = prediction;
  return Object.keys(cells).map(parseCellLocation);
}

function parseCellLocation(key: string): Position {
  return key.split(':').map((coordinate) => parseInt(coordinate)) as Position;
}

function formatCellLocation(position: Position): string {
  return position[0] + ':' + position[1];
}

function prepareHighlightOverlay(overlay, scaleX, scaleY) {
  const canvas = overlay.canvas();
  const context = overlay.context2d();

  canvas.style.mixBlendMode = 'hue';
  context.fillStyle = 'rgba(0,0,0,1)';
  context.fillRect(0, 0, scaleX, scaleY);
}

function prepareHeatmapOverlay(overlay, viewport, scaleX, scaleY, showBlueBg) {
  const canvas = overlay.canvas();
  const context = overlay.context2d();

  if (showBlueBg) {
    context.fillStyle = colors[0];
    context.fillRect(0, 0, scaleX, scaleY);
  }

  const maxLevel = getLevel(viewport.getMaxZoom());
  const level = getLevel(viewport.getZoom());
  const opacity = Math.max(0, 0.7 - level / maxLevel);

  canvas.style.mixBlendMode = 'initial';
  context.lineWidth = 0.001;
  context.strokeStyle = `rgba(0,125,225,${opacity})`;
}

function getLevel(zoom) {
  return Math.log2(zoom) + 1;
}
