import React from 'react';
import {
  Box,
  Checkbox,
  DarkMode,
  Flex,
  HStack,
  Icon,
  Radio,
  RadioGroup,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  useRadio,
  useRadioGroup
} from '@chakra-ui/react';
import {MdRadioButtonChecked, MdRadioButtonUnchecked} from 'react-icons/md';
import invariant from 'tiny-invariant';

import {useLocale} from 'shared';
import {RemoveIcon, Slider, TimeAgo} from 'components/core';
import {
  Panel,
  PanelButton,
  PanelHeader,
  PanelHeading1,
  PanelHeading2,
  PanelDivider,
  PanelIconButton,
  PanelFooter
} from 'components/image-viewer/panel';
import {AIModel} from 'components/settings/types';

type PredictionLabel = {
  name: string;
  displayName: string | {en: string};
  model: AIModel;
  active: boolean;
};

type Props = {
  predictions: Datastore.Prediction[];
  labels: PredictionLabel[];
  activePredictionId: string;
  threshold: number;
  onThresholdChange: (value: number) => void;
  onRemove: (id: string) => void;
  onSelectLabel: (label: PredictionLabel) => void;
  onSwitchPrediction: (id: string) => void;
  onToggleLabel: (label: PredictionLabel) => void;
  canRemove: boolean;
  showVerificationBar: boolean;
  onMarkAsVerified?: () => void;
  onStartVerify?: (prediction: Datastore.Prediction) => void;
  onUndoMarkAsVerified?: () => void;
  viewMode: string;
  onChangeViewMode: (value: string) => void;
};
export const PredictionPanel = ({
  predictions,
  activePredictionId,
  labels,
  threshold,
  onThresholdChange,
  onRemove,
  onSelectLabel,
  onSwitchPrediction,
  onToggleLabel,
  canRemove,
  showVerificationBar,
  onMarkAsVerified,
  onStartVerify,
  onUndoMarkAsVerified,
  viewMode,
  onChangeViewMode
}: Props) => {
  const locale = useLocale();

  const getPrediction = () => {
    return activePredictionId
      ? predictions.find(({id}) => id === activePredictionId)
      : predictions?.[0];
  };

  const handleStartVerify = () => {
    const prediction = getPrediction();
    invariant(prediction);
    invariant(onStartVerify);
    onStartVerify(prediction);
  };

  const prediction = getPrediction();

  if (!prediction) {
    return null;
  }

  const {status, modelName} = prediction;

  let message;
  if (status === 'WAITING') {
    message = locale.todo('Waiting to start processing...');
  } else if (status === 'RUNNING') {
    message = locale.todo('Processing...');
  } else if (status === 'COMPLETED' && labels.length === 0) {
    message = getNoResultsMessage();
  } else if (status === 'FAILED') {
    message = locale.todo('Processing failed');
  }

  function getNoResultsMessage() {
    switch (modelName) {
      case 'cyto-uterine-cervix':
        return locale.todo('NILM');
      case 'cyto-urine':
        return locale.todo('Negative');
      default:
        return locale.todo('Non-neoplastic lesion');
    }
  }

  const showPredictionSwitcher = predictions.length > 1;
  const activeLabelCount = labels.filter((label) => label.active).length;

  return (
    <Panel>
      <PanelHeader>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <PanelHeading1>{locale.predictionPanelHeading}</PanelHeading1>
          <div style={{flex: '1'}} />
          {canRemove && (
            <PanelIconButton
              onClick={() => onRemove(prediction.id)}
              disabled={status === 'WAITING' || status === 'RUNNING'}
              style={{marginLeft: '.5rem'}}
              aria-label="Remove"
            >
              <RemoveIcon fontSize="12px" />
            </PanelIconButton>
          )}
        </div>
        {showPredictionSwitcher && (
          <div style={{paddingTop: '0.5rem'}}>
            <PredictionSwitcher
              predictions={predictions}
              activePredictionId={activePredictionId}
              onChange={onSwitchPrediction}
            />
          </div>
        )}
      </PanelHeader>
      <Box p={2}>
        {message ? (
          <Box my={8} textAlign="center" color="rgba(255, 255, 255, 0.8)">
            {message}
          </Box>
        ) : (
          <>
            <PanelHeading2 mb={2}>
              {locale.get(labels[0]?.model.displayName) || 'Unknown model'}
            </PanelHeading2>
            {labels.map((label, index) => (
              <React.Fragment key={index}>
                {index > 0 && <PanelDivider />}
                <PredictionRow
                  key={index}
                  label={label}
                  onSelect={
                    activeLabelCount === 1 && label.active
                      ? undefined
                      : () => {
                          onSelectLabel(label);
                        }
                  }
                  onToggle={() => {
                    onToggleLabel(label);
                  }}
                />
              </React.Fragment>
            ))}
          </>
        )}
      </Box>
      {status === 'COMPLETED' && (
        <Box pt={3} py={3} px={2} borderTopWidth="1px">
          <ViewModeControl value={viewMode} onChange={onChangeViewMode} />
          {viewMode === 'highlight' && (
            <ThresholdControl value={threshold} onChange={onThresholdChange} />
          )}
        </Box>
      )}
      {showVerificationBar && (
        <VerificationBar
          prediction={prediction}
          onStartVerify={handleStartVerify}
          onMarkAsVerified={onMarkAsVerified}
          onUndoMarkAsVerified={onUndoMarkAsVerified}
        />
      )}
    </Panel>
  );
};

const VerificationBar = ({
  prediction,
  onStartVerify,
  onMarkAsVerified,
  onUndoMarkAsVerified
}: {prediction: Datastore.Prediction} & Pick<
  Props,
  'onStartVerify' | 'onMarkAsVerified' | 'onUndoMarkAsVerified'
>) => {
  const locale = useLocale();
  const {status} = prediction;
  const verificationStatus = prediction.verificationStatus || 'PENDING';
  invariant(onStartVerify);

  if (status !== 'COMPLETED') {
    return null;
  }

  if (verificationStatus === 'ORIGINAL') {
    return null;
  }

  return (
    <PanelFooter
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        backgroundColor: bgColorsByStatus[verificationStatus]
      }}
    >
      {verificationStatus === 'PENDING' && (
        <>
          <span>To be verified</span>
          <PanelButton onClick={() => onStartVerify(prediction)}>
            {locale.todo('START EDITING')}
          </PanelButton>
        </>
      )}
      {verificationStatus === 'VERIFYING' && (
        <>
          <span>Verifying</span>
          {onMarkAsVerified && (
            <PanelButton onClick={onMarkAsVerified}>{locale.todo('MARK AS VERIFIED')}</PanelButton>
          )}
        </>
      )}
      {verificationStatus === 'VERIFIED' && (
        <>
          <span style={{marginRight: '0.25rem'}}>
            Verified <TimeAgo date={prediction.verifiedOn} />
          </span>
          {onUndoMarkAsVerified && (
            <PanelButton onClick={onUndoMarkAsVerified}>
              {locale.todo('BACK TO EDIT MODE')}
            </PanelButton>
          )}
        </>
      )}
    </PanelFooter>
  );
};

const bgColorsByStatus = {
  PENDING: 'rgba(191, 54, 12, 0.8)',
  VERIFYING: 'rgba(0, 145, 234, 0.8)',
  VERIFIED: 'rgba(46, 125, 50, 0.8)'
};

const PredictionRow = ({
  label,
  onToggle,
  onSelect
}: {
  label: PredictionLabel;
  onToggle: () => void;
  onSelect?: () => void;
}) => {
  const locale = useLocale();

  return (
    <Flex justifyContent="space-between">
      <Checkbox name={label.name} onChange={onToggle} isChecked={label.active} size="md">
        <Box as="span" fontSize="sm">
          {locale.get(label.displayName)}
        </Box>
      </Checkbox>
      {onSelect && <PanelButton onClick={onSelect}>Select</PanelButton>}
    </Flex>
  );
};

const PredictionSwitcher = ({
  predictions,
  activePredictionId,
  onChange
}: Pick<Props, 'predictions' | 'activePredictionId'> & {onChange: Props['onSwitchPrediction']}) => {
  const getPredictionTitle = (prediction) => {
    const status = prediction.verificationStatus || 'ORIGINAL';
    if (status === 'ORIGINAL') {
      return 'Original version';
    }
    return 'Amended version';
  };
  const options = predictions.map((prediction) => ({
    value: prediction.id,
    label: getPredictionTitle(prediction)
  }));

  return (
    <RadioGroup onChange={onChange} value={activePredictionId}>
      <Flex justifyContent="space-between">
        {options.map((option) => (
          <Radio key={option.value} value={option.value} size="sm">
            {option.label}
          </Radio>
        ))}
      </Flex>
    </RadioGroup>
  );
};

const ViewModeControl = ({value, onChange}: {value: string; onChange: (value: string) => void}) => {
  const {getRootProps, getRadioProps} = useRadioGroup({
    name: 'viewMode',
    value,
    onChange
  });
  const group = getRootProps();

  return (
    <HStack w="100%" {...group}>
      <RadioCard key="highlight" {...getRadioProps({value: 'highlight'} as any)} flex="1">
        Highlight
      </RadioCard>
      <RadioCard key="heatmap" {...getRadioProps({value: 'heatmap'} as any)} flex="1">
        Heat map
      </RadioCard>
    </HStack>
  );
};

function RadioCard(props) {
  const {getInputProps, getCheckboxProps} = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" flex="1">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderColor="rgba(255,255,255,0.8)"
        borderRadius="md"
        boxShadow="md"
        color="rgba(255,255,255,0.8)"
        _focus={{
          boxShadow: 'outline'
        }}
        textAlign="center"
        py={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {(input as any).checked ? (
          <Icon as={MdRadioButtonChecked} mr={2} fontSize="16px" color="white" />
        ) : (
          <Icon as={MdRadioButtonUnchecked} mr={2} fontSize="16px" />
        )}
        {props.children}
      </Box>
    </Box>
  );
}

const ThresholdControl = ({
  value,
  onChange
}: {
  value: number;
  onChange: (value: number) => void;
}) => {
  return (
    <DarkMode>
      <Flex alignItems="center">
        <Box flexBasis="45px" pt={1} textAlign="left">
          Prob.
        </Box>
        <Box flexGrow={1}>
          <Slider
            value={value}
            onChange={onChange}
            aria-label="Threshold"
            step={5}
            mt={3}
            size="sm"
          >
            <SliderTrack bg="rgba(255,255,255,0.4)">
              <SliderFilledTrack bg="rgba(255,255,255,0.7)" />
            </SliderTrack>
            <SliderThumb />
          </Slider>
        </Box>
        <Box flexBasis="45px" pt={1} textAlign="right">
          {value}%
        </Box>
      </Flex>
    </DarkMode>
  );
};
