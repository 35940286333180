import React from 'react';
import {MdBrokenImage, MdHourglassEmpty} from 'react-icons/md';
import {Box, BoxProps, Flex, Icon, Image} from '@chakra-ui/react';

type Props = {image: Datastore.Image; size: number} & BoxProps;

export const ImageThumbnail = React.memo(({image, size, children, ...props}: Props) => {
  const {smallImageURL: url, status} = image;

  const ImageIcon = () => {
    if (['UPLOADED', 'PROCESSING'].includes(status)) {
      return <Icon as={MdHourglassEmpty} boxSize="40px" color="gray.300" />;
    }
    return <Icon as={MdBrokenImage} boxSize="40px" color="red.300" />;
  };

  if (!!status && status !== 'AVAILABLE') {
    return (
      <ThumbnailContainer size={size} {...props}>
        <ImageIcon />
      </ThumbnailContainer>
    );
  }

  // Because of the annotations displayed in the scan details page,
  // we need a container with `position: related` around the image
  // that fits exactly the image dimensions
  return (
    <ThumbnailContainer size={size} {...props}>
      <Box position="relative" maxH={`${size}px`}>
        <Image src={url} maxW="100%" height="auto" maxH={`${size}px`} />
        {children}
      </Box>
    </ThumbnailContainer>
  );
});

const ThumbnailContainer = (props: {size: number} & BoxProps) => {
  const {children, size, ...otherProps} = props;
  return (
    <Box
      borderWidth="1px"
      bg="white"
      cursor={props.onClick ? 'pointer' : undefined}
      {...otherProps}
    >
      <Flex boxSize={`${size}px`} alignItems="center" justifyContent="center">
        {children}
      </Flex>
    </Box>
  );
};
