import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import {Alert, Box, Stack, Tag} from '@chakra-ui/react';
import {useQuery} from 'react-query';

import {useApp, useLocale} from 'shared';
import {Spinner, Table, TimeAgo, formatDateTime} from 'components/core';

type Props = {scan: Datastore.Scan};
export const ScanHistory = ({scan}: Props) => {
  const {data: events, isLoading, error} = useFetchHistory(scan);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <Alert status="error">Unable to fetch the history: {(error as any).message}</Alert>;
  }

  return <EventList events={events} />;
};

export function useFetchHistory(scan: Datastore.Scan) {
  const app = useApp();

  const loadData = async () => {
    const {events} = await app.getScanHistory({scanId: scan.id});
    return events;
  };

  return useQuery(['scan-history', scan.id, scan.status, scan.tags], loadData);
}

export const EventList = ({events}) => {
  const locale = useLocale();
  const data = useMemo(() => events, [events]);

  const messages = {
    SET_TAGS: (tags) => {
      if (tags.length === 0) {
        return 'Removed tags';
      }
      return (
        <Stack>
          <Box>Set tags</Box>
          {tags.map((tag) => (
            <Box key={tag}>
              <Tag>{tag}</Tag>
            </Box>
          ))}
        </Stack>
      );
    },
    START_VERIFICATION: () => 'Start editing prediction',
    MARK_AS_VERIFIED: () => 'Mark prediction as verified',
    BACK_TO_EDITING_MODE: () => 'Back to editing mode',
    VERIFICATION_STATUS_VERIFIED: () => 'Verify scan quality',
    VERIFICATION_STATUS_REJECTED: (payload) =>
      `Reject scan quality (${
        payload?.rejectionReason ? locale.rejectionReasons[payload.rejectionReason] : '?'
      })`,
    UNDO_SCAN_QUALITY: () => 'Undo quality control',
    RESCAN: () => 'Mark as rescanned',
    UNDO_RESCAN: () => 'Undo rescan',
    REMOVE_PDF_REPORT: () => 'Scanned diagnosis removed'
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: (event) => {
          return (
            <>
              <TimeAgo date={event.createdOn} />
              <br />
              {formatDateTime(event.createdOn, {mask: `MMM dd HH:mm`})}
            </>
          );
        },
        styleProps: {
          width: '140px'
        }
      },
      {
        Header: 'User',
        accessor: (event) => {
          const {user} = event;
          return (
            <>
              <Link to={`/users/${user.id}`}>
                {user.account.firstName} {user.account.lastName}
              </Link>
              <div>{user?.account?.email}</div>
            </>
          );
        },
        styleProps: {
          width: '250px'
        }
      },
      {
        Header: 'Action',
        accessor: (event) => {
          const {type, payload} = event;
          const getMessage = messages[type];
          return getMessage ? getMessage(payload) : `"${type}" (message to be defined)`;
        }
      }
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );
  return <Table data={data} columns={columns} size="sm" />;
};
