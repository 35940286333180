import {useMutation, useQueryClient} from 'react-query';
import invariant from 'tiny-invariant';

import {useApp} from 'shared';

type ImageQueryData = {
  image: Datastore.Image;
};
type Point = {
  x: number;
  y: number;
};

type CreateAnnotationData = {
  path: Point[];
  labelName: string;
};
type CreateAnnotationResponse = [data: {annotation: Datastore.Annotation}];

export function useAnnotationMutation(imageId: string) {
  const bulkWriteAnnotations = useBulkWriteAnnotations();
  const app = useApp();
  const queryClient = useQueryClient();

  const addAnnotation = useMutation<CreateAnnotationResponse, Error, CreateAnnotationData>(
    async ({path, labelName}) => {
      return await bulkWriteAnnotations([{type: 'add', params: {imageId, labelName, path}}]);
    },
    {
      onSuccess(result) {
        const annotation = result[0].annotation;
        queryClient.setQueryData<ImageQueryData>(['image', imageId], (current) => {
          invariant(current);
          const updatedImage = {
            ...current.image,
            annotations: [...current.image.annotations!, annotation]
          };
          return {...current, image: updatedImage};
        });
      }
    }
  );

  const updateAnnotation = useMutation<undefined, Error, UpdateAnnotationData>(
    async (data) => {
      const {id, changes} = data;
      return bulkWriteAnnotations([{type: 'update', params: {annotationId: id, changes}}]);
    },
    {
      onSuccess(result, input) {
        queryClient.setQueryData<ImageQueryData>(['image', imageId], (current) => {
          invariant(current);
          const updatedImage = {
            ...current.image,
            annotations: current.image.annotations!.map(updateItemById(input.id, input.changes))
          };
          return {...current, image: updatedImage};
        });
      }
    }
  );

  const mergeAnnotations = useMutation<undefined, Error, MergeAnnotationData>(
    async (data) => {
      const {updatedId, deletedId, path} = data;
      const operations: WriteOperation[] = [
        {type: 'update', params: {annotationId: updatedId, changes: {path}}},
        {type: 'delete', params: {annotationId: deletedId}}
      ];
      return await bulkWriteAnnotations(operations);
    },
    {
      onSuccess(result, input) {
        queryClient.setQueryData<ImageQueryData>(['image', imageId], (current) => {
          const {updatedId, deletedId, path} = input;
          invariant(current);
          const annotations = current.image
            .annotations!.map(updateItemById(updatedId, {path}))
            .filter(omitItemById(deletedId));
          return {...current, image: {...current!.image, annotations}};
        });
      }
    }
  );

  const deleteAnnotation = useMutation<undefined, Error, Datastore.Annotation['id']>(
    async (annotationId) => {
      return await bulkWriteAnnotations([{type: 'delete', params: {annotationId}}]);
    },
    {
      onSuccess(result, annotationId) {
        queryClient.setQueryData<ImageQueryData>(['image', imageId], (current) => {
          const annotations = current!.image.annotations!.filter(omitItemById(annotationId));
          return {...current, image: {...current!.image, annotations}};
        });
      }
    }
  );

  const deleteAnnotationsByLabel = useMutation<undefined, Error, DeleteAnnotationByLabelData>(
    async (data) => {
      const {labelName} = data;
      const imagesBackend = await app.getImagesBackend();
      const accessToken = app.getAccessToken();
      return await imagesBackend.removeAnnotationsByLabelName({imageId, labelName, accessToken});
    },
    {
      onSuccess(result, input) {
        queryClient.setQueryData<ImageQueryData>(['image', imageId], (current) => {
          const {labelName} = input;
          invariant(current);
          const annotations = current.image.annotations!.filter(
            (annotation) => annotation.labelName !== labelName
          );
          return {...current, image: {...current.image, annotations}};
        });
      }
    }
  );

  const splitAnnotations = useMutation<SplitAnnotationResult, Error, SplitAnnotationData>(
    async (data) => {
      const {annotationId, labelName, updatedPath, addedPaths} = data;

      const update: WriteOperation = {
        type: 'update',
        params: {annotationId, changes: {path: updatedPath}}
      };
      const additions: WriteOperation[] = addedPaths.map((path) => ({
        type: 'add',
        params: {imageId, labelName: labelName, path}
      }));
      const operations: WriteOperation[] = [update, ...additions];
      return await bulkWriteAnnotations(operations);
    },
    {
      onSuccess(result, input) {
        queryClient.setQueryData<ImageQueryData>(['image', imageId], (current) => {
          const {annotationId, labelName, updatedPath, addedPaths} = input;
          invariant(current);
          const addedIds = result.slice(1).map((item) => item.annotation.id);
          const existingAnnotations = current.image.annotations!.map(
            updateItemById(annotationId, {path: updatedPath})
          );
          const addedAnnotations = addedPaths.map((path, index) => {
            return {id: addedIds[index], labelName, path};
          });
          const annotations = existingAnnotations.concat(addedAnnotations);
          return {...current, image: {...current.image, annotations}};
        });
      }
    }
  );

  const markAnnotationAsVerified = useMutation<Date, Error, Datastore.Annotation['id']>(
    async (annotationId) => {
      const imagesBackend = await app.getImagesBackend();
      const accessToken = app.getAccessToken();
      const {verifiedOn} = await imagesBackend.markAnnotationAsVerified({
        annotationId,
        accessToken
      });
      return verifiedOn;
    },
    {
      onSuccess(verifiedOn, annotationId) {
        queryClient.setQueryData<ImageQueryData>(['image', imageId], (current) => {
          invariant(current);
          const annotations = current!.image.annotations!.map(
            updateItemById(annotationId, {verifiedOn})
          );
          return {...current, image: {...current.image, annotations}};
        });
      }
    }
  );

  const markAnnotationAsUnverified = useMutation<void, Error, Datastore.Annotation['id']>(
    async (annotationId) => {
      const imagesBackend = await app.getImagesBackend();
      const accessToken = app.getAccessToken();
      await imagesBackend.markAnnotationAsUnverified({annotationId, accessToken});
    },
    {
      onSuccess(verifiedOn, annotationId) {
        queryClient.setQueryData<ImageQueryData>(['image', imageId], (current) => {
          invariant(current);
          const annotations = current.image.annotations!.map(
            updateItemById(annotationId, {verifiedOn: null, verifier: null})
          );
          return {...current, image: {...current.image, annotations}};
        });
      }
    }
  );

  const markAllAsVerified = useMutation<Date, Error, void>(
    async () => {
      const imagesBackend = await app.getImagesBackend();
      const accessToken = app.getAccessToken();
      const {verifiedOn} = await imagesBackend.markAllAnnotationsAsVerified({imageId, accessToken});
      return verifiedOn;
    },
    {
      onSuccess(verifiedOn) {
        queryClient.setQueryData<ImageQueryData>(['image', imageId], (current) => {
          invariant(current);
          const annotations = current.image.annotations!.map((annotation) => ({
            ...annotation,
            verifiedOn
          }));
          return {...current, image: {...current.image, annotations}};
        });
      }
    }
  );

  return {
    addAnnotation,
    updateAnnotation,
    mergeAnnotations,
    deleteAnnotation,
    deleteAnnotationsByLabel,
    splitAnnotations,
    markAnnotationAsVerified,
    markAnnotationAsUnverified,
    markAllAsVerified
  };
}

type UpdateAnnotationData = {
  id: string;
  changes: {
    path?: Point[];
    labelName?: string;
  };
};

type MergeAnnotationData = {
  updatedId: string;
  deletedId: string;
  path: Point[];
};

type DeleteAnnotationByLabelData = {
  labelName: string;
};

type SplitAnnotationData = {
  annotationId: string;
  labelName: string;
  updatedPath: Point[];
  addedPaths: Point[][];
};
type SplitAnnotationResult = {annotation: Datastore.Annotation}[];

type WriteOperation = {
  type: 'add' | 'update' | 'delete';
  params: any;
};

function useBulkWriteAnnotations() {
  const app = useApp();
  const backendMethodNames = {
    add: 'addAnnotation',
    update: 'updateAnnotation',
    delete: 'deleteAnnotation'
  };

  const bulkWriteAnnotations = async (operations: WriteOperation[]) => {
    const imagesBackend = await app.getImagesBackend();
    const accessToken = app.getAccessToken();
    const invocations = operations.map(({type, params}) => ({
      methodName: backendMethodNames[type],
      params
    }));
    const result = await imagesBackend.invokeMethods({invocations, accessToken});
    return result;
  };
  return bulkWriteAnnotations;
}

// 2 basic helpers to update/delete array of items
const updateItemById = (id: string, changes: Object) => (item) => {
  return item.id === id ? {...item, ...changes} : item;
};

const omitItemById = (id: string) => (item) => {
  return item.id !== id;
};

// function wait(ms = 1000) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }
