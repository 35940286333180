import React from 'react';
import {createContainer} from 'unstated-next';

import {useApp} from 'shared';
import {locales} from 'locales';
import {LocaleDictionary} from 'locales/english';

const DEFAULT_LOCALE_ID = 'ja';
const FALLBACK_LOCALE_ID = 'en';

function useLocaleState() {
  const app = useApp();

  const getLocale = () => {
    const localeId = app.state?.user?.account?.localeId;

    return resolveLocale({
      localeId,
      locales,
      defaultLocaleId: DEFAULT_LOCALE_ID,
      fallbackLocaleId: FALLBACK_LOCALE_ID
    });
  };

  // TODO: implement setLocale()

  const locale = getLocale();
  return locale as LocaleDictionary;
}

export const LocaleContainer = createContainer(useLocaleState);

// the hook to be used everywhere instead of the one from React UI Kit!
// TODO change all `import` statements
export const useLocale = () => LocaleContainer.useContainer();

export function resolveLocale({localeId, locales, defaultLocaleId, fallbackLocaleId}) {
  const availableLocales = [localeId, defaultLocaleId, fallbackLocaleId] // the order matters!
    .filter((id) => Boolean(locales[id])) // keep only ids that have a related locale
    .map((id) => locales[id]);
  const firstAvailableLocale = availableLocales[0];
  if (!firstAvailableLocale) {
    throw new Error(`Unable to resolve "${localeId}"`);
  }
  return firstAvailableLocale;
}

export const withLocale = (Wrapped: any) => {
  const Component = (props) => {
    const locale = useLocale();
    return <Wrapped {...props} locale={locale} />;
  };
  // Component.displayName = `${Wrapped.displayName || Wrapped.name || 'Component'}WithApp`;
  return Component as any;
};
