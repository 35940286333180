import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import groupBy from 'lodash/groupBy';
import {ButtonGroup, Flex} from '@chakra-ui/react';

import {withApp, withLocale} from 'shared';
import {Heading1, Heading2, Button, Spinner, SubTitle} from 'components/core';
import {TrialScanList} from './trial-scan-list';

// @ts-ignore
@withRouter
@withApp
@withLocale
export class TrialScansByDay extends React.Component<any> {
  static propTypes = {
    scans: PropTypes.array.isRequired,
    totalNumberOfScans: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    offset: PropTypes.number.isRequired,
    onShowNextScans: PropTypes.func.isRequired,
    onShowPreviousScans: PropTypes.func.isRequired,
    onReload: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    locale: PropTypes.object.isRequired
  };

  showImage = (scan) => {
    const {history} = this.props;
    history.push(`/images/${scan.image.id}`);
  };

  render() {
    const {
      scans,
      isLoading,
      totalNumberOfScans,
      offset,
      onShowPreviousScans,
      onShowNextScans,
      // onReload, TODO restore the reload button
      locale: l
    } = this.props;

    if (isLoading) {
      return <Spinner />;
    }

    const byDate = groupBy(scans, (scan) => convertDateToKey(scan.createdOn));

    return (
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '1rem'
          }}
        >
          <Heading1 style={{marginBottom: 0}}>
            {l.todo('Trial images by day')}
            {scans.length > 0 && (
              <SubTitle>
                {l.formatNumber(offset + 1)}-
                {l.formatNumber(Math.min(offset + scans.length, totalNumberOfScans))} of{' '}
                {l.formatNumber(totalNumberOfScans)}
              </SubTitle>
            )}
          </Heading1>
          {/* <RoundRefreshButton onClick={onReload} style={{marginTop: 5, marginLeft: '0.5rem'}} /> */}
        </div>

        <div style={{overflow: 'auto'}}>
          {Object.keys(byDate).map((dateKey) => {
            const {year, month, day} = convertKeyToDate(dateKey);
            const scans = byDate[dateKey];

            return <DailyList key={dateKey} year={year} month={month} day={day} scans={scans} />;
          })}
        </div>

        <Flex mt={6} flexShrink={0} justifyContent="flex-end">
          <ButtonGroup>
            <Button onClick={onShowPreviousScans} disabled={offset === 0}>
              {l.previousButtonLabel}
            </Button>
            <Button
              onClick={onShowNextScans}
              disabled={offset + scans.length >= totalNumberOfScans}
            >
              {l.nextButtonLabel}
            </Button>
          </ButtonGroup>
        </Flex>
      </>
    );
  }
}

@withLocale
class DailyList extends React.Component<any> {
  static propTypes = {
    scans: PropTypes.array.isRequired,
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
    day: PropTypes.number.isRequired,
    locale: PropTypes.object.isRequired
  };

  render() {
    const {year, month, day, scans, locale} = this.props;
    const date = new Date(year, month - 1, day);

    return (
      <div style={{marginBottom: '2rem'}}>
        <Heading2
          style={{
            marginBottom: '1rem',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {locale.formatMonth(month)} {day} / {locale.formatDayOfWeek(date.getDay())}
          <span
            style={{
              fontSize: '1rem',
              fontWeight: 500,
              color: 'rgb(128, 128, 128)',
              marginLeft: '0.75rem'
            }}
          >
            ({scans.length} scans)
          </span>
        </Heading2>
        <TrialScanList scans={scans} />
      </div>
    );
  }
}

// native date object => `2020-1-6` string
function convertDateToKey(date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
}

// `2020-1-6` string => {year, month, day} object
function convertKeyToDate(key) {
  const [year, month, day] = key.split('-');
  return {
    year: parseInt(year, 10),
    month: parseInt(month, 10),
    day: parseInt(day, 10)
  };
}
