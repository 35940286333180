import {useQuery} from 'react-query';

import {Scan} from 'models/scan';
import {ItemSelection, SearchOptions, useApp} from 'shared';

export const commonReturnFields = [
  'id',
  'tags',
  'status',
  'imageId', // required to get image related fields: URL and status
  'image.filename',
  'image.smallImageURL',
  'image.status',
  'image.wsiAnnotations.labelName' // TODO add only for admin users?
];

const defaultReturnFields = [
  ...commonReturnFields,
  'organ',
  'supplier',
  'createdOn',
  'image.batch.number',
  'image.size'
];

export function useFetchScans(
  searchOptions: SearchOptions,
  returnFields: string[] = defaultReturnFields,
  selection?: ItemSelection
) {
  const app = useApp();

  const loadScans = async () => {
    const {query, orderBy, orderDirection, offset, limit} = searchOptions;
    const backend = await app.getBackend();
    const accessToken = app.getAccessToken();

    let {scans, totalNumberOfScans} = await backend.findScans({
      query,
      order: [{field: orderBy, direction: orderDirection}],
      returnFields,
      offset,
      limit,
      selection,
      accessToken
    });
    scans = scans.map((scan) => new Scan(scan, {deserialize: true}));
    return {scans: scans as Datastore.Scan[], total: totalNumberOfScans as number};
  };

  const scanIds = selection ? Array.from(selection.itemIds!) : [];
  return useQuery(['scan-search', searchOptions, ...scanIds, ...returnFields], loadScans, {
    keepPreviousData: false,
    cacheTime: 1000 * 60 * 60, // override the default duration of 5 minutes => 1 hour
    // With the introduction of the split layout, the scan list can stay on the screen for a long time
    // so it may be better to refresh data periodically to show processed image thumbnails for example
    refetchInterval: 1000 * 30
  });
}
