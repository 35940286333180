import {Query} from './query';

export class ScanQuery extends Query {
  static schema = [
    {field: 'image.filename', type: 'string'},
    {field: 'reference', type: 'string'},
    {field: 'organ', type: 'string'},
    {field: 'specimenType', type: 'string'},
    {field: 'axis', type: 'string'},
    {field: 'disease', type: 'string'},
    {field: 'staining', type: 'string'},
    {field: 'supplier', type: 'string'},
    {field: 'zoomLevel', type: 'number'},
    {field: 'gene', type: 'string'},
    {field: 'protein', type: 'string'},
    {field: 'scannedBy', type: 'string'},
    {field: 'scannedOn', type: 'date'},
    {field: 'scannedAt', type: 'string'},
    {field: 'collectedOn', type: 'date'},
    {field: 'patientName', type: 'string'},
    {field: 'hasScannedDiagnosis', type: 'boolean'},
    {field: 'diagnosedBy', type: 'string'},
    {field: 'diagnosedOn', type: 'date'},
    {field: 'tags', type: 'string'},
    {field: 'comments', type: 'string'},
    {field: 'customField1', type: 'string'},
    {field: 'customField2', type: 'string'},
    {field: 'customField3', type: 'string'},
    {field: 'image.format', type: 'string'},
    {field: 'image.batch.number', type: 'number'},
    {field: 'image.annotations.labelName', type: 'string'},
    {field: 'image.wsiAnnotations.labelName', type: 'string'},
    {field: 'image.annotations.verifiedOn', type: 'date'},
    {field: 'image.numberOfAnnotations', type: 'number'},
    {field: 'image.numberOfVerifiedAnnotations', type: 'number'},
    {field: 'image.numberOfUnverifiedAnnotations', type: 'number'},
    {field: 'image.numberOfAnnotationLabels', type: 'number'},
    {field: 'imageFormat', type: 'string'}, // Needed for the link "Image Format" in the "/scans/report" page
    {field: 'freeText', type: 'string'},
    {field: 'status', type: 'string'}
  ];
}
