import React, {useMemo} from 'react';
import {Box} from '@chakra-ui/react';
import sortBy from 'lodash/sortBy';
import times from 'lodash/times';
import sum from 'lodash/sum';

import {useLocale} from 'shared';
import {Table} from 'components/core';

type Props = {
  users: any[];
  year: number;
  month: number;
};
export const CalendarView = ({users: loadedUsers, year, month}: Props) => {
  const locale = useLocale();

  const days = getDays(year, month);
  const users = sortBy(loadedUsers, 'account.lastName');

  const data = useMemo(() => days, [days]);

  // TODO: memoize all computations to avoid calling N times the same functions with the same arguments
  const findAnnotationCount = (user, day) => {
    const item = user.annotatedImages?.find((item) => item.day === day);
    return item?.count || 0;
  };

  const getTotalByDay = (day) => {
    return sum(users.map((user) => findAnnotationCount(user, day)));
  };
  const getTotalByUser = (user) => {
    return sum(days.map((day) => findAnnotationCount(user, day)));
  };
  const getGrandTotal = () => {
    return sum(users.map((user) => getTotalByUser(user)));
  };

  const formatCount = (count) => (count !== 0 ? locale.formatNumber(count) : '-');

  const userColumns = users.map((user) => ({
    id: user.id,
    Header: () => user.account.getFullName(locale), // getInitials(l)
    accessor: (day) => {
      const count = findAnnotationCount(user, day);
      return formatCount(count);
    },
    Footer: () => {
      const total = getTotalByUser(user);
      return formatCount(total);
    },
    styleProps: {
      width: '75px',
      textAlign: 'center'
    }
  }));

  const firstColumn = {
    Header: 'Day',
    accessor: (day) => {
      const date = new Date(year, month - 1, day);
      const dayOfWeek = date.getDay();
      const isSunday = dayOfWeek === 0;
      const red = 'rgba(184, 0, 37, 1)';
      const mutedRed = 'rgba(184, 0, 37, 0.4)';
      return (
        <>
          <strong style={{color: isSunday ? red : undefined}}>{day}</strong>
          &nbsp;
          <span
            style={{
              color: isSunday ? mutedRed : 'rgba(0, 0, 0, 0.38)' // t.mutedTextColor
            }}
          >
            {locale.formatDayOfWeek(dayOfWeek, {format: 'partial'})}
          </span>
        </>
      );
    },
    Footer: () => locale.todo('Total'),
    styleProps: {
      width: '75px'
    }
  };

  const totalColumn = {
    Header: 'Total',
    accessor: (day) => {
      const total = getTotalByDay(day);
      return formatCount(total);
    },
    Footer: () => {
      const total = getGrandTotal();
      return formatCount(total);
    },
    styleProps: {
      width: '50px',
      textAlign: 'right',
      fontWeight: 'bold'
    }
  };

  const columns = useMemo(
    () => [firstColumn, ...userColumns, totalColumn],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getRowProps = (row) => {
    const day = row.original;
    const date = new Date(year, month - 1, day);
    const isSunday = date.getDay() === 0;
    return {
      backgroundColor: isSunday ? 'red.50' : undefined
    };
  };

  return (
    <>
      {users.length === 0 && (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flex: '1',
            justifyContent: 'center',
            paddingTop: '3rem',
            paddingBottom: '3rem',
            color: 'rgba(0, 0, 0, 0.38)',
            borderColor: 'rgba(0,0,0,.12)',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderRadius: '.25rem'
          }}
        >
          {locale.todo('No annotation for this month!')}
        </div>
      )}

      {users.length > 0 && (
        <Box overflow="auto">
          <Table data={data as any} columns={columns} getRowProps={getRowProps} showFooter size="sm" />
        </Box>
      )}
    </>
  );
};

/* Generate an array of numbers from 1 to 28, 29, 30 or 31, depending on the month */
function getDays(year, month) {
  const lastDayOfTheMonth = new Date(year, month, 0);
  const lastDayNumber = lastDayOfTheMonth.getDate(); // E.g. 31 for January
  const days = times(lastDayNumber, (i) => i + 1);
  return days;
}
