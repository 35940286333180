import React from 'react';
import {MdFileDownload} from 'react-icons/md';

import {ImageViewerPlugin, PluginSettings} from 'components/image-viewer/types';
import {useModal} from 'components/core';
import {DialogDownloadImage} from './dialog-download-image';
import {createPlugin} from '..';
import {ImageContainer} from 'components/image-viewer/image-container';
import {ToolbarButton} from 'components/image-viewer/button';

export const DownloadPlugin = (settings?: PluginSettings): ImageViewerPlugin => {
  return createPlugin({
    displayName: 'DownloadPlugin',
    decorateSidebar(sidebar) {
      return [...sidebar, {key: 'DOWNLOAD_GROUP', buttons: [<DownloadButton key="Download" />]}];
    }
  });
};

const DownloadButton = () => {
  const {image} = ImageContainer.useContainer();
  const modal = useModal();

  const showDialog = async (image) => {
    await modal.dialog({
      render: (close) => <DialogDownloadImage image={image} onClose={close} />,
      modalProps: {
        size: 'xl'
      }
    });
  };
  return (
    <ToolbarButton>
      <MdFileDownload size="32px" onClick={() => showDialog(image)} />
    </ToolbarButton>
  );
};
