import React, {useContext} from 'react';
import subscribe from '@ministate/react';

import {useModal} from 'components/core';

const AppContext = React.createContext<any>({});

export const AppProvider = ({app, ...rest}) => {
  const modal = useModal();
  app.setModal(modal); // app handles error showing a modal when calling `app.task(fn)` to let users retry.
  return <AppContext.Provider value={app} {...rest} />;
};

/*
HOC to inject `app` props to the wrapped component
*/
export const withApp = (Wrapped) => {
  const Component = (props) => (
    <AppContext.Consumer>{(app) => <Wrapped {...props} app={app} />}</AppContext.Consumer>
  );
  Component.displayName = `${Wrapped.displayName || Wrapped.name || 'Component'}WithApp`;
  return subscribeToApp(Component) as any;
};

/*
Hook version
*/
export const useApp = () => useContext(AppContext);

/*
Needed for ImageViewer features and upload workflow
TODO: get rid of Ministate in the future and implement a proper state management
*/
export const subscribeToApp = (Wrapped) => {
  const Component = (props) => {
    const app = useApp();
    return React.createElement(subscribe(app)(Wrapped), props);
  };
  return Component;
};
