import React from 'react';
import {
  Box,
  BoxProps,
  Button,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@chakra-ui/react';
import {FaUserCircle} from 'react-icons/fa';

import {useApp, useLocale} from 'shared';

export const UserMenu = () => {
  const locale = useLocale();
  const app = useApp();
  const {user} = app.state;

  if (!user) return null;

  const displayName = user.account.getFullName(locale);

  return (
    <Popover placement="bottom-end">
      {({isOpen, onClose}) => (
        <>
          <PopoverTrigger>
            <Button
              as="button"
              display="flex"
              justifyContent="flex-end"
              _focus={{outline: 'none'}}
              backgroundColor="white"
            >
              <Icon as={FaUserCircle} fontSize="24px" color="gray.400" />
              <Box ml={2}>{displayName}</Box>
            </Button>
          </PopoverTrigger>
          <PopoverContent zIndex={10000} maxW="200px" ml="auto" _focus={{outline: 'none'}}>
            <Box py={2}>
              <Menu
                items={[
                  {
                    label: locale.userMenuAccountItemLabel,
                    onClick: () => {
                      app.showAccount();
                      onClose && onClose();
                    },
                    disabled: false
                  },
                  {
                    label: locale.userMenuSignOutItemLabel,
                    onClick: () => {
                      app.signOut();
                    }
                  }
                ]}
              />
            </Box>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

const Menu = ({items}) => {
  return items.map(({label, onClick}) => (
    <MenuItem key={label} onClick={onClick}>
      {label}
    </MenuItem>
  ));
};

const MenuItem = (props: BoxProps) => {
  return <Box {...props} px={4} py={2} cursor="pointer" _hover={{backgroundColor: 'gray.100'}} />;
};
