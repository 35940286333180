import React, {useState} from 'react';
import {
  Alert,
  Box,
  Code,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  ListItem,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  UnorderedList,
  VStack
} from '@chakra-ui/react';
import {useQueryClient} from 'react-query';
import invariant from 'tiny-invariant';

import {useLocale} from 'shared';
import {Button, Spinner} from 'components/core';
import {AnnotationModelInput} from 'components/annotations/annotation-model-input';
import {
  AIData,
  EditableLabel,
  NewLabel,
  getAILabelByName,
  useCreateAILabel,
  useFetchAILabels,
  useUpdateAILabel,
  useDeleteAILabel
} from 'components/settings/ai-labels-api';
import {AILabel, AIModel} from 'components/settings/types';
import {LabelColor, LabelDisplayName, LabelType} from './ai-label-shared';

const DialogLoader = ({children}: {children: (data: AIData) => React.ReactNode}) => {
  const {data, isLoading, error} = useFetchAILabels();

  if (isLoading) {
    return (
      <>
        <ModalHeader>Loading</ModalHeader>
        <ModalBody>
          <Box h={200}>
            <Spinner />
          </Box>
        </ModalBody>
      </>
    );
  }

  if (error) {
    return (
      <>
        <ModalHeader>Error</ModalHeader>
        <ModalBody>
          <Alert status="error">Unable to load AI label data</Alert>
        </ModalBody>
      </>
    );
  }

  invariant(data);
  return <>{children(data)}</>;
};

type CreateDialogProps = {
  onClose: () => void;
};
export const DialogCreateAILabel = ({onClose}: CreateDialogProps) => {
  return (
    <DialogLoader>
      {(data) => {
        const {labels, models} = data;
        return <DialogCreateAILabelInner labels={labels} models={models} onClose={onClose} />;
      }}
    </DialogLoader>
  );
};

type CreateInnerProps = CreateDialogProps & {labels: AILabel[]; models: AIModel[]};
export const DialogCreateAILabelInner = ({labels, models, onClose}: CreateInnerProps) => {
  const locale = useLocale();
  const mutation = useCreateAILabel({models});
  const queryClient = useQueryClient();

  const [values, setValues] = useState<NewLabel>({
    name: '',
    displayName: '',
    modelName: '',
    color: '#39cccc',
    type: 'abnormal-tissue'
  });
  const setField = (fieldName: string) => (value: string) =>
    setValues((current) => ({...current, [fieldName]: value}));
  const {name, displayName, modelName, color, type} = values;

  const submit = () => {
    mutation.mutate(values, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['ai-labels']);
        setTimeout(() => onClose(), 100);
      },
      onError: (error) => {
        console.error('Cant create!', error);
      }
    }); // does not return a Promise, we have to use `onSuccess` callback
  };

  const isFormValid = !!displayName && modelName && !!color;
  const showName = false; // `labelName` is computed by the API from the display name

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        submit();
      }}
    >
      <ModalHeader>Add AI label</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <VStack spacing={4}>
          <AnnotationModelInput
            models={models}
            modelName={modelName}
            onChange={(value) =>
              setValues((current) => {
                invariant(current);
                invariant(value);
                return {...current, modelName: value};
              })
            }
          />
          {showName && (
            <FormControl id="name">
              <FormLabel>Label unique key</FormLabel>
              <Input value={name} onChange={(event) => setField('name')(event.target.value)} />
              <FormHelperText>
                E.g. stomach-adenoma <i>(must be unique, usually starts with the model name)</i>
              </FormHelperText>
            </FormControl>
          )}
          <FormControl id="displayName">
            <FormLabel>Label name on the screen</FormLabel>
            <LabelDisplayName value={displayName} onChange={setField('displayName')} />
            <FormHelperText>E.g. Adenoma</FormHelperText>
          </FormControl>
          <LabelColor value={color} onChange={setField('color')} />
          <FormControl id="type">
            <FormLabel>Type</FormLabel>
            <LabelType
              value={type}
              onChange={(value) => setField('type')((value as AILabel['type']) || '')}
            />
          </FormControl>
          {mutation.isError && !mutation.isLoading && (
            <Alert status="error">
              Unable to create the label {(mutation.error as Error)?.message}
            </Alert>
          )}
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button type="button" onClick={() => onClose()} mr={2} isDisabled={mutation.isLoading}>
          {locale.cancelButtonLabel}
        </Button>
        <Button
          type="submit"
          primary
          isDisabled={!isFormValid}
          isLoading={mutation.isLoading || !!queryClient.isFetching()}
        >
          Add
        </Button>
      </ModalFooter>
    </form>
  );
};

type EditLabelProps = {
  labelName: string;
  onClose: (values?: EditableLabel) => void;
};
export const DialogEditAILabel = ({labelName, onClose}: EditLabelProps) => {
  return (
    <DialogLoader>
      {(data) => {
        const {labels, models} = data;
        const label = getAILabelByName(labels, labelName);
        return <DialogEditAILabelInner label={label} models={models} onClose={onClose} />;
      }}
    </DialogLoader>
  );
};

type EditDialogInnerProps = Pick<EditLabelProps, 'onClose'> & {label: AILabel; models: AIModel[]};

export const DialogEditAILabelInner = ({label, models, onClose}: EditDialogInnerProps) => {
  const locale = useLocale();
  const mutation = useUpdateAILabel({models, labelName: label.name});
  const queryClient = useQueryClient();

  const [values, setValues] = useState<EditableLabel>(label);
  const setField = (fieldName: string) => (value: string) =>
    setValues((current) => ({...current, [fieldName]: value}));

  const {modelName, displayName, color, type} = values;
  const submit = () => {
    mutation.mutate(values, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['ai-labels']);
        setTimeout(() => onClose(values), 100);
      }
    }); // does not return a Promise, we have to use `onSuccess` callback
  };
  const isFormValid = !!displayName && modelName && !!color;

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        submit();
      }}
    >
      <ModalHeader>Edit "{label.name}" label</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <VStack spacing={4}>
          <AnnotationModelInput
            models={models}
            modelName={modelName}
            onChange={(value) =>
              setValues((current) => {
                invariant(current);
                invariant(value);
                return {...current, modelName: value};
              })
            }
          />
          <FormControl id="displayName">
            <FormLabel>Label name on the screen</FormLabel>
            <LabelDisplayName value={displayName} onChange={setField('displayName')} />
          </FormControl>
          <LabelColor value={color} onChange={setField('color')} />
          <FormControl id="type">
            <FormLabel>Type</FormLabel>
            <LabelType
              value={type}
              onChange={(value) => setField('type')((value as AILabel['type']) || '')}
            />
          </FormControl>
          {mutation.isError && !mutation.isLoading && (
            <Alert status="error">
              Unable to edit the label {(mutation.error as Error)?.message}
            </Alert>
          )}
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button type="button" onClick={() => onClose()} mr={2} isDisabled={mutation.isLoading}>
          {locale.cancelButtonLabel}
        </Button>
        <Button
          type="submit"
          primary
          isDisabled={!isFormValid}
          isLoading={mutation.isLoading || !!queryClient.isFetching()}
        >
          Save
        </Button>
      </ModalFooter>
    </form>
  );
};

export const DialogDeleteAILabel = ({labelName, onClose}: EditLabelProps) => {
  return (
    <DialogLoader>
      {(data) => {
        const {labels, models} = data;
        const label = getAILabelByName(labels, labelName);
        return <DialogDeleteAILabelInner label={label} models={models} onClose={onClose} />;
      }}
    </DialogLoader>
  );
};

export const DialogDeleteAILabelInner = ({label, onClose}: EditDialogInnerProps) => {
  const locale = useLocale();
  const mutation = useDeleteAILabel({labelName: label.name});
  const queryClient = useQueryClient();

  const submit = () => {
    mutation.mutate(undefined, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['ai-labels']);
        setTimeout(() => onClose(), 100);
      }
    }); // does not return a Promise, we have to use `onSuccess` callback
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        submit();
      }}
    >
      <ModalHeader>Delete "{label.name}" label</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text>
          {locale.todo('Are you sure you want to delete the label')} {label.displayName}?
        </Text>
        <UnorderedList mt={4}>
          <ListItem>Model: {label.model.displayName}</ListItem>
          <ListItem>
            Label key: <Code>{label.name}</Code>
          </ListItem>
          <ListItem>
            <Flex align="center">
              Displayed as:
              <Box
                w="7px"
                h="16px"
                bg={label.color}
                mx={2}
                borderRightWidth="1px"
                borderLeftWidth="1px"
              />
              {label.displayName}
            </Flex>
          </ListItem>
        </UnorderedList>
      </ModalBody>
      <ModalFooter>
        <Button type="button" onClick={() => onClose()} mr={2} isDisabled={mutation.isLoading}>
          {locale.cancelButtonLabel}
        </Button>
        <Button
          type="submit"
          isLoading={mutation.isLoading || !!queryClient.isFetching()}
          colorScheme="red"
          variant="solid"
        >
          Delete label
        </Button>
      </ModalFooter>
    </form>
  );
};
