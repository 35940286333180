import {useState} from 'react';
import {Alert, Box, Flex, Spinner, ButtonGroup, useToast} from '@chakra-ui/react';
import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';

import {ScanForm} from 'components/scan-form';
import {useFetchScan, useMutationUpdateScan} from 'components/scan-form/scan-actions';
import {useApp, useLocale} from 'shared';
import {splitTags} from 'models/model-utils';
import {Button, CheckIcon} from 'components/core';
import {TabLayoutContainer} from './tab-layout-container';

type Changes = Record<string, any>;

type Props = {
  scanId: Datastore.Scan['id'];
  onSave?: () => void;
};
export const ScanEditPane = ({scanId, onSave}: Props) => {
  const {data: scan, isLoading, error} = useFetchScan(scanId);

  if (isLoading) return <Spinner />;
  if (error) return <Alert status="error">Unable to load the scan {scanId}</Alert>;

  return <Form scan={scan} onSave={onSave} />;
};

const Form = ({scan: originalScan, onSave}) => {
  const app = useApp();
  const locale = useLocale();
  const mutationUpdateScan = useMutationUpdateScan(originalScan.id);
  const {closeTab} = TabLayoutContainer.useContainer();
  const [changes, setChanges] = useState<Changes>({});
  const toast = useToast();
  const scan = {...originalScan, ...changes};

  const handleChange = (field, value) => {
    setChanges((current) => updateStateAtPath(current, field, value));
  };
  const {user} = app.state;

  const tagRestrictions = splitTags(user.tagRestrictions);

  const save = async (event) => {
    event.preventDefault();
    await mutationUpdateScan.mutateAsync(changes as any);
    toast({title: 'Scan updated', description: scan.image.filename, isClosable: true});
    setChanges({});
    closeTab(scan.id);
    onSave();
  };

  return (
    <Flex h="100%" flexGrow={1} flexDir="column" overflow="hidden" as="form" onSubmit={save}>
      <Box overflowY="auto" overflowX="hidden" p={4}>
        <Box borderWidth="1px" borderRadius="lg" p={4}>
          <ScanForm
            getValue={(field) => get(scan, field)}
            setValue={(field, value) => handleChange(field, value)}
            requireFields={tagRestrictions.length ? ['tags'] : []}
          />
        </Box>
      </Box>
      <Flex p={6} flexShrink={0} justifyContent="flex-end" w="100%">
        <ButtonGroup>
          <Button
            type="submit"
            primary
            isDisabled={isEmpty(changes)}
            isLoading={mutationUpdateScan.isLoading}
            leftIcon={<CheckIcon />}
          >
            {locale.todo('Save and close')}
          </Button>
        </ButtonGroup>
      </Flex>
    </Flex>
  );
};

// TODO clean the Scan form state! (duplicate function)
function updateStateAtPath(current, path, value) {
  const copy = {...current};
  set(copy, path, value);
  return copy;
}
