import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Icon,
  Badge
} from '@chakra-ui/react';
import groupBy from 'lodash/groupBy';
import {MdBrokenImage, MdBuild, MdCloudUpload, MdHourglassEmpty} from 'react-icons/md';

import {ScanCompactTable, ScanImageTable} from './dashboard-tables';
import {UploadingImageTable} from './uploading-image-list';
import {CheckIcon} from 'components/core';

const items = [
  {
    status: 'UPLOADED',
    label: 'Waiting to be processed',
    icon: <Icon as={MdHourglassEmpty} color="gray.400" mr={1} fontSize="24px" />,
    color: 'yellow'
  },
  {
    status: 'PROCESSING',
    label: 'Processing',
    icon: <Icon as={MdBuild} color="gray.400" mr={1} fontSize="24px" />,
    color: 'orange'
  },
  {
    status: 'PROCESS_FAILED',
    label: 'Failures',
    icon: <Icon as={MdBrokenImage} color="gray.400" mr={1} fontSize="24px" />,
    color: 'red'
  }
];

export const ProcessingActivity = ({data, ...props}) => {
  const imagesByStatus = groupBy(data.scans, 'image.status');
  const images = data.images;

  return (
    <Accordion allowMultiple {...props}>
      <ImageAccordionItem
        isDisabled={images.length === 0}
        header={
          <>
            <Icon as={MdCloudUpload} color="gray.400" mr={1} fontSize="24px" />
            Uploading
            <CountLabel count={images.length} color="green" />
          </>
        }
        content={<UploadingImageTable images={images} />}
      />
      {items.map(({status, label, icon, color}) => {
        const scans = imagesByStatus[status] || [];
        return (
          <ImageAccordionItem
            key={status}
            isDisabled={scans.length === 0}
            header={
              <>
                {icon} {label}
                <CountLabel count={scans.length} color={color} />
              </>
            }
            content={<ScanCompactTable scans={scans} />}
          />
        );
      })}
      {imagesByStatus.AVAILABLE && (
        <ImageAccordionItem
          header={
            <>
              <Icon as={CheckIcon} color="gray.500" mr={1} fontSize="24px" />
              Latest images
            </>
          }
          content={<ScanImageTable scans={imagesByStatus.AVAILABLE} showHeader={false} />}
        />
      )}
    </Accordion>
  );
};

const CountLabel = ({count, color}) => {
  return count > 0 ? (
    <Badge ml={2} fontSize="0.8em" colorScheme={color}>
      {count}
    </Badge>
  ) : (
    <Box as="span" ml={2} fontStyle="italic" fontSize="0.8em">
      (No images)
    </Box>
  );
};

const ImageAccordionItem = ({header, content, ...props}) => {
  return (
    <AccordionItem {...props}>
      <Box>
        <AccordionButton>
          <Box flex="1" textAlign="left" display="flex" alignItems="center">
            {header}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </Box>
      <AccordionPanel pb={4}>{content}</AccordionPanel>
    </AccordionItem>
  );
};
