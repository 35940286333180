import {
  Alert,
  Box,
  ButtonGroup,
  Code,
  Flex,
  Link,
  LinkProps,
  List,
  ListItem,
  ListIcon,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton
} from '@chakra-ui/react';
import {useQuery} from 'react-query';
import add from 'date-fns/add';
import {MdImage} from 'react-icons/md';
import {FiFile} from 'react-icons/fi';

import {useApp} from 'shared';
import {Button, DateTime, PyramidIcon, Spinner} from 'components/core';
import {Image} from 'models/image';

const NUMBER_OF_DAYS_BEFORE_ARCHIVE = 30;

type ImageVersion = 'original' | 'pyramidal' | 'small';

type ImageURLs = Record<ImageVersion, string>;

type Props = {
  image: Datastore.Image;
  onClose: () => void;
};
export const DialogDownloadImage = ({image, onClose}: Props) => {
  const {data: urls, error, isLoading} = useFetchDownloadURL(image.id);
  return (
    <>
      <ModalHeader>
        Download image
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <DialogBody
          image={image}
          urls={urls as ImageURLs}
          isLoading={isLoading}
          error={error as Error}
        />
      </ModalBody>
      <ModalFooter>
        <Flex justifyContent="flex-end">
          <ButtonGroup>
            <Button type="button" onClick={onClose}>
              Close
            </Button>
          </ButtonGroup>
        </Flex>
      </ModalFooter>
    </>
  );
};

const DialogBody = ({
  image,
  urls,
  error,
  isLoading
}: {
  image: Datastore.Image;
  urls: ImageURLs;
  isLoading: boolean;
  error: Error;
}) => {
  if (isLoading) {
    return (
      <Box h={200}>
        <Spinner />
      </Box>
    );
  }

  if (error) {
    return <Alert status="error">Unable to download the image {image.filename}</Alert>;
  }

  const showOriginalImageLink = isOriginalImageAvailable(image);

  return (
    <>
      <Box mb={4}>
        Right-click on the following links to download the image <Code>{image.filename}</Code>.
      </Box>
      <List spacing={4}>
        <ListItem>
          {showOriginalImageLink ? (
            <>
              <ListIcon as={FiFile} />
              <FileLink href={urls.original}>Original</FileLink>
              <Box as="span" ml={2} color="gray.500">
                (available until <DateTime date={availableUntilDate(image)} mask="yyyy-MM-dd" />)
              </Box>
            </>
          ) : (
            <Box color="gray.500">
              <ListIcon as={FiFile} color="gray.400" />
              Original image: can only be downloaded within {NUMBER_OF_DAYS_BEFORE_ARCHIVE} days
            </Box>
          )}
        </ListItem>
        <ListItem>
          <ListIcon as={MdImage} />
          <FileLink href={urls.small}>Thumbnail</FileLink>
        </ListItem>
        <ListItem>
          <ListIcon as={PyramidIcon} />
          <FileLink href={urls.pyramidal}>Pyramidal TIFF</FileLink>
        </ListItem>
      </List>
    </>
  );
};

const FileLink = (props: LinkProps) => (
  <Link
    color="primary.500"
    textDecoration="underline"
    _hover={{textDecoration: 'none'}}
    {...props}
  />
);

function useFetchDownloadURL(imageId: string) {
  const app = useApp();

  const downloadImage = async () => {
    const imagesBackend = await app.getImagesBackend();
    const accessToken = app.getAccessToken();

    const getImageURLField = (version) => `${version}ImageURL`;

    const versions = ['small', 'original', 'pyramidal'];
    const imageURLFields = versions.map(getImageURLField);

    let {image} = await imagesBackend.getImage({
      imageId,
      returnFields: ['id', ...imageURLFields],
      accessToken
    });

    image = new Image(image, {deserialize: true});

    const urls: ImageURLs = {
      original: image[getImageURLField('original')],
      pyramidal: image[getImageURLField('pyramidal')],
      small: image[getImageURLField('small')]
    };
    return urls;
  };

  return useQuery(['downloadImage', imageId], downloadImage);
}

function isOriginalImageAvailable(image: Datastore.Image) {
  const uploadDate = new Date(image.createdOn);
  const now = new Date();
  const diff = (now.getTime() - uploadDate.getTime()) / 1000 / 60 / 60 / 24;
  return diff < NUMBER_OF_DAYS_BEFORE_ARCHIVE;
}

function availableUntilDate(image: Datastore.Image): Date {
  const uploadDate = new Date(image.createdOn);
  const until = add(uploadDate, {days: NUMBER_OF_DAYS_BEFORE_ARCHIVE});
  return until;
}
