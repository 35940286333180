import React from 'react';
import {useHistory} from 'react-router-dom';
import {Alert, Box, Flex} from '@chakra-ui/react';

import {PaginationControls, PaginationCount, useLocale, useParseURL} from 'shared';
import {Button, Heading1, PageContainer, Spinner, SubTitle} from 'components/core';
import {useFetchAnnotations} from './fetch-annotations';
import {AnnotationList} from './annotation-list';

export const AnnotationListPage = () => {
  const history = useHistory();
  const locale = useLocale();
  const searchOptions = useParseURL({limit: 50});
  const {isLoading, isIdle, data, error} = useFetchAnnotations(searchOptions);

  // wW need to check `idle` state because of the dependent queries
  if (isLoading || isIdle) return <Spinner />;
  if (error) return <Alert status="error">Unable to load the annotations</Alert>;

  const {annotations, totalNumberOfAnnotations: total} = data;

  return (
    <PageContainer maxW="container.lg">
      <Heading1>
        Annotations
        <SubTitle>
          <PaginationCount
            searchOptions={searchOptions}
            total={total}
            pageItemCount={annotations.length}
          />
        </SubTitle>
      </Heading1>
      <Box overflow="auto" borderWidth="1px">
        <AnnotationList annotations={annotations} />
      </Box>
      <Flex mt={6} flexShrink={0} justifyContent="space-between">
        <Box>
          <Button
            onClick={() => history.push({pathname: `/annotations/report`})}
            isDisabled={total === 0}
          >
            {locale.todo('Report')}
          </Button>
        </Box>
        <PaginationControls searchOptions={searchOptions} total={total} />
      </Flex>
    </PageContainer>
  );
};
