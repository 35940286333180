import {Box, useToast} from '@chakra-ui/react';
import invariant from 'tiny-invariant';

import {usePermissions} from 'shared';
import {AddIcon, Button, PageContainer, Spinner, useModal} from 'components/core';
import {SettingsHeading, SettingsNavigator} from 'components/settings/settings-shared';
import {useFetchAILabels} from 'components/settings/ai-labels-api';
import {ModelList} from './ai-model-list';
import {AIModel} from 'components/settings/types';
import {DialogCreateAIModel, DialogDeleteAIModel} from './dialog-ai-model';

export const AIModelsPage = () => {
  const modal = useModal();
  const toast = useToast();
  const {data, isLoading} = useFetchAILabels();
  const {hasPermission} = usePermissions();

  if (isLoading) return <Spinner />;

  invariant(data);
  const {models} = data;

  const addModel = async () => {
    const displayName = await modal.dialog({
      render: (close) => <DialogCreateAIModel onClose={close} models={models} />,
      modalProps: {size: 'md'}
    });
    if (!displayName) return;
    toast({
      title: 'Model added',
      description: `${displayName} model was added`,
      status: 'success',
      isClosable: true
    });
  };

  const deleteModel = async (model: AIModel) => {
    const isDeleted = await modal.dialog({
      render: (close) => <DialogDeleteAIModel onClose={close} model={model} />,
      modalProps: {size: 'xl'}
    });
    if (!isDeleted) return false;
    toast({
      title: 'Delete',
      description: `${model.displayName} model was deleted`,
      status: 'info',
      isClosable: true
    });
  };

  const canAddModel = hasPermission('settings/edit');

  return (
    <PageContainer maxW="container.xl">
      <SettingsNavigator currentTab="models" />
      <SettingsHeading count={models.length}>AI Models</SettingsHeading>
      <Box overflowY="scroll">
        <ModelList models={models} onDelete={deleteModel} />
      </Box>
      {canAddModel && (
        <Box py={4}>
          <Button onClick={addModel} primary leftIcon={<AddIcon />}>
            Add model
          </Button>
        </Box>
      )}
    </PageContainer>
  );
};
