import {extendTheme} from '@chakra-ui/react';
import {createBreakpoints} from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  showLeftSidebar: '1400px',
  xl2: '1400px',
  xl3: '1600px'
});

const components = {
  Accordion: {
    baseStyle: {
      container: {
        bg: 'cardBg'
      }
    }
  },
  Button: {
    baseStyle: {
      fontWeight: 'normal'
    },
    defaultProps: {
      variant: 'outline'
    }
  },
  Checkbox: {
    defaultProps: {
      colorScheme: 'primary'
    }
  },
  FormLabel: {
    baseStyle: {
      fontWeight: 'normal',
      color: 'gray.600'
    }
  },
  Heading: {
    baseStyle: {
      fontWeight: 'normal'
    }
  },
  Input: {
    defaultProps: {
      focusBorderColor: 'primary.150'
    },
    variants: {
      outline: (props) => ({
        field: {
          _focus: {
            boxShadow: 'none'
          },
          backgroundColor: () => {
            return props.isDisabled ? 'gray.50' : 'white';
          }
        }
      })
    }
  },
  Link: {
    variants: {
      primary: () => ({
        color: `primary.700`,
        _hover: {
          color: `primary.400`
        }
      })
    }
  },
  NumberInput: {
    defaultProps: {
      focusBorderColor: 'primary.150'
    },
    variants: {
      outline: (props) => ({
        field: {
          _focus: {
            boxShadow: 'none'
          }
        }
      })
    }
  },
  Radio: {
    defaultProps: {
      colorScheme: 'primary'
    }
  },
  Select: {
    variants: {
      outline: (props) => ({
        field: {
          bg: 'cardBg',
          _focus: {
            borderColor: 'primary.150',
            boxShadow: 'none'
          }
        }
      })
    }
  },
  Slider: {
    defaultProps: {
      colorScheme: 'primary'
    }
  },
  Table: {
    defaultProps: {
      isSticky: true
    },
    variants: {
      simple: (props) => ({
        th: {
          bg: 'white',
          borderColor: 'gray.200',
          textTransform: 'none',
          borderBottomWidth: '1px',
          ...(props.isSticky && {
            position: 'sticky',
            top: 0,
            zIndex: 2
          }),
          fontWeight: 'normal',
          fontSize: '14px',
          p: 2
        },
        td: {
          borderColor: 'gray.200',
          // to handle visual issue when using sticky headers with borders,
          // we set the border on the top instead of the bottom
          // and we rely on a container that has borders.
          borderTopWidth: '1px',
          borderBottomWidth: 0,
          p: 2
        }
      })
    }
  },
  Tabs: {
    defaultProps: {
      colorScheme: 'primary'
    }
  },
  Textarea: {
    defaultProps: {
      focusBorderColor: 'primary.150'
    }
  }
};

export const customTheme = extendTheme({
  breakpoints,
  colors: {
    cardBg: 'white',
    primary: {
      // colors created from https://coolors.co/8e338e-e1eff6-97d2fb-83bcff-80ffe8
      50: '#faf0fa',
      100: '#f4ecf4',
      150: '#dfa5df',
      200: '#b77db7',
      300: '#a258a2',
      400: '#984598',
      500: '#8e338e', // our primary color
      600: '#752a75',
      700: '#5b215b',
      800: '#411841',
      900: '#270e27'
    },
    blue: {
      900: '#111a39' // a specific color token "invertBgColor" would be better?
    },
    // Tokens migrated from Radium Starter TODO adjust the ChakraUI palette
    altTextColor: 'rgba(0, 0, 0, 0.6)',
    backgroundColor: '#ffffff',
    inverseAltTextColor: 'rgba(255, 255, 255, 0.8)',
    inverseBoxBackgroundColor: 'rgba(17, 26, 57, 0.7)', // transparent bg color applied to buttons and panels
    inverseBoxBorderColor: 'rgba(255, 255, 255, 0.88)',
    inverseTextColor: 'rgb(255, 255, 255)',
    inverseAccentColor: '#f983e3', // light pink, good contrast on dark background
    grayBackgroundColor: '#f5f8fb',
    mutedTextColor: 'rgba(0, 0, 0, 0.38)',
    secondaryTextColor: 'rgba(0, 0, 0, 0.6)'
  },
  components,
  shadows: {
    outline: '0 0 0 2px rgba(142, 51, 142, 0.4)'
  }
});
