import {useState} from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  CheckboxGroup,
  Checkbox,
  VStack,
  IconButton,
  Icon,
  PopoverFooter,
  ButtonGroup
} from '@chakra-ui/react';
import {createContainer} from 'unstated-next';
import orderBy from 'lodash/orderBy';
import {MdOutlineModeComment, MdSettings} from 'react-icons/md';
import {FiFile} from 'react-icons/fi';

import {usePreferences} from 'shared';
import {Button} from 'components/core';
import {ScanColumnKey, useScanColumns} from './scan-table';

export const DEFAULT_COLUMN_KEYS: ScanColumnKey[] = [
  'hasComments',
  'supplier',
  'organ',
  'batch',
  'createdOn'
];

type CustomColumnSetup = {
  key: ScanColumnKey;
  label: string;
  returnField?: string;
  icon?: React.ReactNode;
};
// The list of custom columns users can see in the table view
// The order matters (order of the selected columns on the screen).
// `returnField` is used to make the request to the API (when it's different from the `key`)
const allCustomColumns: CustomColumnSetup[] = [
  {
    key: 'hasComments',
    label: 'Has comments',
    icon: <Icon as={MdOutlineModeComment} color="gray.500" />
  },
  {key: 'comments', label: 'Comments'},
  {
    key: 'hasScannedDiagnosis',
    label: 'Has scanned diagnosis',
    icon: <Icon as={FiFile} color="gray.500" />
  },
  {key: 'size', label: 'File size', returnField: 'image.size'},
  {key: 'reference', label: 'Reference'},
  {key: 'supplier', label: 'Supplier'},
  {key: 'organ', label: 'Organ'},
  {key: 'specimenType', label: 'Type of specimen'},
  {key: 'gene', label: 'Gene'},
  {key: 'protein', label: 'Protein'},
  {key: 'annotations', label: 'Number of annotations', returnField: 'image.numberOfAnnotations'},
  {key: 'batch', label: 'Batch number', returnField: 'image.batch.number'},
  {key: 'user', label: 'Created by', returnField: 'user.account.email'},
  {key: 'createdOn', label: 'Creation date'},
  {key: 'updatedOn', label: 'Last update date'}
];

function useScanTableCustomColumns() {
  const [keys, setKeys] = usePreferences('scanTableColumns');
  const {columns: allColumnsByKey} = useScanColumns();
  const columns = allCustomColumns
    .filter(({key}) => keys.includes(key))
    .map(({key}) => allColumnsByKey[key]);

  const getReturnFields = () => {
    return allCustomColumns
      .filter(({key}) => keys.includes(key))
      .map(({key, returnField}) => returnField || key);
  };
  return {keys, setKeys, columns, getReturnFields};
}

export const ScanTableCustomColumnsContainer = createContainer(useScanTableCustomColumns);

type Props = {columns: string[]; onChange: (values: string[]) => void};
export const ColumnPicker = ({columns, onChange}: Props) => {
  const [selectedCols, setSelectedCols] = useState<string[]>(columns);
  function reset() {
    setSelectedCols(DEFAULT_COLUMN_KEYS);
  }
  return (
    <Popover placement="left-start">
      <PopoverTrigger>
        <IconButton
          icon={<MdSettings size="24px" />}
          aria-label="Settings"
          size="sm"
          color="gray.500"
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Pick the columns to display</PopoverHeader>
        <PopoverBody>
          <CheckboxGroup
            value={selectedCols}
            onChange={(values: string[]) => setSelectedCols(values)}
          >
            <VStack alignItems="flex-start">
              {allCustomColumns.map(({key, label, icon}) => {
                return (
                  <Checkbox key={key} value={key}>
                    {label} {icon}
                  </Checkbox>
                );
              })}
            </VStack>
          </CheckboxGroup>
        </PopoverBody>
        <PopoverFooter textAlign="right">
          <ButtonGroup>
            <Button
              onClick={reset}
              w="100%"
              display="block"
              isDisabled={areSameValues(selectedCols, DEFAULT_COLUMN_KEYS)}
            >
              Reset
            </Button>
            <Button
              onClick={() => onChange(selectedCols)}
              primary
              isDisabled={areSameValues(selectedCols, columns)}
            >
              OK
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

function areSameValues(colsA: string[], colsB: string[]) {
  return orderBy(colsA).join() === orderBy(colsB).join();
}
