import React, {useState} from 'react';
import {useApp} from 'shared';

import {useModal} from 'components/core';
import {DialogReject} from 'components/quality-control/dialog-quality-control';

export function useQualityCheckActions(scanId, refetch) {
  const app = useApp();
  const modal = useModal();
  const accessToken = app.getAccessToken();
  const [isLoading, setIsLoading] = useState(false);

  const verifyScan = async () => {
    setIsLoading(true);
    const backend = await app.getBackend();
    await backend.qualityControlApproveScan({scanId, accessToken});
    await refetch();
    setIsLoading(false);
  };

  const rejectScan = async () => {
    const input = await modal.dialog({
      render: (close) => <DialogReject onClose={close} />
    });
    if (!input) return;
    const {rejectionReason} = input;
    setIsLoading(true);
    const backend = await app.getBackend();
    await backend.qualityControlRejectScan({scanId, rejectionReason, accessToken});
    await refetch();
    setIsLoading(false);
  };

  const undoVerification = async () => {
    setIsLoading(true);
    const backend = await app.getBackend();
    await backend.qualityControlUndo({scanId, accessToken});
    await refetch();
    setIsLoading(false);
  };

  const rescan = async () => {
    setIsLoading(true);
    const backend = await app.getBackend();
    await backend.qualityControlRescan({scanId, accessToken});
    await refetch();
    setIsLoading(false);
  };

  const undoRescan = async () => {
    setIsLoading(true);
    const backend = await app.getBackend();
    await backend.qualityControlUndoRescan({scanId, accessToken});
    await refetch();
    setIsLoading(false);
  };

  return {verifyScan, rejectScan, undoVerification, rescan, undoRescan, isLoading};
}
