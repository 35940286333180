import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import {parseSearchParams, formatSearchParams, withApp} from 'shared';
import {TrialScansByDay} from './trial-scans-by-day';
import {useFetchScans} from './fetch-scans';

@withApp
// @ts-ignore
@withRouter
export class TrialHome extends React.Component<any> {
  showPreviousScans = () => {
    const {location, history} = this.props;
    const searchParams = parseSearchParams(location.search);

    searchParams.offset -= searchParams.limit;
    if (searchParams.offset < 0) {
      searchParams.offset = 0;
    }

    history.replace({search: formatSearchParams(searchParams)});
  };

  showNextScans = () => {
    const {location, history} = this.props;
    const searchParams = parseSearchParams(location.search);

    searchParams.offset += searchParams.limit;

    history.replace({search: formatSearchParams(searchParams)});
  };

  render() {
    const {app, location} = this.props;
    const searchParams = parseSearchParams(location.search);

    return (
      <TrialView
        app={app}
        {...searchParams}
        showNextScans={this.showNextScans}
        showPreviousScans={this.showPreviousScans}
      />
    );
  }
}

const TrialView = ({offset, limit, showNextScans, showPreviousScans, app}) => {
  const {scans, isLoading, totalNumberOfScans, reload} = useFetchScans({
    offset,
    limit,
    app
  });

  return (
    <TrialScansByDay
      scans={scans}
      isLoading={isLoading}
      totalNumberOfScans={totalNumberOfScans}
      offset={offset}
      onShowPreviousScans={showPreviousScans}
      onShowNextScans={showNextScans}
      onReload={reload}
    />
  );
};
TrialView.propTypes = {
  offset: PropTypes.number,
  limit: PropTypes.number,
  showNextScans: PropTypes.func,
  showPreviousScans: PropTypes.func,
  app: PropTypes.object.isRequired
};
