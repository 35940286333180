import React from 'react';
import {useQuery} from 'react-query';
import {useLocation, useHistory} from 'react-router-dom';
import {Alert, AlertIcon, Box, Flex} from '@chakra-ui/react';
import sumBy from 'lodash/sumBy';

import {useApp, useLocale, useParseURL} from 'shared';
import {
  Heading1,
  Heading2,
  PageContainer,
  RoundBackButton,
  Spinner,
  SubTitle
} from 'components/core';
import {SearchDescription} from 'components/scan-list/search-description';
import {StatsGraph} from './stats-graph';
import {StatsTableView} from './stats-table-view';
import {useScanSearch} from 'components/scan-list/scan-list-actions';

const NUMBER_OF_MONTHS = 24;

export const ScanStats = () => {
  const locale = useLocale();
  const history = useHistory();
  const location = useLocation();
  const searchOptions = useParseURL();
  const {advancedSearch} = useScanSearch();
  const {data: results, isLoading, error} = useFetchStats();

  const showList = () => {
    history.push({pathname: '/scans', search: location.search});
  };

  if (isLoading) return <Spinner />;
  if (error) return <Alert status="error">Unable to load the statistics</Alert>;

  const total = sumBy(results, 'count');
  const firstResults = results?.[0];

  return (
    <PageContainer maxW="container.xl">
      <Flex align="center">
        <RoundBackButton onClick={showList} aria-label="Go back" />
        <Box ml={4}>
          <Heading1 m={0}>
            {locale.todo('Statistics')}
            <SubTitle>Scans added by month</SubTitle>
          </Heading1>
          <SearchDescription searchOptions={searchOptions} addQueryFilter={advancedSearch} mt={2} />
        </Box>
      </Flex>

      <Box overflow="auto" mt={3}>
        <Box p={4} borderWidth="1px" mb={4}>
          <StatsGraph results={results} numberOfMonths={NUMBER_OF_MONTHS} />
          <Alert mt={4}>
            <AlertIcon />
            {searchOptions.query.length === 0 ? (
              <>
                Total: {locale.formatNumber(total)} scans added to {locale.productName} between{' '}
                {locale.formatMonth(firstResults.month)} {firstResults.year} and now.
              </>
            ) : (
              <>Search results: {locale.formatNumber(total)} scans</>
            )}
          </Alert>
        </Box>
        <Box p={4} borderWidth="1px" mb={4}>
          <Heading2>Full results</Heading2>
          <StatsTableView results={results} />
        </Box>
      </Box>
    </PageContainer>
  );
};

function useFetchStats() {
  const app = useApp();
  const {query} = useParseURL();

  const loadData = async () => {
    const accessToken = app.getAccessToken();
    const backend = await app.getBackend();
    const {scans} = await backend.generateStatsByMonth({
      query,
      accessToken
    });
    return scans;
  };
  return useQuery(['stats', query], loadData, {keepPreviousData: true});
}
