import React from 'react';
import numeral from 'numeral';

export const englishLocale = {
  // === Image import ===

  imageImportSpecifications({maximumImageSize}) {
    return `Supported format: JPEG, TIFF, SVS, NDPI; maximum size: ${numeral(
      maximumImageSize
    ).format('0 b')}.`;
  },
  imageImportUnsupportedFormatAlert({filename}) {
    return `The format of the file "${filename}" is not supported.`;
  },
  imageImportMaximumSizeExceededAlert({filename, limit}) {
    return (
      <>
        The image {filename} is too big.
        <br />
        Limit: {this.formatFileSize(limit)}
      </>
    );
  },
  imageImportTooManyImagesAlert({maximumImages, filename}) {
    return `Cannot import more than ${maximumImages} images. The image "${filename}" has not been added.`;
  },

  // === Image viewer ===

  imageViewerDeleteAnnotationConfirm: 'Are you sure you want to delete this annotation?',

  imageViewerDeleteAnnotationsByLabelConfirm({displayName}) {
    return `Are you sure you want to delete all annotations labelled "${displayName}"?`;
  },

  // === Annotation panel ===

  annotationPanelHeading: 'Annotations',
  annotationPanelVerifiedCountLabel: 'Verified:',
  annotationPanelUnverifiedCountLabel: 'Unverified:',
  annotationPanelMarkAllAsVerifiedButtonLabel: 'Mark all as verified',

  // === Annotation model chooser ===

  annotationModelChooserHeading: 'Choose a model',
  annotationModelChooserChooseButtonLabel: 'Choose',
  annotationModelInputModelFieldLabel: 'Model',

  // === Annotation label chooser ===

  annotationLabelChooserHeading: 'Choose a label',
  annotationLabelChooserChooseButtonLabel: 'Choose',
  annotationLabelInputLabelFieldLabel: 'Label',

  // === Annotation editor ===

  annotationEditorHeading: 'Annotation',
  annotationEditorCreatedOnFieldLabel: 'Created on',
  annotationEditorCreatedByFieldLabel: 'Created by',
  annotationEditorVerifiedOnFieldLabel: 'Verified on',
  annotationEditorVerifiedByFieldLabel: 'Verified by',
  annotationEditorMarkAsVerifiedButtonLabel: 'Mark as verified',
  annotationEditorMarkAsUnverifiedButtonLabel: 'Mark as unverified',

  // === Prediction panel ===

  predictionPanelHeading: 'Predictions',

  // === Prediction statuses ===

  predictionInvalidStatusErrorMessage: (
    <>
      Unable to update the prediction, data must have changed since you opened the image. <br />
      Please reload the window.
    </>
  )
};
