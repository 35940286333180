import React, {useEffect, useRef} from 'react';
import {
  Alert,
  Box,
  BoxProps,
  CloseButton,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select
} from '@chakra-ui/react';
import {MdSearch} from 'react-icons/md';
import invariant from 'tiny-invariant';

import {Spinner} from 'components/core';
import {useFetchModels, useFetchModelVersions} from '../evaluations-api';

export type EvaluationSearchFormValues = {
  text?: string;
  modelName?: string;
  modelVersion?: string;
};

export const SearchArea = ({
  values,
  onSearch
}: {
  values: EvaluationSearchFormValues;
  onSearch: (values: EvaluationSearchFormValues) => any;
}) => {
  const {text, modelName, modelVersion} = values;

  return (
    <Box mb={4}>
      <HStack>
        <SearchForm defaultValue={text!} onSearch={(value) => onSearch({...values, text: value})} />
        <Box w={200}>
          <ModelPicker
            value={modelName!}
            onChange={(value) => onSearch({...values, modelName: value, modelVersion: ''})}
          />
        </Box>
        <Box w={250}>
          {modelName ? (
            <ModelVersionPicker
              modelName={modelName}
              value={modelVersion!}
              onChange={(value) => onSearch({...values, modelVersion: value})}
            />
          ) : (
            <DisabledPicker />
          )}
        </Box>
      </HStack>
    </Box>
  );
};

type Props = {
  onSearch: (value: string) => void;
  defaultValue: string;
} & BoxProps;
const SearchForm = ({onSearch, defaultValue, ...props}: Props) => {
  const ref = useRef<HTMLInputElement | null>(null);

  const onSubmit = (event) => {
    event.preventDefault();
    if (!ref) return;
    const text = ref.current!.value.trim();
    onSearch(text);
  };

  useEffect(() => {
    if (!ref) return;
    ref.current!.value = defaultValue;
  }, [defaultValue]);

  return (
    <Box as="form" {...props} onSubmit={onSubmit}>
      <InputGroup size="md" w={250}>
        <InputLeftElement color="gray.500" children={<MdSearch fontSize="24px" />} />
        <Input
          ref={ref}
          defaultValue={defaultValue}
          variant="outline"
          placeholder="ID or Dataset name"
        />
        <InputRightElement
          children={<CloseButton onClick={() => onSearch('')} color="gray.500" />}
        />
      </InputGroup>
    </Box>
  );
};

const ModelPicker = ({value, onChange}: {value: string; onChange: (modelName: string) => void}) => {
  const {data, isLoading, error} = useFetchModels();
  if (error) {
    return <Alert status="error">Unable to load model versions</Alert>;
  }
  if (isLoading) {
    return <Spinner size="sm" />;
  }
  invariant(data);

  const options = data.map((item) => {
    return {value: item.name, text: item.name};
  });
  return (
    <Select value={value} onChange={(event) => onChange(event.target.value)}>
      <option value="">All models</option>
      {options.map(({value, text}) => (
        <option key={value} value={value}>
          {text}
        </option>
      ))}
    </Select>
  );
};

const ModelVersionPicker = ({
  modelName,
  value,
  onChange
}: {
  modelName: string;
  value: string;
  onChange: (value: string) => void;
}) => {
  const {data, isLoading, error} = useFetchModelVersions(modelName);
  if (error) {
    return <Alert status="error">Unable to load model versions</Alert>;
  }
  if (isLoading) {
    return <Spinner size="sm" />;
  }
  invariant(data);

  const options = data.data.map((item) => {
    const version = item.modelVersion.version;
    return {value: version, text: version};
  });

  return (
    <Select value={value} onChange={(event) => onChange(event.target.value)}>
      <option value="">All versions ({options.length})</option>
      {options.map(({value, text}) => (
        <option key={value} value={value}>
          {text}
        </option>
      ))}
    </Select>
  );
};

const DisabledPicker = () => {
  return (
    <Select value="" isDisabled>
      <option value="">All versions</option>
    </Select>
  );
};
