import React from 'react';
import {Spinner as ChakraSpinner, SpinnerProps, Flex} from '@chakra-ui/react';

const baseProps: SpinnerProps = {
  thickness: '3px',
  speed: '1s',
  size: 'xl',
  color: 'primary.500',
  emptyColor: 'gray.100'
};

export const Spinner = (props: SpinnerProps) => (
  <Flex justifyContent={props.justifyContent || 'center'} h="100%" alignItems="center">
    <ChakraSpinner {...baseProps} {...props} />
  </Flex>
);

export const RefetchingIndicator = (props) => {
  return <ChakraSpinner color="primary.500" {...props} />;
};
