import {EditIcon, EraserIcon} from '../icons';
import {ToolbarButton} from 'components/image-viewer/button';
import {ImageContainer} from 'components/image-viewer/image-container';
import {PanningIcon} from 'components/image-viewer/plugins/icons/panning';
import {AnnotationsContainer} from './annotation-container';

export const PanningModeButton = () => {
  const {mode, setMode} = ImageContainer.useContainer();
  return (
    <ToolbarButton onClick={() => setMode('panning')} isActive={mode === 'panning'}>
      <PanningIcon size={28} key="panning" />
    </ToolbarButton>
  );
};

export const PenModeButton = () => {
  const {mode} = ImageContainer.useContainer();
  const {enterDrawingMode} = AnnotationsContainer.useContainer();

  return (
    <ToolbarButton onClick={() => enterDrawingMode('annotating')} isActive={mode === 'annotating'}>
      <EditIcon size={28} key="pen" />
    </ToolbarButton>
  );
};

export const EraserModeButton = () => {
  const {mode} = ImageContainer.useContainer();
  const {enterDrawingMode} = AnnotationsContainer.useContainer();

  return (
    <ToolbarButton onClick={() => enterDrawingMode('erasing')} isActive={mode === 'erasing'}>
      <EraserIcon size={26} key="erasing" />
    </ToolbarButton>
  );
};
