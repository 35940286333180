import React from 'react';
import {FormControl, FormLabel, Select} from '@chakra-ui/react';

import {useLocale} from 'shared';
import {AIModel} from 'components/settings/types';

type Props = {
  modelName: string;
  onChange: (value: string | null) => void;
  models: AIModel[];
  disabled?: boolean;
};
export const AnnotationModelInput = ({modelName, onChange, models, disabled}: Props) => {
  const locale = useLocale();

  const getModelOptions = () => {
    return models.map(({name, displayName}) => ({
      value: name,
      label: locale.get(displayName)
    }));
  };

  return (
    <FormControl id="annotationModel">
      <FormLabel>{locale.annotationModelInputModelFieldLabel}</FormLabel>
      <Select
        value={modelName || ''}
        onChange={(event) => {
          const value = event.currentTarget.value;
          onChange(value || null);
        }}
        isDisabled={disabled}
      >
        <option key=""></option>
        {getModelOptions().map(({value, label}) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};
