import React from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  BoxProps,
  ButtonGroup,
  Code,
  Flex,
  Link,
  ListItem,
  Text,
  UnorderedList
} from '@chakra-ui/react';
import {useDropzone} from 'react-dropzone';
import {parse} from 'papaparse';
import debugModule from 'debug';

import {useLocale} from 'shared/locale';
import {
  Heading1,
  Button,
  Progress,
  PreviousIcon,
  CheckIcon,
  WarningIcon,
  InfoIcon,
  SubTitle,
  PageContainer
} from 'components/core';
import {ImportCSVContainer, ScanRecord, ParsedRowData} from './import-csv-container';
import {RecordTable, ScanFieldName} from './csv-record-table';
import {useFieldSetup, isValidRow, normalizeRow} from './import-csv-validation';
import {NumberOf, TruncatedList} from './batch-import-utils';
import {ExpandableList} from './expandable-list';

const debug = debugModule('medmain:csv');

export const ImportCSVPage = () => {
  const locale = useLocale();
  const {current, send} = ImportCSVContainer.useContainer();
  const {checkFileHeader, checkFileData, fieldSetup, normalizeFieldName} = useFieldSetup();

  const parseCSV = (file: File) => {
    parse(file, {
      header: true, // the first row of the file must contain the field names
      transformHeader: normalizeFieldName,
      complete: function (results) {
        const {
          data,
          meta: {fields}
        } = results;
        try {
          checkFileHeader(fields);
          const rows = data.map(normalizeRow).filter(isValidRow);
          checkFileData(rows);
          const editableFields = getTableFields(fields, fieldSetup);
          send('CHECK', {
            filename: file.name,
            data: rows as ParsedRowData[],
            fields: editableFields
          });
        } catch (error) {
          debug(error);
          send('PARSING_ERROR', {message: (error as Error).message});
        }
      }
    });
  };

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop: (files) => parseCSV(files[0]),
    accept: ['.csv']
  });

  const {filename, records, error} = current.context;

  return (
    <PageContainer maxW="container.lg" overflowY="auto">
      <Box display="flex" flexDir="column" mb={4}>
        <Heading1 display="flex" alignItems="center" mb={0}>
          {locale.todo('Import CSV')}
          <SubTitle>
            {filename}
            <StatusBadge current={current} />
          </SubTitle>
        </Heading1>
      </Box>
      <Box mb={8}>
        {current.matches('idle') && (
          <>
            <Box {...getRootProps({})} mb={4}>
              <input {...getInputProps()} />
              <Button
                isDisabled={false}
                style={{
                  width: `100%`,
                  height: '100%',
                  minHeight: `150px`,
                  ...(isDragActive && {
                    backgroundColor: 'rgb(242, 242, 242)',
                    borderColor: 'rgba(0, 0, 0, 0.157)'
                  })
                }}
              >
                {isDragActive
                  ? locale.todo('Drop CSV File')
                  : locale.todo('Click or drag a CSV file from your computer')}
              </Button>
            </Box>
            {error && (
              <Alert status="error" mb={4} flexDirection="column" alignItems="flex-start">
                <Flex alignItems="center">
                  <AlertIcon as={WarningIcon} boxSize="24px" mr={2} />
                  <AlertTitle>The format of the CSV file is not valid</AlertTitle>
                </Flex>
                <AlertDescription mt={2}>{error.message}</AlertDescription>
              </Alert>
            )}
            <Info />
          </>
        )}
        {current.matches('checking') && <PreviewProgress records={records} />}
        {current.matches('updating') && <UpdateProgress records={records} />}
        <Box>
          {current.context.records.length > 0 && (
            <RecordTable records={current.context.records} fields={current.context.fields} mb={4} />
          )}
        </Box>
        <Box>
          {current.matches('previewing') && (
            <PreviewSummary
              filename={filename}
              records={records}
              onGoPrev={() => send('BACK')}
              onGoNext={() => send('RUN')}
            />
          )}
          {current.matches('done') && (
            <>
              <FinalSummary filename={filename} records={records} />
              <ButtonGroup mt={4}>
                <Button onClick={() => send('RESET')}>Import a New File</Button>
              </ButtonGroup>
            </>
          )}
        </Box>
      </Box>
    </PageContainer>
  );
};

export default ImportCSVPage; // loaded by dynamic import

const Info = () => {
  const {fieldSetup} = useFieldSetup();
  const fields = fieldSetup.map(({name}) => name);

  return (
    <Box mb={4} p={4} borderWidth="1px" borderRadius="4px" bg="white">
      <Box>
        <Text fontWeight="bold" mb={2}>
          <InfoIcon mr={2} boxSize={'24px'} color="primary.400" />
          About the format of the CSV file
        </Text>
        <Text>
          The first row has a specific format that contains the name of the fields to update.
          <br />
          For example:
          <br />
          <Code>{fields.join(',')}</Code>
        </Text>
        <Text>
          Each row must contain the <b>image filename</b> and the <b>supplier</b> of the scan to
          update.
        </Text>
        <UnorderedList styleType="disc" my={2}>
          {fieldSetup.map(({name, aliases, lookup, values}) => (
            <ListItem key={name}>
              {name}
              {lookup && (
                <Badge ml={2} colorScheme="red">
                  required
                </Badge>
              )}
              {values && values?.length > 0 && <ExpandableList values={values} />}
            </ListItem>
          ))}
        </UnorderedList>
        <Text>
          <Link variant="primary" isExternal target="_blank" href="/sample/scan-data-sample.csv">
            Click here to download a sample.
          </Link>
        </Text>
      </Box>
    </Box>
  );
};

const PreviewProgress = ({records}: {records: ScanRecord[]}) => {
  const processedRecords = records.filter((record) => record.status !== 'waiting');
  const value = (processedRecords.length / records.length) * 100;
  return (
    <Box borderWidth="1px" p={4}>
      <Text mb={2}>
        Checking the cases {processedRecords.length}/{records.length}...
      </Text>
      <Progress value={value} />
    </Box>
  );
};

const StatusBadge = ({current}) => {
  const getStatus = () => {
    if (current.matches('checking')) return `Checking...`;
    if (current.matches('updating')) return `Updating...`;
  };
  const status = getStatus();
  if (!status) return null;
  return <Badge ml={2}>{status}</Badge>;
};

const PreviewSummary = ({
  filename,
  records,
  supplier,
  onGoNext,
  onGoPrev
}: {
  filename: string;
  records: ScanRecord[];
  supplier?: string;
  onGoPrev: () => void;
  onGoNext: () => void;
}) => {
  const validRecords = records.filter((record) => record.previewStatus === 'valid');
  const errors = records.filter((record) => record.previewStatus === 'not-valid');
  const hasValidRecords = validRecords.length > 0;
  const hasErrors = errors.length > 0;

  const getAlertStatus = () => {
    if (!hasValidRecords) return 'error';
    if (hasErrors) return 'warning';
    return 'info';
  };

  return (
    <>
      <Alert status={getAlertStatus()} flexDirection="column" alignItems="flex-start">
        <AlertTitle display="block">Preview Summary</AlertTitle>
        <UnorderedList styleType="disc" mt={2}>
          {supplier && <ListItem>Supplier: {supplier}</ListItem>}
          <ListItem>
            Total number of row: <NumberOf items={records} name="row" />
          </ListItem>
          <ListItem>
            Ready to update: <NumberOf items={validRecords} name="case" />
            <CaseNumberList records={validRecords} ml={2} />
          </ListItem>
          <ListItem>
            Errors: <NumberOf items={errors} name="error" />
            <CaseNumberList records={errors} ml={2} />
          </ListItem>
        </UnorderedList>
      </Alert>
      <Flex mt={4} justifyContent="space-between">
        <Button onClick={onGoPrev} leftIcon={<PreviousIcon />}>
          Pick an other CSV file
        </Button>
        {hasValidRecords && (
          <Button primary rightIcon={<CheckIcon />} onClick={onGoNext}>
            Update <NumberOf name="scan" items={validRecords} />
          </Button>
        )}
      </Flex>
    </>
  );
};

const UpdateProgress = ({records}: {records: ScanRecord[]}) => {
  const recordsToUpdate = records.filter((record) => record.previewStatus === 'valid');
  const processedRecords = records.filter((record) =>
    ['updated', 'failed'].includes(record.status)
  );
  const total = recordsToUpdate.length;
  const count = processedRecords.length;
  const value = (count / total) * 100;
  return (
    <Box borderWidth="1px" p={4}>
      <Text mb={2}>
        Updating the cases {count}/{total}...
      </Text>
      <Progress value={value} />
    </Box>
  );
};

const FinalSummary = ({filename, records}: {filename: string; records: ScanRecord[]}) => {
  const updatedRecords = records.filter((record) => record.status === 'updated');
  const errors = records.filter((record) => record.status !== 'updated');
  const hasError = errors.length > 0;
  return (
    <Alert status={hasError ? 'warning' : 'success'} flexDirection="column" alignItems="flex-start">
      <AlertTitle display="block">File {filename} was processed successfully.</AlertTitle>
      <UnorderedList styleType="disc" mt={2}>
        <ListItem>
          Processed: <NumberOf items={records} name="row" />
        </ListItem>
        <ListItem>
          Updated: <NumberOf items={updatedRecords} name="case" />
          <CaseNumberList records={updatedRecords} ml={2} />
        </ListItem>
        <ListItem>
          Errors: <NumberOf items={errors} name="error" />
          <CaseNumberList records={errors} ml={2} />
        </ListItem>
      </UnorderedList>
    </Alert>
  );
};

type CaseNumberListProps = {records: ScanRecord[]; limit?: number} & BoxProps;
const CaseNumberList = ({records, limit = 10, ...props}: CaseNumberListProps) => (
  <TruncatedList items={records.map((record) => record.imageFilename)} limit={limit} {...props} />
);

// Return a selection of editable case fields to be displayed by the table,
// based on the fields provided by the CSV file
function getTableFields(fields: ScanFieldName[], fieldSetup): ScanFieldName[] {
  const availableFields = fieldSetup.filter(({lookup}) => !lookup);
  return availableFields.map(({name}) => name).filter((field) => fields.includes(field));
}
