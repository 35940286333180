import {Item} from './item';

export class Account extends Item {
  getFullName(locale) {
    const {firstName, lastName} = this;
    return locale.formatFullName({firstName, lastName});
  }

  /*
  Return the first letters of the names (`firstName` first!)
  Used in reports to save space.
  */
  getInitials() {
    const {firstName, lastName} = this;
    return [firstName, lastName].map(name => name.slice(0, 1)).join('');
  }
}
