import {Alert, AlertDescription, AlertIcon, Box, BoxProps, VStack} from '@chakra-ui/react';
import invariant from 'tiny-invariant';

import {Spinner} from 'components/core';
import {ImageViewerLink} from 'components/scan-list/scan-columns';
import {ScanCard} from 'components/scan-list/scan-gallery';
import {useFetchScans} from 'components/scan-list/fetch-scans';
import {SearchOptions} from 'shared';

type Props = {
  scan: Datastore.Scan;
} & BoxProps;
export const RelatedScans = ({scan, ...props}: Props) => {
  const {data, error, isLoading} = useFetchRelatedScans(scan);

  if (isLoading) {
    return (
      <Box h={150} borderWidth="1px" {...props}>
        <Spinner />
      </Box>
    );
  }

  if (error) return <Alert status="error">Unable to fetch the related scans</Alert>;

  invariant(data);
  const {scans} = data;
  if (!scans.length) {
    return (
      <Alert h={150} borderWidth="1px" {...props} bg="transparent" fontSize="sm">
        <AlertIcon color="gray.300" />
        <AlertDescription color="gray.700">No scan with the same filename</AlertDescription>
      </Alert>
    );
  }

  return (
    <Box {...props}>
      <VStack spacing={4}>
        {scans.map((scan) => (
          <ImageViewerLink
            key={scan.id}
            imageId={scan.imageId || scan.image.id}
            isAvailable={scan.image.status === 'AVAILABLE'}
          >
            <ScanCard scan={scan} size={300} />
          </ImageViewerLink>
        ))}
      </VStack>
    </Box>
  );
};

function useFetchRelatedScans(scan: Datastore.Scan) {
  const searchOptions: SearchOptions = {
    query: [
      {
        field: 'image.filename',
        operator: 'is',
        value: scan.image.filename
      },
      {
        field: 'id',
        operator: 'is-not',
        value: scan.id
      }
    ],
    orderBy: 'createdOn',
    orderDirection: 'DESC'
  };
  const returnFields = [
    'id',
    'supplier',
    'createdOn',
    'status',
    'imageId',
    'image.filename',
    'image.smallImageURL',
    'image.status'
  ];
  return useFetchScans(searchOptions, returnFields);
}
