import {useState, useEffect, useRef} from 'react';
import {createContainer} from 'unstated-next';
import round from 'lodash/round';
import debounce from 'lodash/debounce';
import {ImageContainer} from 'components/image-viewer/image-container';
import {useEffectOnce} from 'react-use';
import debugModule from 'debug';

const minMagnification = 0.1;

enum MaxMagnification {
  X20 = 20,
  X40 = 40
}

export function useMagnification() {
  const {image, getViewer} = ImageContainer.useContainer();
  const [initialMagnification, setInitialMagnification] = useState<number>(0);
  const [currentMagnification, setCurrentMagnification] = useState<number>(0);
  const debug = debugModule('medmain:image-viewer:zoom-plugin');
  const maxMagnification = getMaxMagnification(image);
  const hasChanged = useRef(false); // trigger an effect only when the change comes from the plugin

  useEffect(() => {
    if (!hasChanged.current) {
      debug('No change to apply to the viewport');
      return;
    }
    hasChanged.current = false;

    const setImageZoomLevel = (imageZoomLevel) => {
      const {viewport} = getViewer();
      const viewportZoomLevel = viewport.imageToViewportZoom(imageZoomLevel);
      viewport.zoomTo(viewportZoomLevel);
    };

    const imageZoomLevel = currentMagnification / maxMagnification;
    setImageZoomLevel(imageZoomLevel);
  }, [currentMagnification, debug, getViewer, maxMagnification]);

  useEffectOnce(() => {
    const viewer = getViewer();
    viewer.addHandler('zoom', onZoom);
  });

  const setMagnification = (value: number) => {
    const magnification = Math.max(roundMagnification(value), minMagnification);
    if (!initialMagnification) {
      setInitialMagnification(magnification);
    }
    setCurrentMagnification(magnification);
  };

  // to be hooked to Image Viewer zoom event handler
  // to set up the initial magnification and handle mouse/keyboards event
  const onZoom = debounce(({zoom: viewportZoom}) => {
    const {viewport} = getViewer();
    const imageZoom = viewport.viewportToImageZoom(viewportZoom);
    const magnification = round(imageZoom * maxMagnification, 1);
    setMagnification(magnification);
    debug('Zoom event', hasChanged.current, {
      magnification,
      viewportZoom,
      imageZoom
    });
  }, 200);

  // called by one of the button "2x", "5x", "10x"...
  const changeMagnification = (value: number) => {
    hasChanged.current = true;
    setMagnification(value);
  };

  // called by the "Fit" button
  const resetMagnification = () => {
    hasChanged.current = true;
    setMagnification(initialMagnification);
  };

  return {
    magnification: currentMagnification,
    maxMagnification,
    changeMagnification,
    resetMagnification
  };
}

export const MagnificationContainer = createContainer(useMagnification);

// Should return 20 (for regular WSI images), 40 (for hi-res) or 0 for low res image
function getMaxMagnification(image: Datastore.Image): number {
  const {
    info: {
      dimensions: {width, height},
      magnification
    }
  } = image;
  if (magnification) {
    return magnification;
  }
  // Apply a simple heuristic for images that don't provide magnification data
  // TODO: remove when old images are all re-processed or if we don't need to show the magnification tool on old images
  const isWSI = width > 5000 || height > 5000; // "Whole-Slide-Imaging" are supposed to be big images
  return isWSI ? MaxMagnification.X20 : 0;
}

function roundMagnification(value: number): number {
  const numberOfDecimals = value > 1 ? 0 : 1;
  return round(value, numberOfDecimals);
}
