import React, {useMemo} from 'react';
import {Box, Stack, Tag} from '@chakra-ui/react';

import {useLocale} from 'shared';
import {Table, TimeAgo} from 'components/core';
import {FilenameLink} from 'components/scan-list/scan-columns';

export const TrialScanList = ({scans}) => {
  const locale = useLocale();
  const data = useMemo(() => scans, [scans]);
  const columns = useMemo(
    () => [
      {
        Header: 'Filename',
        accessor: (scan) => <FilenameLink scanId={scan.id} filename={scan.image.filename} />
      },
      {
        Header: 'Size',
        accessor: (scan) => locale.formatFileSize(scan.image.size),
        styleProps: {
          width: '100px'
        }
      },
      {
        Header: 'Reference',
        accessor: (scan) => scan.reference,
        styleProps: {
          width: '80px'
        }
      },
      {
        Header: 'Organ',
        accessor: (scan) => scan.organ,
        styleProps: {
          width: '120px'
        }
      },
      {
        Header: 'Tags',
        accessor: (scan) => {
          let tags = (scan.tags || '').split(',');
          tags = tags.map((tag) => tag.trim());
          tags = tags.filter((tag) => Boolean(tag));
          return (
            <Stack>
              {tags.map((tag, index) => (
                <Box key={index}>
                  <Tag size="sm">{tag}</Tag>
                </Box>
              ))}
            </Stack>
          );
        },
        styleProps: {
          width: '120px'
        }
      },
      {
        Header: 'Verification',
        accessor: (scan) => {
          const prediction = scan.image?.predictions[0];
          if (!prediction) {
            return 'No prediction';
          }
          const verificationStatus = prediction.verificationStatus || 'PENDING';
          // All apologies for using emoji in the code, icons that match the global design would be better
          const messages = {
            PENDING: 'To be verified',
            VERIFYING: `🔍 Verifying`,
            VERIFIED: `✅ Verified`
          };

          return messages[verificationStatus];
        },
        styleProps: {
          width: '120px'
        }
      },
      {
        Header: 'Verified by',
        accessor: (scan) => {
          const account = scan.image?.predictions[0]?.verifier?.account;
          if (!account) {
            return null;
          }

          const fullName = account.getFullName(locale);
          return fullName;
        },
        styleProps: {
          width: '120px'
        }
      },
      {
        Header: 'Added',
        accessor: (scan) => <TimeAgo date={scan.createdOn} />,
        styleProps: {
          width: '120px'
        }
      }
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );
  return <Table data={data} columns={columns} size="sm" />;
};
