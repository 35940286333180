/* global Resource */
import Base from '@ministate/base';
import {jsonLocalStorage, jsonSessionStorage} from 'json-web-storage';

import {User} from 'models/user';

const DEFAULT_LOCALE_ID = 'ja';
const FALLBACK_LOCALE_ID = 'en';

export const createRootApp = ({backendURL, locales}) =>
  class RootApp extends Base {
    async initialize() {
      if (window.IS_SUPPORTED_BROWSER === false) {
        throw new Error('Your browser is not supported!');
      }

      this.state = {...this.state, isBusy: false};
    }

    getAccount() {
      throw new Error('Abstract method called');
    }

    getAccessToken() {
      if (!this._accessToken) {
        this._accessToken =
          jsonSessionStorage.getItem('accessToken') || jsonLocalStorage.getItem('accessToken');
      }
      return this._accessToken;
    }

    setAccessToken(accessToken, {keepUserSignedIn} = {}) {
      this._accessToken = accessToken;
      if (accessToken) {
        if (keepUserSignedIn) {
          jsonSessionStorage.removeItem('accessToken');
          jsonLocalStorage.setItem('accessToken', accessToken);
        } else {
          jsonSessionStorage.setItem('accessToken', accessToken);
          jsonLocalStorage.removeItem('accessToken');
        }
      } else {
        jsonSessionStorage.removeItem('accessToken');
        jsonLocalStorage.removeItem('accessToken');
      }
    }

    userIsKeptSignedIn() {
      return Boolean(jsonLocalStorage.getItem('accessToken'));
    }

    getLocale() {
      const localeId = this.getLocaleId();
      return resolveLocale({
        localeId,
        locales,
        defaultLocaleId: DEFAULT_LOCALE_ID,
        fallbackLocaleId: FALLBACK_LOCALE_ID
      });
    }

    async setLocale(localeId) {
      const account = this.getAccount();
      if (account) {
        const accessToken = this.getAccessToken();
        const backend = await this.getBackend();
        await backend.setAccountLocaleId({localeId, accessToken});
        account.localeId = localeId;
        this.publish();
      } else {
        jsonLocalStorage.setItem('localeId', localeId);
      }
    }

    getLocaleId() {
      const account = this.getAccount();
      return account?.localeId || jsonLocalStorage.getItem('localeId');
    }

    getLocales() {
      return Object.values(locales);
    }

    setModal(modal) {
      this.modal = modal;
    }

    async task(fn) {
      let done = false;

      if (this.state.isBusy) {
        console.warn('Cannot run a task because the application is busy doing something else.');
        return done;
      }

      this.setState({isBusy: true});

      let retry;
      do {
        try {
          await fn();
          done = true;
        } catch (err) {
          console.error(err);

          const locale = this.getLocale();
          if (!this.modal) return;

          if (err.isFatal) {
            const message = err.userMessage || locale.errorDialogMessage;
            await this.modal.alert(message, {title: locale.errorDialogTitle});
            retry = false;
          } else {
            const message =
              err.userMessage || `${locale.errorDialogMessage} ${locale.errorDialogRetryMessage}`;
            retry = await this.modal.confirm(message, {
              title: locale.errorDialogTitle,
              okButton: locale.errorDialogRetryButtonLabel
            });
          }
        }
      } while (retry && !done);

      this.setState({isBusy: false});

      return done;
    }

    async getBackend() {
      if (!this._backend) {
        this._backend = await this.createBackend(backendURL);
      }
      return this._backend;
    }

    async createBackend(url) {
      return await Resource.$import(url);
    }
  };

export const createBaseApp = ({
  productId,
  backendURL,
  medmainAccountsWebsiteURL,
  adminURL,
  locales
}) =>
  class BaseApp extends createRootApp({backendURL, locales}) {
    async initialize() {
      await super.initialize();

      let user;
      let accessToken;

      const url = new URL(window.location.href);
      accessToken = url.searchParams.get('accessToken');
      const stayConnected = url.searchParams.get('stayConnected');

      if (accessToken) {
        this.setAccessToken(accessToken, {keepUserSignedIn: stayConnected === '1'});
        url.searchParams.delete('accessToken');
        url.searchParams.delete('stayConnected');
        window.history.replaceState(undefined, undefined, url.toString());
      } else {
        accessToken = this.getAccessToken();
      }

      if (accessToken) {
        try {
          user = await this.loadUser({accessToken});
        } catch (err) {
          if (
            err.code === 'ACCESS_TOKEN_IS_INVALID' ||
            err.code === 'ACCOUNT_NOT_FOUND' ||
            err.code === 'ACCOUNT_IS_UNAVAILABLE'
          ) {
            this.setAccessToken(undefined);
          } else {
            throw err;
          }
        }
      }

      this.state = {...this.state, user};
    }

    getAccount() {
      return this.state.user && this.state.user.account;
    }

    // === User ===

    signIn() {
      const url = new URL(medmainAccountsWebsiteURL);
      url.pathname = '/sign-in';
      url.searchParams.set('productId', productId);
      url.searchParams.set('callbackURL', window.location.href);
      window.location.replace(url.toString());
    }

    signOut() {
      this.setAccessToken(undefined);
      const url = new URL(medmainAccountsWebsiteURL);
      url.pathname = '/sign-out';
      url.searchParams.set('productId', productId);
      url.searchParams.set('callbackURL', getBaseURL());
      window.location.replace(url.toString());
    }

    showAccount() {
      const url = new URL(medmainAccountsWebsiteURL);
      url.pathname = '/account';
      url.searchParams.set('productId', productId);
      url.searchParams.set('callbackURL', window.location.href);
      window.location.assign(url.toString());
    }

    hasAdmin() {
      return Boolean(adminURL);
    }

    showAdmin() {
      window.location.assign(adminURL);
    }

    async loadUser({accessToken}) {
      const backend = await this.getBackend();
      let {user} = await backend.getUser({accessToken});
      user = new User(user, {deserialize: true});
      return user;
    }
  };

export const createAdminApp = ({productId, backendURL, medmainAccountsWebsiteURL, locales}) =>
  class BaseApp extends createBaseApp({
    productId,
    backendURL,
    medmainAccountsWebsiteURL,
    locales
  }) {
    // === Users ===
    async getUser({userId}) {
      const backend = await this.getBackend();
      const accessToken = this.getAccessToken();
      let {user} = await backend.getUser({userId, accessToken});
      user = new User(user, {deserialize: true});
      return user;
    }

    async updateUser({userId, changes}) {
      const backend = await this.getBackend();
      const accessToken = this.getAccessToken();
      try {
        await backend.updateUser({
          userId,
          changes,
          accessToken
        });
      } catch (err) {
        const l = this.getLocale();
        if (err.code === 'PERMISSION_DENIED') {
          err.userMessage = l.todo('Permission denied.');
          err.isFatal = true;
        }
        throw err;
      }
    }

    async ensureUser({email, accessLevel, roles}) {
      const backend = await this.getBackend();
      const accessToken = this.getAccessToken();
      try {
        await backend.ensureUser({email, accessLevel, roles, accessToken});
      } catch (err) {
        const l = this.getLocale();
        if (err.code === 'ACCOUNT_NOT_FOUND') {
          err.userMessage = l.todo('There are no Medmain accounts matching this email address.');
          err.isFatal = true;
        } else if (err.code === 'PERMISSION_DENIED') {
          err.userMessage = l.todo('Permission denied.');
          err.isFatal = true;
        }
        throw err;
      }
    }

    async findUsers({offset, limit}) {
      const backend = await this.getBackend();
      const accessToken = this.getAccessToken();
      let {users} = await backend.findUsers({offset, limit, accessToken});
      users = users.map((user) => new User(user, {deserialize: true}));
      return users;
    }
  };

export function getBaseURL() {
  const url = new URL(window.location.href);
  url.pathname = '/';
  url.search = '';
  url.hash = '';
  return url.toString();
}

// TODO: move to an other place, this function is duplicated!!
export function resolveLocale({localeId, locales, defaultLocaleId, fallbackLocaleId}) {
  const availableLocales = [localeId, defaultLocaleId, fallbackLocaleId] // the order matters!
    .filter((id) => Boolean(locales[id])) // keep only ids that have a related locale
    .map((id) => locales[id]);
  const firstAvailableLocale = availableLocales[0];
  if (!firstAvailableLocale) {
    throw new Error(`Unable to resolve "${localeId}"`);
  }
  return firstAvailableLocale;
}
