import React, {useState} from 'react';
import {
  ButtonGroup,
  Flex,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Alert,
  Box
} from '@chakra-ui/react';

import {useLocale} from 'shared';
import {Button, Spinner} from 'components/core';
import {AnnotationLabelInput} from 'components/annotations/annotation-label-input';
import {useFetchAILabels} from 'components/settings/ai-labels-api';

type Props = {
  onClose: (labelName?: string) => void;
  defaultModelName?: string;
};
export const AnnotationLabelChooser = ({defaultModelName, onClose}: Props) => {
  const locale = useLocale();
  const [labelName, setLabelName] = useState('');
  // refetchOnMount is disabled to avoid errors when un-mounting
  // TODO: avoid fetching labels from the parent component (ImageViewer)?
  const {data, error, isLoading} = useFetchAILabels({refetchOnMount: false});

  if (isLoading)
    return (
      <>
        <Header />
        <ModalBody>
          <Box h={100}>
            <Spinner />
          </Box>
        </ModalBody>
      </>
    );

  if (error)
    return (
      <>
        <Header />
        <ModalBody>
          <Alert status="error">Unable to load the AI labels</Alert>
        </ModalBody>
      </>
    );

  const {models, labels} = data!;

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onClose(labelName);
      }}
    >
      <Header />

      <ModalBody>
        <AnnotationLabelInput
          labelName={labelName}
          onChange={(labelName) => setLabelName(labelName)}
          models={models}
          labels={labels}
          defaultModelName={defaultModelName}
        />
      </ModalBody>

      <ModalFooter>
        <Flex justifyContent="flex-end">
          <ButtonGroup>
            <Button type="button" onClick={() => onClose(undefined)}>
              {locale.cancelButtonLabel}
            </Button>
            <Button type="submit" primary disabled={!labelName}>
              {locale.annotationLabelChooserChooseButtonLabel}
            </Button>
          </ButtonGroup>
        </Flex>
      </ModalFooter>
    </form>
  );
};

const Header = () => {
  const locale = useLocale();
  return (
    <ModalHeader>
      {locale.annotationLabelChooserHeading}
      <ModalCloseButton />
    </ModalHeader>
  );
};
