import {Box, Flex, Text} from '@chakra-ui/layout';

import {ImageContainer} from 'components/image-viewer/image-container';
import {ImageViewerPlugin} from 'components/image-viewer/types';
import {createPlugin} from 'components/image-viewer/plugins';
import {MagnificationContainer} from './magnification-container';
import {MagnificationPicker} from './magnification-picker';
import {truncateFilename} from 'models/image';

export function MagnificationPlugin(): ImageViewerPlugin {
  return createPlugin({
    displayName: 'MagnificationPlugin',
    Provider(props) {
      return <MagnificationContainer.Provider {...props} />;
    },
    TopArea() {
      const magnificationState = MagnificationContainer.useContainer();
      const {image, isBusy} = ImageContainer.useContainer();
      return (
        <Box>
          <Flex
            backgroundColor="inverseBoxBackgroundColor"
            borderWidth={1}
            borderColor="inverseBoxBorderColor"
            color="white"
            borderRadius="md"
            alignItems="center"
            height="2.5rem"
          >
            <Flex
              flexGrow={1}
              pl={2}
              pr={4}
              alignItems="center"
              maxWidth={400}
              cursor="default"
              pointerEvents="all"
            >
              <Text isTruncated>{isBusy ? 'Saving...' : truncateFilename(image.filename)}</Text>
            </Flex>
            {magnificationState.maxMagnification > 0 && (
              <Flex px={0} borderLeftWidth="1px" height="100%" align="center">
                <MagnificationPicker {...magnificationState} />
              </Flex>
            )}
          </Flex>
        </Box>
      );
    }
  });
}
