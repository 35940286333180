import React, {useMemo} from 'react';
import {Box, Flex, Icon, /*, IconButton,*/ Tag, VStack} from '@chakra-ui/react';
import {FiFile} from 'react-icons/fi';
import {Column} from 'react-table';
import {MdOutlineModeComment} from 'react-icons/md';

import {
  ItemSelection,
  SearchOptions,
  useSearch,
  useLocale,
  usePermissions /*useApp*/
} from 'shared';
import {ExtendedColumn, MenuItem, Table} from 'components/core';
import {
  BatchNumberLink,
  CompactDate,
  EmptyValue,
  FilenameLink,
  KeywordLink,
  TagLinkGroup,
  WSIAnnotationGroup
} from './scan-columns';
import {ImageThumbnail} from './image-thumbnail';
import {
  RejectedIcon,
  RescannedIcon,
  ToBeVerifiedIcon
} from 'components/quality-control/quality-control-shared';
import {TabLayoutContainer} from 'components/scan-split-layout/tab-layout-container';
import {truncateFilename} from 'models/image';

/*
`BasicScanTable` can render any group of `columns` as there are different needs depending on the page:
- Scan list page
- The 4 "Quality control" pages
- Trash bin
*/

type Props = {
  scans: Datastore.Scan[];
  selection?: ItemSelection;
  onSelect?: (selection: ItemSelection) => void;
  contextMenuItems?: MenuItem[][];
  searchOptions: SearchOptions;
  columns: Column<Datastore.Scan>[];
};
export const BasicScanTable = ({scans, selection, searchOptions, columns, ...props}: Props) => {
  const {changeSortingOrder} = useSearch({searchOptions});

  const data = useMemo(() => scans, [scans]);
  const {orderBy, orderDirection} = searchOptions;

  return (
    <Box overflow="auto" borderWidth="1px">
      <Table
        data={data}
        columns={columns}
        size="sm"
        selection={selection}
        onHeaderClick={changeSortingOrder}
        orderBy={orderBy}
        orderDirection={orderDirection}
        {...props}
      />
    </Box>
  );
};

export type ScanColumnKey =
  | 'annotations'
  | 'batch'
  | 'comments'
  | 'createdOn'
  | 'details'
  | 'filename'
  | 'gene'
  | 'protein'
  | 'hasComments'
  | 'hasScannedDiagnosis'
  | 'organ'
  | 'reference'
  | 'size'
  | 'supplier'
  | 'specimenType'
  | 'user'
  | 'updatedOn';

export const useScanColumns = () => {
  const locale = useLocale();

  const {onImageClick} = TabLayoutContainer.useContainer();

  const thumbnail = ({size}: {size: number}): ExtendedColumn<Datastore.Scan> => ({
    id: 'thumbnail',
    orderByPath: null, // disable the "click on header to sort" features
    Header: 'Image',
    Cell: ({row: {original: scan}}) => {
      return <ImageThumbnail image={scan.image} size={size} onClick={() => onImageClick(scan)} />;
    },
    styleProps: {
      width: `${size}px`,
      textAlign: 'center'
    }
  });

  const columns: Record<ScanColumnKey, ExtendedColumn<Datastore.Scan>> = {
    details: {
      id: 'imageFilename',
      Header: 'Filename',
      Cell: ({row: {original: scan}}) => <ScanDetailsCell scan={scan} />,
      styleProps: {
        width: 'auto'
      }
    },
    filename: {
      id: 'image.filename',
      Header: 'Filename',
      Cell: ({row: {original: scan}}) => {
        return <FilenameLink scanId={scan.id} filename={scan.image.filename} />;
      },
      styleProps: {
        width: 'auto'
      }
    },
    hasScannedDiagnosis: {
      id: 'hasScannedDiagnosis',
      Header: <Icon as={FiFile} color="gray.500" />,
      Cell: ({row: {original: scan}}) => (scan.hasScannedDiagnosis ? <Icon as={FiFile} /> : null),
      styleProps: {
        padding: 0,
        width: '30px',
        textAlign: 'center'
      }
    },
    hasComments: {
      id: 'hasComments',
      Header: <Icon as={MdOutlineModeComment} color="gray.500" />,
      Cell: ({row: {original: scan}}) =>
        scan.hasComments ? <Icon as={MdOutlineModeComment} /> : null,
      styleProps: {
        padding: 0,
        width: '30px',
        textAlign: 'center'
      }
    },
    size: {
      id: 'imageSize',
      Header: 'Size',
      accessor: (scan) => locale.formatFileSize(scan.image.size),
      styleProps: {
        width: '100px'
      }
    },
    supplier: {
      id: 'supplier',
      Header: 'Supplier',
      accessor: (scan) => scan.supplier,
      Cell: ({value}) => <KeywordLink category="supplier" value={value} />,
      styleProps: {
        width: '150px'
      }
    },
    specimenType: {
      id: 'specimenType',
      Header: 'Type of specimen',
      accessor: (scan) => scan.specimenType,
      Cell: ({value}) => <KeywordLink category="specimenType" value={value} />,
      styleProps: {
        width: '150px'
      }
    },
    batch: {
      id: 'imageBatchNumber',
      Header: 'Batch',
      accessor: (scan) => scan.image.batch.number,
      Cell: ({value}) => <BatchNumberLink batchNumber={value} />,
      styleProps: {
        width: '90px'
      }
    },
    organ: {
      id: 'organ',
      Header: 'Organ',
      accessor: (scan) => scan.organ,
      Cell: ({value}) => <KeywordLink category="organ" value={value} />,
      styleProps: {
        width: '90px'
      }
    },
    user: {
      Header: 'Created by',
      accessor: (scan: Datastore.Scan) => {
        const user = scan.user || scan.image.user; // TODO fix the backend, the user can be assigned to the image or to the scan
        return user?.account?.email || '?';
      },
      styleProps: {
        width: '250px'
      }
    },
    reference: {
      id: 'reference',
      Header: 'Reference',
      accessor: (scan) => scan.reference
    },
    gene: {
      id: 'gene',
      Header: 'Gene',
      accessor: (scan) => scan.gene
    },
    protein: {
      id: 'protein',
      Header: 'Protein',
      accessor: (scan) => scan.protein
    },
    comments: {
      id: 'comments',
      Header: 'Comments',
      accessor: (scan) => scan.comments
    },
    createdOn: {
      id: 'createdOn',
      Header: 'Created at',
      accessor: (scan) => scan.createdOn,
      Cell: ({value}) => <CompactDate date={value} />,
      styleProps: {
        width: '150px'
      }
    },
    updatedOn: {
      id: 'updatedOn',
      Header: 'Last update',
      accessor: (scan) => scan.updatedOn,
      Cell: ({value}) => <CompactDate date={value} />,
      styleProps: {
        width: '150px'
      }
    },
    annotations: {
      id: 'annotations',
      orderByPath: null, // disable the click to sort feature as it's not implemented in the backend for `image.*` fields
      Header: 'Annotations',
      Cell: ({row: {original: scan}}) => {
        const {
          image: {numberOfAnnotations}
        } = scan;
        return numberOfAnnotations || <EmptyValue />;
      },
      styleProps: {
        width: 110
      }
    }
  };

  return {thumbnail, columns};
};

const ScanDetailsCell = ({scan}: {scan: Datastore.Scan}) => {
  const {hasPermission} = usePermissions();
  const isAvailable = (scan: Datastore.Scan) => scan.image.status === 'AVAILABLE';

  return (
    <VStack alignItems="flex-start">
      {scan.image.filename ? (
        <Flex alignItems="center">
          <FilenameLink scanId={scan.id} filename={truncateFilename(scan.image.filename)} />
        </Flex>
      ) : (
        <Box fontStyle="italic">(Deleted Image)</Box>
      )}
      {hasPermission('quality-control/view') && isAvailable(scan) && (
        <QualityCheckTag status={scan.status} />
      )}
      <TagLinkGroup scanTags={scan.tags} />
      <WSIAnnotationGroup annotations={scan.image.wsiAnnotations || []} />
    </VStack>
  );
};

const QualityCheckTag = ({status}: {status: Datastore.Scan['status']}) => {
  if (status === 'TO_BE_VERIFIED') {
    return (
      <Tag colorScheme="blue" size="sm">
        To be verified
        <ToBeVerifiedIcon ml={1} fontSize="16px" />
      </Tag>
    );
  }
  if (status === 'REJECTED') {
    return (
      <Tag colorScheme="red" size="sm">
        Rejected
        <RejectedIcon ml={1} />
      </Tag>
    );
  }
  if (status === 'RESCANNED') {
    return (
      <Tag colorScheme="orange" size="sm">
        Rescanned
        <RescannedIcon ml={1} />
      </Tag>
    );
  }
  return null;
};
