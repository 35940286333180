import React from 'react';
import {
  useTable,
  Column,
  ColumnInstance,
  HeaderGroup,
  TableInstance,
  UseFiltersState,
  UsePaginationInstanceProps,
  UsePaginationState
} from 'react-table';
import {
  Checkbox,
  Flex,
  Table as ChakraTable,
  Tfoot,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableProps
} from '@chakra-ui/react';
import invariant from 'tiny-invariant';

import {ItemSelection, SearchOptions, useLocale} from 'shared';
import {Popover} from 'components/core';
import {Menu, MenuItem} from './menu';
import {ChevronDownIcon, ChevronUpIcon} from './icons';

type ColumnExtraProps = {
  orderByPath?: string | null;
  styleProps?: object;
};
export type ExtendedColumn<D extends object> = Column<D> & ColumnExtraProps;
type ExtendedColumnHeader<D extends object> = HeaderGroup<D> & ColumnExtraProps;
type ExtendedColumnInstance<D extends object> = ColumnInstance<D> & ColumnExtraProps;

export type PaginationTableInstance<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> & {
    state: UsePaginationState<T> & UseFiltersState<T>;
  };

const defaultPropGetter = (row?: any) => ({});

type Props<D extends object> = {
  columns: Column<D>[];
  data: D[];
  getRowProps?: (row: any) => any;
  getRowId?: (row: any) => any;
  // Extra features
  selection?: ItemSelection;
  onSelect?: (selection: ItemSelection) => void;
  showHeader?: boolean;
  isSticky?: boolean;
  showFooter?: boolean;
  orderBy?: SearchOptions['orderBy'];
  orderDirection?: SearchOptions['orderDirection'];
  onHeaderClick?: (id: string) => void;
  contextMenuItems?: MenuItem[][];
} & Omit<TableProps, 'onSelect'>;

export const Table = <D extends object>(props: Props<D>) => {
  return props.contextMenuItems ? <TableWithMenu {...props} /> : <BasicTable {...props} />;
};

const TableWithMenu = <D extends object>({contextMenuItems, ...props}: Props<D>) => {
  if (!contextMenuItems) return null;
  const content =
    typeof contextMenuItems === 'function'
      ? contextMenuItems
      : ({close}) => <Menu items={contextMenuItems} onClick={close} />;
  return (
    <Popover content={content} position={'cursor'}>
      {({open}) => {
        return <BasicTable {...props} onContextMenu={open} />;
      }}
    </Popover>
  );
};

export const BasicTable = <D extends object>({
  columns,
  data,
  getRowProps = defaultPropGetter,
  getRowId,
  showHeader = true,
  showFooter = false,
  isSticky = true,
  selection,
  onSelect,
  onContextMenu,
  orderBy,
  orderDirection,
  onHeaderClick,
  ...tableProps
}: Props<D> & {onContextMenu?: any}) => {
  const tableInstance = useTable<D>({columns, data, getRowId});

  const {getTableProps, getTableBodyProps, footerGroups, headerGroups, rows, prepareRow} =
    tableInstance;

  const {toggleItem, toggleAllItems} = useItemSelection(data, selection, onSelect);

  const selectContextMenuItem = (itemId) => {
    invariant(selection);
    if (!selection.isItemSelected(itemId)) {
      selection = selection.toggleAllItems(false);
      selection = selection.toggleItem(itemId, true);
      if (onSelect) onSelect(selection);
    }
  };

  if (rows.length === 0) return <NoItemsFound />;

  return (
    <ChakraTable {...getTableProps()} {...tableProps} borderWidth="0px">
      {showHeader && (
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {selection && (
                <Th w="40px" px={0} py={2} textAlign="center">
                  <Checkbox
                    isChecked={selection.mode === 'all'}
                    onChange={(event) => toggleAllItems(event.target.checked)}
                    bg="white"
                    size="lg"
                    spacing={0}
                  />
                </Th>
              )}
              {headerGroup.headers.map((column: ExtendedColumnHeader<D>) => {
                const isCurrentOrder = orderBy === column.id;
                const isClickable = !!onHeaderClick && column.orderByPath !== null;
                return (
                  <Th
                    {...column.getHeaderProps()}
                    {...column.styleProps}
                    onClick={
                      isClickable
                        ? () => {
                            const path = column.orderByPath || column.id; // fallback to column `id` if `path` is not provided
                            if (onHeaderClick) onHeaderClick(path);
                          }
                        : undefined
                    }
                    cursor={isClickable ? 'pointer' : undefined}
                  >
                    {column.render('Header')}
                    {isCurrentOrder && <SortMarker direction={orderDirection} />}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
      )}

      <Tbody {...getTableBodyProps()} borderTopWidth={showHeader ? undefined : '1px'}>
        {rows.map((row) => {
          prepareRow(row);
          const itemId = (row.original as any).id; // TODO use a generic way to get row id from data
          const isSelected = selection?.isItemSelected(itemId);
          const handleContextMenu =
            onContextMenu &&
            ((event) => {
              selectContextMenuItem(itemId);
              onContextMenu(event);
            });
          return (
            <Tr bg={isSelected ? 'primary.50' : 'cardBg'} {...row.getRowProps(getRowProps(row))}>
              {selection && (
                <Td w="40px" px={0} textAlign="center">
                  <Checkbox
                    key={itemId}
                    isChecked={isSelected}
                    onChange={(event) => {
                      toggleItem(itemId, event.target.checked, (event as any).nativeEvent.shiftKey);
                    }}
                    size="lg"
                    spacing={0}
                  />
                </Td>
              )}
              {row.cells.map((cell) => {
                return (
                  <Td
                    onContextMenu={(event) => {
                      // Ignore right clicks on regular links that should open the browser menu
                      if (onContextMenu && !isClickOnLink(event)) {
                        event.preventDefault();
                        handleContextMenu(event);
                      }
                    }}
                    {...(cell.column as ExtendedColumnInstance<D>).styleProps}
                    {...cell.getCellProps([])}
                  >
                    {cell.render('Cell')}
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
      {showFooter && (
        <Tfoot>
          {footerGroups.map((group) => (
            <Tr {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <Th
                  bottom={0}
                  {...(column as ExtendedColumnHeader<D>).styleProps}
                  {...column.getFooterProps()}
                >
                  {column.render('Footer')}
                </Th>
              ))}
            </Tr>
          ))}
        </Tfoot>
      )}
    </ChakraTable>
  );
};

export const NoItemsFound = () => {
  const locale = useLocale();
  return (
    <Flex
      w="100%"
      py={12}
      bg="white"
      justifyContent="center"
      alignItems="center"
      borderTopWidth="1px"
      borderBottomWidth="1px"
      color="gray.500"
      fontSize="lg"
    >
      {locale.listNoItemsFound}
    </Flex>
  );
};

function isClickOnLink(event) {
  return ['A', 'IMG'].includes(event.target.tagName.toUpperCase());
}

const SortMarker = ({direction}) => {
  const Component = direction === 'ASC' ? ChevronUpIcon : ChevronDownIcon;
  return <Component />;
};

export function useItemSelection(
  items: any[],
  selection?: ItemSelection,
  onSelect?: (selection: ItemSelection) => void
) {
  const toggleItem = (itemId, checked, shiftKey) => {
    invariant(selection);
    if (shiftKey) {
      const itemIds = items.map((item) => item.id);
      selection = selection.toggleItemRange(itemId, checked, itemIds);
    } else {
      selection = selection.toggleItem(itemId, checked);
    }
    if (onSelect) onSelect(selection);
  };

  const toggleAllItems = (checked) => {
    invariant(selection);
    selection = selection.toggleAllItems(checked);
    if (onSelect) onSelect(selection);
  };

  return {toggleItem, toggleAllItems};
}
