import React, {useState} from 'react';
import {Box, Flex, ListItem, Text, UnorderedList} from '@chakra-ui/react';
import {Button, ChevronDownIcon, ChevronUpIcon} from 'components/core';

type Props = {values: string[]};
export const ExpandableList = ({values}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return isOpen ? (
    <Box fontSize="sm">
      <Flex alignItems="center">
        <Box flex={1}>Pick from this list of {values.length} items:</Box>
        <Box>
          <Button onClick={() => setIsOpen(false)} size="sm" ml={2} leftIcon={<ChevronUpIcon />}>
            Hide Details
          </Button>
        </Box>
      </Flex>
      <UnorderedList listStyleType="circle">
        {values.map((value) => (
          <ListItem key={value}>{value}</ListItem>
        ))}
      </UnorderedList>
    </Box>
  ) : (
    <Flex alignItems="center" w="100%">
      <Text isTruncated color="gray.500" mb={0} flex={1}>
        {values.join(' ')}
      </Text>
      <Box>
        <Button onClick={() => setIsOpen(true)} size="sm" ml={2} rightIcon={<ChevronDownIcon />}>
          Show Details
        </Button>
      </Box>
    </Flex>
  );
};
