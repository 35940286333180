import React from 'react';
import {Grid, GridItem} from '@chakra-ui/react';
import startCase from 'lodash/startCase';

import {useLocale} from 'shared';
import {DateTime} from 'components/core';
import {BatchNumberLink} from 'components/scan-list/scan-columns';

export const ScanImageInfo = ({scan}: {scan: Datastore.Scan}) => {
  const {image} = scan;
  const locale = useLocale();
  const resolution = image.getResolutionInDPI();
  const {vendor, magnification} = image.info || {};

  return (
    <Grid templateColumns="120px 1fr" fontSize="14px" columnGap={4} rowGap={2}>
      <ImageInfoLabel>{locale.todo('Format')}</ImageInfoLabel>
      <GridItem>{image.format}</GridItem>
      <ImageInfoLabel>{locale.todo('Size')}</ImageInfoLabel>
      <GridItem>{locale.formatFileSize(image.size)}</GridItem>
      <ImageInfoLabel>{locale.todo('Dimensions')}</ImageInfoLabel>
      <GridItem>
        {image.info?.dimensions
          ? `${image.info.dimensions.width} x ${image.info.dimensions.height} pixels`
          : '--'}
      </GridItem>
      {magnification && (
        <>
          <ImageInfoLabel>{locale.todo('Magnification')}</ImageInfoLabel>
          <GridItem>x{magnification}</GridItem>
        </>
      )}
      {vendor && (
        <>
          <ImageInfoLabel>{locale.todo('Scanner')}</ImageInfoLabel>
          <GridItem>{startCase(vendor)}</GridItem>
        </>
      )}
      <ImageInfoLabel>{locale.todo('Resolution')}</ImageInfoLabel>
      <GridItem>{resolution !== undefined ? `${Math.round(resolution)} DPI` : '--'}</GridItem>
      <ImageInfoLabel>{locale.todo('Batch')}</ImageInfoLabel>
      <GridItem>
        <BatchNumberLink batchNumber={image.batch.number} />
      </GridItem>
      <ImageInfoLabel>{locale.todo('Added by')}</ImageInfoLabel>
      <GridItem>{scan.user?.account?.email}</GridItem>
      <ImageInfoLabel>{locale.todo('Added on')}</ImageInfoLabel>
      <GridItem>
        <DateTime date={scan.createdOn} />
      </GridItem>
      <ImageInfoLabel>{locale.todo('Updated on')}</ImageInfoLabel>
      <GridItem>
        {scan.updatedOn.valueOf() !== scan.createdOn.valueOf() ? (
          <DateTime date={scan.updatedOn} />
        ) : (
          '--'
        )}
      </GridItem>
      {scan.deletedOn && (
        <>
          <ImageInfoLabel>{locale.todo('Deleted')}</ImageInfoLabel>
          <GridItem>
            <DateTime date={scan.deletedOn} />
          </GridItem>
        </>
      )}
    </Grid>
  );
};

const ImageInfoLabel = (props) => <GridItem color="gray.500" textAlign="right" {...props} />;
