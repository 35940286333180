import {useQuery, QueryObserverOptions} from 'react-query';

import {SearchOptions, useApp} from 'shared';
import {
  EvaluationIndexResource,
  EvaluationDetailResponse,
  PredictionResultResource,
  ModelVersionIndexResource,
  ModelResource
} from 'medrive-types';

export type EvaluationListResponse = {
  total: number;
  data: EvaluationIndexResource[];
};

export function useFetchEvaluations(
  searchOptions: SearchOptions,
  queryOptions?: QueryObserverOptions<any, any>
) {
  const app = useApp();

  const loadEvaluations = async () => {
    const accessToken = app.getAccessToken();
    const backend = await app.getBackend();
    const {query, limit, offset} = searchOptions;
    const {
      evaluations: {data, total}
    } = await backend.findEvaluations({query, limit, offset, accessToken});
    return {data, total};
  };

  return useQuery<EvaluationListResponse, Error>(
    ['evaluation-list', searchOptions],
    loadEvaluations,
    {retry: false, ...queryOptions}
  );
}

export function useFetchEvaluationDetails(
  evaluationId: string,
  queryOptions?: QueryObserverOptions<any, any>
) {
  const app = useApp();

  const loadEvaluation = async () => {
    const accessToken = app.getAccessToken();
    const backend = await app.getBackend();
    const {evaluation} = await backend.getEvaluation({evaluationId, accessToken});
    return evaluation;
  };

  return useQuery<EvaluationDetailResponse, Error>(
    ['evaluation-details', evaluationId],
    loadEvaluation,
    {retry: false, refetchOnWindowFocus: false, ...queryOptions}
  );
}

export function useFetchEvaluationPrediction(
  evaluationId: string,
  imageId: string,
  queryOptions?: QueryObserverOptions<any, any>
) {
  const app = useApp();

  const loadPrediction = async () => {
    const accessToken = app.getAccessToken();
    const backend = await app.getBackend();
    const {
      results: {prediction}
    } = await backend.fetchMedriveData({
      endPoint: `/evaluations/${evaluationId}/predictions/${imageId}`,
      accessToken
    });
    const normalizedPrediction = normalizePrediction(
      imageId,
      prediction as PredictionResultResource
    );
    return normalizedPrediction!;
  };

  return useQuery<SerializedPrediction, Error>(
    ['evaluation-prediction', evaluationId, imageId],
    loadPrediction,
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity, // don't revalidate predictions to avoid un-necessary re-renders
      ...queryOptions
    }
  );
}

export function useFetchModels() {
  const app = useApp();

  const loadModels = async () => {
    const accessToken = app.getAccessToken();
    const backend = await app.getBackend();
    const {
      results: {models}
    } = await backend.fetchMedriveData({endPoint: `/models`, accessToken});
    return models;
  };

  return useQuery<ModelResource[], Error>(['evaluation-models'], loadModels, {
    retry: false
  });
}

export type ModelVersionListResponse = {
  total: number;
  data: ModelVersionIndexResource[];
};

export function useFetchModelVersions(modelName: string) {
  const app = useApp();

  const loadVersions = async () => {
    const accessToken = app.getAccessToken();
    const backend = await app.getBackend();
    const {
      results: {data, total}
    } = await backend.fetchMedriveData({endPoint: `/models/${modelName}/versions`, accessToken});
    return {data, total};
  };

  return useQuery<ModelVersionListResponse, Error>(
    ['evaluation-model-versions', modelName],
    loadVersions,
    {retry: false}
  );
}

type SerializedPrediction = {
  id: string;
  model: string;
  status: string;
  results: {
    version: number;
    labels: Record<number, string>;
    cellSize: number;
    cells: Record<string, Record<number, number>>;
  };
};

function normalizePrediction(id: string, input: PredictionResultResource): SerializedPrediction {
  const labels = Object.keys(input.probabilities);

  let cells = {};
  input.locations.forEach((cellLocation, index) => {
    const [y, x] = cellLocation;
    const locationKey = `${x}:${y}`;
    const prob = getProbabilitiesAtTileIndex(input, index); // {0: 95.004};
    cells[locationKey] = prob;
  });

  return {
    id,
    model: '?',
    status: 'COMPLETED',
    results: {
      version: 4,
      labels,
      cellSize: input.cellSize,
      cells
    }
  };
}

function getProbabilitiesAtTileIndex(input: PredictionResultResource, tileIndex: number) {
  const labels = Object.keys(input.probabilities);
  const probabilities = {};
  labels.forEach((label, index) => {
    const value = input.probabilities[label][tileIndex];
    probabilities[index] = value ? value * 100 : 0;
  });
  return probabilities;
}

// TODO: use the API to fetch the available evaluations
export function useEvaluationNav(evaluationId: string) {
  const number = Number(evaluationId);
  if (isNaN(number)) return {previousId: null, nextId: null};

  return {
    previousId: number > 0 ? number - 1 : null,
    nextId: number + 1
  };
}
