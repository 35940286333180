import React from 'react';

import {useApp, useLocale} from 'shared';
import {Button} from 'components/core';
import {useFetchAILabels} from 'components/settings/ai-labels-api';
import {ScanCard} from 'components/scan-list/scan-gallery';
import {Box, Flex} from '@chakra-ui/react';
import {ImageViewerLink} from 'components/scan-list/scan-columns';

const IMAGE_SIZE = 400;
const IMAGE_STATUSES = {
  CREATED: 'Image created',
  UPLOADING: 'Uploading image...',
  UPLOAD_FAILED: 'Image upload failed',
  UPLOADED: 'Waiting to process image...',
  PROCESSING: 'Processing image...',
  PROCESS_FAILED: 'Image processing failed',
  AVAILABLE: 'Image available'
};

export const ScanImagePreview = ({scan}: {scan: Datastore.Scan}) => {
  const app = useApp();
  const locale = useLocale();
  const {data} = useFetchAILabels();
  const labels = data?.labels;
  const {image} = scan;

  const reprocessImage = async () => {
    await app.reprocessImage(image);
  };

  // we pass the labels to be able to display the annotations, right from the thumbnails
  if (image.status === 'AVAILABLE') {
    return (
      <ImageViewerLink imageId={scan.imageId || scan.image.id} isAvailable>
        <ScanCard scan={scan} size={IMAGE_SIZE - 2} showHeader={false} labels={labels} />
      </ImageViewerLink>
    );
  }

  return (
    <Flex
      boxSize={IMAGE_SIZE}
      alignItems="center"
      justifyContent="center"
      borderWidth="1px"
      borderRadius="4px"
    >
      <Box textAlign="center">
        <Box mb={4}>
          {locale.todo(IMAGE_STATUSES[image.status] || locale.todo('Unknown status'))}
        </Box>
        {image.status === 'PROCESS_FAILED' && <Button onClick={reprocessImage}>Retry</Button>}
      </Box>
    </Flex>
  );
};
