import {Alert, Flex} from '@chakra-ui/react';
import {useQuery} from 'react-query';
import invariant from 'tiny-invariant';

import {Heading1, SubTitle, Spinner} from 'components/core';
import {PaginationCount, SearchOptions, useApp, useParseURL} from 'shared';
import Scan from 'models/scan';
import {ScanSplitLayout} from 'components/scan-split-layout/scan-split-layout';
import {DeletedScanList} from './deleted-scan-list';

export const TrashBinPage = () => {
  return (
    <ScanSplitLayout>
      <TrashBinPane />
    </ScanSplitLayout>
  );
};

export const TrashBinPane = () => {
  const searchOptions = useParseURL({limit: 50, orderBy: 'deletedOn'});

  const {data, error, isLoading, refetch} = useFetchDeletedScans(searchOptions);

  if (isLoading) return <Spinner />;
  if (error) return <Alert status="error">Unable to load the deleted scans</Alert>;
  invariant(data);
  const {scans, total} = data;

  return (
    <Flex pt={4} px={6} pb={6} flexDir="column" h="100%" overflow="auto">
      <Heading1 mb={2}>
        Trash bin
        {scans.length > 0 && (
          <SubTitle>
            <PaginationCount
              searchOptions={searchOptions}
              total={total}
              pageItemCount={scans.length}
            />
          </SubTitle>
        )}
      </Heading1>

      <DeletedScanList
        scans={scans}
        reloadScans={refetch}
        searchOptions={searchOptions}
        total={total}
      />
    </Flex>
  );
};

export function useFetchDeletedScans(searchOptions: SearchOptions) {
  const app = useApp();

  const loadScans = async () => {
    const accessToken = app.getAccessToken();
    const backend = await app.getBackend();
    const {orderBy, orderDirection, offset, limit} = searchOptions;
    const {scans, totalNumberOfScans} = await backend.findDeletedScans({
      order: [{field: orderBy, direction: orderDirection}],
      offset,
      limit,
      accessToken
    });
    return {
      scans: scans.map((scan) => new Scan(scan, {deserialize: true})) as Datastore.Scan[],
      total: totalNumberOfScans as number
    };
  };
  return useQuery(['scan-bin', searchOptions], loadScans);
}
