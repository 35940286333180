import {useEffect} from 'react';
import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import {useLocation} from 'react-router';

import {AppHeader} from './header';
import {SidebarContent} from './sidebar';

const headerHeight = 14;
const sidebarWidth = '200px';

export function AppLayout({children}) {
  const sidebar = useDisclosure();
  const location = useLocation();

  useEffect(() => {
    sidebar.onClose();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Flex
      width="100%"
      flexDirection="column"
      as="section"
      bg={useColorModeValue('gray.50', 'gray.700')}
      h="100vh"
      minH="0px"
    >
      <AppHeader sidebar={sidebar} height={headerHeight} />
      <SidebarContent display={{base: 'none', showLeftSidebar: 'unset'}} w={sidebarWidth} />
      <Drawer isOpen={sidebar.isOpen} onClose={sidebar.onClose} placement="left">
        <DrawerOverlay />
        <DrawerContent>
          <SidebarContent w="full" borderRight="none" sidebar={sidebar} />
        </DrawerContent>
      </Drawer>
      <Box
        ml={{base: 0, showLeftSidebar: sidebarWidth}}
        transition=".3s ease"
        pt={headerHeight}
        minH={0}
        flexGrow={1}
        display="flex"
        flexDirection="column"
      >
        <Flex
          overflowY="hidden"
          flexGrow={1}
          flexDirection="column"
          p={{base: 4, showLeftSidebar: 6}}
          position="relative"
        >
          {children}
        </Flex>
      </Box>
    </Flex>
  );
}
