import {usePermissions} from 'shared';
import {ImageViewerPlugin} from 'components/image-viewer/types';
import {ImageContainer} from 'components/image-viewer/image-container';
import {PredictionPluginContainer} from './prediction-container';
import {PredictionViewer} from './prediction-viewer';
import {PredictionPanel} from './prediction-panel';
import {createPlugin} from '..';
import {ToolbarButton} from 'components/image-viewer/button';
import {AIIcon} from '../icons';
import {AILabel} from 'components/settings/types';

export function PredictionPlugin(settings?): ImageViewerPlugin {
  const {canRemovePrediction = true} = settings || {};
  return createPlugin({
    displayName: 'PredictionPlugin',

    Provider({children}) {
      return <PredictionPluginContainer.Provider children={children} />;
    },

    decorateSidebar: (sidebar) => {
      return [
        ...sidebar,
        {key: 'PREDICTION', buttons: [<RequestPredictionButton key="request" />]}
      ];
    },

    MainArea() {
      const {image} = ImageContainer.useContainer();
      const {predictions} = image;
      const {activeLabelNames, activePredictionId, threshold, viewMode} =
        PredictionPluginContainer.useContainer();

      const activePrediction = predictions.find(
        (prediction) => prediction.id === activePredictionId
      );

      if (!activePrediction?.results) {
        return null;
      }

      const showPredictions = activePrediction.status === 'COMPLETED';
      return showPredictions ? (
        <PredictionViewer
          key="PredictionViewer"
          prediction={activePrediction}
          highlightLabels={activeLabelNames}
          imageHeight={image.info.dimensions.height}
          imageWidth={image.info.dimensions.width}
          viewMode={viewMode}
          threshold={threshold}
        />
      ) : null;
    },

    RightArea() {
      const {
        activeLabelNames,
        activePredictionId,
        threshold,
        setThreshold,
        predictions,
        labels,
        setActiveLabelNames,
        switchPrediction,
        viewMode,
        setViewMode,
        removePrediction,
        isTrialImage
      } = PredictionPluginContainer.useContainer();
      const {hasPermission} = usePermissions();

      if (!activePredictionId) {
        return null;
      }
      const predictionLabels = getPredictionLabelsInfo(labels);

      function getPredictionLabelsInfo(labels: AILabel[]) {
        return labels.map(({name, displayName, model}) => {
          const active = activeLabelNames.includes(name);
          return {name, displayName, model, active};
        });
      }

      function handleToggleActivePredictionLabel({name}) {
        const isLabelActive = activeLabelNames.includes(name);
        setActiveLabelNames(
          isLabelActive
            ? activeLabelNames.filter((label) => label !== name)
            : [...activeLabelNames, name]
        );
      }

      return (
        <PredictionPanel
          key={'PREDICTION_PANEL'}
          predictions={predictions}
          activePredictionId={activePredictionId}
          labels={predictionLabels}
          canRemove={canRemovePrediction}
          onRemove={removePrediction}
          onSelectLabel={(label) => {
            setActiveLabelNames([label.name]);
          }}
          onToggleLabel={handleToggleActivePredictionLabel}
          viewMode={viewMode}
          onChangeViewMode={setViewMode}
          threshold={threshold}
          showVerificationBar={isTrialImage && hasPermission('trial/verify')}
          onSwitchPrediction={switchPrediction}
          onThresholdChange={setThreshold}
          onStartVerify={undefined}
          onMarkAsVerified={undefined}
          onUndoMarkAsVerified={undefined}
        />
      );
    }
  });
}

const RequestPredictionButton = () => {
  const {handlePrediction} = PredictionPluginContainer.useContainer();
  return (
    <ToolbarButton onClick={handlePrediction}>
      <AIIcon size={32} />
    </ToolbarButton>
  );
};
