import React, {useState} from 'react';
import {
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Radio,
  RadioGroup,
  Stack
} from '@chakra-ui/react';

import {ItemSelection, useLocale} from 'shared';
import {Button} from 'components/core';
import invariant from 'tiny-invariant';

type Props = {
  selection?: ItemSelection;
  onClose: (value?: {rejectionReason: string}) => void;
};
export const DialogReject = ({selection, onClose}: Props) => {
  const locale = useLocale();
  const getNumberOfItems = () => {
    if (!selection) return 1;
    invariant(selection.itemIds);
    return selection.mode === 'pick' ? locale.formatNumber(selection.itemIds.size) : 'all';
  };

  const [rejectionReason, setRejectionReason] = useState<string>('');

  const rejectionReasons = Object.entries(locale.rejectionReasons).map(([key, label]) => ({
    value: key,
    label
  }));

  const submit = async () => {
    onClose({rejectionReason});
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        submit();
      }}
    >
      <ModalHeader>
        {locale.todo('Quality Check')}
        <span
          style={{
            fontSize: '1rem',
            fontWeight: 500,
            color: 'rgb(128, 128, 128)',
            marginLeft: '0.75rem'
          }}
        >
          In {getNumberOfItems()} selected scans
        </span>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Box mb={4}>Pick the reason of the rejection</Box>
        <RadioGroup
          value={rejectionReason}
          onChange={(value) => setRejectionReason(value as string)}
        >
          <Stack>
            {rejectionReasons.map((reason) => (
              <Radio key={reason.value} value={reason.value}>
                {reason.label}
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      </ModalBody>
      <ModalFooter>
        <Button type="button" onClick={() => onClose()} mr={2}>
          {locale.cancelButtonLabel}
        </Button>
        <Button type="submit" primary isDisabled={rejectionReason === ''}>
          {locale.todo('Reject')}
        </Button>
      </ModalFooter>
    </form>
  );
};
