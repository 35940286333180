import React, {useState} from 'react';
import {
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Box,
  Alert
} from '@chakra-ui/react';
import {useQueryClient} from 'react-query';

import {ACCESS_LEVELS, useApp, useLocale} from 'shared';
import {Button, formatDateTime, Spinner, useModal} from 'components/core';
import {AnnotationLabelInput} from 'components/annotations/annotation-label-input';
import {useFetchAILabels} from 'components/settings/ai-labels-api';
import {useFetchAnnotation} from 'components/annotations/fetch-annotations';
import {AILabel, AIModel} from 'components/settings/types';
import {useAnnotationMutation} from './annotation-api';

type Changes = {
  labelName: string;
};
type Props = {
  image: Datastore.Image;
  annotationId: Datastore.Annotation['id'];
  onClose: (changes?: Changes) => void;
};
export const AnnotationEditor = ({image, annotationId, onClose}: Props) => {
  const app = useApp();
  const {user} = app.state;

  const getReturnFields = () => {
    const returnFields = ['createdOn', 'id', 'labelName', 'verifiedOn'];
    if (user.accessLevel >= ACCESS_LEVELS.SUPER_ADMIN) {
      returnFields.push('user.account.email', 'verifier.account.email');
    }
    return returnFields;
  };
  const labelsQuery = useFetchAILabels();
  const annotationQuery = useFetchAnnotation(annotationId, getReturnFields());

  if (labelsQuery.isLoading || annotationQuery.isLoading) {
    return (
      <>
        <Header onClose={onClose} />
        <ModalBody>
          <Box h={300}>
            <Spinner />
          </Box>
        </ModalBody>
      </>
    );
  }

  if (labelsQuery.error || annotationQuery.error) {
    return (
      <>
        <Header onClose={onClose} />
        <ModalBody>
          <Alert status="error">Unable to load the annotation</Alert>
        </ModalBody>
      </>
    );
  }

  const {labels, models} = labelsQuery.data!;
  const annotation = annotationQuery.data!;

  return (
    <AnnotationEditorInner
      image={image}
      annotation={annotation}
      labels={labels}
      models={models}
      onClose={onClose}
    />
  );
};

type InnerProps = {
  image: Datastore.Image;
  annotation: Datastore.Annotation;
  onClose: Props['onClose'];
  labels: AILabel[];
  models: AIModel[];
};
export const AnnotationEditorInner = ({image, annotation, onClose, labels, models}: InnerProps) => {
  const app = useApp();
  const locale = useLocale();
  const modal = useModal();
  const [labelName, setLabelName] = useState(annotation.labelName);
  const {user} = app.state;
  const queryClient = useQueryClient();
  const {deleteAnnotation, updateAnnotation, markAnnotationAsVerified, markAnnotationAsUnverified} =
    useAnnotationMutation(image.id);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleSave = async () => {
    setIsUpdating(true);
    await updateAnnotation.mutateAsync({id: annotation.id, changes: {labelName}});
    await queryClient.invalidateQueries(['image', image.id]);
    onClose({labelName});
  };

  const handleToggleVerification = async () => {
    setIsUpdating(true);
    if (!annotation.verifiedOn) {
      await markAnnotationAsVerified.mutateAsync(annotation.id);
    } else {
      await markAnnotationAsUnverified.mutateAsync(annotation.id);
    }
    await queryClient.refetchQueries(['image', image.id]);
    await queryClient.invalidateQueries(['annotation', annotation.id]); // needed to update "Verified by"
    setIsUpdating(false);
  };

  const handleDelete = async () => {
    const okay = await modal.confirm(locale.imageViewerDeleteAnnotationConfirm, {
      title: locale.warningDialogTitle,
      okButton: locale.deleteButtonLabel
    });
    if (okay) {
      setIsUpdating(true);
      await deleteAnnotation.mutateAsync(annotation.id);
      await queryClient.invalidateQueries(['image', image.id]);
      onClose();
    }
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleSave();
      }}
      autoComplete="off"
    >
      <Header onClose={() => onClose({labelName})} isUpdating={isUpdating} />

      <ModalBody>
        <AnnotationLabelInput
          labelName={labelName}
          onChange={(value) => setLabelName(value)}
          models={models}
          labels={labels}
        />

        <Grid templateColumns="1fr 1fr" gap={4} mt={4}>
          <GridItem>
            <FormControl id="createdOn">
              <FormLabel>{locale.annotationEditorCreatedOnFieldLabel}</FormLabel>
              <Input
                id="createdOn"
                value={annotation.createdOn ? formatDateTime(annotation.createdOn) : '--'}
                readOnly
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="createdBy">
              <FormLabel>{locale.annotationEditorCreatedByFieldLabel}</FormLabel>
              <Input id="createdBy" value={annotation.user?.account?.email || '--'} readOnly />
            </FormControl>
          </GridItem>
          {user.accessLevel >= ACCESS_LEVELS.SUPER_ADMIN && (
            <>
              <GridItem>
                <FormControl id="verifiedOn">
                  <FormLabel>{locale.annotationEditorVerifiedOnFieldLabel}</FormLabel>
                  <Input
                    id="verifiedOn"
                    value={annotation.verifiedOn ? formatDateTime(annotation.verifiedOn) : '--'}
                    readOnly
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl id="verifiedBy">
                  <FormLabel>{locale.annotationEditorVerifiedByFieldLabel}</FormLabel>
                  <Input
                    id="verifiedBy"
                    value={annotation.verifier?.account?.email || '--'}
                    readOnly
                  />
                </FormControl>
              </GridItem>
            </>
          )}
        </Grid>
      </ModalBody>

      <ModalFooter>
        <Flex w="100%" justifyContent="space-between">
          <ButtonGroup>
            <Button
              type="submit"
              primary
              isDisabled={!labelName || labelName === annotation.labelName || isUpdating}
            >
              {locale.saveButtonLabel}
            </Button>

            {user.accessLevel >= ACCESS_LEVELS.SUPER_ADMIN && (
              <Button type="button" onClick={handleToggleVerification} isDisabled={isUpdating}>
                {!annotation.verifiedOn
                  ? locale.annotationEditorMarkAsVerifiedButtonLabel
                  : locale.annotationEditorMarkAsUnverifiedButtonLabel}
              </Button>
            )}
          </ButtonGroup>

          <Button type="button" onClick={handleDelete} isDisabled={isUpdating}>
            {locale.deleteButtonLabel}
          </Button>
        </Flex>
      </ModalFooter>
    </form>
  );
};

const Header = ({onClose, isUpdating}: {onClose: () => void; isUpdating?: boolean}) => {
  const locale = useLocale();
  return (
    <ModalHeader>
      {locale.annotationEditorHeading}
      {isUpdating && (
        <Box as="span" color="gray.400" fontSize="sm" ml={4}>
          Updating...
        </Box>
      )}
      <ModalCloseButton onClick={() => onClose()} />
    </ModalHeader>
  );
};
