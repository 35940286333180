import capitalize from 'lodash/capitalize';
import isNil from 'lodash/isNil';
import numeral from 'numeral';

import {englishLocale as medmainImagesEnglishLocale} from './images-english';
import common from './common';

const englishLocale = {
  $id: 'en',
  $name: 'English',

  // === General ===

  supportEmail: 'support@medmain.com',

  okButtonLabel: 'OK',
  cancelButtonLabel: 'Cancel',
  closeButtonLabel: 'Close',
  newButtonLabel: '＋ New',
  saveButtonLabel: 'Save',
  deleteButtonLabel: 'Delete',
  continueButtonLabel: 'Continue →',
  backButtonLabel: '← Back',
  nextButtonLabel: 'Next →',
  previousButtonLabel: '← Previous',
  signInButtonLabel: 'Sign in',
  signUpButtonLabel: 'Sign up',
  signOutButtonLabel: 'Sign out',

  optionalFieldMention: 'Optional',

  firstDayOfWeek: 1, // Monday

  lastNameIsBeforeFirstName: false,

  formatFullName({firstName, lastName}) {
    return this.lastNameIsBeforeFirstName ? `${lastName} ${firstName}` : `${firstName} ${lastName}`;
  },

  // === Error dialog ===

  errorDialogTitle: 'Sorry...',
  errorDialogMessage: 'An error occurred.',
  errorDialogRetryMessage: 'Would you want to try again?',
  errorDialogRetryButtonLabel: 'Retry',

  // === Warning dialog ===

  warningDialogTitle: 'Warning!',

  // === List component ===

  listNoItemsFound: 'No items found.',

  // === User menu ===

  userMenuAccountItemLabel: 'My account',
  userMenuAdminItemLabel: 'Admin',
  userMenuSignOutItemLabel: 'Sign out',

  // === Formatting ===

  formatNumber(number) {
    return typeof number === 'number' && !isNaN(number)
      ? numeral(number).format('0,0.[000]')
      : '--';
  },

  formatPercentage(percentage) {
    if (typeof percentage !== 'number' || isNaN(percentage)) return '-.-%';
    if (percentage < 0.1) return `<0.1%`;
    return numeral(percentage).format('0.0') + '%';
  },

  formatArea(area) {
    return typeof area === 'number' && !isNaN(area)
      ? numeral(area).format('0.000') + ' mm²'
      : '-.--- mm²';
  },

  /*
  Format a month number (indexed to 1!), according to the current locale and the given format:
  'full' => 'January'
  'partial' => 'Jan'
  */
  formatMonth(monthNumber, {format = 'full'} = {}) {
    const months = this.months[format];
    if (!months || !Array.isArray(months)) {
      throw new Error('Invalid month format for the current locale!');
    }
    const text = months[monthNumber - 1];
    if (!text) {
      throw new Error(`Invalid month number: ${monthNumber}`);
    }
    return text;
  },

  months: {
    full: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    partial: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  },

  /*
  Format a "day of the week" number (0 means Sunday), according to a given format:
  'full' => 'Monday'
  'partial' => 'Mon'
  */
  formatDayOfWeek(day, {format = 'full'} = {}) {
    const days = this.days[format];
    if (!days || !Array.isArray(days)) {
      throw new Error('Invalid day format for the current locale!');
    }
    const text = days[day];
    if (!text) {
      throw new Error(`Invalid day of the week number: ${day}`);
    }
    return text;
  },

  days: {
    full: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    partial: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  },

  formatFileSize(size) {
    const getMask = () => {
      if (size >= 1e9) {
        return '0.00'; // >1 GB => 2 decimals (E.g 3.25 GB)
      }
      return '0'; // by default: no decimals (E.g. 523 MB)
    };
    return typeof size === 'number' && !isNaN(size) ? numeral(size).format(`${getMask()} b`) : '--';
  },

  formatStatus(status) {
    return capitalize((status || '').replace(/_/g, ' '));
  },

  formatBatchNumber(number) {
    return number ? '#' + number : '--';
  },

  // === Input fields ===

  formatTextInput(string) {
    return string ? string : '';
  },

  parseTextInput(string) {
    return string ? string : null;
  },

  cleanTextInput(string) {
    if (string) {
      string = string.trim();
    }
    return string ? string : null;
  },

  formatNumberInput(number) {
    if (isNil(number)) {
      return '';
    }
    return String(number);
  },

  // Returns a number, NaN or null
  parseNumberInput(string) {
    string = string.trim();
    if (!string) {
      return null;
    }
    const number = Number(string);
    if (this.formatNumberInput(number) !== string) {
      return NaN;
    }
    return number;
  },

  numberInputCustomValidity(number) {
    if (isNil(number)) {
      return '';
    }
    if (typeof number === 'number' && !isNaN(number)) {
      return '';
    }
    return this.numberInputInvalidityMessage;
  },

  numberInputInvalidityMessage: 'Please enter a valid number.',

  formatDateInput(date) {
    if (isNil(date)) {
      return '';
    }
    const year = String(date.getFullYear()).padStart(4, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return year + '/' + month + '/' + day;
  },

  parseDateInput(string) {
    string = string.trim();
    if (!string) {
      return null;
    }
    if (string.length !== 10) {
      return null;
    }
    const date = new Date(
      string.slice(0, 4) + '-' + string.slice(5, 7) + '-' + string.slice(8, 10)
    );
    if (isNaN(date.valueOf())) {
      return null;
    }
    return date;
  },

  dateInputInvalidityMessage: 'Please enter a valid date',

  dateInputPlaceholder: 'YYYY/MM/DD'
};

export const english = {
  ...common,
  ...englishLocale,
  ...medmainImagesEnglishLocale,

  // === General ===

  productName: 'PidPort Datastore',

  scanFields: {
    comments: 'Comments',
    customField1: 'Custom Field 1',
    customField2: 'Custom Field 2',
    customField3: 'Custom Field 3',
    'image.filename': 'Filename',
    organ: 'Organ',
    specimenType: 'Type of specimen',
    supplier: 'Supplier',
    tags: 'Tags',
    'image.annotations.labelName': 'Annotation label',
    'image.wsiAnnotations.labelName': 'WSI label'
  },

  // === Scan report ===

  scanReportFieldHeadings: {
    organ: 'Organs',
    specimenType: 'Specimen types',
    axis: 'Axis',
    disease: 'Diseases',
    staining: 'Stainings',
    supplier: 'Suppliers',
    zoomLevel: 'Magnifications',
    tags: 'Tags',
    imageFormat: 'Image formats',
    gene: 'Genes',
    protein: 'Proteins'
  },

  // === Quality Control ===
  rejectionReasons: {
    blot: 'Blot',
    focus: 'Focus',
    stripe: 'Stripe',
    other: 'Other'
  },

  qualityControlListHeader: {
    TO_BE_VERIFIED: 'Scans to be verified',
    VERIFIED: 'Verified scans',
    REJECTED: 'Rejected scans'
  }
};

export default english;

export type LocaleDictionary = typeof english;
