import React from 'react';

import {useQualityControlColumns} from './quality-control-shared';
import {QualityControlNav} from './quality-control-home';
import {QualityControlList, useQualityControlList} from './quality-control-scan-list';

export const QualityControlVerified = () => {
  const {defaultColumns, verifiedOn} = useQualityControlColumns();
  const columns = [...defaultColumns, verifiedOn];

  const state = useQualityControlList({status: 'VERIFIED', orderBy: 'verifiedOn'});

  return (
    <>
      <QualityControlNav tabIndex={1} />
      <QualityControlList columns={columns} {...state} />
    </>
  );
};
