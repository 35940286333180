import React, {useState} from 'react';
import {
  Alert,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack
} from '@chakra-ui/react';
import {useMutation, useQueryClient} from 'react-query';
import startCase from 'lodash/startCase';

import {KeywordCategory, KeywordItem, KeywordsContainer, useApp, useLocale} from 'shared';
import {Button} from 'components/core';

type Props = {
  category: KeywordCategory;
  onClose: (item?: Pick<KeywordItem, 'value' | 'label'>) => void;
};
export const DialogAddKeyword = ({category, onClose}: Props) => {
  const locale = useLocale();
  const {getKeywords} = KeywordsContainer.useContainer();
  const existingValues = getKeywords(category).map((item) => item.value);
  const categoryDisplayName = startCase(category.toLowerCase());

  const [value, setValue] = useState<string>('');
  const [label, setLabel] = useState<string>('');

  const mutation = useAddKeyword(category);
  const queryClient = useQueryClient();

  const isUnique = !isDuplicate(existingValues, value);
  const isFormValid = label.trim().length > 1 && value.trim().length > 0;

  const submit = () => {
    mutation.mutate(
      {value, label},
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['keywords']);
          setTimeout(() => onClose({value, label}));
        }
      }
    ); // does not return a Promise, we have to use `onSuccess` callback
  };
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        submit();
      }}
    >
      <ModalHeader>Add {categoryDisplayName}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <VStack spacing={6}>
          <FormControl id="value" isInvalid={!isUnique && !mutation.isSuccess}>
            <FormLabel>{categoryDisplayName} code</FormLabel>
            <Input value={value} onChange={(event) => setValue(event.target.value)} />
            <FormHelperText>The value must be unique and cannot be changed later.</FormHelperText>
            <FormErrorMessage>
              {!isUnique && <>This keyword value already exists, please enter another value!</>}
            </FormErrorMessage>
          </FormControl>
          <FormControl id="displayName">
            <FormLabel>{categoryDisplayName} name on the screen</FormLabel>
            <Input value={label} onChange={(event) => setLabel(event.target.value)} />
            <FormHelperText>
              The name displayed on the screen can be changed at any time, no stress!
            </FormHelperText>
          </FormControl>
          {mutation.isError && !mutation.isLoading && (
            <Alert status="error">Unable to create the keyword {mutation.error?.message}</Alert>
          )}
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button type="button" onClick={() => onClose()} mr={2} isDisabled={mutation.isLoading}>
          {locale.cancelButtonLabel}
        </Button>
        <Button
          type="submit"
          primary
          isDisabled={!isFormValid}
          isLoading={mutation.isLoading || !!queryClient.isFetching()}
        >
          Save
        </Button>
      </ModalFooter>
    </form>
  );
};

function isDuplicate(values: string[], code: string) {
  return values.some((value) => value.trim().toLowerCase() === code.trim().toLowerCase());
}

function useAddKeyword(category: KeywordCategory) {
  const app = useApp();
  const accessToken = app.getAccessToken();

  return useMutation<KeywordItem, Error, any>(async ({value, label}: KeywordItem) => {
    const backend = await app.getBackend();
    return backend.createKeyword({category, value, label, accessToken});
  });
}
