import React from 'react';
import {usePagination, useTable} from 'react-table';

import {pageSizes, Pagination} from 'components/core/pagination';
import {PaginationTableInstance} from 'components/core';
import './data-grid.css';

const defaultPropGetter = (row?: any) => ({});

export const DataGrid = <D extends object>({
  columns,
  data,
  getRowProps = defaultPropGetter,
  ...tableProps
}) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {pageIndex: 0, pageSize: 10} as any
    },
    usePagination
  ) as unknown as PaginationTableInstance<D>;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    // pagination state
    ...paginationOptions
  } = tableInstance;

  const total = rows.length;
  const minPageSize = pageSizes[0];
  const showPagination = total > minPageSize;

  return (
    <>
      {showPagination && <Pagination {...paginationOptions} total={total} mb={2} />}
      <div className="handsontable">
        <table {...getTableProps()} {...tableProps}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps(getRowProps(row))}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps([
                          {
                            className: (cell.column as any).className,
                            style: (cell.column as any).style
                          }
                        ])}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {showPagination && <Pagination {...paginationOptions} total={rows.length} mt={2} />}
    </>
  );
};
