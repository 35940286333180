import kebabCase from 'lodash/kebabCase';
import dotProp from 'dot-prop';

// Ported from https://github.com/medmain/medmain-js/blob/master/packages/core/src/util.js
// TODO remove in the model hierarchy in the future?

// Transforms an object of props to an array of paths
// Example: {id: true, image: {filename: true}} => ['id', 'image.filename']
export function propsToPaths(propsOrPaths) {
  if (Array.isArray(propsOrPaths)) {
    return propsOrPaths;
  }

  if (_isObject(propsOrPaths)) {
    return _getPaths(propsOrPaths);
  }

  throw new Error(`'propsOrPaths' argument should be an array or an object`);
}

function _getPaths(props) {
  const paths = [];

  for (const [key, value] of Object.entries(props)) {
    if (_isObject(value)) {
      const subpaths = _getPaths(value);
      for (const subpath of subpaths) {
        paths.push(key + '.' + subpath);
      }
    } else if (value !== undefined) {
      paths.push(key);
    }
  }

  return paths;
}

function _isObject(value) {
  return typeof value === 'object' && value !== null && !(value instanceof Date);
}

export function describeChanges(changes) {
  const fields = [];

  const paths = propsToPaths(changes);
  for (const path of paths) {
    const field = kebabCase(path);

    let value = dotProp.get(changes, path);
    if (value instanceof Date) {
      value = value.toISOString().slice(0, 10).replace(/-/g, '/');
    } else {
      value = JSON.stringify(value);
    }

    fields.push({field, value});
  }
  return fields;
}

export function splitTags(tags) {
  tags = tags || '';
  tags = tags.split(',');
  tags = tags.map((tag) => tag.trim());
  tags = tags.filter((tag) => tag);
  return tags;
}
