import React, {useEffect, useRef} from 'react';
import {Box, BoxProps, Input, InputGroup, InputLeftElement} from '@chakra-ui/react';
import {MdSearch} from 'react-icons/md';
import invariant from 'tiny-invariant';

type Props = {
  onSearch: (value: string) => void;
  defaultValue: string;
} & BoxProps;

export const QuickSearchForm = ({onSearch, defaultValue, ...props}: Props) => {
  const ref = useRef<HTMLInputElement>();

  const onSubmit = (event) => {
    event.preventDefault();
    invariant(ref.current);
    const text = ref.current.value.trim();
    if (text) onSearch(text);
  };

  useEffect(() => {
    invariant(ref.current);
    ref.current.value = defaultValue;
  }, [defaultValue]);

  return (
    <Box as="form" {...props} onSubmit={onSubmit}>
      <InputGroup size="sm" w="300px">
        <InputLeftElement color="gray.500" children={<MdSearch fontSize="24px" />} />
        <Input
          ref={ref as React.MutableRefObject<HTMLInputElement>}
          defaultValue={defaultValue}
          variant="outline"
          placeholder="Search"
        />
      </InputGroup>
    </Box>
  );
};
