import React from 'react';

// Message to be displayed to new pathologists joining the app before their access has been changed from 0 to 2.
// Yes, we all know that the current workflow is broken!
export const NoAccess = () => {
  return (
    <div
      style={{
        margin: '0 auto',
        maxWidth: 800,
        paddingTop: '5rem',
        textAlign: 'center'
      }}
    >
      <h3 style={{fontSize: '2rem', fontWeight: 'bold', marginBottom: '1rem'}}>
        PidPort Datastore was replaced by PidPort Studio
      </h3>

      <p style={{marginBottom: '1rem'}}>
        Please click the following link to access PidPort Studio:
      </p>

      <p>
        <a
          href="https://studio.pidport.com/"
          style={{
            fontSize: '1.25rem',
            fontWeight: 'bold',
            color: 'var(--chakra-colors-primary-500)',
            textDecoration: 'underline'
          }}
        >
          {'https://studio.pidport.com/'}
        </a>
      </p>
    </div>
  );
};
