import React, {useState} from 'react';
import {Box, BoxProps, FormControl, FormLabel, HStack, Select} from '@chakra-ui/react';

import {useLocale} from 'shared';
import {AnnotationModelInput} from './annotation-model-input';
import {AILabel, AIModel} from 'components/settings/types';

type Props = {
  labelName?: string;
  onChange: (labelName: string) => void;
  models: AIModel[];
  labels: AILabel[];
  defaultModelName?: string;
  disabled?: boolean;
} & BoxProps;
export const AnnotationLabelInput = ({
  labelName,
  onChange,
  models,
  labels,
  defaultModelName,
  disabled,
  ...rest
}: Props) => {
  const locale = useLocale();
  const getInitialModelName = () => {
    if (!labelName) return defaultModelName;
    const label = labels.find((label) => label.name === labelName);
    return label?.modelName;
  };

  const [modelName, setModelName] = useState(getInitialModelName);

  const getLabelOptions = () => {
    if (!modelName) return [];
    return labels
      .filter((label) => label.modelName === modelName)
      .map(({name, displayName}) => ({
        value: name,
        label: locale.get(displayName)
      }));
  };

  const handleModelChange = (modelName) => {
    setModelName(modelName);
    handleLabelChange('');
  };

  const handleLabelChange = (labelName) => {
    onChange(labelName || null);
  };

  return (
    <HStack {...rest}>
      <Box flex={1}>
        <AnnotationModelInput
          modelName={modelName || ''}
          onChange={handleModelChange}
          models={models}
          disabled={disabled}
        />
      </Box>
      <Box flex={1}>
        <FormControl id="annotationLabel">
          <FormLabel>{locale.annotationLabelInputLabelFieldLabel}</FormLabel>
          <Select
            value={labelName || ''}
            onChange={(event) => handleLabelChange(event.currentTarget.value)}
            isDisabled={disabled}
          >
            <option key=""></option>
            {getLabelOptions().map(({value, label}) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>
    </HStack>
  );
};
