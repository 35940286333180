import {Icon} from '@chakra-ui/react';
import {IoMdPricetag} from 'react-icons/io';

import {createPlugin} from '..';
import {ImageViewerPlugin} from 'components/image-viewer/types';
import {WSILabelPanel} from './wsi-label-panel';
import {ImageContainer} from 'components/image-viewer/image-container';
import {PluginContainer} from './wsi-annotations-container';
import {ToolbarButton} from 'components/image-viewer/button';

export const WSIAnnotationsPlugin = (): ImageViewerPlugin => {
  return createPlugin({
    displayName: 'WSIAnnotationsPlugin',
    Provider(props) {
      return <PluginContainer.Provider {...props} />;
    },
    decorateSidebar: (sidebar) => {
      return [...sidebar, {key: 'WSI_GROUP', buttons: [<AddLabelButton key="wsi" />]}];
    },
    RightArea() {
      const {image} = ImageContainer.useContainer();
      const {addWSILabel, removeWSILabel, labels} = PluginContainer.useContainer();
      const allowAnnotations = true; // TODO
      return (
        <WSILabelPanel
          annotations={image.wsiAnnotations || []}
          labels={labels}
          onAddAnnotation={allowAnnotations ? addWSILabel : undefined}
          onRemoveAnnotation={allowAnnotations ? removeWSILabel : undefined}
        />
      );
    }
  });
};

const AddLabelButton = () => {
  const {addWSILabel} = PluginContainer.useContainer(); // eslint-disable-line react-hooks/rules-of-hooks
  return (
    <ToolbarButton>
      <Icon as={IoMdPricetag} fontSize="24px" onClick={addWSILabel} />
    </ToolbarButton>
  );
};
