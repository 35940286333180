import {Item} from './item';
import {Account} from './account';

export class User extends Item {
  constructor(data, options) {
    data = {...data};

    if (data.account) {
      data.account = new Account(data.account, options);
    }

    if (!data.roles) {
      data.roles = [];
    }

    super(data, options);
  }

  hasRole({name}) {
    return this.roles && Array.isArray(this.roles) && this.roles.includes(name);
  }
}
