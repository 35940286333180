import React from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import {Alert, Box, Center, Flex, Spinner} from '@chakra-ui/react';
import {useQuery} from 'react-query';

import {useApp, useLocale} from 'shared';
import {SearchOptions, useParseURL} from 'shared/search';
import {Heading1, PageContainer, RoundBackButton, SubTitle} from 'components/core';
import {useScanSearch} from 'components/scan-list/scan-list-actions';
import {SearchDescription} from 'components/scan-list/search-description';
import {ReportList} from './report-list';

export const ScanReportPage = () => {
  const history = useHistory();
  const location = useLocation();
  const locale = useLocale();
  const searchOptions = useParseURL();
  const {advancedSearch} = useScanSearch();
  const {data: report, isLoading, error} = useFetchReport(searchOptions);

  const showList = () => {
    history.push({pathname: '/scans', search: location.search});
  };

  if (isLoading)
    return (
      <Center h="100%" flexDir="column">
        <Spinner color="primary.500" size="xl" speed="1s" />
        <Box mt={8}>Please wait while we are building a report from more than 400,000 scans...</Box>
      </Center>
    );
  if (error) return <Alert status="error">Unable to load the report</Alert>;

  return (
    <PageContainer maxW="container.lg">
      <Flex align="center">
        <RoundBackButton onClick={showList} aria-label="Go back" />
        <Box ml={4}>
          <Heading1 m={0}>
            {locale.todo('Scan report')}
            <SubTitle>
              {locale.todo(`${locale.formatNumber(report.totalNumberOfScans)} scans in total`)}
            </SubTitle>
          </Heading1>
          <SearchDescription searchOptions={searchOptions} addQueryFilter={advancedSearch} mt={2} />
        </Box>
      </Flex>
      <Flex mt={3} p={6} minH={0} borderWidth="1px" bg="cardBg">
        <ReportList fields={report.fields} />
      </Flex>
    </PageContainer>
  );
};

function useFetchReport(searchOptions: SearchOptions) {
  const app = useApp();

  const loadReport = async () => {
    const accessToken = app.getAccessToken();
    const backend = await app.getBackend();
    const report = await backend.generateReport({query: searchOptions.query, accessToken});
    return report;
  };

  return useQuery(['report', searchOptions.query], loadReport, {keepPreviousData: true});
}
