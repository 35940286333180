import compact from 'lodash/compact';
import invariant from 'tiny-invariant';

type Selection = {
  mode: 'all' | 'pick' | 'omit';
  itemIds: Set<string> | undefined;
  lastToggledItemId: string | undefined;
};

export class ItemSelection {
  mode: Selection['mode'];
  itemIds: Selection['itemIds'];
  lastToggledItemId: Selection['lastToggledItemId'];

  constructor(
    selection: Selection = {
      mode: 'pick',
      itemIds: new Set(),
      lastToggledItemId: undefined
    }
  ) {
    this.mode = selection.mode;
    this.itemIds = selection.itemIds && new Set(selection.itemIds);
    this.lastToggledItemId = selection.lastToggledItemId;
  }

  toggleItem(itemId: string, selected: boolean) {
    const selection = this.clone();

    selection._toggleItem(itemId, selected);
    selection.lastToggledItemId = itemId;

    return selection;
  }

  _toggleItem(itemId, selected) {
    if (this.mode === 'all') {
      this.mode = 'omit';
      this.itemIds = new Set();
    }

    if (this.mode === 'pick') {
      invariant(this.itemIds);
      if (selected) {
        this.itemIds.add(itemId);
      } else {
        this.itemIds.delete(itemId);
      }
    } else if (this.mode === 'omit') {
      invariant(this.itemIds);
      if (!selected) {
        this.itemIds.add(itemId);
      } else {
        this.itemIds.delete(itemId);
      }
    }
  }

  toggleAllItems(selected: boolean) {
    const selection = this.clone();

    if (selected) {
      selection.mode = 'all';
      selection.itemIds = undefined;
    } else {
      selection.mode = 'pick';
      selection.itemIds = new Set();
    }

    selection.lastToggledItemId = undefined;

    return selection;
  }

  toggleItemRange(itemId, selected, allItemIds) {
    const selection = this.clone();

    let itemIndex = allItemIds.indexOf(itemId);

    let lastItemIndex = selection.lastToggledItemId
      ? allItemIds.indexOf(selection.lastToggledItemId)
      : itemIndex;

    if (itemIndex === -1 || lastItemIndex === -1) {
      return selection;
    }

    if (itemIndex < lastItemIndex) {
      [lastItemIndex, itemIndex] = [itemIndex, lastItemIndex];
    }

    for (let index = lastItemIndex; index <= itemIndex; index++) {
      const itemId = allItemIds[index];
      selection._toggleItem(itemId, selected);
    }

    selection.lastToggledItemId = itemId;

    return selection;
  }

  isItemSelected(itemId) {
    if (this.mode === 'all') {
      return true;
    }

    if (this.mode === 'pick') {
      invariant(this.itemIds);
      return this.itemIds.has(itemId);
    }

    if (this.mode === 'omit') {
      invariant(this.itemIds);
      return !this.itemIds.has(itemId);
    }

    throw new Error("'mode' is invalid");
  }

  isEmpty(totalItems) {
    if (this.mode === 'all') {
      return totalItems === 0;
    }

    if (this.mode === 'pick') {
      invariant(this.itemIds);
      return this.itemIds.size === 0;
    }

    if (this.mode === 'omit') {
      invariant(this.itemIds);
      return this.itemIds.size === totalItems;
    }

    throw new Error("'mode' is invalid");
  }

  getNumberOfItems(total: number) {
    switch (this.mode) {
      case 'all':
        return total;
      case 'pick':
        invariant(this.itemIds);
        return this.itemIds.size;
      case 'omit':
        invariant(this.itemIds);
        return total - this.itemIds.size;
    }
  }

  clone() {
    return new ItemSelection(this);
  }

  toJSON() {
    return {
      mode: this.mode,
      itemIds: this.itemIds && Array.from(this.itemIds)
    };
  }
}

// We need to preserve the order of the scans when handling CSV files
// TODO improve the implementation as this function does not take into account pagination?
export function orderItems<T extends {id: string}>(items: T[], ids: string[]): T[] {
  return compact(
    ids.map((id) => {
      const item = items.find((item) => item.id === id);
      return item;
    })
  );
}
