import React from 'react';

import {useQualityControlColumns} from './quality-control-shared';
import {QualityControlNav} from './quality-control-home';
import {QualityControlList, useQualityControlList} from './quality-control-scan-list';

export const QualityControlRejected = () => {
  const {defaultColumns, verifiedOn, rejectionReason} = useQualityControlColumns();
  const columns = [...defaultColumns, verifiedOn, rejectionReason];

  const state = useQualityControlList({status: 'REJECTED', orderBy: 'verifiedOn'});

  return (
    <>
      <QualityControlNav tabIndex={2} />
      <QualityControlList columns={columns} {...state} />
    </>
  );
};
