import {PageContainer} from 'components/core';
import {SettingsNavigator} from '../settings-shared';
import {KeywordManagement} from './keywords-manage';

export const SuppliersPage = () => {
  return (
    <PageContainer maxW="container.xl">
      <SettingsNavigator currentTab={'suppliers'} />
      <KeywordManagement heading={'Suppliers'} category="supplier" />
    </PageContainer>
  );
};

export const OrgansPage = () => {
  return (
    <PageContainer maxW="container.xl">
      <SettingsNavigator currentTab={'organs'} />
      <KeywordManagement heading={'Organs'} category="organ" />
    </PageContainer>
  );
};

export const SpecimenTypePage = () => {
  return (
    <PageContainer maxW="container.xl">
      <SettingsNavigator currentTab={'specimens'} />
      <KeywordManagement heading={'Types of specimen'} category="specimenType" />
    </PageContainer>
  );
};

export const StainingPage = () => {
  return (
    <PageContainer maxW="container.xl">
      <SettingsNavigator currentTab={'staining'} />
      <KeywordManagement heading={'Staining'} category="staining" />
    </PageContainer>
  );
};
