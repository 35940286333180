import {useState} from 'react';
import {useEffectOnce, useLatest} from 'react-use';
import {createContainer} from 'unstated-next';
import debugModule from 'debug';

import {ImageContainer} from 'components/image-viewer/image-container';

const debug = debugModule('medmain:image-viewer:scalebar-plugin');

type ScaleSetting = [numberOfCentimeters: number, unit: string];

const SCALE_BAR_MAX_WIDTH = 200;
const SCALES: ScaleSetting[] = [
  [1, '1 cm'],
  [2, '5 mm'],
  [5, '2 mm'],
  [10, '1 mm'],
  [20, '500 µm'],
  [50, '200 µm'],
  [100, '100 µm'],
  [200, '50 µm'],
  [500, '20 µm'],
  [1000, '10 µm'],
  [2000, '5 µm'],
  [5000, '2 µm'],
  [10000, '1 µm'],
  [20000, '500 nm'],
  [50000, '200 nm'],
  [100000, '100 nm'],
  [200000, '50 nm'],
  [500000, '20 nm'],
  [1000000, '10 nm'],
  [2000000, '5 nm'],
  [5000000, '2 nm'],
  [10000000, '1 nm']
];

function useScaleBarPluginState() {
  const {getViewer, image} = ImageContainer.useContainer();
  const cmPerDot = 1 / getDotsPerCentimeter(image);
  const [scaleBar, setScaleBar] = useState<ScaleSetting>();
  const latestImage = useLatest(image);

  useEffectOnce(() => {
    const viewer = getViewer();
    viewer.addHandler('zoom', handleZoom);
  });

  const handleZoom = () => {
    const {viewport} = getViewer();
    const viewportBounds = viewport.getBounds();
    const containerWidth = viewport.getContainerSize().x;
    const imageWidth = latestImage.current.info.dimensions.width;
    const boundWidthAsCm = imageWidth * viewportBounds.width * cmPerDot;

    let pixelsPerCm = containerWidth / boundWidthAsCm;
    for (let i = 0; i < SCALES.length; i++) {
      const [factor, unit] = SCALES[i];
      const pixelsPerUnit = pixelsPerCm / factor;
      if (pixelsPerUnit < SCALE_BAR_MAX_WIDTH) {
        debug('Set scale bar', [pixelsPerUnit, unit]);
        setScaleBar([pixelsPerUnit, unit]);
        break;
      }
    }
  };

  return {scaleBar};
}

export const ScaleBarPluginContainer = createContainer(useScaleBarPluginState);

function getDotsPerCentimeter(image: Datastore.Image) {
  const {x, unit} = image.info.resolution;

  if (!hasCorrectResolutionData(image)) return getDefaultDPC(image);

  return unit === 'DPC' ? x : x / 2.54;
}

// The resolution was not correctly extracted for images before 2021
// +94,000 images with 25.4 DPI
// +12,000 images with 10 DPI
function hasCorrectResolutionData(image: Datastore.Image) {
  const {x, unit} = image.info.resolution;
  if (!x || !unit) return false;
  if (unit === 'DPI' && x === 25.4) return false;
  if (unit === 'DPC' && x === 10) return false;
  return true;
}

// TODO improve the heuristic to infer the magnification of the image?
// The best would be to search for scans with  `zoomLevel = 40` and update the magnification in the related `image`
function getDefaultDPC(image: Datastore.Image) {
  const isX40 = image.info.magnification === 40 || image.filename.includes('x40');
  return isX40 ? 40000 : 20000;
}
