import React from 'react';
import sortBy from 'lodash/sortBy';
import {Box, Checkbox, Divider, Flex, HStack} from '@chakra-ui/react';

import {useLocale} from 'shared';
import {AddIcon, RemoveIcon} from 'components/core';
import {
  Panel,
  PanelHeader,
  PanelHeading1,
  PanelHeading2,
  PanelFooter,
  PanelDivider,
  PanelButton,
  PanelIconButton
} from 'components/image-viewer/panel';

type Props = {
  annotations: Datastore.Annotation[];
  labels: any[];
  onAddLabel: (value: any) => void;
  onRemoveLabel: (value: any) => void;
  onSelectLabel: (value: any) => void;
  onToggleLabel: (value: any) => void;
  onMarkAllAsVerified: () => void;
  showVerificationBar: boolean;
};

export const AnnotationPanel = ({
  annotations = [],
  labels,
  onAddLabel,
  onMarkAllAsVerified,
  onRemoveLabel,
  onSelectLabel,
  onToggleLabel,
  showVerificationBar
}: Props) => {
  const locale = useLocale();
  return (
    <Panel>
      <PanelHeader style={{display: 'flex', justifyContent: 'space-between'}}>
        <PanelHeading1>{locale.annotationPanelHeading}</PanelHeading1>
        <PanelIconButton onClick={onAddLabel} aria-label="Add">
          <AddIcon fontSize="12px" />
        </PanelIconButton>
      </PanelHeader>
      <LabelGroup
        annotations={annotations}
        labels={labels}
        showVerificationBar={showVerificationBar}
        onRemoveLabel={onRemoveLabel}
        onSelectLabel={onSelectLabel}
        onToggleLabel={onToggleLabel}
      />
      <VerificationBar
        annotations={annotations}
        showVerificationBar={showVerificationBar}
        onMarkAllAsVerified={onMarkAllAsVerified}
      />
    </Panel>
  );
};

const LabelGroup = ({
  annotations,
  labels,
  showVerificationBar,
  onRemoveLabel,
  onSelectLabel,
  onToggleLabel
}) => {
  const locale = useLocale();
  let currentModel;

  const getAnnotationCountByLabel = (labelName) => {
    const foundAnnotations = annotations.filter((annotation) => annotation.labelName === labelName);
    const verifiedAnnotations = foundAnnotations.filter((annotation) => annotation.verifiedOn);
    return {
      verified: verifiedAnnotations.length,
      unverified: foundAnnotations.length - verifiedAnnotations.length
    };
  };

  return (
    <Box alignItems="initial" px={2} py={0}>
      {sortBy(labels, 'order').map((label) => {
        if (!label.model) {
          return null; // don't display anything if the model was not found
        }
        let heading;
        let separator;
        if (label.model !== currentModel) {
          heading = (
            <PanelHeading2 mt={2} mb={2}>
              {locale.get(label.model.displayName)}
            </PanelHeading2>
          );
          currentModel = label.model;
        } else {
          separator = <PanelDivider />;
        }
        const counts = getAnnotationCountByLabel(label.name);
        return (
          <React.Fragment key={label.name}>
            {heading}
            {separator}
            <AnnotationLabel
              label={label}
              onRemove={onRemoveLabel}
              onSelect={onSelectLabel}
              onToggle={onToggleLabel}
              counts={showVerificationBar ? counts : undefined}
            />
          </React.Fragment>
        );
      })}
    </Box>
  );
};

const VerificationBar = ({annotations, showVerificationBar, onMarkAllAsVerified}) => {
  const locale = useLocale();

  if (!showVerificationBar) {
    return null;
  }

  const verifiedAnnotations = annotations.filter((annotation) => annotation.verifiedOn);
  const numberOfVerifiedAnnotations = verifiedAnnotations.length;
  const numberOfUnverifiedAnnotations = annotations.length - numberOfVerifiedAnnotations;
  return (
    <PanelFooter
      style={{
        marginTop: '.5rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline'
      }}
    >
      <Flex alignItems="center">
        <div>
          <span
            style={{
              color: 'rgba(255, 255, 255, 0.8)' // t.inverseAltTextColor
            }}
          >
            {locale.annotationPanelVerifiedCountLabel}{' '}
          </span>
          <strong>{numberOfVerifiedAnnotations}</strong>
        </div>
        <Divider orientation="vertical" mx={2} borderColor="white" height="20px" />
        <div>
          <span style={{color: 'rgba(255, 255, 255, 0.8)'}}>
            {locale.annotationPanelUnverifiedCountLabel}{' '}
          </span>
          <strong>{numberOfUnverifiedAnnotations}</strong>
        </div>
      </Flex>
      <PanelButton onClick={onMarkAllAsVerified} disabled={!numberOfUnverifiedAnnotations}>
        {locale.annotationPanelMarkAllAsVerifiedButtonLabel}
      </PanelButton>
    </PanelFooter>
  );
};

const AnnotationLabel = ({label, counts, onSelect, onToggle, onRemove}) => {
  const locale = useLocale();
  const borderStyle = '1px solid rgba(255, 255, 255, 0.5)';

  const handleRemove = () => {
    onRemove(label);
  };

  const handleSelect = () => {
    onSelect(label);
  };

  const handleToggle = () => {
    onToggle(label);
  };

  return (
    <HStack>
      <Box lineHeight="1">
        <Checkbox isChecked={label.isActive} onChange={handleToggle} />
      </Box>
      <Box
        w="5px"
        bg={label.color}
        display="block"
        alignSelf="stretch"
        borderLeft={borderStyle}
        borderRight={borderStyle}
      />
      <Box
        onClick={handleSelect}
        color={label.isCurrent ? undefined : 'rgba(255, 255, 255, 0.8)'} // t.inverseAltTextColor,
        fontWeight={label.isCurrent ? 600 : undefined}
        textDecoration={label.isCurrent ? 'underline' : undefined}
        flexGrow={3}
        flexBasis="4.25rem"
        wordBreak="break-word"
        cursor="pointer"
      >
        {locale.get(label.displayName)}
        {counts && (
          <Box as="span" color="rgba(255, 255, 255, 0.5)">
            {' ('}
            {counts.verified}/{counts.unverified}
            {')'}
          </Box>
        )}
      </Box>
      <Box>
        <PanelIconButton onClick={handleRemove} aria-label="Remove">
          <RemoveIcon fontSize="12px" />
        </PanelIconButton>
      </Box>
    </HStack>
  );
};
