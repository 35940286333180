import {useQuery} from 'react-query';

import {useApp} from 'shared';
import {Prediction} from 'models/prediction';

export function useFetchPredictions(searchOptions) {
  const app = useApp();
  const {offset, limit} = searchOptions;

  const loadPredictions = async () => {
    const imagesBackend = await app.getImagesBackend();
    const accessToken = app.getAccessToken();

    let {predictions, totalNumberOfPredictions} = await imagesBackend.findPredictions({
      productId: 'pidport-datastore',
      offset,
      limit,
      accessToken
    });

    predictions = predictions.map((prediction) => new Prediction(prediction, {deserialize: true}));
    return {predictions, totalNumberOfPredictions};
  };

  return useQuery(['predictions', searchOptions], loadPredictions, {
    keepPreviousData: false,
    cacheTime: 1000 * 60 * 60 // override the default duration of 5 minutes => 1 hour
  });
}
