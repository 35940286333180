import React, {useState} from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import Dropzone from 'react-dropzone';
import {
  Box,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Checkbox
} from '@chakra-ui/react';
import {useUpdate} from 'react-use';

import {subscribeToApp, useApp, useLocale} from 'shared';
import {Button, FileChooser, SubTitle, useModal} from 'components/core';
import {ScanForm} from 'components/scan-form';
import {FileList} from './file-list';
import {ToBeVerifiedIcon} from 'components/quality-control/quality-control-shared';

const contentHeight = 500;

// TODO: refactor the state management strategy, getting rid of Ministate
export const DialogAddScan = subscribeToApp(({onClose}) => {
  const app = useApp();
  const locale = useLocale();
  const modal = useModal();
  const update = useUpdate();
  const {addedImages, scanSeedValues, batchNumber} = app.state;
  const [step, setStep] = useState(() => (addedImages.length === 0 ? 1 : 2));

  const changeScanSeedValues = (field, value) => {
    // to keep the form responsive when typing fast, we mutate the global state and force an update
    set(scanSeedValues, field, value === null ? undefined : value);
    update(); // equivalent to `this.forceUpdate()` with React classes
  };

  const clearScanSeedValues = () => {
    app.setState({scanSeedValues: app.state.scanSeedValues.clear()});
  };

  const close = async () => {
    const uncompletedUploadImages: any[] = [];
    const failedUploadImages: any[] = [];
    for (const image of addedImages) {
      if (image.status === 'UPLOAD_FAILED') {
        failedUploadImages.push(image);
      } else if (image.status !== 'UPLOADED') {
        uncompletedUploadImages.push(image);
      }
    }

    if (uncompletedUploadImages.length) {
      await modal.alert(locale.todo('Some images are still being uploaded. Thanks for waiting.'), {
        title: locale.errorDialogTitle
      });
      return;
    }

    if (failedUploadImages.length) {
      const retry = await modal.confirm(locale.todo('Some image uploads have failed.'), {
        // TODO: Add "You should restart or cancel them."
        title: locale.warningDialogTitle,
        okButton: locale.todo('Retry'),
        cancelButton: locale.todo('Discard')
      });
      if (retry) {
        for (const image of failedUploadImages) {
          await app.startOrRetryUploadingImage(image);
        }
        return;
      }
    }

    app.completeAddingImages();
    onClose();
  };

  return step === 1 ? (
    <Step1
      scanSeedValues={scanSeedValues}
      changeScanSeedValues={changeScanSeedValues}
      clearScanSeedValues={clearScanSeedValues}
      continueToStep2={() => setStep(2)}
      close={close}
    />
  ) : (
    <Step2
      images={addedImages}
      batchNumber={batchNumber}
      goBackToStep1={() => setStep(1)}
      close={close}
    />
  );
});

const SHOULD_BE_VERIFIED_STATUS: Datastore.Scan['status'] = 'TO_BE_VERIFIED';

const Step1 = ({
  scanSeedValues,
  changeScanSeedValues,
  clearScanSeedValues,
  close,
  continueToStep2
}) => {
  const locale = useLocale();
  const shouldBeVerified = scanSeedValues.status === SHOULD_BE_VERIFIED_STATUS;

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        continueToStep2();
      }}
    >
      <ModalHeader>
        {locale.todo('Add scans')}
        <SubTitle>{locale.todo('Step 1')}</SubTitle>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Box
          overflowY="scroll"
          overflowX="auto"
          h={contentHeight}
          borderWidth="1px"
          borderRadius="4px"
        >
          <Box
            borderBottomWidth="1px"
            py={2}
            px={4}
            bg={shouldBeVerified ? 'primary.50' : undefined}
          >
            <Flex justifyContent="space-between" align="center">
              <Box>
                <Checkbox
                  isChecked={shouldBeVerified}
                  onChange={(event) => {
                    const value = event.target.checked ? SHOULD_BE_VERIFIED_STATUS : null;
                    changeScanSeedValues('status', value);
                  }}
                >
                  The images should be verified
                </Checkbox>
              </Box>
              <Box>
                <ToBeVerifiedIcon
                  fontSize={'24px'}
                  mr={1}
                  color={shouldBeVerified ? 'primary.200' : 'gray.300'}
                />
                <Box as="span" fontSize="sm" color={shouldBeVerified ? 'primary.300' : 'gray.500'}>
                  Quality Control
                  {shouldBeVerified ? ' ON' : ' OFF'}
                </Box>
              </Box>
            </Flex>
          </Box>
          <Box p={4}>
            <ScanForm
              getValue={(field) => get(scanSeedValues, field)}
              setValue={(field, value) => changeScanSeedValues(field, value)}
              excludeFields={['image.filename', 'reference']}
            />
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Flex w="100%">
          <Box flexGrow={1}>
            <Button
              type="button"
              onClick={clearScanSeedValues}
              isDisabled={scanSeedValues.isEmpty()}
            >
              {locale.todo('Clear')}
            </Button>
          </Box>
          <Box>
            <Button type="button" onClick={close} mr={2}>
              {locale.cancelButtonLabel}
            </Button>
            <Button type="submit" primary>
              {locale.continueButtonLabel}
            </Button>
          </Box>
        </Flex>
      </ModalFooter>
    </form>
  );
};

const Step2 = ({
  images,
  batchNumber,
  goBackToStep1,
  close
}: {
  images: any[];
  batchNumber: number;
  goBackToStep1: () => void;
  close: () => void;
}) => {
  const locale = useLocale();
  const app = useApp();
  const fileChooserRef = React.useRef<FileChooser>();
  const {validateImageFile} = useValidateImageFile();

  const addImages = async (files) => {
    for (const file of files) {
      if (!(await validateImageFile(file))) {
        return;
      }
    }

    await app.startBatchUpload(files); // for notification and logging purpose in the backend

    for (const file of files) {
      await app.addImage(file);
    }
  };
  return (
    <>
      <ModalHeader>
        <Flex justifyContent="space-between">
          <Box>
            {locale.todo('Add scans')}
            <SubTitle>{locale.todo('Step 2')}</SubTitle>
          </Box>
          <Box>
            {batchNumber && (
              <Box color="gray.500">Batch {locale.formatBatchNumber(batchNumber)}</Box>
            )}
          </Box>
        </Flex>
      </ModalHeader>
      <ModalBody>
        <Box display="flex" h={contentHeight}>
          <Dropzone onDrop={addImages}>
            {({getRootProps, getInputProps, isDragActive}) => (
              <Box
                w="100%"
                borderWidth="1px"
                p={3}
                overflowY="scroll"
                {...getRootProps()}
                outlineColor="primary.500"
              >
                {images.length > 0 && <FileList images={images} />}

                {images.length === 0 && (
                  <Flex h="100%" alignItems="center" justifyContent="center">
                    <Box color="gray.500">
                      {locale.todo('Drop some image files or click the button below.')}
                    </Box>
                  </Flex>
                )}
              </Box>
            )}
          </Dropzone>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Flex w="100%">
          <Box flexGrow={1}>
            <Button onClick={() => (fileChooserRef.current as any).open()}>
              {locale.todo('＋ Add images')}
            </Button>
          </Box>
          <Box>
            <Button onClick={goBackToStep1} isDisabled={images.length > 0} mr={2}>
              {locale.backButtonLabel}
            </Button>
            <Button onClick={close} primary>
              {locale.todo('Done')}
            </Button>
          </Box>
        </Flex>
      </ModalFooter>

      <FileChooser
        ref={fileChooserRef as React.MutableRefObject<FileChooser>}
        onChoose={addImages}
        acceptedTypes={app.getAcceptedImageTypes()}
        acceptedExtensions={app.getAcceptedImageExtensions()}
      />
    </>
  );
};

const useValidateImageFile = () => {
  const locale = useLocale();
  const modal = useModal();
  const app = useApp();

  const validateImageFile = async (file) => {
    const extension = getFileExtension(file.name).toLowerCase();
    const acceptedTypes = app.getAcceptedImageTypes();
    const acceptedExtensions = app.getAcceptedImageExtensions();
    const maximumImageSize = app.getMaximumImageSize();

    if (!(acceptedTypes.includes(file.type) || acceptedExtensions.includes(extension))) {
      await modal.alert(locale.imageImportUnsupportedFormatAlert({filename: file.name}), {
        title: locale.errorDialogTitle
      });
      return false;
    }

    if (file.size > maximumImageSize) {
      await modal.alert(
        locale.imageImportMaximumSizeExceededAlert({filename: file.name, limit: maximumImageSize}),
        {
          title: locale.errorDialogTitle
        }
      );
      return false;
    }

    return true;
  };

  return {validateImageFile};
};

export function getFileExtension(filename) {
  var index = filename.lastIndexOf('.');

  if (index !== -1) {
    return filename.slice(index);
  }

  return '';
}
