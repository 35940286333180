import {OwnedItem} from 'models/owned-item';
import {Image} from 'models/image';
export class Scan extends OwnedItem {
  constructor(data, {deserialize, ...otherOptions} = {}) {
    data = {...data};

    if (deserialize && data.scannedOn) {
      data.scannedOn = new Date(data.scannedOn);
    }

    if (deserialize && data.collectedOn) {
      data.collectedOn = new Date(data.collectedOn);
    }

    if (deserialize && data.diagnosedOn) {
      data.diagnosedOn = new Date(data.diagnosedOn);
    }

    if (deserialize && data.deletedOn) {
      data.deletedOn = new Date(data.deletedOn);
    }

    data.image = new Image(data.image, {deserialize, ...otherOptions});

    super(data, {deserialize, ...otherOptions});
  }
}

export default Scan;
