import isObjectLike from 'lodash/isObjectLike';

export const common = {
  get(locales) {
    if (!isObjectLike(locales)) {
      return locales;
    }

    let result = locales[this.$id];

    if (result === undefined) {
      const firstKey = Object.keys(locales)[0];
      result = locales[firstKey];
    }

    return result;
  },

  todo(text) {
    return text;
  }
};

export default common;
