import {Model} from './model';

export class Item extends Model {
  constructor(data, {deserialize, ...otherOptions} = {}) {
    data = {...data};

    if (deserialize && data.createdOn) {
      data.createdOn = new Date(data.createdOn);
    }

    if (deserialize && data.updatedOn) {
      data.updatedOn = new Date(data.updatedOn);
    }

    super(data, {deserialize, ...otherOptions});
  }
}
