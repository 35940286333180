import React from 'react';
import {Box, BoxProps, Heading, HeadingProps, chakra} from '@chakra-ui/react';

export const Heading1 = (props: HeadingProps) => {
  return <Heading fontSize="1.5625rem" color="gray.600" fontWeight="normal" mb={4} {...props} />;
};

export const Heading2 = (props: HeadingProps) => {
  return <Heading size="md" fontWeight="normal" mb={4} {...props} />;
};

export const Heading3 = (props: HeadingProps) => {
  return <Heading size="sm" fontWeight="normal" mb={4} {...props} />;
};

export const SubTitle = (props: BoxProps) => (
  <Box as="span" ml={3} fontSize="1rem" color="gray.500" {...props} />
);

export const PageContainer = chakra(Box, {
  baseStyle: {
    w: '100%',
    mx: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'hidden'
  }
});
