import debugModule from 'debug';

import {useModal} from 'components/core';
import {useLocale} from 'shared';
import {
  areTheSamePoint,
  concatenatePaths,
  getConnectionPoint,
  erasePoints
} from 'models/annotation';
import {Overlay} from 'components/image-viewer/openseadragon';
import {AnnotationPath} from 'components/annotations/annotation-view';
import {ImageViewerPlugin, PluginSettings} from 'components/image-viewer/types';
import {ImageContainer, PANNING_MODE} from 'components/image-viewer/image-container';
import {createPlugin} from 'components/image-viewer/plugins';
import {Annotator} from './annotator';
import {Eraser} from './eraser';
import {AnnotationPanel} from './annotation-panel';
import {AnnotationsContainer} from './annotation-container';
import {useAnnotationMutation} from './annotation-api';
import {EraserModeButton, PanningModeButton, PenModeButton} from './annotation-buttons';
import {editCursor, eraserCursor} from './cursors';

const debug = debugModule('medmain:annotations');

const ANNOTATING_MODE = 'annotating';
const ERASING_MODE = 'erasing';

const ANNOTATOR_PRECISION = 2; // 0.00 -> 100.00

const annotationStyle = {
  fill: 'none',
  strokeOpacity: 0.8,
  strokeWidth: '3px'
};

interface AnnotationPluginSettings extends PluginSettings {
  canDrawAnnotation?: boolean;
  canEraseAnnotation?: boolean;
  canVerifyAnnotation?: boolean;
}

export const AnnotationPlugin = (settings?: AnnotationPluginSettings): ImageViewerPlugin => {
  const {
    canDrawAnnotation = true,
    // canEraseAnnotation = true,
    canVerifyAnnotation = true
  } = settings || {};

  return createPlugin({
    displayName: 'AnnotationPlugin',
    decorateSidebar: (sidebar) => [
      ...sidebar,
      {
        key: 'ANNOTATION_GROUP',
        buttons: [
          <PanningModeButton key="panning" />,
          <PenModeButton key="pen" />,
          <EraserModeButton key="eraser" />
        ]
      }
    ],
    modes: [
      {
        key: ANNOTATING_MODE,
        cursor: `url('${editCursor}') 3 21, cell`
      },
      {
        key: ERASING_MODE,
        cursor: `url('${eraserCursor}') 9 21, crosshair`
      }
    ],
    Provider({children}) {
      return <AnnotationsContainer.Provider children={children} />;
    },
    MainArea() {
      const {image, mode, isQuickPanning} = ImageContainer.useContainer();
      const {
        annotationLabels,
        activeLabelNames,
        currentAnnotationLabel,
        editAnnotationDetails,
        hasCurrentAnnotationLabel
      } = AnnotationsContainer.useContainer();
      const annotations = image.annotations!;

      const {
        addAnnotation,
        updateAnnotation,
        mergeAnnotations,
        splitAnnotations,
        deleteAnnotation
      } = useAnnotationMutation(image.id);

      const isErasing = mode === ERASING_MODE;
      const isAnnotating = mode === ANNOTATING_MODE && currentAnnotationLabel;
      const activeAnnotations = annotations.filter((annotation) =>
        hasCurrentAnnotationLabel(annotation)
      );
      const inactiveAnnotations = annotations.filter(
        (annotation) => !hasCurrentAnnotationLabel(annotation)
      );

      const renderedAnnotations = isErasing ? inactiveAnnotations : annotations;

      const eraserPaths = activeAnnotations.map((annotation) => annotation.path);

      const handles = getAnnotationHandles(annotations);

      function getAnnotationHandles(annotations: Datastore.Annotation[]) {
        const isClosedShape = ({path}) => areTheSamePoint(path[0], path[path.length - 1]);

        const canEditPath = (annotation: Datastore.Annotation) =>
          hasCurrentAnnotationLabel(annotation) &&
          isActiveAnnotationLabel(annotation) &&
          !isClosedShape(annotation);

        const handles = annotations
          .filter(canEditPath)
          .reduce((acc: Datastore.Annotation['path'], {path}) => {
            const firstPoint = path[0];
            const lastPoint = path[path.length - 1];
            return [...acc, firstPoint, lastPoint];
          }, []); // `Annotator` component only need a "flat" array of end-points

        return handles as unknown as Datastore.Annotation['path'];
      }

      function isActiveAnnotationLabel({labelName}) {
        return activeLabelNames?.includes(labelName);
      }

      const handleEndAnnotating = async ({
        path: addedPoints
      }: {
        path: Datastore.Annotation['path'];
      }) => {
        const connectedAnnotations = getConnectedAnnotations(addedPoints);

        if (connectedAnnotations.length === 0) {
          debug('Add annotation', addedPoints.length);
          const label = getCurrentAnnotationLabel();
          await addAnnotation.mutateAsync({labelName: label!.name, path: addedPoints});
        } else if (connectedAnnotations.length === 1) {
          const annotation = connectedAnnotations[0];
          const path = concatenatePaths([annotation.path, addedPoints]);
          debug('Update annotation', annotation.path.length, addedPoints.length, '=>', path.length);
          await updateAnnotation.mutateAsync({id: annotation.id, changes: {path}});
        } else if (connectedAnnotations.length === 2) {
          const existingPaths = connectedAnnotations.map(({path}) => path);
          const path = concatenatePaths([existingPaths[0], addedPoints, existingPaths[1]]);
          debug('Merging 2 annotations', '=>', path.length);
          await mergeAnnotations.mutateAsync({
            updatedId: connectedAnnotations[0].id,
            deletedId: connectedAnnotations[1].id,
            path
          });
        }
      };

      function getCurrentAnnotationLabel() {
        return annotationLabels.find((label) => label.isCurrent);
      }

      const getConnectedAnnotations = (path) => {
        const getEndpoints = (points) => [points[0], points[points.length - 1]];

        const endpoints = getEndpoints(path);

        return annotations.filter(({path}) => {
          const existingEndpoints = getEndpoints(path);
          const areConnectedPaths = Boolean(getConnectionPoint(existingEndpoints, endpoints));
          return areConnectedPaths;
        });
      };

      const handleEndErasing = async ({erasedPoints}) => {
        debug(`${erasedPoints.length} points to erase`);
        const activeAnnotations = annotations.filter(hasCurrentAnnotationLabel);
        for (const annotation of activeAnnotations) {
          await eraseAnnotationPoints(annotation, erasedPoints);
        }
      };

      const eraseAnnotationPoints = async (
        annotation: Datastore.Annotation,
        points: Datastore.Annotation['path']
      ) => {
        if (points.length === 0) {
          return;
        }
        const areTheSamePath = (a, b) => a.length === b.length; // we don't need to compare every point (very small optimization!)
        const paths = erasePoints(annotation.path, points);

        if (paths.length === 0) {
          debug(`Delete the whole annotation ${annotation.id}`);
          await deleteAnnotation.mutateAsync(annotation.id);
        } else if (paths.length === 1) {
          if (!areTheSamePath(annotation.path, paths[0])) {
            debug(
              `Shorten the annotation ${annotation.id}, ${annotation.path.length} => ${paths[0].length} points`
            );
            await updateAnnotation.mutateAsync({id: annotation.id, changes: {path: paths[0]}});
          }
        } else {
          debug(
            `Split ${annotation.id} into ${paths.length} parts`,
            annotation.path.length,
            '=>',
            paths.map((path) => path.length)
          );
          const {id, labelName} = annotation;
          const [updatedPath, ...addedPaths] = paths;
          await splitAnnotations.mutateAsync({
            annotationId: id,
            labelName,
            updatedPath,
            addedPaths
          });
        }
      };

      // Create a single click event handler used by all annotations that
      // expects the OpenSeadragon custom event object
      // instead of creating multiple inline handlers `onClick={annotation => {}}`
      // TODO: more test to see if this optimization is necessary.
      const handleAnnotationClick = async ({originalEvent}) => {
        const pathElement = originalEvent.target;
        const {id} = pathElement;
        if (!id) {
          throw new Error(`No "id" assigned to the selected path!`);
        }
        const selectedAnnotation = image.annotations!.find((annotation) => annotation.id === id);
        if (!selectedAnnotation) {
          throw new Error(`Annotation not found: ${id}`);
        }
        editAnnotationDetails(selectedAnnotation.id);
      };

      return [
        isAnnotating ? (
          <Overlay key="annotator">
            <Annotator
              onEnd={handleEndAnnotating}
              imageDimensions={image.info.dimensions}
              precision={ANNOTATOR_PRECISION}
              annotationColor={currentAnnotationLabel.color}
              annotationStyle={annotationStyle}
              handles={handles}
              disabled={isQuickPanning}
            />
          </Overlay>
        ) : null,
        isErasing ? (
          <Overlay key="eraser">
            <Eraser
              paths={eraserPaths}
              onEnd={handleEndErasing}
              imageDimensions={image.info.dimensions}
              precision={ANNOTATOR_PRECISION}
              annotationStyle={{
                ...annotationStyle,
                stroke: currentAnnotationLabel && currentAnnotationLabel.color
              }}
              disabled={isQuickPanning}
            />
          </Overlay>
        ) : null,
        <Overlay key="annotations">
          <svg
            width={'100%'}
            height={'100%'}
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            version="1.2"
            xmlns="http://www.w3.org/2000/svg"
          >
            {renderedAnnotations.map((annotation, index) => {
              const label = annotationLabels.find((label) => label.name === annotation.labelName);
              if (!label!.isActive) {
                return null;
              }
              const isInactive =
                ['annotating', 'erasing'].includes(mode) && !hasCurrentAnnotationLabel(annotation);
              return (
                <AnnotationPath
                  key={index}
                  id={annotation.id}
                  path={annotation.path}
                  onClick={
                    canDrawAnnotation && mode === PANNING_MODE ? handleAnnotationClick : undefined
                  }
                  style={{
                    ...annotationStyle,
                    stroke: label!.color,
                    strokeOpacity: isInactive ? 0.4 : annotationStyle.strokeOpacity,
                    strokeDasharray:
                      canVerifyAnnotation && !annotation.verifiedOn ? '3,6' : undefined
                  }}
                />
              );
            })}
          </svg>
        </Overlay>
      ];
    },

    RightArea() {
      const {
        activeLabelNames,
        annotationLabels,
        addLabel,
        currentLabelName,
        setCurrentLabelName,
        toggleLabel
      } = AnnotationsContainer.useContainer();
      const showVerificationBar = true; // FIXME
      const modal = useModal();
      const locale = useLocale();
      const {image} = ImageContainer.useContainer();
      const {deleteAnnotationsByLabel, markAllAsVerified} = useAnnotationMutation(image.id);
      const annotations = image.annotations;

      function toggleLabelName(labelName: string) {
        const isActive = (activeLabelNames || []).includes(labelName);
        const nextLabelNames = isActive
          ? (activeLabelNames || []).filter((name) => name !== labelName)
          : [...(activeLabelNames || []), labelName];
        toggleLabel(nextLabelNames);
      }

      const handleRemoveAnnotationLabel = async (label) => {
        const okay = await modal.confirm(
          locale.imageViewerDeleteAnnotationsByLabelConfirm({
            displayName: locale.get(label.displayName)
          }),
          {
            title: locale.warningDialogTitle,
            okButton: locale.deleteButtonLabel
          }
        );

        if (okay) {
          deleteAnnotationsByLabel.mutateAsync({labelName: label.name});
        }
      };

      const handleMarkAllAnnotationsAsVerified = () => {
        markAllAsVerified.mutate();
      };

      if (!annotations!.length && !currentLabelName) return null;

      return (
        <AnnotationPanel
          annotations={annotations!}
          labels={annotationLabels}
          onAddLabel={addLabel}
          onRemoveLabel={handleRemoveAnnotationLabel}
          onSelectLabel={(label) => setCurrentLabelName(label.name)}
          onToggleLabel={(label) => toggleLabelName(label.name)}
          showVerificationBar={showVerificationBar}
          onMarkAllAsVerified={handleMarkAllAnnotationsAsVerified}
        />
      );
    }
  });
};
