import {useMemo} from 'react';
import {Box, Button, Code, Icon, IconButton, Link, HStack} from '@chakra-ui/react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import {FaTrashRestoreAlt} from 'react-icons/fa';

import {DeleteIcon, EditIcon, formatDateTime, Table, TimeAgo} from 'components/core';
import {formatSearchParams, KeywordCategory, KeywordItem} from 'shared';

type Props = {
  category: KeywordCategory;
  keywords: KeywordItem[];
  onEdit?: (item: KeywordItem) => void;
  onDelete?: (item: KeywordItem) => void;
  onRestore?: (item: KeywordItem) => void;
};
export const KeywordList = ({category, keywords, onEdit, onDelete, onRestore}: Props) => {
  const location = useLocation();
  const columns = useMemo(
    () => [
      {
        Header: 'Code',
        accessor: (item: KeywordItem) => <Code>{item.value}</Code>
      },
      {
        Header: 'Label',
        accessor: (item: KeywordItem) => item.label
      },
      {
        Header: 'Links',
        accessor: (item: KeywordItem) => {
          const query = [{field: category, value: item.value, operator: 'is'}];
          const searchParams = formatSearchParams({query});
          return (
            <Link as={RouterLink} to={{...location, pathname: '/scans', search: searchParams}}>
              View scans
            </Link>
          );
        }
      },
      {
        Header: 'Created at',
        accessor: (item: KeywordItem) => {
          return <PrettyDate date={new Date(item.createdOn)} />;
        }
      },
      {
        Header: 'Updated at',
        accessor: (item: KeywordItem) => {
          if (item.createdOn === item.updatedOn) return <Box color="gray.500">--</Box>;
          return <PrettyDate date={new Date(item.updatedOn)} />;
        }
      },
      {
        Header: 'Actions',
        accessor: (item: KeywordItem) => {
          const isDeleted = !!item.deletedOn;
          const canUpdateItem = !!onEdit && !isDeleted;
          const canDeleteItem = !!onDelete && !isDeleted;
          const canRestoreItem = !!onRestore && isDeleted;
          if (!canUpdateItem && !canDeleteItem && !canRestoreItem) return '--';
          return (
            <HStack justifyContent="center">
              {canDeleteItem && (
                <IconButton
                  size="sm"
                  onClick={() => onDelete(item)}
                  colorScheme="red"
                  aria-label="Delete"
                  borderRadius="md"
                  icon={<DeleteIcon />}
                />
              )}
              {canUpdateItem && (
                <Button size="sm" onClick={() => onEdit(item)} rightIcon={<EditIcon />}>
                  Edit
                </Button>
              )}
              {canRestoreItem && (
                <Button
                  size="sm"
                  onClick={() => onRestore(item)}
                  rightIcon={<Icon as={FaTrashRestoreAlt} />}
                >
                  Restore
                </Button>
              )}
            </HStack>
          );
        },
        styleProps: {
          textAlign: 'center'
        }
      }
    ],
    [] //eslint-disable-line react-hooks/exhaustive-deps
  );

  function getRowProps(row) {
    const item = row.original as KeywordItem;
    if (item.deletedOn) {
      return {
        bg: 'red.50'
      };
    }
  }

  return (
    <Box maxW={1000} borderWidth="1px">
      <Table data={keywords} columns={columns} size="sm" getRowProps={getRowProps} />
    </Box>
  );
};

const PrettyDate = ({date}: {date: Date}) => {
  const differenceInHours = (Date.now() - +date) / 1000 / 60 / 60;
  const isRecent = differenceInHours < 12;
  return (
    <>
      {formatDateTime(date, {mask: 'yyyy-MM-dd'})}
      {isRecent && (
        <Box mt={1} color="gray.500">
          <TimeAgo date={date} />
        </Box>
      )}
    </>
  );
};
