import React from 'react';
import {Link as RouterLink, LinkProps, Route, Switch} from 'react-router-dom';
import {Flex, Link, Tab, TabList, Tabs} from '@chakra-ui/react';

import {Heading1} from 'components/core';
import {ScanSplitLayout} from 'components/scan-split-layout/scan-split-layout';
import {QualityControlInbox} from './quality-control-inbox';
import {QualityControlVerified} from './quality-control-verified';
import {QualityControlRejected} from './quality-control-rejected';
import {QualityControlRescanned} from './quality-control-rescanned';
import {
  RejectedIcon,
  RescannedIcon,
  ToBeVerifiedIcon,
  VerifiedIcon
} from './quality-control-shared';

export const QualityControlHome = () => {
  return (
    <ScanSplitLayout>
      <QualityControlPane />
    </ScanSplitLayout>
  );
};
export const QualityControlPane = () => {
  return (
    <Flex p={6} flexDir="column" h="100%" overflow="auto">
      <Heading1 mb={2}>Quality Control</Heading1>
      <Switch>
        <Route exact path="/quality-control" component={QualityControlInbox} />
        <Route path="/quality-control/verified" component={QualityControlVerified} />
        <Route path="/quality-control/rejected" component={QualityControlRejected} />
        <Route path="/quality-control/rescanned" component={QualityControlRescanned} />
      </Switch>
    </Flex>
  );
};

export const QualityControlNav = ({tabIndex}: {tabIndex: number}) => {
  return (
    <Tabs index={tabIndex} mb={2}>
      <TabList>
        <TabLink to="/quality-control/">
          <ToBeVerifiedIcon mr={1} fontSize="20px" />
          To be verified
        </TabLink>
        <TabLink to="/quality-control/verified">
          <VerifiedIcon mr={1} fontSize="20px" />
          Verified
        </TabLink>
        <TabLink to="/quality-control/rejected">
          <RejectedIcon mr={1} fontSize="16px" />
          To be rescanned
        </TabLink>
        <TabLink to="/quality-control/rescanned">
          <RescannedIcon mr={1} fontSize="16px" />
          Rescanned
        </TabLink>
      </TabList>
    </Tabs>
  );
};

const TabLink = (props: LinkProps) => {
  return (
    <Tab>
      <Link as={RouterLink} {...props} _hover={{textDecoration: 'none'}} />
    </Tab>
  );
};
