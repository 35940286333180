// Ported from https://github.com/medmain/medmain-js/blob/master/packages/core/src/models/query.js
import isNil from 'lodash/isNil';

type Expression = {
  field: string;
  operator: string;
  value: any;
};
type FieldSchema = {
  field: string;
  type: string;
};
type Schema = FieldSchema[];

export class Query {
  _expressions: Expression[];
  static schema: Schema;

  constructor(expressions: Expression[] = []) {
    this._expressions = expressions.map(({field, operator, value}) => ({
      field,
      operator,
      value
    }));
  }

  static fromJSON(json: any[] = []) {
    const expressions: Expression[] = [];

    for (let {field, operator, value} of json) {
      const fieldSchema = this.getFieldSchema(field);
      if (!fieldSchema) {
        continue;
      }
      if (fieldSchema.type === 'date' && value) {
        value = new Date(value);
      }
      expressions.push({field, operator, value});
    }

    const query = new this(expressions);

    return query;
  }

  static getFieldSchema(field) {
    return this.schema.find((fieldSchema) => fieldSchema.field === field);
  }

  clone() {
    return new (this.constructor as typeof Query)(this._expressions);
  }

  clear() {
    this._expressions = [];
  }

  isEmpty() {
    return this._getCleanExpressions().length === 0;
  }

  toJSON() {
    return this._getCleanExpressions();
  }

  forEachExpression(func) {
    return this._expressions.forEach(func);
  }

  _getCleanExpressions() {
    return this._expressions.filter(({field, operator, value}) => {
      if (!field) {
        return false;
      }
      const shouldHaveValue = !(operator === 'is-empty' || operator === 'is-not-empty'); // Move this logic in operator definitions
      if (shouldHaveValue && isNil(value)) {
        return false;
      }
      return true;
    });
  }

  getExpression(field) {
    return this._expressions.find((expression) => expression.field === field);
  }

  getDefaultValue(field) {
    const schema = (this.constructor as typeof Query).getFieldSchema(field);
    if (!schema) return '';
    if (schema.type === 'string') return '';
    if (schema.type === 'array') return [];
  }

  setExpression(field, {operator, value}) {
    const fieldSchema = (this.constructor as typeof Query).getFieldSchema(field);
    if (!fieldSchema) {
      throw new Error(`Field '${field}' not found in the query schema`);
    }
    let expression = this.getExpression(field);
    if (!expression) {
      expression = {field, operator, value};
      this._expressions.push(expression);
    }
    expression.operator = operator;
    expression.value = value;
  }

  deleteExpression(field) {
    const index = this._expressions.findIndex((expression) => expression.field === field);
    if (index !== -1) {
      this._expressions.splice(index, 1);
    }
  }

  getValue(field) {
    const expression = this.getExpression(field);
    return expression?.value || this.getDefaultValue(field);
  }

  setValue(field, value) {
    this.setExpression(field, {operator: 'is', value});
  }

  describe() {
    const expressions: {
      field: string;
      operator: string;
      value: string | string[];
    }[] = [];

    for (let {field, operator, value} of this._expressions) {
      // field = kebabCase(field);
      value = stringifyValue(value);
      expressions.push({field, operator, value});
    }

    return expressions;
  }
}

function stringifyValue(value: any): string | string[] {
  if (value instanceof Date) {
    return value.toISOString().slice(0, 10).replace(/-/g, '/');
  }

  if (Array.isArray(value)) return value;

  return JSON.stringify(value);
}
