import throat from 'throat';
import sumBy from 'lodash/sumBy';

import {createAdminApp} from 'app-root';
import {createMedmainImagesMixin} from './app-images-mixin';
import {getImageFormat} from 'models/image';
import {User} from 'models/user';
import {constants} from './constants';
import locales from './locales';
import Scan from './models/scan';
import TrackingEvent from './models/tracking';

const PRODUCT_ID = 'pidport-datastore';
const MAXIMUM_IMAGE_SIZE = 5 * 1000 * 1000 * 1000; // 5 Gbytes, max size supported by PUT requests
// TODO check "multipart upload" API to increase the limit

const BaseApp = createAdminApp({
  productId: PRODUCT_ID,
  backendURL: constants.BACKEND_URL,
  medmainAccountsWebsiteURL: constants.MEDMAIN_ACCOUNTS_WEBSITE_URL,
  locales
});

const MedmainImagesMixin = createMedmainImagesMixin({
  backendURL: constants.MEDMAIN_IMAGES_URL,
  maximumImageSize: MAXIMUM_IMAGE_SIZE
});

class App extends MedmainImagesMixin(BaseApp) {
  async initialize() {
    await super.initialize();

    this.state = {
      ...this.state,
      addedImages: [],
      batchNumber: undefined,
      scanSeedValues: new Scan({status: null}) // uploaded scans don't need to be verified by default
    };

    this.uploadThroat = throat(3);
  }

  // === Scans ===

  async getScan({scanId}) {
    const backend = await this.getBackend();
    const accessToken = this.getAccessToken();
    const {scan} = await backend.getScan({
      scanId,
      returnFields: [
        'id',
        'reference',
        'organ',
        'specimenType',
        'axis',
        'disease',
        'staining',
        'status',
        'rejectionReason',
        'supplier',
        'zoomLevel',
        'gene',
        'protein',
        'scannedAt',
        'scannedBy',
        'scannedOn',
        'patientName',
        'collectedBy',
        'collectedOn',
        'diagnosedBy',
        'diagnosedOn',
        'hasScannedDiagnosis',
        'scannedDiagnosisURL',
        'tags',
        'comments',
        'customField1',
        'customField2',
        'customField3',
        'createdOn',
        'updatedOn',
        'user.account.email',
        'user.id',
        'image.id',
        'image.batch.number',
        'image.filename',
        'image.size',
        'image.format',
        'image.info',
        'image.status',
        'image.smallImageURL',
        'image.annotations.id',
        'image.annotations.labelName',
        'image.annotations.path',
        'image.wsiAnnotations.id',
        'image.wsiAnnotations.labelName',
        'verifiedOn',
        'verifierId',
        'verifier.account.email',
        'deletedOn'
      ],
      accessToken
    });
    return new Scan(scan, {deserialize: true});
  }

  async getScanHistory({scanId}) {
    const backend = await this.getBackend();
    const accessToken = this.getAccessToken();
    const {events} = await backend.getScanHistory({scanId, accessToken});
    return {events: events.map((event) => new TrackingEvent(event, {deserialize: true}))};
  }

  async createScan({imageId, seedValues}) {
    const backend = await this.getBackend();
    const accessToken = this.getAccessToken();
    const {scan} = await backend.createScan({imageId, seedValues, accessToken});
    return new Scan(scan, {deserialize: true});
  }

  async updateScan({scanId, changes}) {
    const backend = await this.getBackend();
    const accessToken = this.getAccessToken();
    await backend.updateScan({scanId, changes, accessToken});
  }

  async deleteScan({scanId}) {
    const backend = await this.getBackend();
    const accessToken = this.getAccessToken();
    await backend.deleteScan({scanId, accessToken});
  }

  async getPreviousScanId({scanId, query, orderBy, orderDirection, offset, limit}) {
    const backend = await this.getBackend();
    const accessToken = this.getAccessToken();
    const {previousScanId} = await backend.getPreviousScanId({
      scanId,
      query,
      order: [{field: orderBy, direction: orderDirection}],
      offset,
      limit,
      accessToken
    });
    return previousScanId;
  }

  async getNextScanId({scanId, query, orderBy, orderDirection, offset, limit}) {
    const backend = await this.getBackend();
    const accessToken = this.getAccessToken();
    const {nextScanId} = await backend.getNextScanId({
      scanId,
      query,
      order: [{field: orderBy, direction: orderDirection}],
      offset,
      limit,
      accessToken
    });
    return nextScanId;
  }

  async updateScans({query, selection, changes}) {
    try {
      const backend = await this.getBackend();
      const accessToken = this.getAccessToken();
      await backend.updateScans({query, selection, changes, accessToken});
    } catch (err) {
      const l = this.getLocale();
      if (err.code === 'PERMISSION_DENIED') {
        err.userMessage = l.todo('Permission denied.');
        err.isFatal = true;
      }
      throw err;
    }
  }

  async deleteScans({query, selection}) {
    const backend = await this.getBackend();
    const accessToken = this.getAccessToken();
    await backend.deleteScans({query, selection, accessToken});
  }

  _scanAutocompleteRequests = {};

  async getScanAutocomplete({field, value}) {
    value = value ? value.trim() : '';

    let request = this._scanAutocompleteRequests[field];
    if (!request) {
      request = {items: []};
      this._scanAutocompleteRequests[field] = request;
    }

    if (request.value === value) {
      return request.items;
    }

    request.value = value;

    if (request.isRunning) {
      return request.items;
    }

    request.isRunning = true;

    while (true) {
      const items = await this._getScanAutocomplete({field, value});

      if (request.value === value) {
        request.items = items;
        request.isRunning = false;
        return items;
      }

      value = request.value;
    }
  }

  async _getScanAutocomplete({field, value}) {
    if (value.length < 1) {
      return [];
    }

    const backend = await this.getBackend();
    const accessToken = this.getAccessToken();
    const {items} = await backend.getScanAutocomplete({field, value, accessToken});

    return items;
  }

  // === Images ===

  async startBatchUpload(files) {
    const {scanSeedValues} = this.state;
    const scan = {
      ...scanSeedValues,
      imageFilename: files[0].name,
      imageSize: files[0].size
    };
    const size = sumBy(files, (file) => file.size);
    const backend = await this.getBackend();
    const accessToken = this.getAccessToken();
    await backend.startImageUpload({count: files.length, size, scan, accessToken});
  }

  async addImage(file) {
    const imagesBackend = await this.getImagesBackend();
    const accessToken = this.getAccessToken();
    let {addedImages, batchNumber} = this.state;

    if (batchNumber === undefined) {
      const {batch} = await imagesBackend.createBatch({accessToken});
      batchNumber = batch.number;
      await this.setState({batchNumber});
    }

    const image = {filename: file.name, file};
    addedImages.push(image);
    await this.startOrRetryUploadingImage(image);

    this.publish();
  }

  async startOrRetryUploadingImage(image) {
    const imagesBackend = await this.getImagesBackend();
    const accessToken = this.getAccessToken();
    const {batchNumber, scanSeedValues: seedValues} = this.state;

    this.uploadThroat(async () => {
      try {
        if (!image.id) {
          const format = getImageFormat({filename: image.file.name, type: image.file.type});
          const result = await imagesBackend.createImage({
            productId: PRODUCT_ID,
            batchNumber,
            filename: image.file.name,
            size: image.file.size,
            format,
            accessToken
          });
          Object.assign(image, result.image);
        }

        await this.uploadImage(image);
        await this.createScan({imageId: image.id, seedValues});
      } catch (err) {
        image.status = 'UPLOAD_FAILED';
        console.error(err);
        this.publish();
      }
    });
  }

  completeAddingImages() {
    this.setState({addedImages: [], batchNumber: undefined});
  }

  async findTrialScans({offset, limit}) {
    const backend = await this.getBackend();
    const accessToken = this.getAccessToken();
    const {scans, verifiersById, totalNumberOfScans} = await backend.findTrialScans({
      offset,
      limit,
      accessToken
    });

    const addVerifierData = (scan) => {
      const deserializedScan = new Scan(scan, {deserialize: true});
      const {image} = deserializedScan;
      const predictions = (image.predictions || []).map((prediction) => {
        const {verifierId} = prediction;
        const foundUser = verifiersById[verifierId];
        const verifier = foundUser && new User(foundUser);
        return verifier ? {...prediction, verifier} : prediction;
      });
      return {...deserializedScan, image: {...image, predictions}};
    };

    return {scans: scans.map(addVerifierData), totalNumberOfScans};
  }

  // === Annotations ===

  async generateAnnotationReport({year, month, verificationStatus, viewType}) {
    const imagesBackend = await this.getImagesBackend();
    const accessToken = this.getAccessToken();
    const timezoneOffset = new Date().getTimezoneOffset();
    let {users} = await imagesBackend.generateAnnotationReport({
      productId: PRODUCT_ID,
      year,
      month,
      verificationStatus,
      viewType,
      timezoneOffset,
      accessToken
    });
    users = users.map((user) => new User(user, {deserialize: true}));
    return {users};
  }

  async startImportCSV(payload) {
    const accessToken = this.getAccessToken();
    const backend = await this.getBackend();
    await backend.startImportCSV({accessToken, payload});
  }

  async endImportCSV(payload) {
    const accessToken = this.getAccessToken();
    const backend = await this.getBackend();
    await backend.endImportCSV({accessToken, payload});
  }
}

export const app = new App();

export default app;
