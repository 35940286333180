import React from 'react';
import compact from 'lodash/compact';
import {
  Box,
  BoxProps,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger
} from '@chakra-ui/react';
import styled from '@emotion/styled';

export type MenuItem = {
  label: React.ReactNode;
  icon?: React.ReactNode;
  onClick: (event: any) => void;
};

type Props = {
  items: MenuItem[][];
  onClick?: (event: any) => void;
  onClose?: () => void;
};

export const Menu = ({items, onClick: menuOnClick, onClose}: Props) => {
  return (
    <MenuContainer py={1} background="white">
      {compact(items).map((group, groupIndex) => {
        return (
          <div key={groupIndex}>
            {group.map((item, index) => {
              const {label, icon, onClick, ...other} = item;
              const handleClick = (event) => {
                if (menuOnClick) {
                  menuOnClick(event);
                }
                return onClick({onClose});
              };
              return (
                <MenuEntry key={index} onClick={handleClick} {...other}>
                  {icon && (
                    <Flex w="20px" mr={1} align="center">
                      {icon}
                    </Flex>
                  )}
                  {label}
                </MenuEntry>
              );
            })}
          </div>
        );
      })}
    </MenuContainer>
  );
};

// We use CSS to style "groups" of menu elements, separating them by a line
// doing this we don't need the concept of "menu separator"
const MenuContainer = styled(Box)`
  > :not([hidden]) ~ :not([hidden]) {
    border-top-width: 1px;
  }
`;

export const MenuEntry = (props: BoxProps) => {
  return <Flex cursor="pointer" _hover={{bg: 'gray.50'}} align="center" {...props} px={4} py={2} />;
};

export const MenuDivider = () => {
  return <Box my={1} borderBottomWidth="1px" />;
};

export const DropdownMenu = ({
  items,
  children,
  ...props
}: {
  items: MenuItem[][];
  children: React.ReactNode;
} & PopoverProps) => {
  return (
    <Popover {...props}>
      {({isOpen, onClose}) => (
        <>
          <PopoverTrigger>{children}</PopoverTrigger>
          <PopoverContent w="200px">
            <PopoverBody p={0}>
              <Menu items={items} onClose={onClose} />
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
