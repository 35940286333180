import invariant from 'tiny-invariant';

import {useApp} from 'shared';

export const ACCESS_LEVELS = {
  NO_ACCESS: 0,
  USER: 1,
  ADMIN: 2,
  SUPER_ADMIN: 3,
  HYPER_ADMIN: 4,
  ULTRA_ADMIN: 5
};

export const MAXIMUM_ACCESS_LEVEL = ACCESS_LEVELS.ULTRA_ADMIN;

type Role = {
  name: string;
};

export const ROLES = {
  TRIAL: {
    name: 'TRIAL',
    label: 'Thailand trial basic user',
    shortLabel: 'Trial',
    comment: 'Thailand hospital trial user: can only view verified predictions',
    disabled: true
  },
  TRIAL_ADMIN: {
    name: 'TRIAL_ADMIN',
    label: 'Thailand trial admin user',
    shortLabel: 'Trial Admin',
    comment:
      'Thailand hospital trial admin: can view original predictions, edit and mark as verified',
    disabled: true
  },
  AI_PREDICTION: {
    name: 'AI_PREDICTION',
    label: 'AI Prediction',
    shortLabel: 'AI',
    comment: 'Can request AI prediction and see results',
    disabled: false
  },
  CREATE_SCAN: {
    name: 'CREATE_SCAN',
    label: 'Upload',
    shortLabel: 'Upload',
    comment: 'Can create new scans',
    disabled: false
  },
  DOWNLOAD_IMAGE: {
    name: 'DOWNLOAD_IMAGE',
    label: 'Download image',
    shortLabel: 'Download',
    comment: 'Can download image from the viewer',
    disabled: false
  },
  EVALUATION: {
    name: 'EVALUATION',
    label: 'AI Evaluation',
    shortLabel: 'Evaluation',
    comment: 'Can view AI evaluations',
    disabled: false
  }
};

type PermissionType =
  | 'bin'
  | 'dashboard'
  | 'evaluations/view'
  | 'image/download'
  | 'predictions/request'
  | 'quality-control/view'
  | 'quality-control/verify'
  | 'quality-control/rescan'
  | 'trial/view'
  | 'trial/verify'
  | 'settings/view'
  | 'settings/edit'
  | 'scans/create'
  | 'scans/delete/bulk';

type User = {
  accessLevel: number;
  hasRole: (role: Role) => boolean;
  id: string;
};

const permissions = new Map<PermissionType, (user: User, resource?: any) => boolean>([
  ['bin', (user: User) => user.accessLevel >= 4],
  ['dashboard', (user: User) => user.accessLevel >= 3],
  ['evaluations/view', (user: User) => user.hasRole(ROLES.EVALUATION)],
  ['image/download', (user: User) => user.hasRole(ROLES.DOWNLOAD_IMAGE)],
  ['predictions/request', (user: User) => user.hasRole(ROLES.AI_PREDICTION)],
  ['quality-control/view', (user: User) => user.accessLevel >= 3],
  ['quality-control/verify', (user: User) => user.accessLevel >= 4],
  ['quality-control/rescan', (user: User) => user.accessLevel >= 3],
  ['scans/create', (user: User) => user.hasRole(ROLES.CREATE_SCAN)],
  ['scans/delete/bulk', (user: User) => user.accessLevel >= 3],
  ['settings/view', (user: User) => user.accessLevel >= 3],
  ['settings/edit', (user: User) => user.accessLevel >= 4],
  ['trial/view', (user: User) => user.hasRole(ROLES.TRIAL_ADMIN)],
  ['trial/verify', (user: User) => user.hasRole(ROLES.TRIAL_ADMIN)]
]);

// Policies based not only on user access roles but also on the resource itself
// TODO refactor to move all previous `permissions` to `POLICIES`, removing hasPermission function
export const POLICIES = {
  'scan/delete': (user: User, scan: Datastore.Scan) => {
    if (user.accessLevel > 2) return true;
    invariant(scan.user);
    return user.accessLevel > 1 && scan.user.id === user.id;
  },
  'scan/pdf/delete': (user) => {
    return user.accessLevel > 3;
  }
};

// TODO refactor and call directly POLICIES from components
export function hasPermission(permissionType: PermissionType, user: User) {
  if (!permissions.has(permissionType)) throw new Error(`No permission defined: ${permissionType}`);
  const handler = permissions.get(permissionType);
  invariant(handler);
  return handler(user);
}

export const usePermissions = () => {
  const app = useApp();
  const {user} = app.state;

  return {
    hasPermission: (permissionType: PermissionType) => {
      return hasPermission(permissionType, user);
    },
    isSuperAdmin: user.accessLevel >= ACCESS_LEVELS.SUPER_ADMIN // TODO use `hasPermission` instead of direct access to access levels
  };
};
