import React, {useState} from 'react';
import {
  ListItem,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  UnorderedList,
  Checkbox,
  VStack,
  Box
} from '@chakra-ui/react';

import {useLocale} from 'shared';
import {Button, useModal} from 'components/core';

const fieldSetup = [
  {name: 'organ', label: 'Organ'},
  {name: 'staining', label: 'Staining'},
  {name: 'disease', label: 'Disease'},
  {name: 'gene', label: 'Gene'},
  {name: 'protein', label: 'Protein'},
  {name: 'comments', label: 'Comments'},
  {name: 'tags', label: 'Tags'},
  {name: 'customField1', label: 'Custom field 1'},
  {name: 'customField2', label: 'Custom field 2'},
  {name: 'customField3', label: 'Custom field 3'}
];

type Props = {
  numberOfItems: number;
  onClose: (value: any) => void;
};
export const DialogEmptyFields = ({numberOfItems, onClose}: Props) => {
  const locale = useLocale();
  const modal = useModal();
  const [fields, setFields] = useState(fieldSetup.map((item) => ({...item, isSelected: false})));

  const selectedFields = fields.filter(({isSelected}) => Boolean(isSelected));

  const handleChange = (name, value) => {
    setFields((fields) =>
      fields.map((field) => ({
        ...field,
        isSelected: field.name === name ? value : field.isSelected
      }))
    );
  };

  const submit = async () => {
    const fieldsToEmpty = fields.filter(({isSelected}) => Boolean(isSelected));

    const okay = await modal.confirm(
      <>
        <div>
          Are you sure you want to <strong>definitely</strong> delete the following fields in{' '}
          <strong>{numberOfItems} selected scans</strong>?
        </div>
        <UnorderedList style={{marginTop: '0.5rem'}}>
          {fieldsToEmpty.map((field) => (
            <ListItem key={field.name}>{field.label}</ListItem>
          ))}
        </UnorderedList>
      </>,
      {title: 'Caution!', okButton: 'Empty fields'}
    );

    if (!okay) {
      return;
    }

    onClose(fieldsToEmpty);
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        submit();
      }}
    >
      <ModalHeader>
        {locale.todo('Empty fields')}
        <span
          style={{
            fontSize: '1rem',
            fontWeight: 500,
            color: 'rgb(128, 128, 128)',
            marginLeft: '0.75rem'
          }}
        >
          In {numberOfItems} selected scans
        </span>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Box mb={4}>
          Pick one or several fields, the content of the fields will be removed in all selected
          scans.
        </Box>
        <VStack spacing={0} alignItems="flex-start">
          {fields.map((field) => (
            <Checkbox
              key={field.name}
              isChecked={field.isSelected}
              onChange={(event) => handleChange(field.name, event.target.checked)}
              colorScheme="primary"
            >
              {field.label}
            </Checkbox>
          ))}
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button type="button" onClick={() => onClose(false)} mr={2}>
          {locale.cancelButtonLabel}
        </Button>
        <Button type="submit" primary isDisabled={selectedFields.length === 0}>
          {locale.todo('Empty fields')}
        </Button>
      </ModalFooter>
    </form>
  );
};
