// SVG source: https://github.com/Templarian/MaterialDesign/blob/master/icons/svg/pencil.svg
export const editCursor =
  'data:image/svg+xml,' +
  encodeURIComponent(`
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="full" width="24" height="24" viewBox="0 0 24.00 24.00" enable-background="new 0 0 24.00 24.00" xml:space="preserve">
      <path fill="#000000" fill-opacity="1" stroke-width="0.2" stroke-linejoin="round" d="M 20.7062,7.04108C 21.0972,6.65002 21.0972,6.01703 20.7062,5.62708L 18.3702,3.29108C 17.9802,2.90002 17.3472,2.90002 16.9562,3.29108L 15.1242,5.12305L 18.8742,8.87305M 2.99916,17.248L 2.99916,20.998L 6.74916,20.998L 17.8142,9.93304L 14.0642,6.18304L 2.99916,17.248 Z "/>
    </svg>
`);

// SVG source: https://github.com/Templarian/MaterialDesign/blob/master/icons/svg/eraser.svg
export const eraserCursor =
  'data:image/svg+xml,' +
  encodeURIComponent(`
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="full" width="24" height="24" viewBox="0 0 24.00 24.00" enable-background="new 0 0 24.00 24.00" xml:space="preserve">
      <path fill="#000000" fill-opacity="1" stroke-linejoin="round" d="M 16.2426,3.55762L 21.1924,8.50737C 21.9734,9.28841 21.9734,10.5547 21.1924,11.3358L 12,20.5282C 10.4379,22.0903 7.90525,22.0903 6.34316,20.5282L 2.80761,16.9926C 2.02657,16.2116 2.02657,14.9453 2.80761,14.1642L 13.4142,3.55761C 14.1953,2.77657 15.4616,2.77657 16.2426,3.55762 Z M 4.22183,15.5784L 7.75736,19.114C 8.53841,19.895 9.80474,19.895 10.5858,19.114L 14.1213,15.5784L 9.17157,10.6287L 4.22183,15.5784 Z "/>
    </svg>
`);
