import React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  Divider,
  Heading
} from '@chakra-ui/react';

export const Panel = (props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="325px"
      backgroundColor={'inverseBoxBackgroundColor'}
      color="white"
      borderWidth={1}
      borderColor="inverseBoxBorderColor"
      borderRadius="md"
      fontSize="14px"
      {...props}
    />
  );
};

export const PanelHeader = (props) => {
  return <Box p={2} borderBottomWidth={1} borderColor="inverseBoxBorderColor" {...props} />;
};

export const PanelHeading1 = (props) => {
  return <Heading fontSize="16px" mt={0} mb={0} fontWeight="normal" color="white" {...props} />;
};

export const PanelHeading2 = (props) => {
  return <Heading size="sm" mb={3} fontWeight="normal" color="white" {...props} />;
};

export const PanelFooter = (props) => {
  return <Box p={2} borderTopWidth={1} {...props} />;
};

export const PanelDivider = ({direction = 'horizontal'}) => {
  const color = 'rgba(255, 255, 255, 0.4)';

  if (direction === 'horizontal') {
    return <Divider color={color} mt={2} mb={2} />;
  }

  return <Divider ml={2} mr={2} color={color} flex="none" />;
};

export const PanelButton = (props: ButtonProps) => (
  <Button
    variant="outline"
    bg="transparent"
    borderWidth="1px"
    minWidth="unset"
    borderRadius="md"
    size="xs"
    _hover={{bg: 'transparent'}}
    {...props}
  />
);

export const PanelIconButton = (props: IconButtonProps) => (
  <IconButton
    variant="outline"
    bg="transparent"
    borderWidth="1px"
    boxSize="20px"
    minWidth="unset"
    borderRadius="md"
    _hover={{bg: 'transparent'}}
    {...props}
  />
);
