import React from 'react';
import invariant from 'tiny-invariant';

import {withOpenSeadragon} from 'components/image-viewer/openseadragon';

import {
  areTheSamePoint,
  round,
  getDistance,
  convertPercentPointToImagePoint
} from 'models/annotation';
import {KeyHandler} from 'components/core';
import {AnnotationView} from 'components/annotations/annotation-view';

type Point = {x: number; y: number};

type Props = {
  onStart?: () => void;
  onEnd: ({path}: {path: Point[]}) => Promise<void>;
  imageDimensions: Datastore.Image['info']['dimensions'];
  precision?: number;
  annotationColor?: string;
  annotationStyle?: Object;
  handles: Point[];
  handleSize?: number;
  magnetism?: number;
  disabled: boolean;
  style?: object;
  openSeadragon?: any;
};

type State = {
  path: Point[];
  isCompleted: undefined | boolean;
  isEnding: boolean;
  addedHandles: Point[];
  usedHandles: Point[];
};

@withOpenSeadragon
export class Annotator extends React.Component<Props, State> {
  annotatorRef: React.RefObject<HTMLDivElement>;

  static defaultProps = {
    precision: 3,
    handleSize: 12,
    magnetism: 8
  };

  constructor(props) {
    super(props);
    this.state = {
      path: [],
      isCompleted: undefined,
      isEnding: false,
      addedHandles: [],
      usedHandles: []
    };

    this.annotatorRef = React.createRef();
  }

  componentDidMount() {
    const {openSeadragon} = this.props;

    openSeadragon.addDragHandler(this.handleDrag);
    openSeadragon.addDragEndHandler(this.handleDragEnd);
  }

  componentWillUnmount() {
    const {openSeadragon} = this.props;

    openSeadragon.removeDragHandler(this.handleDrag);
    openSeadragon.removeDragEndHandler(this.handleDragEnd);
  }

  handleDrag = (event) => {
    const {onStart, disabled, openSeadragon} = this.props;
    let {path, isCompleted, isEnding} = this.state;

    if (disabled) {
      return false;
    }

    if (isCompleted) {
      return;
    }

    if (isEnding) {
      return;
    }

    const imagePoint = openSeadragon.convertScreenPointToImagePoint(event.position);
    const point = this.adjustPoint(imagePoint);
    const handle = this.findHandle(point);

    if (!path.length) {
      // The user starts drawing
      this.addHandle(point);
      if (onStart) {
        onStart();
      }
    } else {
      // The user continue drawing
      const lastPoint = path[path.length - 1];
      if (lastPoint && areTheSamePoint(point, lastPoint)) {
        return;
      }
      if (handle) {
        isCompleted = true;
      }
    }

    if (handle) {
      this.useHandle(handle);
    }

    path.push(point);

    this.setState({isCompleted});
  };

  handleDragEnd = async () => {
    const {onEnd} = this.props;
    const {path, isEnding} = this.state;

    if (isEnding) {
      return;
    }

    if (path.length >= 2) {
      this.setState({isEnding: true});
      try {
        await onEnd({path});
      } finally {
        this.setState({isEnding: false});
      }
    }

    this.setState({path: [], isCompleted: undefined, addedHandles: [], usedHandles: []});
  };

  handleDiscardStart = () => {
    const {path, isEnding} = this.state;

    if (isEnding) {
      return;
    }

    if (!path.length) {
      return false; // propagate the Escape KeyDown event
    }

    this.setState({
      path: [], // pressing the Escape key discards the path being drawn
      isCompleted: true,
      addedHandles: [],
      usedHandles: []
    });
  };

  handleDiscardEnd = () => {
    const {isEnding} = this.state;

    if (isEnding) {
      return;
    }

    this.setState({isCompleted: undefined});
  };

  adjustPoint = (imagePoint) => {
    const point = this.convertToPercentPoint(imagePoint);

    const handle = this.findHandle(point, {useMagnetism: true});
    if (handle) {
      return handle;
    }

    return point;
  };

  getHandles = () => {
    const {handles} = this.props;
    const {addedHandles} = this.state;

    // Special case to avoid a warning "2 children with the same key"
    // just after a new annotation has been saved, the `addedHandle` is a duplicate until the `path` is cleared
    // TODO: maybe `path` state should be "lifted" at the plugin level
    // because we want want to manipulate `path` and `handles` in the same time?
    if (
      addedHandles.length === 1 &&
      handles.some((handle) => areTheSamePoint(handle, addedHandles[0]))
    ) {
      return handles;
    }

    return [...handles, ...addedHandles];
  };

  addHandle = (point) => {
    const {addedHandles} = this.state;

    if (!this.findHandle(point)) {
      addedHandles.push(point);
    }
  };

  findHandle = (point, options = {}) => {
    const {magnetism} = this.props;
    invariant(magnetism);
    const {useMagnetism} = options as any;

    const handles = this.getHandles();

    if (!useMagnetism) {
      return handles.find((handle) => areTheSamePoint(handle, point));
    }

    let foundHandle;
    let maxDistance = magnetism;

    for (const handle of handles) {
      const distance = getDistance(
        this.convertToScreenPoint(point),
        this.convertToScreenPoint(handle)
      );
      if (distance < maxDistance) {
        foundHandle = handle;
        maxDistance = distance;
      }
    }

    return foundHandle;
  };

  // image pixel => image %
  convertToPercentPoint = ({x: imageX, y: imageY}) => {
    const {
      imageDimensions: {width, height},
      precision
    } = this.props;

    return {
      x: round((imageX / width) * 100, precision),
      y: round((imageY / height) * 100, precision)
    };
  };

  // image % => screen pixel
  convertToScreenPoint = (point) => {
    const {imageDimensions, openSeadragon} = this.props;

    return openSeadragon.convertImagePointToScreenPoint(
      convertPercentPointToImagePoint(point, imageDimensions)
    );
  };

  useHandle = (point) => {
    const {usedHandles} = this.state;

    if (!this.isUsedHandle(point)) {
      usedHandles.push(point);
    }
  };

  isUsedHandle = (point) => {
    const {usedHandles} = this.state;

    return usedHandles.some((handle) => areTheSamePoint(handle, point));
  };

  render() {
    const {disabled, annotationColor, annotationStyle, style} = this.props;
    const {path} = this.state;

    return (
      <div
        ref={this.annotatorRef}
        style={{
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
          position: 'relative',
          ...style
        }}
      >
        <KeyHandler
          value={'Escape'}
          onDown={this.handleDiscardStart}
          onUp={this.handleDiscardEnd}
        />
        <AnnotationView
          path={path}
          style={{
            ...annotationStyle,
            stroke: annotationColor
          }}
        />
        {/* A container is needed to display correctly the handles on top of the annotation, when the user is drawing */}
        {!disabled && (
          <div style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%'}}>
            {this.renderHandles()}
          </div>
        )}
      </div>
    );
  }

  renderHandles() {
    const {handleSize, annotationColor} = this.props;

    const handles = this.getHandles().filter((handle) => !this.isUsedHandle(handle));

    return (
      <svg
        width={'100%'}
        height={'100%'}
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        version="1.2"
        xmlns="http://www.w3.org/2000/svg"
      >
        {handles.map((point) => {
          return this.renderCircle({
            center: point,
            size: `${handleSize}px`,
            color: annotationColor
          });
        })}
      </svg>
    );
  }

  /*
  Render a circle at a fixed sized, no matter the zoom level
  Note: a SVG `<circle>` would be rendered as an ellipse that becomes bigger when the user zooms.
  */
  renderCircle({center, size, color}) {
    const shift = (position) => round(position + 0.00001, 5);
    return (
      <line
        key={`${center.x}:${center.y}`}
        x1={center.x}
        y1={center.y}
        x2={shift(center.x)}
        y2={shift(center.y)}
        pointerEvents="none"
        style={{
          stroke: color,
          strokeWidth: size,
          strokeLinecap: 'round',
          vectorEffect: 'non-scaling-stroke'
        }}
      />
    );
  }
}
