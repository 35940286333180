import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@chakra-ui/react';

export class Popover extends React.Component<any, any> {
  static propTypes = {
    content: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    alignment: PropTypes.oneOf(['left', 'right']),
    position: PropTypes.oneOf(['bottom', 'top', 'cursor']),
    style: PropTypes.object,
    contentStyle: PropTypes.object,
    children: PropTypes.func.isRequired
  };

  static defaultProps = {
    alignment: 'left',
    position: 'bottom'
  };

  state = {
    isOpen: false,
    overlayPosition: undefined, // where to position the overlay when the Popover position is "cursor"
    context: undefined // data from the customer, to be passed to the context menu
  };

  contentRef = React.createRef();

  componentWillUnmount() {
    this.removeEventListeners();
  }

  handleBodyClick = (event) => {
    if (this.isOutsideClick(event)) {
      event.stopPropagation();
      this.close(); // close if the user has clicked outside the popover, ignoring clicks on the "content"
    }
  };

  isOutsideClick = (event) => {
    const contentNode = this.contentRef.current;
    if (!contentNode) return true;
    return !(contentNode as any).contains(event.target);
  };

  handleBodyKeyDown = (event) => {
    if (event.keyCode === 27) {
      event.stopPropagation();
      this.close(); // Escape key
    }
  };

  open = (event, context) => {
    const overlayPosition = this.getOverlayPosition(event);
    this.setState({isOpen: true, overlayPosition, context});
    this.addEventListeners();
  };

  close = () => {
    this.setState({isOpen: false});
    this.removeEventListeners();
  };

  toggle = (event, context) => {
    if (this.state.isOpen) {
      this.close();
    } else {
      this.open(event, context);
    }
  };

  addEventListeners = () => {
    document.body.addEventListener('click', this.handleBodyClick);
    document.body.addEventListener('keydown', this.handleBodyKeyDown);
  };

  removeEventListeners = () => {
    document.body.removeEventListener('click', this.handleBodyClick);
    document.body.removeEventListener('keydown', this.handleBodyKeyDown);
  };

  getOverlayPosition = (event) => {
    const {offsetLeft: containerX, offsetTop: containerY} = event.currentTarget;
    const {offsetX, offsetY} = event.nativeEvent;

    return {
      x: containerX + offsetX,
      y: containerY + offsetY
    };
  };

  render() {
    const {content, alignment, position, style, contentStyle, children} = this.props;
    const {isOpen, overlayPosition, context} = this.state;

    let alignmentStyle;
    if (isOpen) {
      alignmentStyle = {
        paddingTop: '4px',
        paddingBottom: '4px'
      };

      if (alignment === 'right') {
        alignmentStyle = {
          ...alignmentStyle,
          left: 'auto',
          right: 0
        };
      }

      if (position === 'top') {
        alignmentStyle = {
          ...alignmentStyle,
          bottom: '100%',
          top: 'auto'
        };
      }

      if (position === 'cursor') {
        alignmentStyle = {
          ...alignmentStyle,
          bottom: 'auto',
          left: (overlayPosition as any).x,
          top: (overlayPosition as any).y
        };
      }
    }

    const borderColor = 'rgba(0, 0, 0, 0.12)';

    return (
      <div style={{position: 'relative', ...style}}>
        {(children as any)({isOpen, open: this.open, toggle: this.toggle, close: this.close})}
        {isOpen && (
          <div
            style={{
              position: 'absolute',
              display: 'block',
              left: 0,
              minWidth: '200px',
              top: '100%',
              zIndex: 20000,
              ...alignmentStyle,
              ...contentStyle
            }}
          >
            <Box
              ref={this.contentRef as any}
              bg="white"
              borderWidth="1px"
              borderRadius="4px"
              boxShadow={`0 0 0 1px ${borderColor}, 0 4px 11px ${borderColor}`}
            >
              {typeof content === 'function'
                ? content({close: this.close, ...(context as any)})
                : content}
            </Box>
          </div>
        )}
      </div>
    );
  }
}
