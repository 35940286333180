import React from 'react';
import {Route, Switch} from 'react-router-dom';

import {ScanListPage} from 'components/scan-list/scan-list-page';
import {ScanEditorPage} from 'components/scan-form/scan-editor-page';
import {ScanReportPage} from 'components/scan-report/scan-report-page';
import {ScanStats} from './scan-stats';

export class ScanHome extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/scans" component={ScanListPage} />
        <Route path="/scans/report" component={ScanReportPage} />
        <Route path="/scans/stats" component={ScanStats} />
        <Route path="/scans/:scanId" component={ScanEditorPage} />
      </Switch>
    );
  }
}
