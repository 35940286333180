import React from 'react';
import {NavLink, NavLinkProps} from 'react-router-dom';
import {
  Box,
  BoxProps,
  Collapse,
  Flex,
  Icon,
  chakra,
  UseDisclosureReturn,
  useDisclosure
} from '@chakra-ui/react';
import {AiOutlineHome, AiOutlineSetting} from 'react-icons/ai';
import {BiLineChart} from 'react-icons/bi';
import {FiCheckSquare, FiUsers} from 'react-icons/fi';
import {MdHourglassEmpty} from 'react-icons/md';
import {RiDashboardLine, RiFileUploadLine} from 'react-icons/ri';

import {usePermissions} from 'shared';
import {ImportCSVContainer} from 'components/import-csv/import-csv-container';
import {ChevronRightIcon} from 'components/core';
import {PidPortLogo} from './pidport-logo';

type Props = {sidebar?: UseDisclosureReturn} & BoxProps;
export const SidebarContent = ({sidebar, ...props}: Props) => {
  const {hasPermission, isSuperAdmin} = usePermissions();
  const moreLinksDisclosure = useDisclosure();
  return (
    <Box
      as="nav"
      pos="fixed"
      top={0}
      left={0}
      zIndex="sticky"
      h="full"
      pt={0}
      pb={10}
      overflowX="hidden"
      overflowY="auto"
      bg="cardBg"
      borderRightWidth="1px"
      {...props}
    >
      <Flex h={14} px={4} alignItems="center">
        <PidPortLogo width={130} />
      </Flex>
      <Flex direction="column" fontSize="sm" color="gray.600" aria-label="Main Navigation">
        <NavItem to="/scans" icon={<Icon as={AiOutlineHome} />}>
          Scans
        </NavItem>
        {hasPermission('dashboard') && (
          <NavItem to="/dashboard" icon={<Icon as={RiDashboardLine} />}>
            Dashboard
          </NavItem>
        )}
        {hasPermission('quality-control/view') && (
          <NavItem to="/quality-control" icon={<Icon as={FiCheckSquare} />}>
            Quality Control
          </NavItem>
        )}
        {hasPermission('scans/create') && <ImportCSVMenuItem />}
        {hasPermission('evaluations/view') && (
          <NavItem to="/evaluations" icon={<Icon as={BiLineChart} />}>
            Evaluations
          </NavItem>
        )}
        {isSuperAdmin && (
          <NavItem to="/users" icon={<Icon as={FiUsers} />}>
            Users
          </NavItem>
        )}
        {hasPermission('settings/view') && (
          <NavItem to="/settings" icon={<Icon as={AiOutlineSetting} />}>
            Settings
          </NavItem>
        )}
        {isSuperAdmin && (
          <NavItem
            onClick={moreLinksDisclosure.onToggle}
            as={Flex}
            borderBottomWidth="0"
            icon={
              <ChevronRightIcon
                transform={moreLinksDisclosure.isOpen ? 'rotate(90deg)' : undefined}
                fontSize="24px"
              />
            }
          >
            More
          </NavItem>
        )}
        <Collapse in={moreLinksDisclosure.isOpen}>
          {isSuperAdmin && <SubNavItem to="/annotations">Annotations</SubNavItem>}
          {isSuperAdmin && <SubNavItem to="/predictions">Predictions</SubNavItem>}
          <SubNavItem to="/csv-bulk-actions">CSV bulk actions</SubNavItem>
          <SubNavItem to="/bin">Trash bin</SubNavItem>
          <SubNavItem to="/trial">Thailand trial</SubNavItem>
        </Collapse>
      </Flex>
    </Box>
  );
};

type NavItemProps = {
  extra?: React.ReactNode;
  icon?: JSX.Element;
} & BoxProps &
  Partial<NavLinkProps>;
const NavItem = ({extra, ...props}: NavItemProps) => {
  const {icon, children, ...rest} = props;
  return (
    <Flex
      as={NavLink}
      align="center"
      px="4"
      pl="4"
      py="3"
      cursor="pointer"
      color="gray.600"
      _hover={{
        bg: 'gray.50',
        color: 'gray.900'
      }}
      role="group"
      transition=".15s ease"
      borderRightWidth="3px"
      borderRightColor="transparent"
      sx={{
        '&.active': {
          color: 'primary.500',
          bg: 'primary.50',
          borderRightColor: 'primary.300'
        }
      }}
      borderBottomWidth="1px"
      borderBottomStyle="dashed"
      borderBottomColor="gray.100"
      {...rest}
    >
      {icon && React.cloneElement(icon, {boxSize: 6, mr: 2})}
      {children}
      {extra && (
        <Flex ml={2} alignItems="center">
          {extra}
        </Flex>
      )}
    </Flex>
  );
};

const SubNavItem = chakra(NavItem, {
  baseStyle: {
    pl: 12,
    py: 2
  }
});

const ImportCSVMenuItem = () => {
  const {rowTotal, current} = ImportCSVContainer.useContainer();
  const isProcessing = current.matches('checking') || current.matches('updating');

  return (
    <NavItem
      to="/import-csv"
      icon={<Icon as={RiFileUploadLine} />}
      extra={
        <>
          {rowTotal > 0 && <CountBadge>{rowTotal}</CountBadge>}
          {isProcessing && <Icon as={MdHourglassEmpty} color="blue.500" fontSize="16px" />}
        </>
      }
    >
      Import CSV
    </NavItem>
  );
};

const CountBadge = (props: BoxProps) => {
  return <Box d="flex" color="blue.500" {...props} />;
};
