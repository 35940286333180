import React from 'react';
import {
  Box,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Wrap,
  WrapItem,
  Text,
  Flex,
  SliderProps,
  Checkbox,
  BoxProps,
  VStack
} from '@chakra-ui/react';

import {ItemSelection, usePermissions} from 'shared';
import {ImageThumbnail} from './image-thumbnail';
import {FilenameLink, WSIAnnotationGroup} from './scan-columns';
import {NoItemsFound, Slider, useItemSelection} from 'components/core';
import {
  RejectedIcon,
  RescannedIcon,
  ToBeVerifiedIcon,
  VerifiedIcon
} from 'components/quality-control/quality-control-shared';
import {ThumbnailAnnotation} from 'components/annotations/thumbnail-annotation';
import {getAILabelByName} from 'components/settings/ai-labels-api';
import {AILabel} from 'components/settings/types';
import {TabLayoutContainer} from 'components/scan-split-layout/tab-layout-container';
import {truncateFilename} from 'models/image';

type Props = {
  scans: Datastore.Scan[];
  thumbnailSize: number;
  selection?: ItemSelection;
  onSelect?: (selection) => void;
};
export const ScanGallery = ({scans, thumbnailSize, selection, onSelect}: Props) => {
  const {toggleItem} = useItemSelection(scans, selection, onSelect);
  const {onImageClick} = TabLayoutContainer.useContainer();

  if (scans.length === 0) return <NoItemsFound />;

  return (
    <Box overflowY="auto" overflowX="hidden">
      <Wrap spacing={4}>
        {scans.map((scan) => {
          const isSelected = selection?.isItemSelected(scan.id);
          return (
            <WrapItem key={scan.id}>
              <ScanCard
                scan={scan}
                onImageClick={onImageClick}
                size={thumbnailSize}
                isSelected={isSelected}
                onSelectScan={
                  selection ? (value, shiftKey) => toggleItem(scan.id, value, shiftKey) : undefined
                }
              />
            </WrapItem>
          );
        })}
      </Wrap>
    </Box>
  );
};

type ScanCardProps = {
  scan: Datastore.Scan;
  onImageClick?: (scan: Datastore.Scan) => void;
  size: number;
  isSelected?: boolean;
  onSelectScan?: (value: boolean, shiftKey: boolean) => void;
  showHeader?: boolean;
  labels?: AILabel[];
} & BoxProps;
export const ScanCard = ({
  scan,
  onImageClick,
  size,
  isSelected,
  onSelectScan,
  showHeader = true,
  labels,
  ...props
}: ScanCardProps) => {
  const {hasPermission} = usePermissions();
  const imageStatus = scan.image.status;
  const width = size ? size + 2 : '100%';

  return (
    <Box
      borderWidth="1px"
      w={width}
      borderColor={isSelected ? 'primary.300' : 'gray.200'}
      {...props}
    >
      {showHeader && (
        <Flex
          p={2}
          borderBottomWidth="1px"
          borderColor={isSelected ? 'primary.300' : 'gray.200'}
          overflowY="hidden"
          bg={isSelected ? 'primary.50' : 'white'}
        >
          {onSelectScan && (
            <Checkbox
              isChecked={isSelected}
              onChange={(event) =>
                onSelectScan(event.target.checked, (event as any).nativeEvent.shiftKey)
              }
              bg="white"
              size="lg"
              spacing={0}
            />
          )}
          <Text isTruncated fontSize="small" my={0} ml={2}>
            <FilenameLink scanId={scan.id} filename={truncateFilename(scan.image.filename)} />
          </Text>
        </Flex>
      )}
      <Box position="relative">
        <ImageThumbnail
          image={scan.image}
          size={size}
          borderWidth={0}
          onClick={onImageClick ? () => onImageClick(scan) : undefined}
        >
          <ImageAnnotations image={scan.image} labels={labels} />
        </ImageThumbnail>
        {imageStatus === 'AVAILABLE' && hasPermission('quality-control/view') && (
          <Box position="absolute" top={2} left={2}>
            <QualityControlIndicator status={scan.status} size={size > 228 ? 'md' : 'sm'} />
          </Box>
        )}
        <ImageWSILabels annotations={scan.image.wsiAnnotations} isCompact={size < 200} />
      </Box>
    </Box>
  );
};

const ImageAnnotations = ({labels, image}) => {
  if (!labels) return null;
  return (
    <>
      {image.annotations.map((annotation, index) => {
        const label = getAILabelByName(labels, annotation.labelName);
        return (
          <div
            key={index}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            }}
          >
            <ThumbnailAnnotation
              path={annotation.path}
              style={{
                fill: 'none',
                stroke: label?.color,
                strokeWidth: '1px'
              }}
            />
          </div>
        );
      })}
    </>
  );
};

const ImageWSILabels = ({
  annotations,
  isCompact
}: {
  annotations: Datastore.Image['wsiAnnotations'];
  isCompact: boolean;
}) => {
  if (!annotations?.length) return null;
  return (
    <Box position="absolute" left={2} bottom={2} width="100%">
      <VStack alignItems="flex-start" spacing={isCompact ? 1 : 2}>
        <WSIAnnotationGroup annotations={annotations} isCompact={isCompact} />
      </VStack>
    </Box>
  );
};

export const GallerySizeControl = (props: SliderProps) => {
  return (
    <Flex alignItems="center" w={150}>
      <Box fontSize="small" px={3}>
        S
      </Box>
      <Slider aria-label="Image size" min={80} max={512} step={16} {...props}>
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb borderWidth="1px" borderColor="gray.200" />
      </Slider>
      <Box fontSize="small" px={3}>
        L
      </Box>
    </Flex>
  );
};

type IndicatorSize = 'sm' | 'md'; // to adjust the size of the icons on top of the thumbnails

const QualityControlIndicator = ({
  status,
  size
}: {
  status: Datastore.Scan['status'];
  size: IndicatorSize;
}) => {
  const iconSize = size === 'md' ? '24px' : '20px';
  if (status === 'TO_BE_VERIFIED') {
    return (
      <Pill bg="blue.400" size={size}>
        <ToBeVerifiedIcon fontSize={iconSize} color="white" />
      </Pill>
    );
  }
  if (status === 'VERIFIED') {
    return (
      <Pill bg="green.400" size={size}>
        <VerifiedIcon color="white" fontSize="24px" />
      </Pill>
    );
  }
  if (status === 'REJECTED') {
    return (
      <Pill bg="red.400" size={size}>
        <RejectedIcon color="white" fontSize="24px" />
      </Pill>
    );
  }
  if (status === 'RESCANNED') {
    return (
      <Pill bg="orange.400" size={size}>
        <RescannedIcon color="white" fontSize="20px" />
      </Pill>
    );
  }
  return null;
};

const Pill = ({size, ...props}: {size: IndicatorSize} & BoxProps) => {
  return (
    <Flex
      boxSize={size === 'md' ? '32px' : '24px'}
      borderRadius="99px"
      align="center"
      justify="center"
      {...props}
    />
  );
};
