import React from 'react';
import flatten from 'lodash/flatten';
import {VStack} from '@chakra-ui/react';

import {KeyHandler} from 'components/core/key-handler';
import {ToolbarButtonGroup} from './button';
import {ImageContainer, PANNING_MODE} from './image-container';
import {ImageViewerPlugin, ImageViewerMode, /*SidebarButton,*/ SidebarButtonGroup} from './types';

export const Toolbar = ({plugins}: {plugins: ImageViewerPlugin[]}) => {
  const {mode, setMode, setIsQuickPanning, zenMode} = ImageContainer.useContainer();
  const {groups /*, shortcuts*/} = buildSidebarButtons({
    plugins,
    mode,
    setMode
  });
  return (
    <>
      <KeyHandler
        value=" "
        onDown={() => setIsQuickPanning(true)}
        onUp={() => setIsQuickPanning(false)}
      />
      <VStack
        spacing={4}
        mt={4}
        alignItems="flex-start"
        opacity={zenMode ? 0 : 1}
        transition="opacity 0.3s"
      >
        {groups
          .filter(({buttons}) => buttons.length > 0)
          .map(({key, buttons}) => (
            <ToolbarButtonGroup key={key}>
              {buttons.map((SidebarButton) => SidebarButton)}
            </ToolbarButtonGroup>
          ))}
      </VStack>
    </>
  );
};

export function getPluginModes(plugins: ImageViewerPlugin[]): ImageViewerMode[] {
  const pluginModes = flatten(
    plugins.filter(({modes}) => !!modes && modes.length > 0).map((plugin) => plugin.modes!)
  );
  return pluginModes;
}

export function getAvailableModes(plugins: ImageViewerPlugin[]): ImageViewerMode[] {
  const defaultMode: ImageViewerMode = {key: PANNING_MODE, cursor: 'move'};

  const pluginModes = getPluginModes(plugins);
  return pluginModes.length > 0 ? [defaultMode, ...pluginModes] : pluginModes;
}

function buildSidebarButtons({
  plugins,
  mode,
  setMode
}: {
  plugins: ImageViewerPlugin[];
  mode: string;
  setMode: (string) => any;
}): {groups: SidebarButtonGroup[]} {
  const initialSidebar: SidebarButtonGroup[] = [];
  const sidebarButtonGroups = plugins.reduce((sidebar, {displayName, decorateSidebar}) => {
    return decorateSidebar ? decorateSidebar(sidebar) : sidebar;
  }, initialSidebar);

  return {
    groups: sidebarButtonGroups
    // shortcuts
  };
}
