import {
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  Code,
  Icon,
  ListItem,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  UnorderedList,
  VStack
} from '@chakra-ui/react';
import {useMutation, useQueryClient} from 'react-query';
import startCase from 'lodash/startCase';
import {FaTrashRestoreAlt} from 'react-icons/fa';
import invariant from 'tiny-invariant';

import {KeywordCategory, KeywordItem, useApp, useLocale} from 'shared';
import {Button, DeleteIcon} from 'components/core';

type Props = {
  category: KeywordCategory;
  item: KeywordItem;
  onClose: (item?: KeywordItem) => void;
};
export const DialogDeleteKeyword = ({category, item, onClose}: Props) => {
  const locale = useLocale();
  const categoryDisplayName = startCase(category.toLowerCase());

  invariant(item.id);
  const mutation = useDeleteKeyword(item.id);
  const queryClient = useQueryClient();

  const submit = () => {
    mutation.mutate(undefined, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['keywords']);
        setTimeout(() => onClose(item));
      }
    }); // does not return a Promise, we have to use `onSuccess` callback
  };
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        submit();
      }}
    >
      <ModalHeader>Delete {categoryDisplayName}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <VStack spacing={6} alignItems="flex-start">
          <Alert status="warning">
            <AlertIcon />
            <AlertDescription>Are you sure you want to delete this keyword?</AlertDescription>
          </Alert>
          <Box>
            <UnorderedList>
              <ListItem>
                Code: <Code>{item.value}</Code>
              </ListItem>
              <ListItem>Name on the screen: {item.label}</ListItem>
            </UnorderedList>
          </Box>

          {mutation.isError && !mutation.isLoading && (
            <Alert status="error">
              Unable to delete the keyword {(mutation.error as Error)?.message}
            </Alert>
          )}
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button type="button" onClick={() => onClose()} mr={2} isDisabled={mutation.isLoading}>
          {locale.cancelButtonLabel}
        </Button>
        <Button
          type="submit"
          isLoading={mutation.isLoading || !!queryClient.isFetching()}
          colorScheme="red"
          variant="solid"
          rightIcon={<DeleteIcon />}
        >
          Delete
        </Button>
      </ModalFooter>
    </form>
  );
};

function useDeleteKeyword(keywordId: string) {
  const app = useApp();
  const accessToken = app.getAccessToken();

  return useMutation(async () => {
    const backend = await app.getBackend();
    return backend.deleteKeyword({keywordId, accessToken});
  });
}

export const DialogRestoreKeyword = ({category, item, onClose}: Props) => {
  const locale = useLocale();
  invariant(item.id);
  const mutation = useRestoreKeyword(item.id);
  const queryClient = useQueryClient();

  const submit = () => {
    mutation.mutate(undefined, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['keywords']);
        setTimeout(() => onClose(item));
      }
    }); // does not return a Promise, we have to use `onSuccess` callback
  };
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        submit();
      }}
    >
      <ModalHeader>Restore keyword</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <VStack spacing={6} alignItems="flex-start">
          <Alert status="info">
            <AlertIcon />
            <AlertDescription>Are you sure you want to restore this keyword?</AlertDescription>
          </Alert>
          <Box>
            <UnorderedList>
              <ListItem>
                Code: <Code>{item.value}</Code>
              </ListItem>
              <ListItem>Name on the screen: {item.label}</ListItem>
            </UnorderedList>
          </Box>

          {mutation.isError && !mutation.isLoading && (
            <Alert status="error">
              Unable to restore the keyword {(mutation.error as Error)?.message}
            </Alert>
          )}
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button type="button" onClick={() => onClose()} mr={2} isDisabled={mutation.isLoading}>
          {locale.cancelButtonLabel}
        </Button>
        <Button
          type="submit"
          primary
          isLoading={mutation.isLoading || !!queryClient.isFetching()}
          rightIcon={<Icon as={FaTrashRestoreAlt} />}
        >
          Restore
        </Button>
      </ModalFooter>
    </form>
  );
};

function useRestoreKeyword(keywordId: string) {
  const app = useApp();
  const accessToken = app.getAccessToken();

  return useMutation(async () => {
    const backend = await app.getBackend();
    return backend.restoreKeyword({keywordId, accessToken});
  });
}
