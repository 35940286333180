import React, {useState} from 'react';
import {
  Alert,
  Box,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Grid,
  Checkbox,
  Flex
} from '@chakra-ui/react';
import {useQuery} from 'react-query';
import {unparse} from 'papaparse';
// TODO: add 'copy-to-clipboard' to package.json instead of relying on `react-use` sub-dependency
// Problem with `react-use`: options are not exposed and we cannot set the `text/plain` format
import copyToClipboard from 'copy-to-clipboard';
import uniq from 'lodash/uniq';

import {ItemSelection, orderItems, SearchOptions, useApp, usePreferences} from 'shared';
import {Button, Spinner} from 'components/core';
import {pick} from 'lodash';

type ExportField = {
  key: string;
  label: string;
  fieldName?: string;
  isSelected?: boolean;
  accessor?: (value: string) => string;
};

const fieldSetup: ExportField[] = [
  {key: 'id', label: 'Scan ID'},
  {
    key: 'scanURL',
    fieldName: 'id',
    label: 'Scan URL',
    accessor: (id) => `${window.location.origin}/scans/${id}`
  },
  {key: 'imageId', label: 'Image ID'},
  {
    key: 'imageURL',
    fieldName: 'imageId',
    label: 'Image URL',
    accessor: (id) => `${window.location.origin}/images/${id}`
  },
  {key: 'imageFilename', label: 'Filename'},
  {key: 'supplier', label: 'Supplier'},
  {key: 'specimenType', label: 'Type of specimen'},
  {key: 'organ', label: 'Organ'},
  {key: 'disease', label: 'Disease'},
  {key: 'comments', label: 'Comments'},
  {key: 'tags', label: 'Tags'},
  {key: 'customField1', label: 'Custom field 1'},
  {key: 'customField2', label: 'Custom field 2'},
  {key: 'customField3', label: 'Custom field 3'}
];

type Props = {
  searchOptions: SearchOptions;
  selection: ItemSelection;
  onClose: () => void;
};
export const DialogExportCSV = ({searchOptions, selection, onClose}: Props) => {
  const [defaultColumnNames, setDefaultColumnNames] = usePreferences('exportColumns');
  const [fields, setFields] = useState<ExportField[]>(
    fieldSetup.map((item) => ({...item, isSelected: defaultColumnNames.includes(item.key)}))
  );
  const handleChange = (name, value) =>
    setFields((fields) =>
      fields.map((field) => ({
        ...field,
        isSelected: field.key === name ? value : field.isSelected
      }))
    );

  const columns = fields.filter(({isSelected}) => Boolean(isSelected));

  const onCopy = () => setDefaultColumnNames(columns.map((column) => column.key));

  return (
    <>
      <ModalHeader>Export to CSV</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Box mb={4}>Pick the fields to include and push the "Copy" button</Box>
        <Grid templateColumns="50% 1fr">
          {fields.map((field) => (
            <Checkbox
              key={field.key}
              isChecked={field.isSelected}
              onChange={(event) => handleChange(field.key, event.target.checked)}
            >
              {field.label}
            </Checkbox>
          ))}
        </Grid>
        <Box h="320px" mt={8}>
          {columns.length > 0 ? (
            <CSVData
              searchOptions={searchOptions}
              columns={columns}
              selection={selection}
              onCopy={onCopy}
            />
          ) : (
            <Alert status="warning">Pick one column at least!</Alert>
          )}
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => onClose()}>Close</Button>
      </ModalFooter>
    </>
  );
};

const CSVData = ({
  searchOptions,
  selection,
  columns,
  onCopy
}: Pick<Props, 'searchOptions' | 'selection'> & {columns: ExportField[]; onCopy: () => void}) => {
  const {data: scans, isLoading, error} = useFetchScans({searchOptions, selection, columns});

  if (error) {
    return <Alert status="error">Unable to load the scans</Alert>;
  }
  if (isLoading) {
    return (
      <Box bg="gray.100" h="100%">
        <Spinner />
      </Box>
    );
  }

  return <ViewCSV scans={scans} onCopy={onCopy} columns={columns} />;
};

const ViewCSV = ({
  scans,
  onCopy,
  columns
}: {
  scans: Datastore.Scan[];
  onCopy: () => void;
  columns: ExportField[];
}) => {
  const [copiedValue, copy] = useCopyToClipboard();
  const cleanedScans = scans
    .map((scan) => addComputedFields(scan, columns))
    .map((scan) => processScan(scan, columns))
    .map(cleanScanTextFields);
  const csvContent = unparse(cleanedScans) as string;

  const isCopied = csvContent === copiedValue;

  return (
    <Flex h="100%" flexDir="column">
      <Box overflow="scroll" p={4} bg="gray.100" flexGrow={1}>
        <pre style={{color: 'black'}}>{csvContent}</pre>
      </Box>
      <Box pt={4}>
        <Button
          onClick={() => {
            copy(csvContent);
            onCopy();
          }}
          colorScheme="primary"
          variant="outline"
          bg="white"
          w="100%"
          display="block"
        >
          {isCopied ? 'Copied' : `Copy ${scans.length + 1} rows`}
        </Button>
      </Box>
    </Flex>
  );
};

function useFetchScans({
  searchOptions,
  columns,
  selection
}: Pick<Props, 'searchOptions' | 'selection'> & {columns: ExportField[]}) {
  const app = useApp();
  const {query, offset, orderBy, orderDirection} = searchOptions;
  const returnFields = getReturnFields(columns);
  const limit = 1000; // let users export up to 1000 scans instead of the usual pagination limit... is it OK to export scans not on the screen?

  // "ItemSelection" behaves a bit differently than when doing bulk updates:
  // selecting all items means all item in the current page (and not all the results of the search)
  // the reason: we want to export the documents on the screen and limit the amount of document returned.
  // This is why we have to adjust both `offset` and `limit` parameters.
  const loadScans = async () => {
    const backend = await app.getBackend();
    const accessToken = app.getAccessToken();

    const {scans} = await backend.findScans({
      query,
      selection,
      returnFields,
      offset: selection.mode === 'pick' ? 0 : offset, // no pagination when the user picks items
      orderBy,
      orderDirection,
      limit: selection.mode === 'omit' ? limit - selection.itemIds!.size : limit,
      accessToken
    });
    // Preserve the order from the original file when exporting after having picked a CSV file
    return selection.mode === 'pick' ? orderItems(scans, Array.from(selection!.itemIds!)) : scans;
  };
  return useQuery(['csv', searchOptions, returnFields], loadScans, {keepPreviousData: false});
}

function useCopyToClipboard() {
  const [value, setValue] = useState('');

  const write = (text: string): void => {
    setValue(text);
    copyToClipboard(text, {format: 'text/plain'}); // we need `text/plain` to handle correctly end of lines
  };

  return [value, write] as const;
}

function processScan(scan: Datastore.Scan, columns: ExportField[]) {
  const displayedFieldNames = columns.map((column) => column.key);
  return pick(scan, displayedFieldNames);
}

function getReturnFields(columns: ExportField[]): string[] {
  return uniq(['id', ...columns.map((field) => field.fieldName || field.key)]);
}

function cleanScanTextFields(scan: Partial<Datastore.Scan>) {
  const fields = ['comments', 'customField1', 'customField2', 'customField3'];
  let updatedScan = {...scan};
  fields.forEach((field) => {
    if (scan[field]) {
      updatedScan[field] = cleanInputBeforeExport(scan[field]);
    }
  });
  return updatedScan;
}

function addComputedFields(scan: Datastore.Scan, columns: ExportField[]) {
  let updatedScan = {...scan};
  columns.forEach((field) => {
    if (field.accessor) {
      updatedScan[field.key] = field.accessor(scan[field.fieldName!]);
    }
  });
  return updatedScan;
}

function cleanInputBeforeExport(input: string): string {
  const result = input
    .replace(/，/g, ',') // remove double-bytes commas, interpreted as a column separator by Google spreadsheet
    .replace(/\n/g, ' ') // remove end-of-lines
    .trim();
  return result;
}
