import {Icon} from '@chakra-ui/react';
import {createContainer} from 'unstated-next';
import {MdRemove} from 'react-icons/md';

import {PluginSettings} from 'components/image-viewer/types';
import {AddIcon, useKeyHandler} from 'components/core';
import {createPlugin} from '..';
import {ImageContainer} from 'components/image-viewer/image-container';
import {ToolbarButton} from 'components/image-viewer/button';

export const ZoomPlugin = (settings?: PluginSettings) => {
  return createPlugin({
    displayName: 'ZoomPlugin',
    Provider: (props) => <ZoomPluginContainer.Provider {...props} />,
    decorateSidebar(sidebar) {
      // Put the zoom buttons before any other group of buttons
      return [
        {
          key: 'ZOOM_GROUP',
          buttons: [<ZoomInButton key="zoomIn" />, <ZoomOutButton key="zoonOut" />]
        },
        ...sidebar
      ];
    }
  });
};

function useZoomState() {
  const {getViewer, image} = ImageContainer.useContainer();

  const zoomBy = (viewer, factor) => {
    const {viewport} = viewer;
    if (viewport) {
      viewport.zoomBy(factor);
      viewport.applyConstraints();
    }
  };

  const zoomIn = () => {
    const viewer = getViewer();
    zoomBy(viewer, viewer.zoomPerClick / 1.0);
  };

  const zoomOut = () => {
    const viewer = getViewer();
    zoomBy(viewer, 1.0 / viewer.zoomPerClick);
  };

  useKeyHandler({viewId: image.id, value: 'z', onDown: zoomIn});
  useKeyHandler({viewId: image.id, value: 'x', onDown: zoomOut});

  return {zoomIn, zoomOut};
}

const ZoomPluginContainer = createContainer(useZoomState);

const ZoomInButton = () => {
  const {zoomIn} = ZoomPluginContainer.useContainer();
  return (
    <ToolbarButton onClick={zoomIn}>
      <Icon as={AddIcon} />
    </ToolbarButton>
  );
};

const ZoomOutButton = () => {
  const {zoomOut} = ZoomPluginContainer.useContainer();
  return (
    <ToolbarButton onClick={zoomOut}>
      <Icon as={MdRemove} boxSize="24px" />
    </ToolbarButton>
  );
};
