import {Box, useToast} from '@chakra-ui/react';
import {AddIcon, Button, Spinner, useModal} from 'components/core';
import {useQuery} from 'react-query';

import {KeywordCategory, KeywordItem, useApp, usePermissions} from 'shared';
import {SettingsHeading} from '../settings-shared';
import {DialogAddKeyword} from './dialog-add-keyword';
import {DialogDeleteKeyword, DialogRestoreKeyword} from './dialog-delete-keyword';
import {DialogEditKeyword} from './dialog-edit-keyword';
import {KeywordList} from './keyword-list';

type Props = {
  category: KeywordCategory;
  heading: React.ReactNode;
};
export const KeywordManagement = ({category, heading}: Props) => {
  const modal = useModal();
  const toast = useToast();
  const {hasPermission} = usePermissions();
  const {data: allKeywords, isLoading} = useFetchKeywords();

  if (isLoading) {
    return <Spinner />;
  }

  const keywords = allKeywords[category] || [];

  const addKeyword = async () => {
    const input: KeywordItem = await modal.dialog({
      render: (close) => <DialogAddKeyword onClose={close} category={category} />,
      modalProps: {size: 'xl'}
    });
    if (!input) return;
    toast({
      title: 'Keyword created',
      description: `Code: ${input.value}, label: ${input.label}`,
      status: 'success',
      isClosable: true
    });
  };

  const editKeyword = async (item: KeywordItem) => {
    const updatedLabel = await modal.dialog({
      render: (close) => <DialogEditKeyword onClose={close} category={category} item={item} />,
      modalProps: {size: 'xl'}
    });
    if (!updatedLabel) return;
    toast({
      title: 'Keyword updated',
      description: `${item.label}  => ${updatedLabel}`,
      status: 'success',
      isClosable: true
    });
  };

  const deleteKeyword = async (item: KeywordItem) => {
    const deletedItem = await modal.dialog({
      render: (close) => <DialogDeleteKeyword onClose={close} category={category} item={item} />,
      modalProps: {size: 'lg'}
    });
    if (!deletedItem) return;
    toast({
      title: 'Keyword deleted',
      description: `Code: ${deletedItem.value}, label: ${deletedItem.label}`,
      status: 'success',
      isClosable: true
    });
  };

  const restoreKeyword = async (item: KeywordItem) => {
    const restoredItem = await modal.dialog({
      render: (close) => <DialogRestoreKeyword onClose={close} category={category} item={item} />,
      modalProps: {size: 'xl'}
    });
    if (!restoredItem) return;
    toast({
      title: 'Keyword restored',
      description: `Code: ${restoredItem.value}, label: ${restoredItem.label}`,
      status: 'success',
      isClosable: true
    });
  };

  const canAddKeyword = hasPermission('settings/edit');
  const canEditKeyword = hasPermission('settings/edit');
  const canDeleteKeyword = hasPermission('settings/edit');

  return (
    <>
      <SettingsHeading count={keywords.length}>{heading}</SettingsHeading>
      <Box overflowY="scroll">
        <KeywordList
          category={category}
          keywords={keywords}
          onEdit={canEditKeyword ? editKeyword : undefined}
          onDelete={canDeleteKeyword ? deleteKeyword : undefined}
          onRestore={canDeleteKeyword ? restoreKeyword : undefined}
        />
      </Box>
      {canAddKeyword && (
        <Box py={4}>
          <Button onClick={addKeyword} primary leftIcon={<AddIcon />}>
            Add
          </Button>
        </Box>
      )}
    </>
  );
};

function useFetchKeywords() {
  const app = useApp();

  const loadKeywords = async () => {
    const accessToken = app.getAccessToken();
    const backend = await app.getBackend();
    const returnFields = [
      'id',
      'value',
      'label',
      'category',
      'createdOn',
      'updatedOn',
      'deletedOn'
    ];
    const {keywords} = await backend.findKeywords({returnFields, accessToken});
    return keywords;
  };

  return useQuery(['keywords', 'full-list'], loadKeywords, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
}
