import React, {Suspense, lazy} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Center} from '@chakra-ui/react';

import {FullScreenProvider, useApp} from 'shared';
import {useAppUpdateChecker} from 'app-update-checker';
import Home from './home';
import {NoAccess} from './no-access';
import {NotFound} from './sorry';
import {FullHeight, KeyProvider, Spinner} from './core';

// Lazy loading of the Image Viewer pages to avoid including OpenSeadragon in the default bundle
const ImageHome = lazy(() => import('./image-home'));
const PredictionViewerPage = lazy(() => import('./evaluations/prediction-viewer-page'));

export const Root = () => {
  useAppUpdateChecker({
    interval: 60 * 1000, // check if an app update is available every minute
    isSimulationMode: false // set to true to check the notification in dev
  });

  return (
    <FullScreenProvider>
      <Suspense fallback={<FullHeightSpinner />}>
        <KeyProvider>
          <Router>
            <Switch>
              <PrivateRoute path="/images" component={ImageHome} />
              <PrivateRoute
                path="/evaluations/:evaluationId/predictions/:imageId?"
                component={PredictionViewerPage}
              />
              <PrivateRoute path="/" component={Home} />
              <Route component={NotFound} />
            </Switch>
          </Router>
        </KeyProvider>
      </Suspense>
    </FullScreenProvider>
  );
};

const PrivateRoute = ({component: Component, ...rest}) => {
  const app = useApp();
  return (
    <Route
      {...rest}
      render={(props) => {
        const {user} = app.state;

        if (!user) {
          app.signIn();
          return;
        }

        if (user.accessLevel === 0) {
          return <NoAccess />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

const FullHeightSpinner = () => {
  return (
    <FullHeight growable={false}>
      <Center h="100%">
        <Spinner size="xl" />
      </Center>
    </FullHeight>
  );
};

export default Root;
