import React, {useState} from 'react';
import {
  Alert,
  Code,
  FormControl,
  FormLabel,
  Input,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack
} from '@chakra-ui/react';
import {useMutation, useQueryClient} from 'react-query';
import startCase from 'lodash/startCase';

import {KeywordCategory, KeywordItem, useApp, useLocale} from 'shared';
import {Button} from 'components/core';
import invariant from 'tiny-invariant';

type Props = {
  category: KeywordCategory;
  item: KeywordItem;
  onClose: (label?: string) => void;
};
export const DialogEditKeyword = ({category, item, onClose}: Props) => {
  const locale = useLocale();
  const [label, setLabel] = useState<string>(item.label);
  const categoryDisplayName = startCase(category.toLowerCase());

  invariant(item.id);
  const mutation = useEditKeyword(item.id);
  const queryClient = useQueryClient();
  const isFormValid = label !== item.label && label.trim().length > 1;

  const submit = () => {
    mutation.mutate(label, {
      onSuccess: async () => {
        await queryClient.invalidateQueries('keywords');
        setTimeout(() => onClose(label));
      }
    }); // does not return a Promise, we have to use `onSuccess` callback
  };
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        submit();
      }}
    >
      <ModalHeader>Edit {categoryDisplayName}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <VStack spacing={6}>
          <FormControl id="value">
            <FormLabel>
              {categoryDisplayName} code <i>(Not editable)</i>
            </FormLabel>
            <Code>{item.value}</Code>
          </FormControl>
          <FormControl id="displayName">
            <FormLabel>{categoryDisplayName} name on the screen</FormLabel>
            <Input value={label} onChange={(event) => setLabel(event.target.value)} />
          </FormControl>
          {mutation.isError && !mutation.isLoading && (
            <Alert status="error">
              Unable to update the keyword {(mutation.error as Error)?.message}
            </Alert>
          )}
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button type="button" onClick={() => onClose()} mr={2} isDisabled={mutation.isLoading}>
          {locale.cancelButtonLabel}
        </Button>
        <Button
          type="submit"
          primary
          isDisabled={!isFormValid}
          isLoading={mutation.isLoading || !!queryClient.isFetching()}
        >
          Save
        </Button>
      </ModalFooter>
    </form>
  );
};

function useEditKeyword(keywordId: string) {
  const app = useApp();
  const accessToken = app.getAccessToken();

  return useMutation(async (label: string) => {
    const backend = await app.getBackend();
    const changes = {label};
    return backend.updateKeyword({keywordId, changes, accessToken});
  });
}
