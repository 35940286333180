import {useEffect, useState} from 'react';

export function useFetchScans({offset, limit, app}) {
  const [{scans, totalNumberOfScans}, setScanData] = useState({scans: [], totalNumberOfScans: 0});
  const [isLoading, setLoading] = useState(true);

  async function fetchScans() {
    setLoading(true);
    await app.task(async () => {
      const {scans, totalNumberOfScans} = await app.findTrialScans({offset, limit});
      setLoading(false);
      setScanData({scans, totalNumberOfScans});
    });
  }

  useEffect(
    () => {
      fetchScans();
    },
    [offset, limit] // eslint-disable-line
  );

  return {scans, totalNumberOfScans, isLoading, reload: fetchScans};
}
