import React, {useMemo} from 'react';
import {Box} from '@chakra-ui/react';

import {useLocale} from 'shared';
import {Table} from 'components/core';

export const BillingView = ({users}) => {
  const locale = useLocale();

  const data = useMemo(() => users, [users]);
  const columns = useMemo(
    () => [
      {
        Header: 'Email',
        accessor: (user) => user.account.email
      },
      {
        Header: 'First name',
        accessor: (user) => user.account.firstName
      },
      {
        Header: 'Last name',
        accessor: (user) => user.account.lastName
      },
      {
        Header: locale.todo('Total of annotated images'),
        accessor: (user) => user.count,
        styleProps: {
          textAlign: 'right',
          fontWeight: 'bold'
        }
      }
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );
  return (
    <Box overflow="auto">
      <Table data={data} columns={columns} size="sm" />
    </Box>
  );
};
