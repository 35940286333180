import {useQuery, QueryObserverOptions, useMutation} from 'react-query';
import {createContainer} from 'unstated-next';

import rawLabelGroups from 'data/ai-label-groups.json';
import {useApp} from 'shared';
import {AILabel, AIModel, AIPredictionLabelGroup} from './types';

export type AIData = {
  labels: AILabel[];
  labelGroups: AIPredictionLabelGroup[];
  models: AIModel[];
};
export type EditableLabel = Pick<AILabel, 'modelName' | 'displayName' | 'color' | 'type'>;
export type NewLabel = Omit<AILabel, 'model'>;

export function useFetchAILabels(queryOptions?: QueryObserverOptions<any, any>) {
  const app = useApp();

  const loadLabels = async () => {
    const accessToken = app.getAccessToken();
    const backend = await app.getBackend();
    try {
      const {settings} = await backend.findAILabels({accessToken});
      const data = deserializeAISettingsResponse(settings);
      return data;
    } catch (error) {
      console.error(`Error returned by AI labels API!`, (error as Error).message);
      return {labels: [], labelGroups: [], models: []};
    }
  };

  return useQuery<AIData, Error>(['ai-labels'], loadLabels, {refetchOnWindowFocus: false});
}

// a simple container to make AI data available from the Scan table cells, in order to show WSI labels
export const AIDataContainer = createContainer(useFetchAILabels);

export function useCreateAILabel({models}) {
  const app = useApp();
  const accessToken = app.getAccessToken();

  return useMutation(async (newLabel: NewLabel) => {
    const backend = await app.getBackend();
    const values = {...formatLabelDataBeforeSubmitting(models, newLabel), annotationType: 'PATH'};
    return backend.createAILabel({values, accessToken});
  });
}

export function useCreateAIModel() {
  const app = useApp();
  const accessToken = app.getAccessToken();

  return useMutation(async (displayName: string) => {
    const backend = await app.getBackend();
    return backend.createAIGroup({values: {displayName}, accessToken});
  });
}

export function useUpdateAILabel({models, labelName}) {
  const app = useApp();
  const accessToken = app.getAccessToken();

  return useMutation(async (values: EditableLabel) => {
    const backend = await app.getBackend();
    const changes = formatLabelDataBeforeSubmitting(models, values);
    return backend.updateAILabel({labelName, changes, accessToken});
  });
}

export function useDeleteAILabel({labelName}) {
  const app = useApp();
  const accessToken = app.getAccessToken();

  return useMutation(async () => {
    const backend = await app.getBackend();
    return backend.deleteAILabel({labelName, accessToken});
  });
}

export function useDeleteAIModel() {
  const app = useApp();
  const accessToken = app.getAccessToken();

  return useMutation(async (id: string) => {
    const backend = await app.getBackend();
    return backend.deleteAIGroup({id: id.toString(), accessToken});
  });
}

function deserializeAISettingsResponse(values): AIData {
  const getModelByOrganId = (organId) => {
    const organ = values.organs.find(({id}) => id === organId);
    return organ || {name: `organ-${organId}`};
  };

  const labels = values.labels.map((label) => {
    const model = getModelByOrganId(label.organId);
    return {
      ...label,
      modelName: model.name,
      model
    };
  });

  const labelGroups: AIPredictionLabelGroup[] = rawLabelGroups.map((group) => {
    const model = getModelByOrganId(group.modelName);
    const foundLabels = group.labelNames.map((labelName) => getAILabelByName(labels, labelName));
    return {
      ...group,
      labels: foundLabels,
      model
    };
  });

  const models = values.groups.map((group) => ({
    ...group,
    labelCount: findNumberOfLabelByModel(labels, group.name)
  }));

  return {
    labels,
    labelGroups,
    models
  };
}

function findNumberOfLabelByModel(labels: AILabel[], modelName: string) {
  return labels.filter((label) => label.modelName === modelName).length;
}

export function getAILabelByName(labels: AILabel[], labelName: string) {
  return labels.find((label) => label.name === labelName) || generateDefaultAILabel(labelName);
}

function generateDefaultAILabel(labelName: string): AILabel {
  const modelName = 'unknown-model';
  return {
    name: labelName,
    displayName: labelName,
    model: {
      name: modelName,
      displayName: 'Unknown model'
    },
    modelName,
    color: '#aaa'
  };
}

export function getAIModelByName(models: AIModel[], modelName: string) {
  return models.find((model) => model.name === modelName);
}

// `modelName` => `organId`
function formatLabelDataBeforeSubmitting(models: AIModel[], values: EditableLabel) {
  const {modelName, ...rest} = values;
  const model = getAIModelByName(models, modelName);
  const organId = model?.id || undefined;
  return {...rest, organId};
}

export type LabelCounts = {
  annotatedImageCount: number;
  annotationCount: number;
  wsiLabelCount: number;
};
export type LabelWithCounts = AILabel & Partial<LabelCounts>;
export type LabelStatistics = {
  [labelName: string]: LabelCounts;
};

export function useFetchAILabelsStatistics() {
  const app = useApp();

  const loadStats = async () => {
    const accessToken = app.getAccessToken();
    const backend = await app.getBackend();
    const results = await backend.generateLabelsStatistics({accessToken});
    return results.countsByLabel || {};
  };

  return useQuery<LabelStatistics, Error>(['annotation-labels-stats'], loadStats, {
    refetchOnReconnect: false
  });
}
