import React, {useState} from 'react';
import {
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Stack
} from '@chakra-ui/react';

import {useLocale} from 'shared';
import {Button} from 'components/core';
import {AccessLevelPicker, RolePicker} from './user-fields';

export const UserAdd = ({onClose}) => {
  const locale = useLocale();
  const [values, setValues] = useState({email: '', accessLevel: 1, roles: []});
  const {email, accessLevel, roles} = values;

  const handleEmailChange = (email) => setValues((values) => ({...values, email}));

  const handleAccessLevelChange = (accessLevel) =>
    setValues((values) => ({...values, accessLevel}));

  const handleRolesChange = (roles) => setValues((values) => ({...values, roles}));

  const cancel = async () => {
    onClose(false);
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onClose({email, accessLevel, roles});
      }}
    >
      <ModalHeader>
        {locale.todo('Add user')}
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Stack spacing={6}>
          <FormControl id="email">
            <FormLabel>{locale.todo(`Medmain account's email`)}</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(event) => handleEmailChange(event.target.value)}
              required
              autoFocus
            />
          </FormControl>
          <FormControl id="accessLevel">
            <FormLabel>{locale.todo('Access level')}</FormLabel>
            <AccessLevelPicker accessLevel={accessLevel} onChange={handleAccessLevelChange} />
          </FormControl>
          <FormControl id="roles">
            <FormLabel>{locale.todo('Roles')}</FormLabel>
            <RolePicker roles={roles} onChange={handleRolesChange} />
          </FormControl>
        </Stack>

        <Flex justifyContent="flex-end" mt={6}>
          <ButtonGroup>
            <Button type="button" onClick={cancel}>
              {locale.cancelButtonLabel}
            </Button>
            <Button type="submit" primary disabled={!email}>
              {locale.todo('Add')}
            </Button>
          </ButtonGroup>
        </Flex>
      </ModalBody>
    </form>
  );
};
