import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Box, BoxProps, Flex, IconButton, Link} from '@chakra-ui/react';
import {FiMenu} from 'react-icons/fi';

import {PidPortLogo} from './pidport-logo';
import {UserMenu} from './user-menu';

export const AppHeader = ({sidebar, height}) => {
  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      w="full"
      pl={4}
      pr={6}
      bg="white"
      borderBottomWidth="1px"
      h={height}
      zIndex={1101}
      position="fixed"
    >
      <Flex alignItems="center">
        <IconButton
          aria-label="Menu"
          display={{base: 'inline-flex', showLeftSidebar: 'none'}}
          onClick={sidebar.onOpen}
          icon={<FiMenu fontSize="24px" color="gray.500" />}
          size="sm"
          borderRadius="md"
          mr={3}
          variant="ghost"
        />
        <Link
          as={RouterLink}
          to="/"
          display="flex"
          mt="-2px"
          lineHeight={1}
          _hover={{textDecoration: 'none'}}
        >
          <PidPortLogo width={130} />
          <Flex alignItems="flex-end">
            <Box mt={2} ml={3} color="rgba(0, 0, 0, 0.6)" fontSize="1.25rem" letterSpacing={1}>
              Datastore
            </Box>
            <EnvironmentMark ml={2} transform="translateY(-1px)" />
          </Flex>
        </Link>
      </Flex>
      <UserMenu />
    </Flex>
  );
};

// Show an indication about the current environment, when we are not in production.
const EnvironmentMark = (props: BoxProps) => {
  const envName = process.env.REACT_APP_ENV_NAME;
  const color = process.env.REACT_APP_ENV_COLOR || 'primary.300';
  if (!envName) return null;

  return (
    <Box
      backgroundColor={color}
      px="5px"
      py="2px"
      color="white"
      textTransform="uppercase"
      fontSize="12px"
      fontWeight="bold"
      borderRadius="4px"
      {...props}
    >
      {envName}
    </Box>
  );
};
