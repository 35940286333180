import {useHistory, useLocation} from 'react-router-dom';

export type SearchQueryItem = {
  field: string;
  operator: string;
  value: string;
};

export type SortDirection = 'ASC' | 'DESC';

export interface SearchOptions {
  query: SearchQueryItem[];
  offset?: number;
  limit?: number;
  orderBy?: string;
  orderDirection?: SortDirection;
}

const globalDefaultSearchParams: SearchOptions = {
  query: [],
  orderBy: 'createdOn',
  orderDirection: 'DESC',
  offset: 0,
  limit: 50
};

export function useParseURL(viewDefaultSearchParams?: Partial<SearchOptions>) {
  const location = useLocation();
  const defaultSearchParams = {
    ...globalDefaultSearchParams,
    ...viewDefaultSearchParams
  };
  const searchParams = parseSearchParams(location.search, defaultSearchParams);
  return searchParams;
}

export function usePagination({
  searchOptions,
  total
}: {
  searchOptions: Required<SearchOptions>;
  total: number;
}) {
  const history = useHistory();
  const {offset, limit} = searchOptions;

  function goToNextPage() {
    searchOptions.offset += limit;
    history.replace({search: formatSearchParams(searchOptions)});
  }

  function goToPreviousPage() {
    searchOptions.offset -= searchOptions.limit;
    if (searchOptions.offset < 0) {
      searchOptions.offset = 0;
    }
    history.replace({search: formatSearchParams(searchOptions)});
  }

  return {
    goToPreviousPage,
    goToNextPage,
    hasPreviousPage: offset > 0,
    hasNextPage: offset + limit < total
  };
}

export function useSearch({searchOptions}: {searchOptions: SearchOptions}) {
  const history = useHistory();

  const updateLocationSearch = (changes) => {
    return formatSearchParams({...searchOptions, ...changes, offset: undefined});
  };

  function changeSortingOrder(path: string) {
    const {orderBy, orderDirection} = searchOptions;
    const isBooleanColumn = path.startsWith('has'); // TODO setup the default sort order when clicking in the table cols
    const isDateColumn = path.endsWith('On');
    const defaultSortOrder = isBooleanColumn || isDateColumn ? 'DESC' : 'ASC';
    const changes =
      path !== orderBy
        ? {orderBy: path, orderDirection: defaultSortOrder}
        : {orderDirection: orderDirection === 'ASC' ? 'DESC' : 'ASC'};

    history.replace({search: updateLocationSearch(changes)});
  }

  function removeQueryFilter(field: String) {
    const changes = {query: searchOptions.query.filter((item) => item.field !== field)};
    history.push({search: updateLocationSearch(changes)});
  }

  function removeAllQueryFilters() {
    const changes = {query: []};
    history.push({search: updateLocationSearch(changes)});
  }

  return {
    changeSortingOrder,
    removeQueryFilter,
    removeAllQueryFilters,
    updateLocationSearch
  };
}

export function parseSearchParams(
  queryString: string,
  defaults: SearchOptions = globalDefaultSearchParams
): Required<SearchOptions> {
  const searchParams = new URLSearchParams(queryString);

  const queryParam = searchParams.get('query');
  const query = queryParam ? JSON.parse(queryParam) : defaults.query;

  const orderByParam = searchParams.get('orderBy');
  const orderBy = orderByParam ? orderByParam : defaults.orderBy!;

  const orderDirectionParam = searchParams.get('orderDirection');
  const orderDirection = orderDirectionParam
    ? (orderDirectionParam as SearchOptions['orderDirection'])
    : defaults.orderDirection;

  const offsetParam = searchParams.get('offset');
  const offset = offsetParam ? Number(offsetParam) : defaults.offset;

  const limitParam = searchParams.get('limit');
  const limit = limitParam ? Number(limitParam) : defaults.limit;

  return {
    query,
    orderBy,
    orderDirection: orderDirection as SortDirection,
    offset: offset as number,
    limit: limit as number
  };
}

export function formatSearchParams(searchParams: SearchOptions): string {
  let formattedSearchParams = {};

  for (let [name, value] of Object.entries(searchParams)) {
    if (typeof value === 'object') {
      value = stringifyObject(value);
    } else if (typeof value === 'number') {
      value = String(value);
    }

    if (value !== undefined) {
      formattedSearchParams[name] = value;
    }
  }

  formattedSearchParams = new URLSearchParams(formattedSearchParams);
  return formattedSearchParams.toString();
}

// TODO: where to handle the logic defined in Query.isEmpty() method?
function stringifyObject(value) {
  if (typeof value.isEmpty === 'function') {
    if (value.isEmpty()) return undefined;
  }
  if (Array.isArray(value)) {
    if (value.length === 0) return undefined;
  }
  return JSON.stringify(value);
}
