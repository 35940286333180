import React, {useState, useMemo} from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  ButtonGroup
} from '@chakra-ui/react';
import {useLatest} from 'react-use';
import {createContainer} from 'unstated-next';

import {Button} from './button';

// The public imperative API to be used by components
export function useModal() {
  const {open} = ModalContainer.useContainer();

  const actions = useMemo(() => {
    function dialog<T = any>(options: Omit<Options, 'resolve'>) {
      return open<T>(options);
    }

    const alert = (content, options) => {
      const {modalProps, title, okButton} = options || {};
      return dialog({
        modalProps: modalProps,
        render: (close) => (
          <ModalAlert content={content} title={title || ''} close={close} okButton={okButton} />
        )
      });
    };

    const confirm = (content, options) => {
      const {modalProps, title, okButton, cancelButton} = options || {};
      return dialog({
        modalProps: modalProps,
        render: (close) => (
          <ModalConfirm
            content={content}
            close={close}
            title={title}
            okButton={okButton}
            cancelButton={cancelButton}
          />
        )
      });
    };
    return {dialog, alert, confirm};
  }, [open]);

  return actions;
}

type Options<T = any> = {
  resolve: (value: T) => void;
  render: (close: (value?: T) => void) => React.ReactNode;
  modalProps?: Partial<Omit<React.ComponentProps<typeof Modal>, 'children'>>;
};
type ModalState = Options[];

function useModalInternalState() {
  const [modalState, setModalState] = useState<ModalState>([]);
  const modalStateRef = useLatest(modalState);

  const actions = useMemo(() => {
    function open<T>(options: Omit<Options, 'resolve'>) {
      return new Promise<T>((resolve) => {
        setModalState((state) => [...state, {...options, resolve}]);
      });
    }

    const close = (value) => {
      const modal = modalStateRef.current[modalStateRef.current.length - 1];
      modal.resolve(value);
      setModalState((state) => state.slice(0, -1));
    };
    return {open, close};
  }, [modalStateRef]);

  return {
    modalState,
    ...actions
  };
}

export const ModalContainer = createContainer(useModalInternalState);

export const ModalRoot = () => {
  const {close, modalState} = ModalContainer.useContainer();
  if (modalState.length === 0) return null;

  return (
    <>
      {modalState.map(({modalProps, render}, id) => (
        <Modal key={`Modal-${id}`} isOpen onClose={() => close(false)} {...modalProps}>
          <ModalOverlay />
          <ModalContent>{render(close)}</ModalContent>
        </Modal>
      ))}
    </>
  );
};

type Props = {
  content: React.ReactNode;
  close: (value: any) => void;
  title?: string | React.ReactNode;
  okButton?: string;
  cancelButton?: string;
};

const ModalAlert = ({title, content, okButton = 'OK', close}: Props) => {
  return (
    <>
      {title && (
        <ModalHeader>
          {title}
          <ModalCloseButton />
        </ModalHeader>
      )}
      <ModalBody>{content}</ModalBody>
      <ModalFooter>
        <Button onClick={() => close(true)}>{okButton}</Button>
      </ModalFooter>
    </>
  );
};

const ModalConfirm = ({title, content, close, okButton = 'OK', cancelButton = 'Cancel'}: Props) => {
  return (
    <>
      {title && (
        <ModalHeader>
          {title}
          <ModalCloseButton />
        </ModalHeader>
      )}
      <ModalBody>{content}</ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button onClick={() => close(false)}>{cancelButton}</Button>
          <Button primary onClick={() => close(true)}>
            {okButton}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </>
  );
};

export const withModal = (Wrapped) => {
  const Component = (props) => {
    const modal = useModal();
    return <Wrapped {...props} modal={modal} />;
  };
  Component.displayName = `${Wrapped.displayName || Wrapped.name || 'Component'}WithModal`;
  return Component;
};
