import React, {useMemo} from 'react';
import {Box, Stack} from '@chakra-ui/react';

import {useLocale} from 'shared';
import {formatDateTime, Table, TimeAgo, WarningIcon} from 'components/core';
import {BatchNumberLink, FilenameLink, TagLinkGroup} from 'components/scan-list/scan-columns';
import {ImageThumbnail} from 'components/scan-list/image-thumbnail';
import {isProcessingLate} from './dashboard-utils';

export const ScanCompactTable = ({scans}) => {
  const {filename, size, supplier, batch, user, imageUpdateDate} = useColumns();

  const columns = useMemo(() => [filename, size, supplier, batch, user, imageUpdateDate], []); // eslint-disable-line react-hooks/exhaustive-deps
  const data = useMemo(() => scans, [scans]);

  return (
    <Box borderWidth="1px">
      <Table columns={columns} data={data} size="sm" />
    </Box>
  );
};

export const ScanImageTable = ({scans, ...props}) => {
  const {image, details, size, batch, supplier, user, imageUpdateDate} = useColumns();

  const columns = useMemo(() => [image, details, size, supplier, batch, user, imageUpdateDate], []); // eslint-disable-line react-hooks/exhaustive-deps
  const data = useMemo(() => scans, [scans]);

  return (
    <Box borderWidth="1px">
      <Table data={data} columns={columns} size="sm" {...props} />
    </Box>
  );
};

export const useColumns = () => {
  const locale = useLocale();

  return {
    image: {
      Header: 'Image',
      accessor: (scan) => {
        return <ImageThumbnail image={scan.image} size={80} />;
      },
      styleProps: {
        width: '82px',
        textAlign: 'center',
        px: 0
      }
    },
    details: {
      Header: 'Filename',
      accessor: (scan) => {
        return (
          <Stack>
            <FilenameLink scanId={scan.id} filename={scan.image.filename} />
            <TagLinkGroup scanTags={scan.tags} />
          </Stack>
        );
      },
      styleProps: {
        width: 'auto'
      }
    },
    filename: {
      Header: 'Filename',
      accessor: (scan) => {
        return <FilenameLink scanId={scan.id} filename={scan.image.filename} />;
      },
      styleProps: {
        width: 'auto'
      }
    },
    size: {
      Header: 'Size',
      accessor: (scan) => locale.formatFileSize(scan.image.size),
      styleProps: {
        width: '150px',
        textAlign: 'right'
      }
    },
    supplier: {
      Header: 'Supplier',
      accessor: (scan) => scan.supplier,
      styleProps: {
        width: '100px'
      }
    },
    batch: {
      Header: 'Batch',
      accessor: (scan) => <BatchNumberLink batchNumber={scan.imageBatchNumber} />,
      styleProps: {
        width: '80px'
      }
    },
    user: {
      Header: 'User',
      accessor: (scan) => {
        const user = scan.user || scan.image.user; // TODO fix the backend, the user can be assigned to the image or to the scan
        return user?.account?.email || '?';
      },
      styleProps: {
        width: '200px'
      }
    },
    imageUpdateDate: {
      Header: 'Updated',
      accessor: (scan: Datastore.Scan) => {
        const date = new Date(scan.image.updatedOn);
        const differenceInMinutes = (Date.now() - +date) / 1000 / 60;
        const showTime = differenceInMinutes > 45;
        const isLate = isProcessingLate(scan);
        return (
          <>
            <TimeAgo date={date} />
            {showTime && (
              <Box color={isLate ? 'orange.500' : 'gray.500'} fontWeight={600}>
                {formatDateTime(date, {mask: 'HH:mm'})}
                {isLate && <WarningIcon color="orange.500" />}
              </Box>
            )}
          </>
        );
      },
      styleProps: {
        width: '150px'
      }
    }
  };
};
