import React, {useEffect, useMemo, useState} from 'react';
import {Alert, Button as ChakraButton, Box, Flex, Icon, BoxProps} from '@chakra-ui/react';
import {IoMdPricetag} from 'react-icons/io';
import {MdEdit, MdGridOn} from 'react-icons/md';
import invariant from 'tiny-invariant';

import {ChevronUpIcon, DropdownMenu, MenuItem, Spinner} from 'components/core';
import {
  ItemSelection,
  orderItems,
  PaginationCount,
  SearchOptions,
  useLocale,
  useParseURL
} from 'shared';
import {useScanColumns} from 'components/scan-list/scan-table';
import {PaginatedScanList} from 'components/scan-list/scan-list-shared';
import {useFetchScans} from 'components/scan-list/fetch-scans';
import {useScanListActions} from 'components/scan-list/scan-list-actions';

type Props = {
  ids: string[];
} & BoxProps;
export const PreviewScans = ({ids, ...props}: Props) => {
  const locale = useLocale();

  // HACK: we use the URL to handle the pagination state
  // TODO: we should be able to navigate through results using a local state instead
  const searchOptions = useParseURL({limit: 50});
  const paginatedIds = paginateItems(ids, searchOptions);

  const [selection, setSelection] = useState<ItemSelection>(
    new ItemSelection({
      mode: 'pick',
      itemIds: new Set(paginatedIds),
      lastToggledItemId: undefined
    })
  );

  useEffect(() => {
    setSelection(
      new ItemSelection({
        mode: 'pick',
        itemIds: new Set(paginatedIds),
        lastToggledItemId: undefined
      })
    );
  }, [ids, searchOptions.offset, searchOptions.limit]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    data,
    error,
    isLoading,
    isFetching,
    refetch: reloadScans
  } = useFetchScans({...searchOptions, offset: 0}, undefined, selection); // we don't want to paginate in the server-side as the id list is already paginated!

  const {
    thumbnail,
    columns: {details, size, supplier, createdOn, organ}
  } = useScanColumns();

  const {addBulkWSILabels, exportCSV, replaceScans} = useScanListActions({
    selection,
    total: ids.length,
    reloadScans
  });

  const actionMenuItems: MenuItem[][] = [
    [
      {
        label: locale.todo('Export to CSV'),
        icon: <Icon as={MdGridOn} color="gray.500" fontSize="16x" />,
        onClick: exportCSV
      },
      {
        label: locale.todo('Replace fields'),
        icon: <Icon as={MdEdit} color="gray.500" fontSize="16x" />,
        onClick: replaceScans
      },
      {
        label: locale.todo('Add WSI label'),
        icon: <Icon as={IoMdPricetag} color="gray.500" />,
        onClick: addBulkWSILabels
      }
    ]
  ];

  const columns = useMemo(
    () => [thumbnail({size: 80}), details, size, supplier, organ, createdOn],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (isLoading || isFetching) return <Spinner />;
  if (error) return <Alert status="error">Unable to load the scans</Alert>;
  invariant(data);

  const {scans} = data;
  const total = ids.length;
  const orderedScans = orderItems(scans, paginatedIds);

  return (
    <PaginatedScanList
      scans={orderedScans}
      searchOptions={searchOptions}
      total={total}
      columns={columns}
      header={
        <Flex>
          Found: {total} scans
          <Box color="gray.500" ml={2}>
            <PaginationCount
              searchOptions={searchOptions}
              total={total}
              pageItemCount={scans.length}
            />
          </Box>
        </Flex>
      }
      footer={
        <Box>
          <DropdownMenu items={actionMenuItems} placement="top-start">
            <ChakraButton rightIcon={<ChevronUpIcon />} variant="outline">
              {locale.todo('Actions')}
            </ChakraButton>
          </DropdownMenu>
        </Box>
      }
    />
  );
};

function paginateItems(items: string[], searchOptions: Required<SearchOptions>) {
  const {limit, offset} = searchOptions;
  return items.slice(offset, limit + offset);
}
