import {useMemo} from 'react';
import {Box, Code, Flex, IconButton, Stack} from '@chakra-ui/react';
import {Link as RouterLink} from 'react-router-dom';
import numeral from 'numeral';

import {formatSearchParams, SearchQueryItem, useLocale} from 'shared';
import {Button, DeleteIcon, EditIcon, SearchIcon, Table} from 'components/core';
import {AILabel} from 'components/settings/types';
import {LabelWithCounts} from 'components/settings/ai-labels-api';

type Props = {
  labels: AILabel[];
  onEdit?: (labelName: string) => void;
  onDelete?: (labelName: string) => void;
  isLoadingStatistics: boolean;
};

const annotationTypes = {
  'normal-tissue': 'Normal tissue',
  'abnormal-tissue': 'Abnormal tissue'
};

export const LabelList = ({labels, onEdit, onDelete, isLoadingStatistics}: Props) => {
  const locale = useLocale();

  const columns = useMemo(
    () => [
      {
        Header: 'DisplayName',
        Cell: ({row: {original: label}}) => (
          <Flex alignItems="center">
            <Box
              w="7px"
              h="20px"
              bg={label.color}
              mr={2}
              borderRightWidth="1px"
              borderLeftWidth="1px"
            />
            {locale.get(label.displayName)}
          </Flex>
        )
      },
      {Header: 'Key', Cell: ({row: {original: label}}) => <Code>{label.name}</Code>},
      {
        Header: 'Type',
        Cell: ({row: {original: label}}) => (label.type && annotationTypes[label.type]) || '-',
        styleProps: {
          width: 150
        }
      },
      {
        Header: 'Annotations',
        Cell: ({row: {original}}) => {
          const label = original as LabelWithCounts;
          const {annotatedImageCount, annotationCount, name} = label;
          const query: SearchQueryItem[] = [
            {field: 'image.annotations.labelName', value: name, operator: 'is'}
          ];
          const searchParams = formatSearchParams({query});

          if (isLoadingStatistics) return null; // don't show anything while statistics data is still loading
          if (!annotatedImageCount) {
            return (
              <Box fontStyle="italic" color="gray.500" textAlign="center">
                No annotation
              </Box>
            );
          }

          return (
            <Flex alignItems="center" justifyContent="space-between">
              <Stack>
                <Box>
                  {formatAnnotationCount(annotationCount!)}{' '}
                  {pluralize('annotation', annotationCount!)}
                </Box>
                <Box>
                  {formatAnnotationCount(annotatedImageCount!)}{' '}
                  {pluralize('image', annotatedImageCount!)}
                </Box>
              </Stack>
              <Box>
                <IconButton
                  as={RouterLink}
                  to={{pathname: '/scans', search: searchParams}}
                  icon={<SearchIcon />}
                  aria-label="View"
                  size="sm"
                />
              </Box>
            </Flex>
          );
        },
        styleProps: {
          width: 180
        }
      },
      {
        Header: 'WSI labels',
        Cell: ({row: {original}}) => {
          const label = original as LabelWithCounts;
          const count = label.wsiLabelCount;
          const query: SearchQueryItem[] = [
            {field: 'image.wsiAnnotations.labelName', value: label.name, operator: 'is'}
          ];
          const searchParams = formatSearchParams({query});

          if (isLoadingStatistics) return null; // don't show anything while statistics data is still loading
          if (!count) {
            return (
              <Box fontStyle="italic" color="gray.500" textAlign="center">
                No WSI label
              </Box>
            );
          }

          return (
            <Flex alignItems="center" justifyContent="space-between">
              <Box>
                {formatAnnotationCount(count)} {pluralize('WSI label', count)}
              </Box>
              <IconButton
                as={RouterLink}
                to={{pathname: '/scans', search: searchParams}}
                icon={<SearchIcon />}
                size="sm"
                aria-label="View"
              />
            </Flex>
          );
        },
        styleProps: {
          width: 160
        }
      },
      {
        Header: 'Actions',
        accessor: (label: AILabel) => {
          const canDelete =
            !!onDelete &&
            !isLoadingStatistics &&
            label.annotationCount === 0 &&
            label.wsiLabelCount === 0;
          return (
            <>
              {canDelete && (
                <IconButton
                  size="sm"
                  aria-label="Delete"
                  onClick={() => onDelete(label.name)}
                  icon={<DeleteIcon />}
                  colorScheme="red"
                  borderRadius="md"
                />
              )}
              {!!onEdit ? (
                <Button size="sm" onClick={() => onEdit(label.name)} leftIcon={<EditIcon />} ml={2}>
                  Edit
                </Button>
              ) : (
                '--'
              )}
            </>
          );
        },
        styleProps: {
          textAlign: 'right',
          width: 150
        }
      }
    ],
    [isLoadingStatistics] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return <Table data={labels} columns={columns} showHeader={false} size="sm" />;
};

function formatAnnotationCount(count: number) {
  const mask = count > 999 ? '0.0' : '0';
  return numeral(count).format(`${mask} a`);
}

function pluralize(word: string, count: number) {
  return count > 1 ? `${word}s` : word;
}
