import React from 'react';
import {ButtonGroup} from '@chakra-ui/react';

import {useLocale} from 'shared/locale';
import {Button, KeyHandler} from 'components/core';
import {SearchOptions, usePagination} from './search';

type Props = {
  searchOptions: SearchOptions;
  total: number;
  pageItemCount: number;
};
export const PaginationCount = ({searchOptions, total, pageItemCount}: Props) => {
  const locale = useLocale();
  const {offset} = searchOptions;

  return (
    <>
      {locale.formatNumber(offset! + 1)}-
      {locale.formatNumber(Math.min(offset! + pageItemCount, total))} of{' '}
      {locale.formatNumber(total)}
    </>
  );
};

export const PaginationControls = ({
  searchOptions,
  total
}: {
  searchOptions: Required<SearchOptions>;
  total: number;
}) => {
  const locale = useLocale();
  const {hasPreviousPage, hasNextPage, goToPreviousPage, goToNextPage} = usePagination({
    searchOptions,
    total
  });

  return (
    <>
      <ButtonGroup>
        <Button onClick={goToPreviousPage} isDisabled={!hasPreviousPage}>
          {locale.previousButtonLabel}
        </Button>
        <Button type="button" onClick={goToNextPage} isDisabled={!hasNextPage}>
          {locale.nextButtonLabel}
        </Button>
      </ButtonGroup>{' '}
      <KeyHandler value="ArrowLeft" onDown={goToPreviousPage} />
      <KeyHandler value="ArrowRight" onDown={goToNextPage} />
    </>
  );
};
