import React, {useMemo} from 'react';
import {Box} from '@chakra-ui/react';

import {useLocale} from 'shared';
import {formatDateTime, Table, TimeAgo} from 'components/core';
import {ImageViewerLink} from 'components/scan-list/scan-columns';
import {ImageThumbnail} from 'components/scan-list/image-thumbnail';

export const PredictionList = ({predictions, models}) => {
  const locale = useLocale();
  const data = useMemo(() => predictions, [predictions]);
  const size = 80;
  const columns = useMemo(
    () => [
      {
        Header: 'Image',
        accessor: (prediction) => (
          <ImageViewerLink imageId={prediction.image.id}>
            <ImageThumbnail image={prediction.image} size={size} />
          </ImageViewerLink>
        ),
        styleProps: {
          width: `${size}px`,
          textAlign: 'center'
        }
      },
      {
        Header: 'File',
        accessor: (prediction) => prediction.image.filename
      },
      {
        Header: 'Model',
        accessor: (prediction) => <AIModelName modelName={prediction.modelName} models={models} />
      },
      {
        Header: 'Status',
        accessor: (prediction) => getPredictionStatusLabel(prediction.status)
      },
      {
        Header: 'User',
        accessor: (prediction) => prediction.user.account.getFullName(locale)
      },
      {
        Header: 'Date',
        accessor: (prediction) => {
          const date = prediction.createdOn;
          return (
            <>
              <Box>{formatDateTime(date)}</Box>
              <Box color="gray.500" mt={1}>
                <TimeAgo date={date} />
              </Box>
            </>
          );
        }
      }
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );
  return <Table data={data} columns={columns} size="sm" />;
};

function getPredictionStatusLabel(status) {
  switch (status) {
    case 'WAITING':
      return 'Waiting';
    case 'RUNNING':
      return 'Running';
    case 'FAILED':
      return 'Failed';
    default:
      return 'OK';
  }
}

const AIModelName = ({modelName, models}) => {
  const locale = useLocale();
  const foundModel = models.find((model) => model.name === modelName);

  return <>{foundModel ? locale.get(foundModel.displayName) : modelName}</>;
};
