import {useState, useRef} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useHistory, useLocation} from 'react-router';
import {useLatest} from 'react-use';
import invariant from 'tiny-invariant';

import {parseSearchParams, useApp, useLocale} from 'shared';
import {useModal} from 'components/core';

export function useFetchScan(scanId) {
  const app = useApp();

  const loadScan = async () => {
    const scan = await app.getScan({scanId});
    return scan;
  };

  return useQuery(['scan', scanId], loadScan, {retry: false});
}

export function useMutationUpdateScan(scanId: Datastore.Scan['id']) {
  const app = useApp();
  const queryClient = useQueryClient();

  return useMutation<Datastore.Scan, any>((changes) => app.updateScan({scanId, changes}), {
    onMutate: (changes) => {
      queryClient.setQueryData<Datastore.Scan>(['scan', scanId], (previousScan) => {
        invariant(previousScan);
        return {
          ...previousScan,
          ...(changes as any)
        };
      });
    }
  });
}

export function useScanNavigation(scanId: Datastore.Scan['id']) {
  const app = useApp();
  const location = useLocation();
  const history = useHistory();
  const locale = useLocale();
  const modal = useModal();
  const {query, orderBy, orderDirection, offset, limit} = parseSearchParams(location.search);
  const delta = useRef(0);
  const [loading, setLoading] = useState<undefined | 'previous' | 'next'>(undefined);

  const latestId = useLatest(scanId); // we need to access "latest" value when using keyboard shortcuts

  const showPreviousScan = async () => {
    delta.current = delta.current - 1;
    setLoading('previous');
    const previousScanId = await app.getPreviousScanId({
      scanId: latestId.current,
      query,
      orderBy,
      orderDirection,
      offset: Math.max(offset + delta.current, 0),
      limit
    });
    setLoading(undefined);

    if (!previousScanId) {
      await modal.alert(locale.todo(`Couldn't find a scan before the current one.`), {
        title: 'Previous scan'
      });
      return;
    }

    history.push({
      pathname: `/scans/${previousScanId}`,
      search: location.search
    });
  };

  const showNextScan = async () => {
    delta.current = delta.current + 1;
    setLoading('next');
    const nextScanId = await app.getNextScanId({
      scanId: latestId.current,
      query,
      orderBy,
      orderDirection,
      offset: Math.max(offset + delta.current, 0),
      limit
    });
    setLoading(undefined);

    if (!nextScanId) {
      await modal.alert(locale.todo(`Couldn't find a scan after the current one.`), {
        title: 'Next scan'
      });
      return;
    }

    history.push({pathname: `/scans/${nextScanId}`, search: location.search});
  };

  return {showPreviousScan, showNextScan, loading};
}
