import {lazy, Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';

import {Spinner} from 'components/core';
import {EvaluationListPage} from './list/evaluation-list-page';

const EvaluationDetailsPage = lazy(() => import('./evaluation-details-page')); // this page bundle is HUGE because of Plotly.js

export const EvaluationsRoot = () => {
  return (
    <Suspense fallback={<SplashScreen />}>
      <Switch>
        <Route exact path="/evaluations" component={EvaluationListPage} />
        <Route exact path="/evaluations/:id" component={EvaluationDetailsPage} />
      </Switch>
    </Suspense>
  );
};

const SplashScreen = () => <Spinner size="xl" />;
