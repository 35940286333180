import React, {useMemo} from 'react';
import {Box, HStack} from '@chakra-ui/react';

import {CheckIcon, Progress, Table} from 'components/core';
import {useLocale} from 'shared/locale';

export const FileList0 = ({images}) => {
  return (
    <>
      {images.map((image) => (
        <Box key={image.file.name}>{image.uploadProgress}</Box>
      ))}
    </>
  );
};

export const FileList = ({images}) => {
  const locale = useLocale();
  const data = useMemo(() => images, [images]);
  const columns = useMemo(
    () => [
      {
        Header: locale.todo('Filename'),
        accessor: (image) => image.file.name
      },
      {
        Header: 'Size',
        accessor: (image) => locale.formatFileSize(image.file.size),
        styleProps: {
          width: '100px',
          textAlign: 'right'
        }
      },
      {
        Header: 'Progress',
        accessor: (image) => {
          if (image.status === 'UPLOADING' && image.uploadProgress !== undefined) {
            const percent = image.uploadProgress * 100;
            return (
              <HStack>
                <Progress w="100px" value={percent} hasStripe />
                <Box fontSize="13px">{percent.toFixed(0)}%</Box>
              </HStack>
            );
          }

          if (image.status === 'UPLOADED') {
            return (
              <>
                <CheckIcon color="green.600" fontSize="16px" mr={2} />
                {locale.todo('Uploaded')}
              </>
            );
          }

          if (image.status === 'UPLOAD_FAILED') {
            return locale.todo('Failed');
          }

          return null;
        },
        styleProps: {
          width: 180,
          textAlign: 'center'
        }
      }
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getRowProps = (row) => {
    const image = row.original;
    let color;
    if (image.status === undefined) {
      color = 'rgba(0, 0, 0, 0.38)'; // t.mutedTextColor
    } else if (image.status === 'UPLOAD_FAILED') {
      color = '#F44336'; // t.errorColor;
    }
    return {color};
  };

  const getRowId = (row) => row.file.name;

  return (
    <Table
      data={data}
      columns={columns}
      getRowId={getRowId}
      getRowProps={getRowProps}
      isSticky={false}
      size="sm"
    />
  );
};
