import kebabCase from 'lodash/kebabCase';
import dotProp from 'dot-prop';
import isNotEmpty from 'has-values';

import {propsToPaths} from './model-utils';

export class Model {
  constructor(data, _options) {
    Object.assign(this, data);
  }

  clone() {
    return new this.constructor(this);
  }

  clear() {
    return new this.constructor();
  }

  isEmpty() {
    return !isNotEmpty(this);
  }

  describe() {
    const fields = [];

    const paths = propsToPaths(this);
    for (const path of paths) {
      const field = kebabCase(path);

      let value = dotProp.get(this, path);
      if (value instanceof Date) {
        value = value.toISOString().slice(0, 10).replace(/-/g, '/');
      } else {
        value = JSON.stringify(value);
      }

      fields.push({field, value});
    }

    return fields;
  }
}
