import {useState} from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  FormControl,
  FormLabel,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Spinner,
  Stack,
  Textarea,
  useToast
} from '@chakra-ui/react';
import {useQueryClient} from 'react-query';

import {useLocale} from 'shared';
import {Button} from 'components/core';
import {TagPicker} from 'components/scan-form/fields';
import {useFetchScan, useMutationUpdateScan} from 'components/scan-form/scan-actions';
import {truncateFilename} from 'models/image';

type Props = {
  scanId: string;
  onClose: (isUpdated: boolean) => void;
};
export const DialogQuickEdit = ({scanId, onClose}: Props) => {
  return (
    <>
      <ModalHeader>
        Scan quick edit
        <ModalCloseButton onClick={() => onClose(false)} />
      </ModalHeader>
      <DialogBody scanId={scanId} onClose={onClose} />
    </>
  );
};

const DialogBody = ({scanId, onClose}: Props) => {
  const {data: scan, isLoading, error} = useFetchScan(scanId);
  if (isLoading) {
    return (
      <Box h={300}>
        <Spinner />
      </Box>
    );
  }
  if (error) {
    return (
      <Alert variant="status">
        <AlertIcon />
        <AlertDescription>Unable to load the scan</AlertDescription>
      </Alert>
    );
  }
  return <DialogForm scan={scan} onClose={onClose} />;
};

type FormValues = Pick<Datastore.Scan, 'tags' | 'comments'>;
const DialogForm = ({scan, onClose}) => {
  const locale = useLocale();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [values, setValues] = useState<FormValues>({tags: scan.tags, comments: scan.comments});
  const mutationUpdateScan = useMutationUpdateScan(scan.id);

  const onChange = (field: string) => (value: string) =>
    setValues((current) => ({...current, [field]: value}));

  const submit = async () => {
    mutationUpdateScan.mutateAsync(values as any);
    await queryClient.invalidateQueries(['image', scan.image.id]);
    // await queryClient.invalidateQueries(['scan', scan.id]); TODO: not necessary
    onClose(true);
    toast({
      title: 'Scan updated',
      description: truncateFilename(scan.image.filename) + '\n' + values.tags,
      isClosable: true
    });
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        submit();
      }}
    >
      <ModalBody>
        <Stack spacing={4}>
          <FormControl>
            <FormLabel>Filename</FormLabel>
            <Box>{truncateFilename(scan.image.filename)}</Box>
          </FormControl>
          <FormControl>
            <FormLabel>Tags</FormLabel>
            <TagPicker value={values.tags} onChange={onChange('tags')} />
          </FormControl>
          <FormControl>
            <FormLabel>Comments</FormLabel>
            <Textarea
              rows={3}
              value={values.comments || ''}
              onChange={(event) => onChange('comments')(event.target.value)}
            />
          </FormControl>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button type="button" onClick={() => onClose(false)} mr={2}>
          {locale.cancelButtonLabel}
        </Button>
        <Button
          type="submit"
          primary
          isLoading={mutationUpdateScan.isLoading || !!queryClient.isFetching()}
        >
          Save
        </Button>
      </ModalFooter>
    </form>
  );
};
