import React from 'react';
import PropTypes from 'prop-types';
import {Box, ButtonGroup, Flex} from '@chakra-ui/react';

import {withLocale} from 'shared';
import {Button} from 'components/core';

@withLocale
export class Navigation extends React.Component<any> {
  static propTypes = {
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
    navigate: PropTypes.func.isRequired
  };

  render() {
    const {year, month, navigate, locale: l, children, ...rest} = this.props;

    const currentDate = new Date(year, month - 1, 1);
    const previousMonthDate = new Date(year, month - 2, 1);
    const nextMonthDate = new Date(year, month, 1);
    const today = new Date();
    const isTodayInCurrentMonth = currentDate.getMonth() === today.getMonth();

    const goToDate = (date) => navigate(date.getFullYear(), date.getMonth() + 1);
    const goToPrevious = () => goToDate(previousMonthDate);
    const goToNext = () => goToDate(nextMonthDate);

    return (
      <nav {...rest}>
        <Flex alignItems="center">
          <Box flexGrow={1}>{children || <span />}</Box>
          <Box>
            <ButtonGroup>
              <Button onClick={goToPrevious}>{l.previousButtonLabel}</Button>
              <Button disabled={isTodayInCurrentMonth} onClick={goToNext}>
                {l.nextButtonLabel}
              </Button>
            </ButtonGroup>
          </Box>
        </Flex>
      </nav>
    );
  }
}
