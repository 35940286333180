import React from 'react';
import {Box, BoxProps, Flex} from '@chakra-ui/react';

import {useLocale} from 'shared';

const monthNames = 'Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec'.split(' ');

type Month = {
  year: number;
  month: number;
};
type ResultItem = Month & {count: number};
type BarGraphItem = Month & {value: number};

type Props = {
  results: ResultItem[];
  numberOfMonths: number;
};
export const StatsGraph = ({results, numberOfMonths}: Props) => {
  if (results.length === 0)
    return <div style={{fontStyle: 'italic', marginBottom: '.5rem'}}>No data available</div>;

  const lastNMonths = getLastNMonths(numberOfMonths);

  const items = lastNMonths.map(({year, month}) => {
    const foundResult = results.find((result) => result.year === year && result.month === month);
    const value = foundResult ? foundResult.count : 0;
    return {year, month, value};
  });

  return <BarGraph items={items} />;
};

const BarGraph = ({items}: {items: BarGraphItem[]}) => {
  const values = items.map(({value}) => value);
  const maxValue = Math.max(...values);

  return (
    <Box w="100%" mt={4}>
      <Flex minHeight={150}>
        {items.map(({year, month, value}) => (
          <Flex
            key={`${year}/${month}`}
            flex={1}
            flexDirection="column"
            justifyContent="flex-end"
            marginRight={1}
          >
            <Bar value={value} maxValue={maxValue} />
          </Flex>
        ))}
      </Flex>
      <Flex>
        {items.map(({year, month}) => (
          <Box key={`${year}/${month}`} flex={1} textAlign="center" fontSize={13}>
            '{year.toString().slice(2)}
            <br />
            {monthNames[month - 1]}
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

const Bar = ({value, maxValue}: {value: number; maxValue: number}) => {
  const locale = useLocale();
  const height = maxValue ? `${Math.round((value * 100) / maxValue)}%` : '0px';

  if (!value) {
    return (
      <Box position="relative" borderBottom="1px dashed #ead9ea" h="1px">
        <BarLabel color="gray.400">0</BarLabel>
      </Box>
    );
  }
  return (
    <Box position="relative" h={height} bg="#ead9ea">
      <BarLabel>{locale.formatNumber(value)}</BarLabel>
    </Box>
  );
};

const BarLabel = (props: BoxProps) => {
  return (
    <Box textAlign="center" fontSize={13} position="absolute" top={-22} width="100%" {...props} />
  );
};

function getLastNMonths(numberOfMonths: number) {
  const today = new Date();
  const date = new Date(today.getFullYear(), today.getMonth(), 1);
  const months: Month[] = [];
  for (let i = 0; i < numberOfMonths; i++) {
    months.push({year: date.getFullYear(), month: date.getMonth() + 1});
    date.setMonth(date.getMonth() - 1);
  }
  months.reverse();
  return months;
}
