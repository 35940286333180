import {Link as RouterLink} from 'react-router-dom';
import {Heading, HeadingProps, Badge, Link, Tabs, TabList, Tab} from '@chakra-ui/react';

export type SettingsTabKey =
  | 'labels'
  | 'models'
  | 'suppliers'
  | 'organs'
  | 'specimens'
  | 'staining';

const setup: {key: SettingsTabKey; label: string}[] = [
  {key: 'labels', label: 'Labels'},
  {key: 'models', label: 'Models'},
  {key: 'suppliers', label: 'Suppliers'},
  {key: 'organs', label: 'Organs'},
  {key: 'specimens', label: 'Types of specimen'},
  {key: 'staining', label: 'Staining'}
];

type Props = {
  currentTab: SettingsTabKey;
};
export const SettingsNavigator = ({currentTab}: Props) => {
  const tabIndex = setup.findIndex((item) => item.key === currentTab);
  return (
    <Tabs index={tabIndex} mb={4}>
      <TabList>
        {setup.map(({key, label}) => (
          <Tab key={key}>
            <Link as={RouterLink} to={`/settings/${key}`} _hover={{textDecoration: 'none'}}>
              {label}
            </Link>
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
};

export const SettingsHeading = ({count, children, ...props}: {count: number} & HeadingProps) => {
  return (
    <Heading mb={4} fontSize="xl" {...props}>
      {children}
      <Badge colorScheme="gray" variant="solid" ml={1} fontSize="0.8rem">
        {count}
      </Badge>
    </Heading>
  );
};
