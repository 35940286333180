import {Item} from './item';
import {User} from './user';

export class OwnedItem extends Item {
  constructor(data, options) {
    data = {...data};

    if (data.user) {
      data.user = new User(data.user, options);
    }

    super(data, options);
  }
}
