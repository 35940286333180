import {createContainer} from 'unstated-next';
import {useMutation, useQueryClient} from 'react-query';
import debugModule from 'debug';
import invariant from 'tiny-invariant';

import {useApp} from 'shared';
import {useModal} from 'components/core';
import {ImageContainer} from 'components/image-viewer/image-container';
import {DialogAddWSILabel} from './wsi-label-add';
import {AIDataContainer} from 'components/settings/ai-labels-api';

const debug = debugModule('medmain:image-viewer:wsi-annotations');

type WSILabelData = {
  labelName: string;
  description: string;
};

function usePluginState() {
  const {image} = ImageContainer.useContainer();
  const modal = useModal();
  const addMutation = useAddMutation(image.id);
  const removeMutation = useRemoveMutation(image.id);
  const {data} = AIDataContainer.useContainer();
  const labels = data?.labels || [];
  const models = data?.models || [];

  const addWSILabel = async () => {
    const input = await modal.dialog({
      render: (close) => (
        <DialogAddWSILabel onClose={close} labels={labels} models={models} image={image} />
      ),
      modalProps: {size: '2xl'}
    });
    if (!input) return;
    const {labelName, description} = input as WSILabelData;
    debug('Add WSI label', labelName, description);
    addMutation.mutate({labelName, description} as any);
  };

  const removeWSILabel = async (annotationId: string) => {
    await removeMutation.mutate(annotationId);
    debug('Label removed', annotationId);
  };

  return {
    labels,
    models,
    addWSILabel,
    removeWSILabel
  };
}

export function useAddMutation(imageId: string) {
  const app = useApp();
  const queryClient = useQueryClient();

  const accessToken = app.getAccessToken();

  return useMutation<Datastore.WSIAnnotation, Error, WSILabelData>(async (data: WSILabelData) => {
    const imagesBackend = await app.getImagesBackend();
    const {wsiAnnotation} = await imagesBackend.addWSIAnnotation({
      imageId: imageId,
      ...data,
      accessToken
    });
    queryClient.setQueryData(['image', imageId], (current) => {
      invariant(current);
      const {image, ...rest} = current as {image: Datastore.Image};
      return {
        image: {
          ...image,
          wsiAnnotations: [...(image.wsiAnnotations || []), wsiAnnotation]
        },
        ...rest
      };
    });
    queryClient.invalidateQueries('scan-search'); // refresh data as we show the labels on the list
    return wsiAnnotation;
  });
}

export function useRemoveMutation(imageId) {
  const app = useApp();
  const queryClient = useQueryClient();

  const accessToken = app.getAccessToken();

  return useMutation<any, Error, string>(async (annotationId: string) => {
    const imagesBackend = await app.getImagesBackend();
    await imagesBackend.removeWSIAnnotation({annotationId, accessToken});
    queryClient.setQueryData(['image', imageId], (current) => {
      invariant(current);
      const {image, ...rest} = current as {image: Datastore.Image};
      return {
        image: {
          ...image,
          wsiAnnotations: (image.wsiAnnotations || []).filter(
            (annotation) => annotation.id !== annotationId
          )
        },
        ...rest
      };
    });
    queryClient.invalidateQueries('scan-search'); // refresh data as we show the labels on the list
  });
}

export const PluginContainer = createContainer(usePluginState);
