import React from 'react';
import {Box, Button, ButtonProps, BoxProps} from '@chakra-ui/react';
import styled from '@emotion/styled';

export const BaseButton = ({isDisabled, ...props}: ButtonProps) => (
  <Button
    variant="unstyled"
    display="flex"
    justifyContent="center"
    alignItems="center"
    padding={0}
    w="40px"
    color="white"
    backgroundColor="inverseBoxBackgroundColor"
    pointerEvents="all"
    cursor={isDisabled ? 'not-allowed' : 'pointer'}
    opacity={isDisabled ? 0.5 : undefined}
    _hover={{backgroundColor: 'inverseBoxBackgroundColor'}}
    _active={{boxShadow: 'none'}}
    _focus={{boxShadow: 'none'}}
    {...props}
  />
);

export const PanelButton = styled(BaseButton)(() => ({
  height: '1.1rem',
  paddingLeft: '5px',
  paddingRight: '5px',
  fontSize: '11px',
  fontWeight: 300,
  backgroundColor: 'transparent',
  borderWidth: '1px'
}));

export const PanelIconButton = styled(BaseButton)(() => ({
  width: '1rem',
  height: '1rem',
  minWidth: 'auto',
  backgroundColor: 'transparent',
  borderWidth: '1px'
}));

export const ToolbarButtonGroup = (props: BoxProps) => (
  <Box
    borderRadius="0.25rem"
    borderWidth="1px"
    borderColor="inverseBoxBorderColor"
    overflow="hidden"
    display="inline-flex"
    flexDirection="column"
    {...props}
  />
);

export const ToolbarButton = ({isActive, ...props}: {isActive?: boolean} & ButtonProps) => (
  <BaseButton
    width="2.5rem"
    height="2.5rem"
    borderRadius={0}
    borderColor="inverseBoxBorderColor"
    borderBottomWidth="1px"
    _last={{
      borderBottomWidth: '0'
    }}
    color={isActive ? 'inverseAccentColor' : 'white'}
    {...props}
  />
);
