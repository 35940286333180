import React, {useState} from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import {chakra, Box, Link, LinkProps, Table, Tbody, Tr, Td} from '@chakra-ui/react';
import invariant from 'tiny-invariant';

import {useLocale} from 'shared';
import {formatSearchParams, parseSearchParams} from 'shared/search';
import {Heading3, SubTitle} from 'components/core';
import {ScanQuery} from 'models/scan-query';

type FieldName =
  | 'organ'
  | 'specimenType'
  | 'disease'
  | 'staining'
  | 'supplier'
  | 'imageFormat'
  | 'magnification'
  | 'tags'
  | 'gene'
  | 'protein';

type ReportFields = Record<FieldName, ReportItem[]>;

type ReportItem = {
  count: number;
  name: string;
};

type Props = {fields: ReportFields};
export const ReportList = ({fields}: Props) => {
  const locale = useLocale();
  const [currentField, setCurrentField] = useState<FieldName | null>(null);

  const showReportItem = (field) => {
    setCurrentField(field);
  };

  const entries = Object.entries(fields);

  if (!entries.length) {
    return null;
  }

  const activeField = (currentField || entries[0][0]) as FieldName;
  const [, currentItems] = entries.find(([field]) => field === activeField) || [];
  invariant(currentItems);

  return (
    <>
      <Box overflowY="auto" flexShrink={0}>
        {entries.map(([field, items]) => {
          return (
            <React.Fragment key={field + Math.random()}>
              <Heading3 fontWeight={field === activeField ? 600 : 'normal'}>
                <Box onClick={() => showReportItem(field)} cursor="pointer" as="span">
                  {locale.scanReportFieldHeadings[field]}
                </Box>
                <SubTitle>({locale.formatNumber(items.length)})</SubTitle>
              </Heading3>
            </React.Fragment>
          );
        })}
      </Box>

      <Box w="1px" mx={3} flex="none" bg="gray.200" />

      <Box overflowY="auto" w="100%">
        {currentItems.length > 0 ? (
          <ItemList items={currentItems} activeField={activeField} />
        ) : (
          <Box fontSize="lg" color="gray.500">
            No items found.
          </Box>
        )}
      </Box>
    </>
  );
};

const ItemList = ({items, activeField}: {items: ReportItem[]; activeField: FieldName}) => {
  const locale = useLocale();
  const total = items.reduce((acc, item) => acc + item.count, 0);

  // A regular HTML table is needed because Masa uses a browser extension to export the table on the screen
  return (
    <Table width="100%">
      <Tbody>
        {items.map((item) => {
          const percentage = (item.count / total) * 100;
          return (
            <Tr key={item.name}>
              <Td p={0}>
                <Box position="relative">
                  {percentage !== 100 && (
                    <Box
                      position="absolute"
                      bg="rgba(237,100,67, 0.1)"
                      width={`${percentage.toFixed()}%`}
                      top={0}
                      left={0}
                      bottom={0}
                    />
                  )}
                  <Box zIndex={1} p={2}>
                    {item.name || <i>(Empty)</i>}
                  </Box>
                </Box>
              </Td>
              <Cell width="100px" textAlign="right">
                {locale.formatNumber(item.count)}
              </Cell>
              <Cell width="100px" textAlign="right">
                {locale.formatPercentage(percentage)}
              </Cell>
              <Cell width="120px">
                <SearchLink field={activeField} item={item}>
                  View scans
                </SearchLink>
              </Cell>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

const SearchLink = ({field, item, ...props}: {field: string; item: ReportItem} & LinkProps) => {
  const location = useLocation();
  const searchParams = parseSearchParams(location.search);
  const query = ScanQuery.fromJSON(searchParams.query);
  query.setValue(field, item.name);
  const nextLocation = {
    pathname: '/scans',
    search: formatSearchParams({...searchParams, query: query.toJSON(), offset: undefined})
  };
  return <Link as={RouterLink} to={nextLocation} {...props} />;
};

const Cell = chakra(Td, {baseStyle: {py: 2, px: 4}});
