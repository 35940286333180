import {useEffect, useState} from 'react';
import {useAsyncDebounce} from 'react-table';
import {
  Box,
  Button,
  ButtonProps,
  Code,
  Input,
  InputGroup,
  InputLeftElement,
  Flex,
  Checkbox,
  CheckboxGroup,
  RadioGroup,
  Radio,
  Stack,
  StackDivider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  InputRightElement,
  IconButton
} from '@chakra-ui/react';
import {MdRefresh, MdSearch} from 'react-icons/md';
import compact from 'lodash/compact';
import orderBy from 'lodash/orderBy';

import {CloseIcon, InfoIcon, NoItemsFound} from 'components/core';
import {ACCESS_LEVELS, ROLES} from 'shared';

const accessLevelNumbers = Object.values(ACCESS_LEVELS);

export const UserTableWithFilters = ({tableInstance}) => {
  const {
    state,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    setGlobalFilter,
    setFilter,
    setAllFilters
  } = tableInstance;

  const onReset = () => {
    setGlobalFilter(undefined);
    setAllFilters([]);
  };

  return (
    <>
      <Stack direction={{base: 'column', '2xl': 'row'}} alignItems="flex-start" spacing={4} mb={4}>
        <Stack direction={{base: 'column', lg: 'row'}} alignItems="center" spacing={4}>
          <SearchBox globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
          <AccessLevelFilters
            filterValue={state.filters.find((filter) => filter.id === 'accessLevel')?.value}
            onChange={(values) => {
              setFilter(
                'accessLevel',
                values.length === accessLevelNumbers.length || values.length === 0
                  ? undefined
                  : orderBy(values)
              );
            }}
          />
        </Stack>
        <RoleFilters
          filterValue={state.filters.find((filter) => filter.id === 'roles')?.value}
          onChange={(values) => {
            setFilter('roles', values);
          }}
        />
      </Stack>
      <Flex
        borderTopWidth="1px"
        borderBottomWidth="1px"
        borderColor="blue.100"
        minH="48px"
        px={4}
        mb={4}
        bg="blue.50"
        alignItems={'center'}
      >
        <FilterMessage state={state} rows={rows} onReset={onReset} />
      </Flex>
      {rows.length > 0 ? (
        <Box overflowY="scroll" borderWidth="1px">
          <Table {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      isNumeric={column.isNumeric}
                    >
                      {column.render('Header')}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <Td
                        bg="white"
                        {...cell.getCellProps()}
                        {...cell.column.styleProps}
                        isNumeric={cell.column.isNumeric}
                      >
                        {cell.render('Cell')}
                      </Td>
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      ) : (
        <NoItemsFound />
      )}
    </>
  );
};

const FilterMessage = ({rows, state, onReset}) => {
  const userCount = rows.length;
  const {globalFilter, filters} = state;
  const isFiltered = globalFilter || filters.length;

  const accessLevels = filters.find((filter) => filter.id === 'accessLevel')?.value;
  const role = filters.find((filter) => filter.id === 'roles')?.value;

  const description = compact([
    globalFilter && `“${globalFilter}”`,
    accessLevels && describeAccessLevelFilter(accessLevels),
    role && describeRoleFilter(role)
  ]).join(' AND ');

  if (!isFiltered) {
    return (
      <Flex>
        <InfoIcon color="blue.400" mr={2} />
        Showing all users{' '}
        <Box as="span" ml={2} fontStyle="italic" color="gray.500">
          (No search filter)
        </Box>
      </Flex>
    );
  }

  if (!userCount) {
    return (
      <Flex alignItems="center">
        <Box>
          No user found for <Code>{description}</Code>. Try an other query or reset the search
          filters
        </Box>
        <Box>
          <ResetFilters onClick={onReset} ml={4} />
        </Box>
      </Flex>
    );
  }

  return (
    <Flex alignItems="center">
      <Box>
        Search filter results for <Code>{description}</Code>: <b>{userCount}</b> users found
      </Box>
      <Box>
        <ResetFilters onClick={onReset} ml={4} />
      </Box>
    </Flex>
  );
};

function describeAccessLevelFilter(accessLevels: number[]) {
  const excludedAccessLevels = accessLevelNumbers.filter((level) => !accessLevels.includes(level));
  if (accessLevels.length === 1) return `access level = ${accessLevels[0]}`;

  const maxExcluded = Math.max(...excludedAccessLevels);
  const minExcluded = Math.min(...excludedAccessLevels);
  const min = Math.min(...accessLevels);
  const max = Math.max(...accessLevels);
  if (maxExcluded < min) {
    return `access level > ${maxExcluded}`;
  }
  if (minExcluded > max) {
    return `access level < ${minExcluded}`;
  }
  return 'access level ∈ {' + accessLevels.join(',') + '}';
}

function describeRoleFilter(roleId: string) {
  const role = ROLES[roleId];
  return `role = ${role?.shortLabel || roleId}`;
}

const ResetFilters = (props: ButtonProps) => {
  return (
    <Button size="sm" variant="outline" ml={4} {...props} rightIcon={<MdRefresh fontSize="16px" />}>
      Reset filters
    </Button>
  );
};

export const SearchBox = ({globalFilter, setGlobalFilter}) => {
  const [value, setValue] = useState('');
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  useEffect(() => {
    if (!globalFilter) {
      setValue('');
    }
  }, [globalFilter]);

  return (
    <Flex>
      <InputGroup w={350}>
        <InputLeftElement color="gray.500" children={<MdSearch fontSize="24px" />} />
        <Input
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          value={value}
          placeholder="Filter by name, email or company"
        />
        <InputRightElement>
          <IconButton
            aria-label="Reset"
            onClick={() => {
              onChange(undefined);
            }}
            icon={<CloseIcon fontSize="16px" color="gray.500" />}
            p="0"
            height="24px"
            minW="20px"
            borderWidth={0}
            backgroundColor="transparent"
            isDisabled={value === ''}
          />
        </InputRightElement>
      </InputGroup>
    </Flex>
  );
};

const AccessLevelFilters = ({filterValue, onChange}) => {
  const initialValues = accessLevelNumbers.map((number) => number.toString());
  const colors = ['gray', 'green', 'yellow', 'orange', 'red', 'purple'];
  const [value, setValue] = useState(initialValues);

  useEffect(() => {
    if (!filterValue) {
      setValue(initialValues);
    }
  }, [filterValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Flex borderWidth="1px" px={4} bg="white" h="40px" alignItems="center" borderRadius="md">
      {/* <Box mr={4}>Level</Box> */}
      <CheckboxGroup
        value={value}
        onChange={(values) => {
          setValue(values as string[]);
          const numbers = values.map(Number);
          onChange(numbers);
        }}
      >
        <Stack direction="row" spacing={3} divider={<StackDivider />}>
          {accessLevelNumbers.map((value) => (
            <Checkbox key={value} value={value.toString()} colorScheme={colors[value]}>
              <>{value.toString()}</>
            </Checkbox>
          ))}
        </Stack>
      </CheckboxGroup>
    </Flex>
  );
};

const RoleFilters = ({filterValue, onChange}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (!filterValue) {
      setValue('');
    }
  }, [filterValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Flex borderWidth="1px" px={4} bg="white" h="40px" alignItems="center" borderRadius="md">
      <RadioGroup
        value={value}
        onChange={(value) => {
          setValue(value as string);
          onChange(value || undefined); // pass `undefined` to remove the filter
        }}
      >
        <Stack direction="row" spacing={3} divider={<StackDivider />}>
          <Radio key="*" value="">
            Any role
          </Radio>
          {Object.values(ROLES)
            .filter((role) => !role.disabled)
            .map((role) => (
              <Radio key={role.name} value={role.name}>
                {role.shortLabel}
              </Radio>
            ))}
        </Stack>
      </RadioGroup>
    </Flex>
  );
};
