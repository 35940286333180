import React from 'react';
import {Box, Checkbox, CheckboxGroup, Select, VStack} from '@chakra-ui/react';

import {MAXIMUM_ACCESS_LEVEL, ROLES} from 'shared';

type Role = {
  name: string;
  label: string;
  comment: string;
};

type AccessLevelPickerProps = {
  accessLevel: number;
  onChange: (accessLevel: number) => void;
};
export const AccessLevelPicker = ({accessLevel, onChange}: AccessLevelPickerProps) => {
  const possibleAccessLevels: {value: string; label: string}[] = [];
  for (let level = 0; level <= MAXIMUM_ACCESS_LEVEL; level++) {
    possibleAccessLevels.push({value: String(level), label: String(level)});
  }

  return (
    <Select
      value={String(accessLevel)}
      onChange={(event) => onChange(parseInt(event.target.value))}
    >
      {possibleAccessLevels.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
};

export const RolePicker = ({roles, onChange, ...rest}) => {
  const availableRoles = Object.values(ROLES);

  return (
    <CheckboxGroup value={roles} onChange={onChange} {...rest}>
      <VStack alignItems="flex-start" justifyContent="flex-start">
        {(availableRoles as Role[]).map(({name, label, comment}) => (
          <Checkbox key={name} value={name} size="md">
            <Box>
              {label}
              <Box fontSize="sm" color="gray.500">
                {comment}
              </Box>
            </Box>
          </Checkbox>
        ))}
      </VStack>
    </CheckboxGroup>
  );
};
