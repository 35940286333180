import React, {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import {
  Alert,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Stack,
  Container,
  Badge,
  AlertDescription,
  AlertIcon
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import invariant from 'tiny-invariant';

import {useApp, useLocale} from 'shared';
import {Button, Heading1, RoundBackButton, Spinner} from 'components/core';
import {AccessLevelPicker, RolePicker} from './user-fields';

type EditableUserState = Partial<
  Pick<Datastore.User, 'accessLevel' | 'roles' | 'adminComments' | 'tagRestrictions'>
>;
export const UserEditor = () => {
  const app = useApp();
  const locale = useLocale();
  const history = useHistory();
  const {userId} = useParams<{userId: string}>();
  const {data: user, error, isLoading, refetch} = useFetchUser(userId);
  const [changes, setChanges] = useState<EditableUserState>({});

  if (isLoading) return <Spinner />;
  if (error) return <Alert status="error">Unable to fetch user's data</Alert>;
  invariant(user);

  const showList = () => {
    history.push('/users');
  };
  const save = async () => {
    const done = await app.task(async () => {
      // It looks redundant but we must call ensureUser() to automatically create users in related products
      await app.ensureUser({email: user.account.email, accessLevel: user.accessLevel, ...changes});
      await app.updateUser({userId: user.id, changes});
    });

    if (done) {
      await refetch();
      setChanges({});
    }
  };
  return (
    <Container
      display="flex"
      flex={1}
      flexDirection="column"
      minHeight={0}
      maxW={700}
      as="form"
      onSubmit={(event) => {
        event.preventDefault();
        save();
      }}
    >
      <Flex align="center" flexShrink={0}>
        <RoundBackButton onClick={showList} aria-label="Back" mr={2} />
        <Heading1 m={0}>
          {locale.todo('User')}
          {user.account.type === 'BOT' && (
            <Badge ml={2} fontSize="lg">
              Bot
            </Badge>
          )}
        </Heading1>
      </Flex>

      {user.account.type === 'BOT' && (
        <Alert status="info" mt={2} borderRadius="md">
          <AlertIcon />
          <AlertDescription>
            This account is used by the TCGA Image Transfer process, do not remove!
          </AlertDescription>
        </Alert>
      )}

      <Box overflowY="scroll" my={3} borderWidth="1px" p={6} borderRadius="lg" bg="cardBg">
        <UserForm originalUser={user} changes={changes} onChange={setChanges} />
      </Box>
      <Flex flexShrink={0}>
        <Button primary type="submit" isDisabled={isEmpty(changes) || app.state.isBusy}>
          {locale.todo('Save')}
        </Button>
      </Flex>
    </Container>
  );
};

const UserForm = ({
  originalUser,
  changes,
  onChange
}: {
  originalUser: Datastore.User;
  changes: EditableUserState;
  onChange: (changes: EditableUserState) => void;
}) => {
  const locale = useLocale();
  const user = {...originalUser, ...changes};

  const {accessLevel, roles, tagRestrictions, adminComments} = user;
  const handleChange = (field) => (value) => onChange({...changes, [field]: value});

  return (
    <Stack spacing={4}>
      <FormControl id="email">
        <FormLabel>{locale.todo(`Email`)}</FormLabel>
        <Input type="email" value={user.account.email} readOnly />
      </FormControl>
      <FormControl id="firstName">
        <FormLabel>{locale.todo(`First name`)}</FormLabel>
        <Input value={user.account.firstName} readOnly />
      </FormControl>
      <FormControl id="lastName">
        <FormLabel>{locale.todo(`Last name`)}</FormLabel>
        <Input value={user.account.lastName} readOnly />
      </FormControl>
      <FormControl id="organization">
        <FormLabel>{locale.todo(`Organization`)}</FormLabel>
        <Input value={user.account.organization} readOnly />
      </FormControl>
      <FormControl id="department">
        <FormLabel>{locale.todo(`Department`)}</FormLabel>
        <Input value={user.account.department} readOnly />
      </FormControl>
      <FormControl id="accessLevel">
        <FormLabel>{locale.todo('Access level')}</FormLabel>
        <AccessLevelPicker accessLevel={accessLevel} onChange={handleChange('accessLevel')} />
      </FormControl>
      <FormControl id="roles">
        <FormLabel>{locale.todo('Roles')}</FormLabel>
        <RolePicker roles={roles} onChange={handleChange('roles')} />
      </FormControl>
      <FormControl id="tagRestrictions">
        <FormLabel>{locale.todo('Tag restrictions')}</FormLabel>
        <Input
          value={tagRestrictions || ''}
          onChange={(event) => handleChange('tagRestrictions')(event.target.value)}
        />
      </FormControl>
      <FormControl id="adminComments">
        <FormLabel>{locale.todo('Admin comments')}</FormLabel>
        <Textarea
          value={adminComments || ''}
          onChange={(event) => handleChange('adminComments')(event.target.value)}
        />
      </FormControl>
    </Stack>
  );
};

export function useFetchUser(userId) {
  const app = useApp();

  const loadUser = async () => {
    const user: Datastore.User = await app.getUser({userId});
    return user;
  };

  return useQuery(['user', userId], loadUser);
}
