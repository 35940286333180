import {
  Input,
  RadioGroup,
  Radio,
  HStack,
  InputGroup,
  InputRightElement,
  Box,
  Grid,
  FormControl,
  FormLabel
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import {HexColorPicker} from 'react-colorful';

import {AILabel} from 'components/settings/types';

type DisplayNameProps = {
  value: AILabel['displayName'];
  onChange: (value: AILabel['displayName']) => void;
};
export const LabelDisplayName = ({value, onChange}: DisplayNameProps) => {
  return <Input value={value} onChange={(event) => onChange(event.target.value)} />;
};

type TypeProps = {
  value: AILabel['type'];
  onChange: (value: AILabel['type']) => void;
};
export const LabelType = ({value, onChange}: TypeProps) => {
  return (
    <RadioGroup
      value={value}
      onChange={(updatedValue) => onChange(updatedValue as AILabel['type'])}
    >
      <HStack>
        <Radio value="abnormal-tissue">Abnormal tissue</Radio>
        <Radio value="normal-tissue">Normal tissue</Radio>
      </HStack>
    </RadioGroup>
  );
};

type ColorProps = {
  value: AILabel['color'];
  onChange: (value: AILabel['color']) => void;
};
export const LabelColor = ({value, onChange}: ColorProps) => {
  return (
    <Grid templateColumns="1fr 1fr" gap={4} w="100%">
      <Box>
        <FormControl id="color">
          <FormLabel>Color</FormLabel>
          <InputGroup w={140}>
            <Input
              value={value}
              onChange={(event) => onChange(event.target.value)}
              placeholder="#ff0000"
            />
            <InputRightElement>
              <Box borderWidth="1px" boxSize="20px" bg={value} />
            </InputRightElement>
          </InputGroup>
        </FormControl>
      </Box>
      <Box pt={3}>
        <ColorPickerContainer>
          <HexColorPicker color={value} onChange={onChange} />
        </ColorPickerContainer>
      </Box>
    </Grid>
  );
};

const ColorPickerContainer = styled.div`
  .react-colorful {
    width: 100%;
    height: 60px;
  }
  .react-colorful__pointer {
    width: 16px;
    height: 16px;
  }
`;
