import debugModule from 'debug';
import {Actions, Model, IJsonModel, IJsonTabSetNode, IJsonTabNode} from 'flexlayout-react';
import {useLatest, useLocalStorage} from 'react-use';
import {createContainer} from 'unstated-next';
import invariant from 'tiny-invariant';

import {truncateFilename} from 'models/image';

const debug = debugModule('medmain:layout');
const MAX_NUMBER_OF_TAB = 5;

export type SplitLayoutComponentKey = 'scan-list' | 'scan-view' | 'scan-edit';

type ImageTab = IJsonTabNode; // TODO add more data to Image tab nodes?

export function useTabLayout() {
  const defaultImagePaneWidthRadio = 0.4;
  const [config, setConfig] = useLocalStorage<IJsonModel>(
    'datastore-layout-0.40',
    getDefaultJson(defaultImagePaneWidthRadio)
  );
  const latestJson = useLatest(config);

  // When the user makes changes like switching or closing tabs or resizing the panes, we want to save the changes
  const onChangeModel = (updatedModel: Model) => {
    debug('The layout has changed, save the config');
    setConfig(updatedModel.toJson());
  };

  const onImageClick = (scan: Datastore.Scan) => {
    const imageId = scan.imageId || scan.image.id; // TODO: the API should be consistent? (Details API does not have `imageId`)
    const node = createTabNode(imageId, scan.image.filename, 'scan-view');
    addTab(imageId, node);
  };

  const onEditClick = (scan: Datastore.Scan) => {
    const node = createTabNode(scan.id, scan.image.filename, 'scan-edit');
    addTab(scan.id, node);
  };

  function createTabNode(
    id: string,
    filename: string,
    component: SplitLayoutComponentKey
  ): IJsonTabNode {
    return {
      type: 'tab',
      id,
      name: truncateFilename(filename),
      component
    };
  }

  // TODO the best would be to use only Actions to interact with the layout
  // but I couldn't find a way to create a tab when the tabSet is destroyed
  // (after the user closes all tabs)
  function addTab(id: string, node: IJsonTabNode) {
    let json = latestJson.current;
    invariant(json);
    const model = Model.fromJson(json);
    if (model.getNodeById(id)) {
      model.doAction(Actions.selectTab(id));
      setConfig(model.toJson());
      debug('Image tab selected, save the layout config');
    } else {
      updateImages(json, (tabs) => [...tabs, node]);
      keepOnlyLatestImages(json, MAX_NUMBER_OF_TAB);
      selectLastTab(json);
      debug('Image added, save the layout config');
      setConfig(json);
    }
  }

  const closeTab = (id: string) => {
    model.doAction(Actions.deleteTab(id));
    setConfig(model.toJson());
  };

  invariant(config);
  const model = Model.fromJson(config);
  return {model, onChangeModel, onImageClick, onEditClick, closeTab};
}

export const TabLayoutContainer = createContainer(useTabLayout);

// Not great but the following functions mutate the `json` configuration object
function keepOnlyLatestImages(json: IJsonModel, limit: number) {
  updateImages(json, (tabs) => tabs.slice(-limit));
}

function selectLastTab(json: IJsonModel) {
  return updateTabSet(json, (tabSet) => {
    const index = tabSet.children.length - 1;
    return {...tabSet, selected: index};
  });
}

function updateImages(json: IJsonModel, updater: (tabs: ImageTab[]) => ImageTab[]) {
  ensureImageTabSetExists(json);
  json.layout.children[1].children = updater(json.layout.children[1].children as ImageTab[]);
}

function updateTabSet(json: IJsonModel, updater: (tabSet: IJsonTabSetNode) => IJsonTabSetNode) {
  ensureImageTabSetExists(json);
  json.layout.children[1] = updater(json.layout.children[1] as IJsonTabSetNode);
}

function ensureImageTabSetExists(json: IJsonModel) {
  if (!json.layout.children[1]) {
    json.layout.children.push({type: 'tabset', id: 'preview-pane', weight: 0.4, children: []});
  }
}

function getDefaultJson(viewPaneWidthRatio: number) {
  const json: IJsonModel = {
    global: {
      enableEdgeDock: false,
      borderEnableDrop: false,
      tabSetEnableDivide: false
    },
    borders: [],
    layout: {
      type: 'row',
      weight: 1,
      id: 'root',
      children: [
        {
          type: 'tabset',
          id: 'main',
          enableDrop: false,
          enableDivide: false,
          weight: viewPaneWidthRatio ? 1 - viewPaneWidthRatio : 1,
          enableTabStrip: false, // don't show any tab in this "tabSet" that is not really a tabSet, just a container for the scan list
          children: [{type: 'tab', id: 'nav', component: 'scan-list'}]
        }
      ]
    }
  };
  return json;
}
