import {Button, Box, Flex, useToast} from '@chakra-ui/react';

import {POLICIES, useApp, useLocale} from 'shared';
import {useModal} from 'components/core';
import {PDFViewer} from './pdf-viewer';

type Props = {scan: Datastore.Scan; refetch: () => void};
export const ScannedDiagnosis = ({scan, refetch}: Props) => {
  const locale = useLocale();
  const modal = useModal();
  const toast = useToast();
  const app = useApp();

  const user = app.state.user;
  const canRemove = POLICIES['scan/pdf/delete'](user);

  // Very cheap implementation of the remove feature, without a loading state :(
  // The whole `scanned diagnosis` feature is supposed to be temporary
  // as we should handle attachments in a more generic way
  const removeReport = async () => {
    const ok = await modal.confirm('Are you sure you want to remove the PDF report?', {
      title: 'PDF report',
      okButton: 'Delete'
    });
    if (!ok) return;
    const accessToken = app.getAccessToken();
    const backend = await app.getBackend();
    try {
      await backend.deleteReportLink({scanId: scan.id, accessToken});
      toast({title: 'PDF report updated', description: scan.image.filename, isClosable: true});
      refetch();
    } catch (error) {
      modal.alert('Sorry, we were not able to remove the PDF, contact the support in Osaka', {
        title: 'Failure'
      });
    }
  };

  return (
    <>
      {scan.hasScannedDiagnosis ? (
        <>
          <Flex mb={4} w="100%" justifyContent="space-between" alignItems="center">
            <Box>
              {scan.image.filename} / {scan.reference}
            </Box>
            <Box>
              {canRemove && (
                <Button onClick={removeReport} colorScheme="red" size="sm">
                  Remove the PDF document
                </Button>
              )}
            </Box>
          </Flex>
          <PDFViewer url={scan.scannedDiagnosisURL!} height="1040" />
        </>
      ) : (
        <Flex
          align="center"
          flex={1}
          justify="center"
          py="20rem"
          color="gray.500"
          borderWidth="1px"
        >
          {locale.todo('No scanned diagnosis.')}
        </Flex>
      )}
    </>
  );
};
