import {
  Badge,
  BadgeProps,
  Box,
  BoxProps,
  Flex,
  Icon,
  HStack,
  Stack,
  StackProps
} from '@chakra-ui/react';
import {MdCancel, MdCheckCircle, MdHourglassEmpty} from 'react-icons/md';

import {AIDataContainer, getAILabelByName} from 'components/settings/ai-labels-api';
import {AILabel} from 'components/settings/types';
import {EvaluationConfusionResource, EvaluationDetailResponse} from 'medrive-types';
import React from 'react';

export const EvaluationSummary = ({data, size}: {data: EvaluationDetailResponse; size: string}) => {
  const scores = data?.scores;
  if (!scores) {
    return null;
  }
  return (
    <Flex alignItems="center">
      <Divider ml={1} />
      <Box fontSize={size}>{data.dataset.organ.name}</Box>
      <Divider />
      <HStack>
        {Object.keys(scores).map((label) => (
          <TrueLabel key={label} labelName={label} />
        ))}
      </HStack>
    </Flex>
  );
};

const Divider = (props: BoxProps) => (
  <Box mx={3} w="1px" h="24px" borderLeftWidth="1px" {...props} />
);

export const TrueLabel = ({labelName, ...props}: {labelName: string} & BadgeProps) => {
  return (
    <LookupLabel labelName={labelName}>
      {(label) => <TrueLabelBadge {...props}>{label!.displayName}</TrueLabelBadge>}
    </LookupLabel>
  );
};

export const TrueLabelBadge = (props: BadgeProps) => {
  return (
    <Badge
      variant="outline"
      fontSize="16px"
      textTransform="none"
      fontWeight="normal"
      color="gray.600"
      borderRadius="sm"
      {...props}
    />
  );
};

export const LookupLabel = ({
  labelName,
  children
}: {
  labelName: string;
  children: (label?: AILabel) => JSX.Element;
}) => {
  const {data} = AIDataContainer.useContainer();
  if (!data) return null;
  const {labels} = data;

  const label = getAILabelByName(labels, labelName);

  return children(label);
};

type PredictedLabelType = 't' | 'f';

type PredictedLabelProps = {
  type: PredictedLabelType;
} & BadgeProps;
export const PredictedLabelBadge = ({type, ...props}: PredictedLabelProps) => {
  return (
    <Badge
      variant="subtle"
      fontSize="14px"
      textTransform="none"
      fontWeight="normal"
      colorScheme={type === 't' ? 'green' : 'red'}
      {...props}
    />
  );
};

export const PredictedLabelGroup = ({
  predictedLabels,
  ...props
}: {
  predictedLabels: PredictedLabel[];
} & StackProps) => {
  if (!predictedLabels)
    return (
      <Box color="gray.500">
        <i>No predicted label</i>
      </Box>
    );
  return (
    <Stack {...props}>
      {predictedLabels.map((predictedLabel) => {
        return (
          <LookupLabel labelName={predictedLabel.predLabel} key={predictedLabel.predLabel}>
            {(label) => (
              <PredictedLabelBadge key={label!.name} type={predictedLabel.type}>
                {label!.displayName}
              </PredictedLabelBadge>
            )}
          </LookupLabel>
        );
      })}
    </Stack>
  );
};

type PredictedLabel = {
  predLabel: string;
  type: PredictedLabelType;
};

export function aggregateConfusionListGroupByScanId(confusionList: EvaluationConfusionResource[]) {
  if (confusionList === null) {
    return {};
  }

  const ret = {};
  confusionList.forEach((confusion) => {
    confusion.scanIds.forEach((scanId) => {
      if (ret[scanId] === undefined) {
        ret[scanId] = [];
      }

      ret[scanId].push({
        predLabel: confusion.predLabel,
        type: confusion.type
      });
    });
  });

  return ret;
}

// To be used in both list and details page when we have data
export const EvaluationStatusIcon = ({status}: {status: string}) => {
  switch (status) {
    case 'Succeeded':
      return <Icon as={MdCheckCircle} color="green.500" fontSize="20px" />;
    case 'Running':
      return <Icon as={MdHourglassEmpty} color="pink.500" fontSize="20px" />;
    case 'Failed':
    case 'Error':
      return <Icon as={MdCancel} color="red.500" fontSize="20px" />;
    default:
      return <>?</>;
  }
};
