import React from 'react';
import {useLocation} from 'react-router-dom';
import {Box, ButtonGroup, Flex, useToast} from '@chakra-ui/react';

import {ItemSelection, parseSearchParams, useApp, useLocale} from 'shared';
import {Button, useModal} from 'components/core';
import {ScanQuery} from 'models/scan-query';
import {DialogReject} from './dialog-quality-control';
import {RejectedIcon, VerifiedIcon} from './quality-control-shared';

export const useQualityControlActions = ({selection, setSelection, reloadScans}) => {
  const app = useApp();
  const locale = useLocale();
  const modal = useModal();
  const location = useLocation();
  const toast = useToast();

  const searchParams = parseSearchParams(location.search);
  const query = ScanQuery.fromJSON(searchParams.query);

  async function rejectScans() {
    const input = await modal.dialog({
      render: (close) => <DialogReject selection={selection} onClose={close} />
    });
    if (!input) return;
    const {rejectionReason} = input;

    try {
      const backend = await app.getBackend();
      const accessToken = app.getAccessToken();
      const {count} = await backend.qualityControlRejectScans({
        query,
        selection,
        rejectionReason,
        accessToken
      });
      setSelection(new ItemSelection());
      toast({
        title: 'Quality control',
        description: `${count} scan(s) rejected`,
        isClosable: true
      });
      reloadScans();
    } catch (error) {
      modal.alert(locale.errorDialogMessage, {title: locale.errorDialogTitle});
    }
  }

  async function verifyScans(options?: {onClose: () => void}) {
    const {onClose} = options || {};
    try {
      const backend = await app.getBackend();
      const accessToken = app.getAccessToken();
      const {count} = await backend.qualityControlApproveScans({query, selection, accessToken});
      setSelection(new ItemSelection());
      if (onClose) onClose();
      toast({
        title: 'Quality control',
        description: `${count} scan(s) verified`,
        isClosable: true
      });
      reloadScans();
    } catch (error) {
      modal.alert(locale.errorDialogMessage, {title: locale.errorDialogTitle});
    }
  }

  return {
    verifyScans,
    rejectScans
  };
};

export const VerificationButtons = ({selection, setSelection, reloadScans}) => {
  const {verifyScans, rejectScans} = useQualityControlActions({
    selection,
    setSelection,
    reloadScans
  });
  return (
    <Flex align="center">
      <ButtonGroup isDisabled={selection.isEmpty()}>
        <Button onClick={() => verifyScans()} colorScheme="green" leftIcon={<VerifiedIcon />}>
          Verify
        </Button>
        <Button onClick={rejectScans} colorScheme="red" leftIcon={<RejectedIcon />}>
          Reject
        </Button>
      </ButtonGroup>
      {selection.isEmpty() && (
        <Box ml={4} color="gray.500">
          <i>(No scan selected)</i>
        </Box>
      )}
    </Flex>
  );
};
