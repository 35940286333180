import {ITabRenderValues, Layout, TabNode} from 'flexlayout-react';
import {MdImage} from 'react-icons/md';

import {SplitLayoutComponentKey, TabLayoutContainer} from './tab-layout-container';
import {ScanViewPane} from './scan-view-pane';
import {ScanEditPane} from './scan-edit-pane';
import {EditIcon, useToggleKeyHandlers} from 'components/core';

import './flexlayout-react.css'; // customized, we don't use `import 'flexlayout-react/style/light.css'`

type Props = {
  onRefresh?: () => void; // a callback to use when editing a scan in order to refresh list data
  children: React.ReactNode;
};
export const ScanSplitLayout = ({children, onRefresh}: Props) => {
  const {model, onChangeModel} = TabLayoutContainer.useContainer();
  const toggle = useToggleKeyHandlers();

  const factory = (node: TabNode) => {
    const component = node.getComponent() as SplitLayoutComponentKey;
    const id = node.getId();
    switch (component) {
      case 'scan-list':
        return children;
      case 'scan-view':
        node.setEventListener('visibility', ({visible}) => {
          toggle(id, visible);
        });
        return <ScanViewPane imageId={id} onSave={onRefresh} />;
      case 'scan-edit':
        return <ScanEditPane scanId={id} onSave={onRefresh} />;
      default:
        throw new Error('Component not implemented: ' + component);
    }
  };

  const onRenderTab = (node: TabNode, renderValues: ITabRenderValues) => {
    const component = node.getComponent() as SplitLayoutComponentKey;
    switch (component) {
      case 'scan-view':
        renderValues.leading = <MdImage />;
        break;
      case 'scan-edit':
        renderValues.leading = <EditIcon />;
        break;
      default:
        break;
    }
  };

  return (
    <Layout
      model={model}
      factory={factory}
      onModelChange={onChangeModel}
      onRenderTab={onRenderTab}
    />
  );
};
