import {useState} from 'react';

import {Query} from 'models/query';

// Custom hook to handle `Query` object originally from `@medmain/core` package
// we need to perform a re-render every time the `Query` object is mutated
export function useSearchQueryForm(query: Query) {
  const [values, setValues] = useState({}); // this state is only used to trigger re-renders, its value does not really matter

  const setValue = (fieldName, value) => {
    setValues({...values, [fieldName]: value});
    query.setValue(fieldName, value);
  };

  const getValue = (fieldName) => {
    return query.getValue(fieldName) || ''; // set "" instead of `undefined` to avoid `A component is changing an uncontrolled input of type undefined to be controlled` warnings
  };

  const setExpression = (fieldName, {operator, value}) => {
    setValues({...values, [fieldName]: value});
    return query.setExpression(fieldName, {operator, value});
  };

  const deleteExpression = (fieldName) => {
    setValues({...values, [fieldName]: ''});
    return query.deleteExpression(fieldName);
  };

  const clear = () => {
    setValues({});
    query.clear();
  };

  return {
    getValue,
    setValue,
    getExpression: query.getExpression,
    setExpression,
    deleteExpression,
    clear
  };
}
