import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Alert,
  Badge,
  Box,
  Flex,
  Heading,
  HStack,
  Spinner as ChakraSpinner
} from '@chakra-ui/react';
import {AddIcon, Button, PageContainer, Spinner, useModal} from 'components/core';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';

import {
  LabelCounts,
  LabelStatistics,
  LabelWithCounts,
  useFetchAILabels,
  useFetchAILabelsStatistics
} from 'components/settings/ai-labels-api';
import {SettingsNavigator} from 'components/settings/settings-shared';
import {AILabel} from 'components/settings/types';
import {LabelList} from './ai-label-list';
import {DialogCreateAILabel, DialogDeleteAILabel, DialogEditAILabel} from './dialog-ai-label';
import {usePermissions} from 'shared';

export const AILabelsPage = () => {
  const modal = useModal();
  const {data, error, isLoading} = useFetchAILabels();
  const {hasPermission} = usePermissions();
  const statsQuery = useFetchAILabelsStatistics();

  if (isLoading) return <Spinner />;

  if (error || !data?.labels.length) {
    return <Alert status="error">Unable to load the AI labels</Alert>;
  }

  const {labels} = data;

  const annotationLabels = orderBy(mergeLabelData(labels, statsQuery.data), 'model.displayName');
  const total = annotationLabels.length;
  const labelGroups = groupBy(annotationLabels, 'modelName');

  const addLabel = async () => {
    const input = await modal.dialog({
      render: (close) => <DialogCreateAILabel onClose={close} />,
      modalProps: {size: 'xl'}
    });
    if (!input) return;
  };

  const canAddLabel = hasPermission('settings/edit');

  return (
    <PageContainer maxW="container.xl">
      <SettingsNavigator currentTab={'labels'} />
      <Flex justifyContent="space-between">
        <Box>
          <Heading mb={4} fontSize="xl">
            AI labels
            <Badge colorScheme="gray" variant="solid" ml={1} fontSize="0.8rem">
              {total}
            </Badge>
          </Heading>
        </Box>
        <Box>
          {statsQuery.isLoading && (
            <HStack pr={4}>
              <Box color="gray.500">
                <i>Loading the statistics...</i>
              </Box>
              <ChakraSpinner size="sm" color="gray.500" />
            </HStack>
          )}
        </Box>
      </Flex>
      <Box overflowY="scroll">
        <LabelGroups labelGroups={labelGroups} isLoadingStatistics={statsQuery.isLoading} />
      </Box>
      {canAddLabel && (
        <Box py={4}>
          <Button onClick={addLabel} primary leftIcon={<AddIcon />}>
            Add label
          </Button>
        </Box>
      )}
    </PageContainer>
  );
};

const LabelGroups = ({
  labelGroups,
  isLoadingStatistics
}: {
  labelGroups: Record<string, AILabel[]>;
  isLoadingStatistics: boolean;
}) => {
  const modal = useModal();
  const {hasPermission} = usePermissions();

  const onEdit = async (labelName) => {
    const input = await modal.dialog({
      render: (close) => <DialogEditAILabel labelName={labelName} onClose={close} />,
      modalProps: {size: 'xl'}
    });
    if (!input) return;
  };

  const onDelete = async (labelName) => {
    const input = await modal.dialog({
      render: (close) => <DialogDeleteAILabel labelName={labelName} onClose={close} />
    });
    if (!input) return;
  };

  const canEditLabel = hasPermission('settings/edit');
  const canDeleteLabel = hasPermission('settings/edit');

  return (
    <Accordion allowMultiple>
      {Object.keys(labelGroups).map((key) => {
        const labels = orderBy(labelGroups[key], 'displayName', 'asc');
        const labelGroupName = labels[0].model.displayName;
        const count = labels.length;
        return (
          <AccordionItem key={key}>
            <AccordionButton>
              <Flex flex="1" textAlign="left" alignItems="center">
                {labelGroupName}
                {count > 0 && <Badge ml={2}>{count}</Badge>}
              </Flex>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <LabelList
                labels={labels}
                onEdit={canEditLabel ? onEdit : undefined}
                onDelete={canDeleteLabel ? onDelete : undefined}
                isLoadingStatistics={isLoadingStatistics}
              />
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

function mergeLabelData(labels: AILabel[], data?: LabelStatistics): LabelWithCounts[] {
  return labels.map((label) => {
    const counts: LabelCounts | undefined = data?.[label.name] || undefined;
    return {
      ...label,
      ...counts
    };
  });
}
